/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react';

export function KillSwitchTooltip(): ReactElement {
	return (
		<>
			Running experiments is temporarily disallowed.
			<br />
			Allow them on the right to run this experiment
		</>
	);
}
