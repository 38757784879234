/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import React, { ReactElement } from 'react';

import { Text, TextProps } from './Text';
import { Tooltip } from '../Tooltip';

export type ShortenedTextProps = { tooltip?: React.ReactNode; noWrap?: boolean } & TextProps;

export function ShortenedText({ children, tooltip, noWrap = false, sx, ...props }: ShortenedTextProps): ReactElement {
	return (
		<Tooltip onlyShowOnEllipsis content={tooltip ?? children}>
			<Text
				{...props}
				sx={{
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: noWrap ? 'nowrap' : undefined,
					...sx,
				}}
			>
				{children}
			</Text>
		</Tooltip>
	);
}
