/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { Stack, Text, Tooltip } from 'components';
import { ReactElement, ReactNode } from 'react';

interface KeyValueProps {
	k: string;
	v: ReactNode;
	withTooltip?: boolean;
}

export default function KeyValue({ k, v, withTooltip }: KeyValueProps): ReactElement {
	const renderedValue =
		typeof v === 'boolean' ? String(v) : typeof v === 'object' ? (Array.isArray(v) ? v.join(', ') : '---') : v;
	return (
		<Stack size="xxSmall">
			<Text variant="small" color="neutral600">
				{k}:
			</Text>
			<Tooltip content={withTooltip ? renderedValue : undefined} onlyShowOnEllipsis>
				<Text variant="smallStrong" sx={{ ...textEllipsis }}>
					{renderedValue}
				</Text>
			</Tooltip>
		</Stack>
	);
}
