/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	Container,
	LoadingIndicator,
	ModalContentV2,
	ModalFooterV2,
	ModalHeaderV2,
	ModalV2,
	Snackbar,
	Stack,
	Text,
} from 'components';
import { ActionPermissions } from 'pages/settings/teams/components/actionPermissions';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { useRefreshIntercept } from 'utils/hooks/useRefreshIntercept';
import { Flex } from '@steadybit/ui-components-lib';
import { usePromise } from 'utils/hooks/usePromise';
import { matchPath, Prompt } from 'url/router';
import { ValidationError } from 'utils/error';
import { Services } from 'services/services';
import React, { ReactElement } from 'react';
import { useHistory } from 'url/hooks';
import { Helmet } from 'react-helmet';
import { ensure } from 'utils/ensure';
import { TeamVO } from 'ui-api';

import { EnvironmentPermissions } from './components/environmentPermissions';
import TeamInformation from './components/teamInformation';
import { TeamFormValues } from './components/teamTypes';
import { TeamMembers } from './components/teamMembers';

function EditTeam({ id, close }: { id: string; close: () => void }): ReactElement {
	const history = useHistory();
	const [submitError, setSubmitError] = React.useState<Error | null>();
	const teamResult = usePromise(() => Services.teams.fetchTeam(id), [id]);

	const team: TeamVO | undefined = teamResult.value;
	if (teamResult.loading || !team) {
		return (
			<ModalV2 withFooter width="90vw">
				<ModalHeaderV2 title="Edit Team" withShadow onClose={close} />
				<ModalContentV2>
					<Flex align="center" justify="center" style={{ paddingTop: '200px', paddingBottom: '200px' }}>
						<LoadingIndicator variant="xxLarge" />
					</Flex>
				</ModalContentV2>
			</ModalV2>
		);
	}

	return (
		<Formik<TeamFormValues>
			initialValues={team}
			onSubmit={async ({ ...values }: TeamFormValues, form: FormikHelpers<TeamFormValues>): Promise<void> => {
				try {
					setSubmitError(null);
					const canEditTeam = Services.permissionsApi.getTeamPermissions(team).canEdit;
					if (canEditTeam) {
						await Services.teams.updateTeam(id, { version: ensure(team).version, ...values });
					}
					if (team._actions.includes('manage-members')) {
						await Services.teams.setTeamMembers(id, { members: values.members });
					}
					Snackbar.dark('Team saved.', { toastId: 'team-saved' });
					history.replace('/settings/teams');
				} catch (error) {
					if (error instanceof ValidationError) {
						error.violations.forEach((violation) => form.setFieldError(violation.field, violation.message));
					} else {
						setSubmitError(error);
					}
				}
			}}
			validate={async (values) => {
				const errors: { [name: string]: string } = {};
				const usernames = values.members.map((m) => m.username);
				const violations = await Services.teams.validateTeamMembers(usernames, id);
				violations.forEach(({ field, message }) => (errors[field] = message));
				return errors;
			}}
		>
			<Form noValidate>
				<ModalV2 withFooter width="90vw">
					<ModalHeaderV2 title={`Edit Team ${team.name}`} onClose={close} withShadow />
					<ModalContentV2>
						<EditTeamForm team={team} />
					</ModalContentV2>
					<Footer submitError={submitError} />
				</ModalV2>
			</Form>
		</Formik>
	);
}

interface EditTeamFormProps {
	team: TeamVO;
}

const EditTeamForm = ({ team }: EditTeamFormProps): ReactElement => {
	const getTeamPermissions = Services.permissionsApi.getTeamPermissions(team);
	const teamCanBeManaged = getTeamPermissions.canManageMembers;
	const teamCanBeEdit = getTeamPermissions.canEdit;
	const isAdminTeam = team?.key === 'ADM';

	return (
		<>
			<Helmet>
				<title>Settings / Teams / {team.name}</title>
			</Helmet>

			<UnsavedChangesPrompt />

			<Stack size={'xLarge'} py="large">
				<TeamInformation keyDisabled disabled={!teamCanBeEdit} isAdminTeam={isAdminTeam} />
				<Container>
					<Text variant={'mediumStrong'} mb={'xSmall'}>
						Members
					</Text>
					<TeamMembers teamId={team.id} disabled={!teamCanBeManaged} />
				</Container>

				<EnvironmentPermissions
					key="environment-permissions"
					addEnvironmentEnabled={true}
					disabled={!teamCanBeEdit}
					disabledExplanation={
						isAdminTeam ? (
							<>
								You can&apos;t change environments for the Administrator team, as it always has access to all
								environments.
							</>
						) : undefined
					}
				/>
				<ActionPermissions
					key="action-permissions"
					readonly={!teamCanBeEdit}
					readonlyExplanation={
						isAdminTeam ? (
							<>
								You can&apos;t change allowed actions for the Administrator team, as it always has access to all
								actions.
							</>
						) : undefined
					}
				/>
			</Stack>
		</>
	);
};

export default EditTeam;

function UnsavedChangesPrompt(): ReactElement {
	const { isSubmitting, dirty } = useFormikContext<TeamFormValues>();
	const history = useHistory();

	useRefreshIntercept(dirty);
	return (
		<Prompt
			when={dirty && !isSubmitting}
			message={(location) => {
				return matchPath(location.pathname, { path: history.location.pathname })
					? true
					: 'You have unsaved changes, do you really want to quit?';
			}}
		/>
	);
}

function Footer({ submitError }: { submitError: Error | null | undefined }): ReactElement {
	const { isSubmitting, dirty, errors } = useFormikContext<TeamFormValues>();

	return (
		<ModalFooterV2 withShadow>
			<>
				{submitError && (
					<Container width="100%">
						<Text variant="mediumStrong" color="coral">
							{submitError.toString()}
						</Text>
					</Container>
				)}
				<Button id={'save'} type={'submit'} disabled={!dirty || Object.keys(errors).length > 0} loading={isSubmitting}>
					Save
				</Button>
			</>
		</ModalFooterV2>
	);
}
