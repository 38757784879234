/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import axios, { AxiosRequestConfig } from 'axios';

interface RetryConfig {
	current: number;
}

declare module 'axios' {
	export interface AxiosRequestConfig {
		retry?: RetryConfig;
	}
}

const MAX_RETRIES = 3;
const TOO_MANY_REQUESTS = 429;

export const setupAxiosRetryInterceptor = (): void => {
	axios.interceptors.response.use(
		(response) => response,
		(error) => {
			if (error.config && error.response?.status === TOO_MANY_REQUESTS) {
				if (!error.config.retry) {
					error.config.retry = { current: 0 };
				}
				const retry = error.config.retry;
				retry.current = retry.current + 1;

				if (retry.current > MAX_RETRIES) {
					return Promise.reject(error);
				}

				// Backend always returns backoff in seconds and not a concrete time.
				const retryAfter = Number(
					error.response?.headers['Retry-After'] || error.response?.headers['retry-after'] || 0,
				);

				if (!Number.isNaN(retryAfter)) {
					return new Promise((resolve) =>
						setTimeout(
							() => {
								resolve(axios.request(error.config));
							},
							retryAfter * 1000 * retry.current,
						),
					);
				}
			}
			return Promise.reject(error);
		},
	);
};
