/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	Container,
	ModalContentV2,
	ModalFooterV2,
	ModalHeaderV2,
	ModalV2,
	Spinner,
	Stack,
	Text,
} from 'components';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { Form, Formik, FormikHelpers } from 'formik';
import { useTenant } from 'tenancy/useTenant';
import { CreateWebhookRequest } from 'ui-api';
import { ValidationError } from 'utils/error';
import { Services } from 'services/services';
import React, { ReactElement } from 'react';
import { isUserAdmin } from 'utils/user';

import { WebhookForm, WebhookFormValues } from './components/webhookForm';

const INITIAL_VALUES: WebhookFormValues = {
	name: '',
	url: 'https://',
	team: '',
	events: ['*'],
	scope: 'GLOBAL',
	secret: undefined,
	targetAttributeIncludes: '*',
	channel: undefined,
	iconUrl: undefined,
};

const getInitialValues = async (isAdmin: boolean): Promise<WebhookFormValues> => {
	if (isAdmin) {
		return { ...INITIAL_VALUES };
	} else {
		const team = await Services.teams.findInitialTeam();
		return { ...INITIAL_VALUES, team: team?.id ?? '', scope: 'TEAM' };
	}
};

interface AddWebhookProps {
	labelTitle?: string;
	type: string;
	onClose?: () => void;
}
function AddWebhook({ labelTitle, type, onClose }: AddWebhookProps): ReactElement {
	const tenant = useTenant();
	const isAdmin = isUserAdmin(tenant.user);
	const [submitError, setSubmitError] = React.useState<Error | null>();
	const [initialValues] = useAsyncState(() => getInitialValues(isAdmin), [isAdmin, type]);

	const handleSubmit = async (values: WebhookFormValues, form: FormikHelpers<CreateWebhookRequest>): Promise<void> => {
		try {
			setSubmitError(null);
			await Services.webhooks.createWebhook({ ...values, type });
			onClose?.();
		} catch (error) {
			if (error instanceof ValidationError) {
				error.violations.forEach((violation) => form.setFieldError(violation.field, violation.message));
			} else {
				setSubmitError(error);
			}
		}
	};

	return (
		<>
			{initialValues.loading ? (
				<Stack>
					<Spinner variant="large" color={'neutral500'} mr={'auto'} />
				</Stack>
			) : null}
			{!initialValues.loading && initialValues.value ? (
				<Formik initialValues={{ ...initialValues.value, type }} onSubmit={handleSubmit} validateOnChange>
					{({ isSubmitting }) => (
						<Form>
							<ModalV2 withFooter>
								<ModalHeaderV2 title={labelTitle || 'Add Webhook'} onClose={onClose} />
								<ModalContentV2>
									<WebhookForm type={type} />
								</ModalContentV2>
								<ModalFooterV2>
									<>
										{submitError && <Container width="100%">{submitError.toString()}</Container>}
										{submitError && (
											<Container width="100%">
												<Text variant="mediumStrong" color="coral">
													{submitError.toString()}
												</Text>
											</Container>
										)}
										<Button id={'save'} type={'submit'} loading={isSubmitting}>
											Save
										</Button>
									</>
								</ModalFooterV2>
							</ModalV2>
						</Form>
					)}
				</Formik>
			) : null}
		</>
	);
}

export default AddWebhook;
