/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	DragDropContext,
	DraggableStateSnapshot,
	DraggingStyle,
	DropResult,
	NotDraggingStyle,
} from 'react-beautiful-dnd';
import { ActionVO, BaseExperimentStepVOUnion, ExperimentLaneVO, TemplateVO, VariableVO } from 'ui-api';
import { UseTemplateFormData } from 'pages/templates/UseTemplateModal/UseTemplateFormLoadingHandler';
import UseTemplateActionsChecker from 'pages/templates/UseTemplateModal/UseTemplateActionsChecker';
import NavigationWithContent from 'pages/templates/UseTemplateModal/pages/NavigationWithContent';
import LoadingTemplateModal from 'pages/templates/UseTemplateModal/LoadingTemplateModal';
import { createContext, ReactElement, ReactNode, useContext, useState } from 'react';
import { ModalContentV2, ModalHeaderV2, ModalOverlay, ModalV2 } from 'components';
import { usePromise } from 'utils/hooks/usePromise';
import { useUrlState } from 'url/useUrlState';
import { Services } from 'services/services';
import { useField } from 'formik';

import { createExperimentRequestFromTemplate, reassignNewStepIds } from '../templates/UseTemplateModal/utils';
import { instantiateAction, instantiateWait } from './actionHelper';
import { selectedStepIdParam, UrlState } from './urlParams';
import { EditorSettingsContext } from './useEditorSettings';
import useActions from './useActions';
import { filterEmpty } from './utils';

interface DragAndDropHandlerProps {
	children: ReactNode | ReactNode[];
}

export const WorkspaceLaneFalloffCatcherMaker = 'workspace-lane-falloff-catcher-';
export const SidebarTemplateMaker = 'sidebar-template-';
export const SearchbarActionMaker = 'searchbar-action-';
export const WorkspaceLanesMaker = 'workspace-lanes';
export const WorkspaceLaneMaker = 'workspace-lane-';
export const WorkspaceStepMaker = 'workspace-step-';
export const SidebarActionMaker = 'sidebar-action-';

interface DragAndDropState {
	isDraggingTemplate: boolean;
}

interface TemplateCreationProps {
	templateId: string;
	beforeLanes: boolean;
}

const DragAndDropContext = createContext<DragAndDropState>({ isDraggingTemplate: false });
export const useDragState = (): DragAndDropState => {
	return useContext(DragAndDropContext);
};

export default function DragAndDropHandler({ children }: DragAndDropHandlerProps): ReactElement {
	const [lanesField, , { setValue: setLanes, setTouched }] = useField<ExperimentLaneVO[]>({ name: 'lanes' });
	const [, , updateUrlState] = useUrlState<UrlState>([selectedStepIdParam]);
	const { value: lanes } = lanesField;
	const { actions: availableActions } = useActions();

	const [draggingState, setDraggingState] = useState<DragAndDropState>({ isDraggingTemplate: false });
	const [templateConfigCreate, setTemplateConfigCreate] = useState<TemplateCreationProps | null>(null);

	async function onDragEnd(result: DropResult): Promise<void> {
		setDraggingState(() => ({ isDraggingTemplate: false }));

		if (!result.destination) {
			return;
		}

		// switching lanes
		if (result.draggableId.startsWith(WorkspaceLaneMaker) && result.destination.droppableId === WorkspaceLanesMaker) {
			const newLanes = moveLane(lanes, result.source.index, result.destination.index);
			setLanes(assignIds(newLanes));
			setTouched(true);
			return;
		}

		// dragging in a template from the sidebar
		if (
			result.draggableId.startsWith(SidebarTemplateMaker) &&
			result.destination.droppableId.startsWith(WorkspaceLaneFalloffCatcherMaker) // it's only allowed to drag templates into the fall-off catcher
		) {
			const destinationDropId = result.destination.droppableId.substring(WorkspaceLaneMaker.length);
			setTemplateConfigCreate({
				templateId: result.draggableId.substring(SidebarTemplateMaker.length),
				beforeLanes: destinationDropId === 'falloff-catcher-above',
			});
			return;
		}

		// dragging from sidebar to workspace
		if (
			(result.draggableId.startsWith(SidebarActionMaker) || result.draggableId.startsWith(SearchbarActionMaker)) &&
			result.destination.droppableId.startsWith(WorkspaceLaneMaker)
		) {
			const actionId = result.draggableId.startsWith(SidebarActionMaker)
				? result.draggableId.substring(SidebarActionMaker.length)
				: result.draggableId.substring(SearchbarActionMaker.length);
			const newLanes = await createStep(actionId, lanes, result, availableActions, (selectedStepId) => {
				updateUrlState({ selectedStepId });
			});
			if (newLanes) {
				setLanes(assignIds(filterEmpty(newLanes)));
				setTouched(true);
			}
			return;
		}

		// dragging inside workspace
		if (
			result.draggableId.startsWith(WorkspaceStepMaker) &&
			result.destination.droppableId.startsWith(WorkspaceLaneMaker)
		) {
			const newLanes = swap(lanes, result);
			if (newLanes) {
				setLanes(assignIds(filterEmpty(newLanes)));
				setTouched(true);
			}
			return;
		}
	}

	return (
		<DragDropContext
			key="DragDropContext"
			onBeforeDragStart={(e) =>
				setDraggingState(() => ({ isDraggingTemplate: e.draggableId.startsWith(SidebarTemplateMaker) }))
			}
			onDragEnd={onDragEnd}
		>
			{templateConfigCreate && (
				<ModalOverlay open onClose={() => setTemplateConfigCreate(null)}>
					<TemplateQuickDropCheck
						templateId={templateConfigCreate.templateId}
						onClose={() => setTemplateConfigCreate(null)}
						onCreateLanes={(_lanes) => {
							reassignNewStepIds(_lanes);
							let newLanes = copy(lanes);
							if (templateConfigCreate.beforeLanes) {
								newLanes = [..._lanes, ...newLanes];
							} else {
								newLanes = [...newLanes, ..._lanes];
							}
							setLanes(assignIds(filterEmpty(newLanes)));
							setTemplateConfigCreate(null);
						}}
					/>
				</ModalOverlay>
			)}
			<DragAndDropContext.Provider value={draggingState}>{children}</DragAndDropContext.Provider>
		</DragDropContext>
	);
}

async function createStep(
	actionId: string,
	lanes: ExperimentLaneVO[],
	result: DropResult,
	availableActions: ActionVO[],
	setStepId: (id: string) => void,
): Promise<ExperimentLaneVO[] | null> {
	if (!result.destination) {
		return null;
	}

	let step;
	if (actionId === 'wait') {
		step = instantiateWait();
	} else {
		const action = availableActions.find((action) => action.id === actionId);
		if (!action) {
			console.error(`Action with id ${actionId} not found`);
			return null;
		}
		step = instantiateAction(action);
	}
	setStepId(step.id);

	const newLanes = copy(lanes);

	const destinationDropId = result.destination.droppableId.substring(WorkspaceLaneMaker.length);
	if (
		destinationDropId === 'above' ||
		destinationDropId === 'falloff-catcher-above' ||
		destinationDropId === 'mid' ||
		destinationDropId === 'falloff-catcher-mid'
	) {
		return [{ id: 'temp', steps: [step] }, ...newLanes];
	}

	if (destinationDropId === 'below' || destinationDropId === 'falloff-catcher-below') {
		return [...newLanes, { id: 'temp', steps: [step] }];
	}

	const toLaneIndex = newLanes.findIndex((lane) => lane.id === destinationDropId);
	const toIndex = result.destination.index;
	const toLane = newLanes[toLaneIndex];

	// insert step into destination lane
	toLane.steps.splice(toIndex, 0, step);

	return newLanes;
}

function swap(lanes: ExperimentLaneVO[], result: DropResult): ExperimentLaneVO[] | null {
	if (!result.destination) {
		return null;
	}

	const destinationDropId = result.destination.droppableId.substring(WorkspaceLaneMaker.length);
	const sourceDropId = result.source.droppableId.substring(WorkspaceLaneMaker.length);

	const newLanes = copy(lanes);
	const fromLaneIndex = lanes.findIndex((lane) => lane.id === sourceDropId);
	const fromIndex = result.source.index;
	const fromLane = newLanes[fromLaneIndex];

	const toLaneIndex = lanes.findIndex((lane) => lane.id === destinationDropId);
	const toIndex = result.destination.index;
	const toLane = newLanes[toLaneIndex];

	if (destinationDropId === 'above' || destinationDropId === 'falloff-catcher-above') {
		const step = removeStep(fromLane, fromIndex);
		return [{ id: 'temp', steps: [step] }, ...newLanes];
	}

	if (destinationDropId === 'below' || destinationDropId === 'falloff-catcher-below') {
		const step = removeStep(fromLane, fromIndex);
		return [...newLanes, { id: 'temp', steps: [step] }];
	}

	if (fromLaneIndex === toLaneIndex && fromIndex === toIndex) {
		return null;
	}

	// remove step from source lane...
	const step = removeStep(fromLane, fromIndex);
	// ...and insert step into destination lane
	toLane.steps.splice(toIndex, 0, step);

	return newLanes;
}

function moveLane(lanes: ExperimentLaneVO[], fromIndex: number, toIndex: number): ExperimentLaneVO[] {
	const newLanes = lanes.slice();
	const [lane] = newLanes.splice(fromIndex, 1);
	newLanes.splice(toIndex, 0, lane);
	return newLanes;
}

function assignIds(lanes: ExperimentLaneVO[]): ExperimentLaneVO[] {
	lanes.forEach((lane, i) => (lane.id = `${i}`));
	return lanes;
}

function copy(lanes: ExperimentLaneVO[]): ExperimentLaneVO[] {
	return lanes.map((lane) => ({ ...lane, steps: lane.steps.map((step) => ({ ...step })) }));
}

function removeStep(lane: ExperimentLaneVO, index: number): BaseExperimentStepVOUnion {
	const [step] = lane.steps.splice(index, 1);
	return step;
}

export function correctDropAnimation(
	snapshot: DraggableStateSnapshot,
	style?: DraggingStyle | NotDraggingStyle | undefined,
): DraggingStyle | NotDraggingStyle | undefined {
	if (!snapshot.isDropAnimating || !snapshot.dropAnimation) {
		return style;
	}

	if (snapshot.draggingOver?.startsWith(WorkspaceLaneFalloffCatcherMaker)) {
		const { moveTo } = snapshot.dropAnimation;

		let y = moveTo.y + 8;
		if (snapshot.draggingOver === `${WorkspaceLaneFalloffCatcherMaker}above`) {
			const domElement = document.getElementById(snapshot.draggingOver);
			const rect = domElement?.getBoundingClientRect();
			if (rect) {
				y += rect.height - 60;
			}
		} else if (snapshot.draggingOver === `${WorkspaceLaneFalloffCatcherMaker}mid`) {
			const domElement = document.getElementById(snapshot.draggingOver);
			const rect = domElement?.getBoundingClientRect();
			if (rect) {
				y += rect.height / 2 - 30;
			}
		}

		// patching the existing style
		return {
			...style,
			transform: `translate(${moveTo.x + 24}px, ${y}px)`,
			// cannot be 0, but make it super tiny
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			transitionDuration: '0.5s',
		};
	}
	return style;
}

export function correctLaneDropAnimation(
	snapshot: DraggableStateSnapshot,
	style?: DraggingStyle | NotDraggingStyle | undefined,
): DraggingStyle | NotDraggingStyle | undefined {
	if (!snapshot.isDropAnimating || !snapshot.dropAnimation) {
		return style;
	}

	if (snapshot.draggingOver === WorkspaceLanesMaker) {
		const { moveTo } = snapshot.dropAnimation;
		const y = moveTo.y + 30;

		// patching the existing style
		return {
			...style,
			transform: `translate(${moveTo.x}px, ${y}px)`,
			// cannot be 0, but make it super tiny
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			transitionDuration: '0.5s',
		};
	}
	return style;
}

interface TemplateQuickDropCheckProps {
	templateId: string;
	onCreateLanes: (lanes: ExperimentLaneVO[]) => void;
	onClose: () => void;
}

function TemplateQuickDropCheck({ templateId, onCreateLanes, onClose }: TemplateQuickDropCheckProps): ReactElement {
	const [, , { setValue: setEnvironmentVariables }] = useField<VariableVO[]>('variables');
	const [{ value: environmentId }] = useField('environmentId');

	const templateResult = usePromise(() => Services.templatesApi.getTemplate(templateId), [templateId]);
	const template = templateResult.value;

	if (!template) {
		return <LoadingTemplateModal onClose={onClose} />;
	}

	return (
		<TemplateFilloutForm
			template={template}
			onSubmit={async (v) => {
				const environmentVariables: VariableVO[] = Array.from(v.variableValuesMap.entries()).map(([k, v]) => ({
					key: k,
					value: v,
				}));
				await Services.environments.updateEnvironmentVariables(environmentId, {
					variables: environmentVariables,
				});
				// we must update this in the form immediately, so the ExperimentVariablesEnrichment gets up2date data
				setEnvironmentVariables(environmentVariables);

				const experiment = createExperimentRequestFromTemplate({
					formData: v,
					teamId: '',
				});
				onCreateLanes(experiment.lanes);
			}}
			onIsEmpty={(values) => onCreateLanes(values.__originalLanes)}
			onClose={onClose}
		/>
	);
}

interface TemplateFilloutFormProps {
	template: TemplateVO;
	onIsEmpty: (values: UseTemplateFormData) => void;
	onSubmit: (v: UseTemplateFormData) => void;
	onClose: () => void;
}

function TemplateFilloutForm({ template, onIsEmpty, onSubmit, onClose }: TemplateFilloutFormProps): ReactElement {
	const [{ value: environmentId }] = useField('environmentId');

	const userResult = usePromise(() => Services.users.getCurrentUser(), []);

	const { allActions } = useActions();
	const environmentResult = usePromise(() => Services.environments.fetchEnvironment(environmentId), [environmentId]);

	if (!userResult.value || !environmentResult.value) {
		return <LoadingTemplateModal onClose={onClose} />;
	}

	const environments = [
		{
			id: environmentId,
			name: environmentResult.value.name,

			// these are not used
			_actions: [],
			teams: [],
			predicate: '',
			global: false,
		},
	];

	return (
		<UseTemplateActionsChecker
			withExperimentHypothesisExtraction={false}
			withExperimentNameExtraction={false}
			environments={environments}
			isCreatedByAdvice={false}
			newExperimentTags={[]}
			actions={allActions}
			template={template}
			onIsEmpty={onIsEmpty}
			onSubmit={onSubmit}
			onClose={onClose}
		>
			<EditorSettingsContext.Provider value={{ mode: 'templateInExperiment' }}>
				<ModalV2 slick withFooter width="90vw" maxWidth="1650px">
					<ModalHeaderV2 title="Add template to the experiment" onClose={onClose} />
					<ModalContentV2>
						<NavigationWithContent
							selectedEnvironment={environments[0]}
							environments={environments}
							submitLabel="Add template steps"
						/>
					</ModalContentV2>
				</ModalV2>
			</EditorSettingsContext.Provider>
		</UseTemplateActionsChecker>
	);
}
