/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { autoPlacement, autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/react-dom';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import useObservable from 'react-use/lib/useObservable';
import { Services } from 'services/services';
import { useClickAway } from 'react-use';
import { theme } from 'styles.v2/theme';
import { Container } from 'components';
import { ReplaySubject } from 'rxjs';
import { debounce } from 'lodash';
import ReactDOM from 'react-dom';

import { IconClose, IconHelp } from '../icons';
import { ButtonIcon } from '../ButtonIcon';
import { Heading } from '../Heading';
import { Button } from '../Button';
import { Stack } from '../Stack';
import { Text } from '../Text';

export type TutorialTooltipProps = {
	id: string;
	title?: React.ReactNode;
	learnMore?: string;
	light?: boolean;
	hideIcon?: boolean;
	hideSkip?: boolean;
	hideCloseIcon?: boolean;
	showAlways?: boolean;
	children?: string | ReactElement;
};

const isGlobalVisible$ = new ReplaySubject(1);
const setIsGlobalVisible = debounce((b) => isGlobalVisible$.next(b), 400);

export function TutorialTooltip({
	children,
	id,
	showAlways,
	hideIcon,
	hideSkip,
	hideCloseIcon,
	title,
	learnMore,
	light = false,
}: TutorialTooltipProps): ReactElement {
	const { refs, floatingStyles } = useFloating({
		middleware: [autoPlacement({}), offset(4), shift(), flip()],
		whileElementsMounted: autoUpdate,
	});

	const isGlobalVisible = useObservable(isGlobalVisible$);
	const [isTriggered, setIsTriggered] = useState(false);
	const openTip = useMemo(
		() => () => {
			setIsTriggered(true);
			setIsGlobalVisible(true);
		},
		[],
	);
	const closeTip = useMemo(
		() => () => {
			setIsTriggered(false);
			setIsGlobalVisible(false);
		},
		[],
	);
	useEffect(() => {
		if ((!Services.users.isTutorialCompleted(id) && !Services.users.isTutorialSkipped()) || showAlways) {
			openTip();
		}
		if (!showAlways) {
			Services.users.addTutorialCompleted(id);
		}
	}, [id, openTip, showAlways]);

	useClickAway(refs.floating, closeTip);

	return (
		<>
			{hideIcon ? (
				<Container width={1} height="100%" ref={refs.setReference}></Container>
			) : (
				<ButtonIcon ref={refs.setReference} ml={'xSmall'} color={'neutral700'}>
					<IconHelp variant={'small'} onClick={isTriggered && isGlobalVisible ? closeTip : openTip} />
				</ButtonIcon>
			)}
			{isTriggered && isGlobalVisible
				? ReactDOM.createPortal(
						<Container
							ref={refs.setFloating}
							style={floatingStyles}
							sx={{
								display: 'flex',
								alignItems: 'flex-start',
								justifyContent: 'space-between',
								width: '400px',

								backgroundColor: light ? 'purple500' : 'slateLight',
								color: light ? 'neutral000' : 'neutral800',
								variant: light ? 'text.mediumMedium' : 'text.smallStrong',
								px: light ? 'small' : 'large',
								pt: light ? 'xSmall' : 'medium',
								pb: light ? 'xSmall' : 'small',
								zIndex: 200,
								borderRadius: light ? '4px' : 0,
							}}
						>
							<Stack size="xxSmall">
								{title && (
									<Heading variant={'small'} color={'neutral800'} mb={'xSmall'}>
										{title}
									</Heading>
								)}

								<Text>{children}</Text>
								{!hideSkip && (
									<Stack direction={'horizontal'} alignItems={'center'} mt={'small'}>
										{learnMore ? (
											<Button onClick={() => window.open(learnMore)} variant={'secondary'} mt={'xxSmall'}>
												Learn more
											</Button>
										) : null}
										{Services.users.isTutorialSkipped() ? null : (
											<Text
												variant="smallStrong"
												color="slate"
												sx={{
													cursor: 'pointer',
													':hover': {
														textDecoration: 'underline',
													},
												}}
												onClick={() => {
													Services.users.skipTutorial();
													closeTip();
												}}
											>
												Skip tutorials
											</Text>
										)}
									</Stack>
								)}
							</Stack>

							{!hideCloseIcon && (
								<Container
									color={light ? 'neutral000' : 'neutral600'}
									ml="xSmall"
									sx={{
										':hover': {
											cursor: 'pointer',
											textDecoration: 'none',
											color: 'neutral700',
											bg:
												'linear-gradient(180deg, ' +
												theme.colors.neutral000 +
												' 0%, ' +
												theme.colors.neutral100 +
												' 100%)',
										},
									}}
									variant={'small'}
									onClick={(e) => {
										e.stopPropagation();
										closeTip();
									}}
								>
									<IconClose />
								</Container>
							)}
						</Container>,
						document.body,
					)
				: null}
		</>
	);
}
