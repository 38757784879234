/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { ReactElement, useState } from 'react';
import { Container, Stack } from 'components';

import StripValues from './StripValues';
import StripLabel from './StripLabel';

// Please also update the docs if you change the colors: https://github.com/steadybit/action-kit/blob/main/docs/widgets.md#configuration
export const colors = {
	success: '#4ab3ae',
	danger: 'repeating-linear-gradient(-45deg, #f4857d 0px, #f4857d 6px, #ed5d50 6px, #ed5d50 8px, #f4857d 8px)',
	warn: 'repeating-linear-gradient(-45deg, #F9D34C 0px, #F9D34C 6px, #F99F4C 6px, #F99F4C 8px, #F9D34C 8px)',
	info: 'repeating-linear-gradient(-45deg, #d5d8e0 0px, #d5d8e0 6px, #cbced9 6px, #cbced9 8px, #d5d8e0 8px)',
};

export interface DataPoint {
	timestamp: number;
	endTimestamp?: number;
	color?: keyof typeof colors;
	values: Record<string, number>;
	url?: string;
	tooltip?: string;
}

export interface StripType {
	id?: string;
	label: string | ReactElement;
	longLabel?: string;
	descriptor?: string;
	dataPoints: DataPoint[];
}

interface StripChartProps {
	getTooltipContent: (strip: StripType, dataPoint: DataPoint) => ReactElement;
	getColor?: (dataPoint: DataPoint) => string;
	fromTimestamp: number;
	toTimestamp: number;
	strips: StripType[];
}

export default function StripChart({
	strips,
	getColor,
	getTooltipContent,
	fromTimestamp,
	toTimestamp,
}: StripChartProps): ReactElement {
	const [hoveredStripId, setHoveredStripId] = useState<number | null>(null);
	return (
		<Container display="flex" alignItems="flex-start" width="100%">
			<Stack size="xxSmall" width={150} maxWidth={150} mr="small">
				{strips.map((strip, i) => {
					const { id, label, longLabel, descriptor, dataPoints } = strip;
					const url = dataPoints[0]?.url;
					return <StripLabel key={id ?? i} label={label} tooltip={longLabel} descriptor={descriptor} url={url} />;
				})}
			</Stack>
			<Stack size="none" flexGrow={1}>
				<Stack size="xxSmall">
					{strips.map((strip, i) => (
						<StripValues
							key={strip.id ?? i}
							muted={!strip.label}
							isHovered={hoveredStripId == null ? true : hoveredStripId === i}
							onMouseEnter={() => setHoveredStripId(i)}
							onMouseLeave={() => setHoveredStripId(null)}
							getTooltipContent={(dataPoint) => getTooltipContent(strip, dataPoint)}
							dataPoints={strip.dataPoints}
							from={fromTimestamp}
							to={toTimestamp}
							getColor={getColor}
						/>
					))}
				</Stack>
			</Stack>
		</Container>
	);
}
