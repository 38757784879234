/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Banner,
	Button,
	ModalContentV2,
	ModalFooterV2,
	ModalHeaderV2,
	ModalOverlay,
	ModalV2,
	Snackbar,
	Stack,
} from 'components';
import { templateImportConflictConfirm } from 'templates/importConflictQuestion';
import { HubVO, TemplateSummaryVO } from 'ui-api';
import { IconArrowLeft } from 'components/icons';
import { ReactElement, useState } from 'react';
import { Services } from 'services/services';

import ChooseTemplatesFromHub from './ChooseTemplatesFromHub';
import FromScratch from './FromScratch';
import FromImport from './FromImport';
import FromUpload from './FromUpload';
import ChooseHub from './ChooseHub';

interface CreateTemplateModalProps {
	onClose: () => void;
}

export default function CreateTemplateModal({ onClose }: CreateTemplateModalProps): ReactElement {
	const [uploadError, setUploadError] = useState<string | null>(null);
	const [selectedContent, setSelectedContent] = useState<'start' | 'importFromHub'>('start');
	const [selectedHub, setSelectedHub] = useState<HubVO | null>(null);
	const [selectedTemplates, setSelectedTemplates] = useState<TemplateSummaryVO[]>([]);

	return (
		<ModalOverlay open centerContent onClose={onClose}>
			{selectedContent === 'start' ? (
				<ModalV2 width="800px" slick centered>
					<ModalHeaderV2 title="Create or Import Template" onClose={onClose} />
					<ModalContentV2>
						<Stack>
							<Stack direction="horizontal" size="large" justifyContent="center" mt="large">
								<FromScratch />
								<FromUpload setUploadError={setUploadError} />
								<FromImport onClick={() => setSelectedContent('importFromHub')} />
							</Stack>
							{uploadError && (
								<Banner
									variant="danger"
									title=""
									description={
										<span style={{ whiteSpace: 'normal' }}>
											{uploadError.split('\n').map((line) => (
												<>
													{line} <br />
												</>
											))}
										</span>
									}
								/>
							)}
						</Stack>
					</ModalContentV2>
				</ModalV2>
			) : selectedHub ? (
				<ModalV2 width="1050px" slick centered withFooter>
					<ModalHeaderV2 title="2. Select Templates" onClose={onClose} />
					<ModalContentV2>
						<ChooseTemplatesFromHub
							hub={selectedHub}
							selectedTemplates={selectedTemplates}
							setSelectedTemplates={setSelectedTemplates}
						/>
					</ModalContentV2>
					<ModalFooterV2>
						<Stack direction="horizontal" justifyContent="space-between" width="100%" px="small">
							<Button variant="secondary" onClick={() => setSelectedHub(null)}>
								<IconArrowLeft ml="-xxSmall" mr="xSmall" />
								Back
							</Button>

							<Button
								disabled={selectedTemplates.length === 0}
								onClick={async () => {
									try {
										await Services.templatesApi.importTemplatesFromHub(
											{
												hubId: selectedHub.id,
												templateIds: selectedTemplates.map((t) => t.id),
											},
											false,
										);
										onClose();
									} catch (err) {
										if (err?.response.status === 409) {
											if (await templateImportConflictConfirm(selectedTemplates.length)) {
												await Services.templatesApi.importTemplatesFromHub(
													{
														hubId: selectedHub.id,
														templateIds: selectedTemplates.map((t) => t.id),
													},
													true,
												);
												onClose();
											}
										} else {
											Snackbar.error('Templates could not be imported', {
												toastId: 'file-upload-error',
											});
											onClose();
										}
									}
								}}
							>
								Import Selection
							</Button>
						</Stack>
					</ModalFooterV2>
				</ModalV2>
			) : (
				<ChooseHub onClose={onClose} selectHub={setSelectedHub} />
			)}
		</ModalOverlay>
	);
}
