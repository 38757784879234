/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { ContainerProps } from 'components';
import { ReactElement } from 'react';

import PaginationComponent from './PaginationComponent';

type PaginationOwnProps = {
	size?: 'small' | 'medium';
	totalPages?: number;
	activePage?: number;
	onClick?: (pageNo: number) => void;
};

export type PaginationProps = PaginationOwnProps & Omit<ContainerProps, keyof PaginationOwnProps | 'to'>;

export function Pagination(props: PaginationProps): ReactElement {
	return <PaginationComponent {...props} />;
}
