/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, Colors, Dropdown, Grid, presets, Text } from '@steadybit/ui-components-lib';
import { ExecutionLog, RawExecutionLog } from 'hocs/executionLog';
import { ReactElement, useMemo, useState } from 'react';
import { AsyncState } from 'utils/hooks/useAsyncState';
import { flatMapSteps } from 'services/experimentApi';
import { flatMap, orderBy, uniqBy } from 'lodash';
import { ExperimentExecutionVO } from 'ui-api';
import { Checkbox, Stack } from 'components';

import useTrackingExperimentExecutionViewed from './hooks/useTrackingExperimentExecutionViewed';

type ExperimentExecutionAgentLogsProps = AsyncState<ExperimentExecutionVO | undefined>;
export function ExperimentExecutionAgentLogs({ value }: ExperimentExecutionAgentLogsProps): ReactElement {
	const logOptions = useMemo(() => {
		if (!value) {
			return [];
		}
		const options = flatMapSteps(value.lanes, (step) => {
			switch (step.type) {
				case 'action':
					return flatMap(step.targetExecutions, (targetExecution) => ({
						value: targetExecution.agentId,
						label: targetExecution.agentHostname,
					}));
				default:
					return [];
			}
		});

		return orderBy(uniqBy(options, 'value'), ['label'], ['asc']);
	}, [value]);

	useTrackingExperimentExecutionViewed(value, 'agent_log');
	const [logSelection, setLogSelection] = useState(logOptions.map((o) => o.value));
	return (
		<>
			<Stack
				direction={'horizontal'}
				justifyContent="space-between"
				size={'small'}
				pr={'medium'}
				py={'xSmall'}
				sx={{ boxShadow: 'applicationSmall', zIndex: 11, bg: 'neutral000' }}
			>
				{value && logOptions.length > 0 ? (
					<SelectAgentLogDropdown
						executionId={value.id}
						options={logOptions}
						value={logSelection}
						onChange={(option) => setLogSelection(option || null)}
					/>
				) : null}
			</Stack>
			{value ? (
				<ExecutionLog
					executionId={value.id}
					type={'AGENT'}
					id={logSelection}
					agentNames={logOptions}
					liveUpdate={!value.ended}
				/>
			) : null}
		</>
	);
}

type SelectAgentLogDropdownProps = {
	options: { label: string; value: string }[];
	executionId: number;
	value: string[];
	onChange: (v: string[]) => void;
};
function SelectAgentLogDropdown({ options, value, executionId, onChange }: SelectAgentLogDropdownProps): ReactElement {
	return (
		<Dropdown
			placement="bottom-start"
			renderDropdownContent={({ width }) => (
				<presets.dropdown.DropdownContentFrame minWidth={width} maxWidth="400px">
					{options.map((option) => (
						<Grid
							key={option.value}
							cols="28px 1fr auto"
							style={{
								py: 'xSmall',
								px: 'xSmall',
							}}
						>
							<Checkbox
								mr={'medium'}
								checked={value.includes(option.value)}
								onChange={(e) =>
									onChange(e.target.checked ? [...value, option.value] : value.filter((v) => v !== option.value))
								}
							/>
							<Text type="smallStrong">{option.label}</Text>
							<RawExecutionLog
								sx={{ ml: 'auto', fontSize: 1 }}
								executionId={executionId}
								id={option.value}
								type={'AGENT'}
							/>
						</Grid>
					))}
				</presets.dropdown.DropdownContentFrame>
			)}
		>
			{({ setRefElement, isOpen, setOpen }) => {
				return (
					<Button
						ref={setRefElement}
						type="secondary"
						withRightIcon={isOpen ? 'arrow-drop-up' : 'arrow-drop-down'}
						style={{
							marginLeft: '2px',
							minWidth: '250px',
							outline: isOpen ? '2px solid ' + Colors.slate : undefined,
						}}
						onClick={() => setOpen(!isOpen)}
					>
						{`Showing ${value.length} of ${options.length} agent logs`}
					</Button>
				);
			}}
		</Dropdown>
	);
}
