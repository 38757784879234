/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react';

import {
	IconComponentProps,
	IconTeam,
	IconTeam1,
	IconTeam10,
	IconTeam11,
	IconTeam12,
	IconTeam2,
	IconTeam3,
	IconTeam4,
	IconTeam5,
	IconTeam6,
	IconTeam7,
	IconTeam8,
	IconTeam9,
} from './index';

interface TeamLogoIconProps extends IconComponentProps {
	logoId?: string;
}

export const TeamLogoColors = [
	'rgb(255, 153, 113)',
	'rgb(116, 193, 255)',
	'rgb(255, 135, 135)',
	'rgb(150, 169, 255)',
	'rgb(82, 200, 197)',
	'rgb(252, 159, 252)',
	'rgb(255, 200, 104)',
	'rgb(121, 224, 150)',
	'rgb(255, 142, 189)',
	'rgb(171, 158, 255)',
	'rgb(100, 223, 188)',
];

export function TeamLogoIcon({ logoId, ...props }: TeamLogoIconProps): ReactElement {
	const iconProps = { ...props };
	switch (logoId) {
		case '1':
			return <IconTeam {...iconProps} />;
		case '2':
			return <IconTeam1 {...iconProps} />;
		case '3':
			return <IconTeam2 {...iconProps} />;
		case '4':
			return <IconTeam3 {...iconProps} />;
		case '5':
			return <IconTeam4 {...iconProps} />;
		case '6':
			return <IconTeam5 {...iconProps} />;
		case '7':
			return <IconTeam6 {...iconProps} />;
		case '8':
			return <IconTeam7 {...iconProps} />;
		case '9':
			return <IconTeam8 {...iconProps} />;
		case '10':
			return <IconTeam9 {...iconProps} />;
		case '11':
			return <IconTeam10 {...iconProps} />;
		case '12':
			return <IconTeam11 {...iconProps} />;
		case '13':
			return <IconTeam12 {...iconProps} />;
		default:
			return <IconTeam {...iconProps} />;
	}
}
