/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ChunkLUT, Dropdown, presets, TextInput } from '@steadybit/ui-components-lib';
import { CSSProperties, ReactElement } from 'react';

import VariablesAndPlaceholders from './VariablesAndPlaceholders';
import useChunkLUT from './useChunkLUT';

interface TextProps {
	placeholder?: string;
	hasErrors?: boolean;
	disabled: boolean;
	value: string;
	setValue: (value: string) => void;
}

export default function Text({ value = '', setValue, ...props }: TextProps): ReactElement {
	return <Input value={value} onChange={setValue} {...props} />;
}

interface InputProps {
	placeholder?: string;
	hasErrors?: boolean;
	sx?: CSSProperties;
	disabled: boolean;
	value: string;
	onChange: (value: string) => void;
	onFocus?: () => void;
	onBlur?: () => void;
}

export function Input({
	placeholder,
	hasErrors,
	disabled,
	value,
	onChange,
	onFocus,
	onBlur,
}: InputProps): ReactElement {
	const chunkLUT: ChunkLUT = useChunkLUT();

	return (
		<Dropdown
			renderDropdownContent={({ width, close }) => (
				<presets.dropdown.DropdownContentFrame width={width}>
					<VariablesAndPlaceholders
						width={width}
						selectItem={(v) => {
							onChange(v);
							close();
						}}
					/>
				</presets.dropdown.DropdownContentFrame>
			)}
		>
			{({ setRefElement, isOpen, setOpen }) => (
				<TextInput
					ref={setRefElement}
					highlightedChunkLUT={chunkLUT}
					placeholder={placeholder}
					highlighted={isOpen}
					errored={hasErrors}
					disabled={disabled}
					value={value}
					onChange={(v) => {
						if (v !== value) {
							onChange(v);
						}
					}}
					onFocus={() => {
						setOpen(true);
						onFocus?.();
					}}
					onBlur={onBlur}
					data-cy="text-field-with-highlighting"
				/>
			)}
		</Dropdown>
	);
}
