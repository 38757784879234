/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Colors, Flex, Text } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';
import { useField } from 'formik';

import ChunksWithPlaceholders from '../ChunksWithPlaceholders';

export default function ExperimentNameField(): ReactElement {
	const [{ value: experimentName }] = useField<string>('__originalExperimentName');

	return (
		<Flex direction="horizontal">
			<Text type="mediumStrong" style={{ textWrap: 'nowrap', minWidth: '140px', color: Colors.neutral800 }}>
				Experiment name:
			</Text>
			<ChunksWithPlaceholders value={experimentName} />
		</Flex>
	);
}
