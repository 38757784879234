/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Grid } from '@steadybit/ui-components-lib';
import { EnvironmentSummaryVO } from 'ui-api';
import { ReactElement } from 'react';
import { Formik } from 'formik';

import SidebarPredicateModule from './SidebarPredicateModule';
import SidebarWrapper from './SidebarWrapper';
import { EnvironmentConfig } from './types';
import ResultView from './ResultView';

interface ReadOnlyEnvironmentDetailsProps {
	environment: EnvironmentSummaryVO;
}

export default function ReadOnlyEnvironmentDetails({ environment }: ReadOnlyEnvironmentDetailsProps): ReactElement {
	return (
		<Formik<EnvironmentConfig>
			initialValues={{
				name: environment.name,
				predicate: environment.predicate,
				teamIds: environment.teams.map((team) => team.id),
			}}
			onSubmit={() => {}}
		>
			<Grid cols="512px 1fr" style={{ height: '100%', pb: 'large' }}>
				<SidebarWrapper>
					<SidebarPredicateModule readonly isGlobalEnvironment={environment.global} />
				</SidebarWrapper>

				<ResultView />
			</Grid>
		</Formik>
	);
}
