/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import TemplatePlaceholder from 'pages/templates/components/TemplatePlaceholder';
import { useEditorSettings } from 'pages/experimentsV2/useEditorSettings';
import { TemplatePlaceholderVO, VariableVO } from 'ui-api';
import Variables from 'components/Variables/Variables';
import { Flex } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';
import { Divider } from 'components';
import { useField } from 'formik';

import AddPlaceholdersButton from './AddPlaceholdersButton';
import AddVariablesButton from './AddVariablesButton';

interface VariablesAndPlaceholdersProps {
	width: string | number | undefined;
	selectItem: (variableOrPlaceholder: string) => void;
}

export default function VariablesAndPlaceholders({
	width,
	selectItem,
}: VariablesAndPlaceholdersProps): ReactElement | null {
	const { mode } = useEditorSettings();

	const [{ value: experimentVariables = [] }] = useField<VariableVO[]>('experimentVariables');
	const [{ value: placeholders = [] }] = useField<TemplatePlaceholderVO[]>('placeholders');
	const [{ value: environmentVariables = [] }] = useField<VariableVO[]>('variables');

	const hasVariables = experimentVariables.length > 0 || environmentVariables.length > 0;
	const hasPlaceholders = placeholders.length > 0;

	if (!hasVariables && !hasPlaceholders && mode === 'templateUsage') {
		return null;
	}
	if (mode === undefined) {
		return null;
	}

	const hasAnyItems = hasVariables || hasPlaceholders;

	return (
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: mode === 'templateEditor' ? '1fr 1fr' : '1fr',
				width,
			}}
		>
			{mode === 'templateEditor' && hasAnyItems && (
				<Flex spacing="xSmall" style={{ p: 'xSmall' }}>
					<TemplatePlaceholder
						placeholders={placeholders}
						onClick={(placeholder) => selectItem(`[[${placeholder.key}]]`)}
					/>
				</Flex>
			)}

			{hasAnyItems && (
				<Flex spacing="xSmall" style={{ p: 'xSmall' }}>
					<Variables
						scope="experiment"
						variables={experimentVariables}
						onVariableClick={(key) => selectItem(`{{${key}}}`)}
					/>
					<Variables
						scope="environment"
						variables={environmentVariables.filter(
							(envVar) => !experimentVariables.some((expVar) => expVar.key === envVar.key),
						)}
						onVariableClick={(key) => selectItem(`{{${key}}}`)}
					/>
				</Flex>
			)}

			{mode !== 'templateUsage' && (
				<>
					{hasAnyItems && <Divider style={{ gridColumn: '1/3' }} />}

					{mode === 'templateEditor' && <AddPlaceholdersButton onPlaceholderSelected={selectItem} />}
					<AddVariablesButton onVariableSelected={selectItem} />
				</>
			)}
		</div>
	);
}
