/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Link as ReactRouterLink, LinkProps as ReactRouterLinkProps } from 'url/Link';
import { ReactElement } from 'react';

import { Link, LinkProps } from './Link';

export type RouterLinkProps = LinkProps & ReactRouterLinkProps<never>;

export function RouterLink({ children, variant = 'primary', ...props }: RouterLinkProps): ReactElement {
	return (
		<Link as={ReactRouterLink} variant={variant} {...props}>
			{children}
		</Link>
	);
}
