/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { Container, Heading, SegmentedControl, StateBadge, Subnav, Text } from 'components';
import { formatDateWithTime } from 'utils/dateFns';
import { ExperimentExecutionVO } from 'ui-api';
import { ReactElement, useMemo } from 'react';
import { usePage } from 'utils/hooks/usePage';
import { useHistory } from 'url/hooks';

type SectionOption = {
	label: string;
	value: string;
};

const useExperimentRunSections = (
	experimentExecution?: ExperimentExecutionVO,
	active?: string,
): { options: SectionOption[]; value?: SectionOption } => {
	const options = useMemo(() => {
		if (!experimentExecution) {
			return [];
		}
		return [
			{
				label: 'Attack Monitor',
				value: 'attack',
			},
			{
				label: 'Agent Log',
				value: 'agent-log',
			},
			{
				label: 'Tracing',
				value: 'telemetry',
			},
		];
	}, [experimentExecution]);
	const value = useMemo(() => options.find((o) => o.value === active), [options, active]);
	return { options, value };
};

export function ExperimentExecutionSubHeader({
	experimentExecutionSection,
	experimentExecution,
}: {
	experimentExecution?: ExperimentExecutionVO;
	experimentExecutionSection?: string;
}): ReactElement {
	const sections = useExperimentRunSections(experimentExecution, experimentExecutionSection);
	const history = useHistory();
	const page = usePage('/experiments');

	if (experimentExecution) {
		return (
			<Subnav maxWidth={'100%'} height={96} sx={{ boxShadow: 'applicationSmall', gridArea: 'subheader' }}>
				<Container display={'flex'} width={'100%'} p={'medium'} alignItems={'center'} justifyContent={'space-between'}>
					<Container display={'flex'} alignItems={'flex-start'} justifyContent={'center'} flexDirection={'column'}>
						<Container display={'flex'} alignItems={'flex-start'} justifyContent={'center'} flexDirection={'row'}>
							<Heading as={'span'} variant={'large'} mr={'xSmall'}>
								#{experimentExecution.id}
							</Heading>
							<StateBadge as="tag" value={experimentExecution.state} />
						</Container>
						<Text
							as={'span'}
							mr={'xSmall'}
							variant={'medium'}
							noWrap
							sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
							data-private
						>
							{formatDateWithTime(experimentExecution.requested)} by {experimentExecution.createdBy.name}
						</Text>
					</Container>
					<Container display={'flex'} justifyContent={'flex-end'}>
						<SegmentedControl
							width={'auto'}
							name={'run-subnav'}
							variant={'small'}
							sx={{ whiteSpace: 'nowrap' }}
							onChange={(value) => history.push(page.toString(value.value))}
							{...sections}
						/>
					</Container>
				</Container>
			</Subnav>
		);
	}
	return <></>;
}
