/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Code } from 'components';
import React from 'react';

export interface GetSetupArg {
	agentKey: string | null;
	ingressUri: string;
	clusterName?: string;
	containerRuntime?: string;
	makeCopyButtonPrimary?: boolean;
	oauth2issuerUri?: string;
	oauth2audience?: string;
	oauth2clientId?: string;
	oauth2clientSecret?: string;
	onCopy?: () => void;
}

export const getAgentLinuxSetup = (arg: GetSetupArg): string =>
	`wget https://get.steadybit.com/agent-linux.sh
chmod a+x agent-linux.sh
./agent-linux.sh --platform-url ${arg.ingressUri} --key ${arg.agentKey ?? '********'}`;

export const getAgentLinuxAdditionalConfig = (arg: GetSetupArg): string =>
	`STEADYBIT_AGENT_AUTH_PROVIDER: OAUTH2
STEADYBIT_AGENT_AUTH_OAUTH2_AUDIENCE: <your-optional-oauth2-audience>
STEADYBIT_AGENT_AUTH_OAUTH2_ISSUER_URI: ${arg.oauth2issuerUri}
STEADYBIT_AGENT_AUTH_OAUTH2_CLIENT_ID: <your-oauth2-client-id>
STEADYBIT_AGENT_AUTH_OAUTH2_CLIENT_SECRET: <your-oauth2-client-secret>`;

export const getAgentDockerSetup = (arg: GetSetupArg): string =>
	`wget https://get.steadybit.com/agent.sh
chmod a+x agent.sh
./agent.sh --platform-url ${arg.ingressUri} --key ${arg.agentKey ?? '********'}`;

export const getAgentK8sHelmSetupText = (arg: GetSetupArg): string => {
	//IF YOU CHANGE SOMETHING, UPDATE ALSO getAgentK8sHelmSetup WHICH IS USED FOR COPYING THE SAME CONTENT TO THE CLIPBOARD!
	return `helm repo add steadybit https://steadybit.github.io/helm-charts
helm repo update
helm install steadybit-agent --namespace steadybit-agent \\
  --create-namespace \\
  --set agent.key=${arg.agentKey ?? '********'} \\
  --set global.clusterName=${arg.clusterName ? '"' + arg.clusterName + '"' : 'my-cluster-name'} \\${
		arg.containerRuntime
			? `
  --set extension-container.container.runtime=${arg.containerRuntime} \\`
			: ''
	}${
		arg.oauth2issuerUri
			? `
  --set agent.auth.provider=oauth2 \\
  --set agent.auth.oauth2.issuerUri=${arg.oauth2issuerUri} \\
  ${arg.oauth2audience ? `--set agent.auth.oauth2.audience=${arg.oauth2audience} \\` : ''}
  --set agent.auth.oauth2.clientId=${arg.oauth2clientId ? '"' + arg.oauth2clientId + '"' : 'my-oauth2-client-id'} \\
  --set agent.auth.oauth2.clientSecret.value=${arg.oauth2clientSecret ? '"' + arg.oauth2clientSecret + '"' : 'my-oauth2-client-secret'} \\`
			: ''
	}
  --set agent.registerUrl=${arg.ingressUri} \\
  steadybit/steadybit-agent`;
};

export const getAgentK8sHelmSetup = (arg: GetSetupArg): React.ReactNode => (
	//IF YOU CHANGE SOMETHING, UPDATE ALSO getAgentK8sHelmSetupText WHICH IS USED FOR COPYING THE SAME CONTENT TO THE CLIPBOARD!
	<>
		helm repo add steadybit https://steadybit.github.io/helm-charts
		<br />
		helm repo update
		<br />
		helm install steadybit-agent --namespace steadybit-agent \<br />
		{'  '}--create-namespace \<br />
		{'  '}--set agent.key={arg.agentKey ?? '********'} \<br />
		{'  '}--set global.clusterName=&quot;<Code highlight>{arg.clusterName ? arg.clusterName : 'my-cluster-name'}</Code>
		&quot; \
		<br />
		{arg.containerRuntime ? (
			<>
				{'  '}--set extension-container.container.runtime={arg.containerRuntime} \<br />
			</>
		) : null}
		{arg.oauth2issuerUri ? (
			<>
				{'  '}--set agent.auth.provider=oauth2 \<br />
				{'  '}--set agent.auth.oauth2.issuerUri={arg.oauth2issuerUri} \<br />
				{arg.oauth2audience ? (
					<>
						{'  '}--set agent.auth.oauth2.audience=<Code highlight>{arg.oauth2audience}</Code> \<br />
					</>
				) : null}
				{'  '}--set agent.auth.oauth2.clientId=
				<Code highlight>{arg.oauth2clientId ? arg.oauth2clientId : 'my-oauth2-client-id'}</Code> \<br />
				{'  '}--set agent.auth.oauth2.clientSecret.value=
				<Code highlight>{arg.oauth2clientSecret ? arg.oauth2clientSecret : 'my-oauth2-client-secret'}</Code> \<br />
			</>
		) : null}
		{'  '}--set agent.registerUrl={arg.ingressUri} \<br />
		{'  '}steadybit/steadybit-agent
	</>
);
