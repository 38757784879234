/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { BasicButtonProps } from 'components';
import { ReactElement } from 'react';

import { Tooltip } from '../Tooltip/Tooltip';
import { Button } from '../Button/Button';

export type sizes = 'large' | 'medium';
export type variants = 'primary' | 'secondary' | 'danger' | 'primaryClicking';

export interface ButtonRoundProps extends BasicButtonProps {
	tooltip?: string;
	size?: sizes;
	variant?: variants;
}

export const sizeToPixel = {
	large: 40,
	medium: 32,
};

export default function ButtonRound({
	children,
	size = 'large',
	sx = {},
	tooltip,
	variant = 'primary',
	...props
}: ButtonRoundProps): ReactElement {
	const button = (
		<Button
			tx={'buttonRound'}
			variant={variant}
			{...props}
			sx={{
				...sx,
				position: 'relative',
				minHeight: sizeToPixel[size],
				minWidth: sizeToPixel[size],
				maxHeight: sizeToPixel[size],
				maxWidth: sizeToPixel[size],
			}}
		>
			{children}
		</Button>
	);

	return tooltip ? <Tooltip content={tooltip}>{button}</Tooltip> : button;
}
