/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Container as ContainerComponent, ContainerProps } from 'components';
import { resolveStyleProp, resolveSx } from 'components/types';
import React, { ReactElement } from 'react';

import { ReactComponent as InformationCircleFilled } from './svg/icon-information-circle-filled.svg';
import { ReactComponent as TargetIndicatorAttack } from './svg/icon-target-indicator-attack.svg';
import { ReactComponent as TargetEnrichmentRule } from './svg/icon-target-enrichment-rules.svg';
import { ReactComponent as PolicyBindingConsole } from './svg/icon-policy-binding-console.svg';
import { ReactComponent as AdviceActionNeeded } from './svg/icon-advice-action-needed.svg';
import { ReactComponent as ChevronDoubleRight } from './svg/icon-chevron-double-right.svg';
import { ReactComponent as ChevronDoubleLeft } from './svg/icon-chevron-double-left.svg';
import { ReactComponent as RoundSpinnerWhite } from './svg/icon-round-spinner-white.svg';
import { ReactComponent as WarningCircleOpen } from './svg/icon-warning-circle-open.svg';
import { ReactComponent as AdviceImplemented } from './svg/icon-advice-implemented.svg';
import { ReactComponent as CalendarRecurrent } from './svg/icon-calendar-recurrent.svg';
import { ReactComponent as ContainerRegistry } from './svg/icon-container-registry.svg';
import { ReactComponent as EnvironmentGlobal } from './svg/icon-environment-global.svg';
import { ReactComponent as ExperimentBlocked } from './svg/icon-experiment-blocked.svg';
import { ReactComponent as InformationCircle } from './svg/icon-information-circle.svg';
import { ReactComponent as SteadybitApproved } from './svg/icon-steadybit-approved.svg';
import { ReactComponent as AdviceValidation } from './svg/icon-advice-validation.svg';
import { ReactComponent as ChevronBeginning } from './svg/icon-chevron-beginning.svg';
import { ReactComponent as ExperimentFailed } from './svg/icon-experiment-failed.svg';
import { ReactComponent as WebhookPreflight } from './svg/icon-webhook-preflight.svg';
import { ReactComponent as ExperimentError } from './svg/icon-experiment-error.svg';
import { ReactComponent as GoogleCloudPlatform } from './svg/icon-google-cloud.svg';
import { ReactComponent as ApiAccessToken } from './svg/icon-api-access-token.svg';
import { ReactComponent as CalendarPaused } from './svg/icon-calendar-paused.svg';
import { ReactComponent as ArrowDropDown } from './svg/icon-arrow-drop-down.svg';
import { ReactComponent as AdviceGeneral } from './svg/icon-advice-general.svg';
import { ReactComponent as AdviceUnknown } from './svg/icon-advice-unknown.svg';
import { ReactComponent as AlertTriangle } from './svg/icon-alert-triangle.svg';
import { ReactComponent as ArrowRight } from './svg/icon-arrow-right-large.svg';
import { ReactComponent as CalendarClock } from './svg/icon-calendar-clock.svg';
import { ReactComponent as WarningCircle } from './svg/icon-warning-circle.svg';
import { ReactComponent as Documentation } from './svg/icon-documentation.svg';
import { ReactComponent as ArrowLeft } from './svg/icon-arrow-left-large.svg';
import { ReactComponent as ChevronRight } from './svg/icon-chevron-right.svg';
import { ReactComponent as DiscoveryKit } from './svg/icon-discovery-kit.svg';
import { ReactComponent as RoundSpinner } from './svg/icon-round-spinner.svg';
import { ReactComponent as WarningSmall } from './svg/icon-warning-small.svg';
import { ReactComponent as ArrowDropUp } from './svg/icon-arrow-drop-up.svg';
import { ReactComponent as Distribution } from './svg/icon-distribution.svg';
import { ReactComponent as Integrations } from './svg/icon-integrations.svg';
import { ReactComponent as Notification } from './svg/icon-notification.svg';
import { ReactComponent as RunAgain } from './svg/icon-run-again-single.svg';
import { ReactComponent as BlastRadius } from './svg/icon-blast-radius.svg';
import { ReactComponent as ChevronDown } from './svg/icon-chevron-down.svg';
import { ReactComponent as ChevronLeft } from './svg/icon-chevron-left.svg';
import { ReactComponent as MetricCheck } from './svg/icon-metric-check.svg';
import { ReactComponent as MetricQuery } from './svg/icon-metric-query.svg';
import { ReactComponent as TemplateAdd } from './svg/icon-template-add.svg';
import { ReactComponent as WarningOpen } from './svg/icon-warning-open.svg';
import { ReactComponent as Description } from './svg/icon-description.svg';
import { ReactComponent as Environment } from './svg/icon-environment.svg';
import { ReactComponent as Information } from './svg/icon-information.svg';
import { ReactComponent as Maintenance } from './svg/icon-maintenance.svg';
import { ReactComponent as AttackTime } from './svg/icon-attack-time.svg';
import { ReactComponent as ChevronEnd } from './svg/icon-chevron-end.svg';
import { ReactComponent as InProgress } from './svg/icon-in-progress.svg';
import { ReactComponent as ContainerD } from './svg/icon-containerd.svg';
import { ReactComponent as Disconnect } from './svg/icon-disconnect.svg';
import { ReactComponent as Experiment } from './svg/icon-experiment.svg';
import { ReactComponent as Kubernetes } from './svg/icon-kubernetes.svg';
import { ReactComponent as Prometheus } from './svg/icon-prometheus.svg';
import { ReactComponent as ActionKit } from './svg/icon-action-kit.svg';
import { ReactComponent as ChevronUp } from './svg/icon-chevron-up.svg';
import { ReactComponent as TrendDown } from './svg/icon-trend-down.svg';
import { ReactComponent as Astronaut } from './svg/icon-astronaut.svg';
import { ReactComponent as Clipboard } from './svg/icon-clipboard.svg';
import { ReactComponent as Container } from './svg/icon-container.svg';
import { ReactComponent as Duplicate } from './svg/icon-duplicate.svg';
import { ReactComponent as Explosion } from './svg/icon-explosion.svg';
import { ReactComponent as Extension } from './svg/icon-extension.svg';
import { ReactComponent as Lightbulb } from './svg/icon-lightbulb.svg';
import { ReactComponent as NewRelic } from './svg/icon-new-relic.svg';
import { ReactComponent as SaveDisc } from './svg/icon-save-disc.svg';
import { ReactComponent as SaveFile } from './svg/icon-save-file.svg';
import { ReactComponent as Advanced } from './svg/icon-advanced.svg';
import { ReactComponent as Bookmark } from './svg/icon-bookmark.svg';
import { ReactComponent as Calendar } from './svg/icon-calendar.svg';
import { ReactComponent as Database } from './svg/icon-database.svg';
import { ReactComponent as Function } from './svg/icon-function.svg';
import { ReactComponent as Settings } from './svg/icon-settings.svg';
import { ReactComponent as Template } from './svg/icon-template.svg';
import { ReactComponent as WeakSpot } from './svg/icon-weakspot.svg';
import { ReactComponent as TrendUp } from './svg/icon-trend-up.svg';
import { ReactComponent as UserAdd } from './svg/icon-user-add.svg';
import { ReactComponent as Archive } from './svg/icon-archive.svg';
import { ReactComponent as Connect } from './svg/icon-connect.svg';
import { ReactComponent as Console } from './svg/icon-console.svg';
import { ReactComponent as Datadog } from './svg/icon-datadog.svg';
import { ReactComponent as Enlarge } from './svg/icon-enlarge.svg';
import { ReactComponent as Explore } from './svg/icon-explore.svg';
import { ReactComponent as Gatling } from './svg/icon-gatling.svg';
import { ReactComponent as Instana } from './svg/icon-instana.svg';
import { ReactComponent as License } from './svg/icon-license.svg';
import { ReactComponent as Logfile } from './svg/icon-logfile.svg';
import { ReactComponent as Message } from './svg/icon-message.svg';
import { ReactComponent as Postman } from './svg/icon-postman.svg';
import { ReactComponent as Refresh } from './svg/icon-refresh.svg';
import { ReactComponent as Warning } from './svg/icon-warning.svg';
import { ReactComponent as Webhook } from './svg/icon-webhook.svg';
import { ReactComponent as Team10 } from './svg/teams/team-10.svg';
import { ReactComponent as Team11 } from './svg/teams/team-11.svg';
import { ReactComponent as Team12 } from './svg/teams/team-12.svg';
import { ReactComponent as NewTab } from './svg/icon-new-tab.svg';
import { ReactComponent as OnPrem } from './svg/icon-on-prem.svg';
import { ReactComponent as Run } from './svg/icon-run-single.svg';
import { ReactComponent as StopV2 } from './svg/icon-stop-v2.svg';
import { ReactComponent as Cancel } from './svg/icon-cancel.svg';
import { ReactComponent as Delete } from './svg/icon-delete.svg';
import { ReactComponent as Docker } from './svg/icon-docker.svg';
import { ReactComponent as GitHub } from './svg/icon-github.svg';
import { ReactComponent as Handle } from './svg/icon-handle.svg';
import { ReactComponent as JMeter } from './svg/icon-jmeter.svg';
import { ReactComponent as Logout } from './svg/icon-logout.svg';
import { ReactComponent as Report } from './svg/icon-report.svg';
import { ReactComponent as Ripple } from './svg/icon-ripple.svg';
import { ReactComponent as Target } from './svg/icon-target.svg';
import { ReactComponent as Upload } from './svg/icon-upload.svg';
import { ReactComponent as Team1 } from './svg/teams/team-1.svg';
import { ReactComponent as Team2 } from './svg/teams/team-2.svg';
import { ReactComponent as Team3 } from './svg/teams/team-3.svg';
import { ReactComponent as Team4 } from './svg/teams/team-4.svg';
import { ReactComponent as Team5 } from './svg/teams/team-5.svg';
import { ReactComponent as Team6 } from './svg/teams/team-6.svg';
import { ReactComponent as Team7 } from './svg/teams/team-7.svg';
import { ReactComponent as Team8 } from './svg/teams/team-8.svg';
import { ReactComponent as Team9 } from './svg/teams/team-9.svg';
import { ReactComponent as Agent } from './svg/icon-agent.svg';
import { ReactComponent as Azure } from './svg/icon-azure.svg';
import { ReactComponent as Badge } from './svg/icon-badge.svg';
import { ReactComponent as Check } from './svg/icon-check.svg';
import { ReactComponent as Clock } from './svg/icon-clock.svg';
import { ReactComponent as Close } from './svg/icon-close.svg';
import { ReactComponent as Debug } from './svg/icon-debug.svg';
import { ReactComponent as Delay } from './svg/icon-delay.svg';
import { ReactComponent as Group } from './svg/icon-group.svg';
import { ReactComponent as Kafka } from './svg/icon-kafka.svg';
import { ReactComponent as Linux } from './svg/icon-linux.svg';
import { ReactComponent as Reset } from './svg/icon-reset.svg';
import { ReactComponent as Share } from './svg/icon-share.svg';
import { ReactComponent as Slack } from './svg/icon-slack.svg';
import { ReactComponent as Sleep } from './svg/icon-sleep.svg';
import { ReactComponent as Start } from './svg/icon-start.svg';
import { ReactComponent as State } from './svg/icon-state.svg';
import { ReactComponent as Trash } from './svg/icon-trash.svg';
import { ReactComponent as Crio } from './svg/icon-crio.svg';
import { ReactComponent as Edit } from './svg/icon-edit.svg';
import { ReactComponent as Help } from './svg/icon-help.svg';
import { ReactComponent as Host } from './svg/icon-host.svg';
import { ReactComponent as Java } from './svg/icon-java.svg';
import { ReactComponent as Kong } from './svg/icon-kong.svg';
import { ReactComponent as Lock } from './svg/icon-lock.svg';
import { ReactComponent as Logo } from './svg/icon-logo.svg';
import { ReactComponent as Runs } from './svg/icon-runs.svg';
import { ReactComponent as Tags } from './svg/icon-tags.svg';
import { ReactComponent as Team } from './svg/icon-team.svg';
import { ReactComponent as User } from './svg/icon-user.svg';
import { ReactComponent as Aws } from './svg/icon-aws.svg';
import { ReactComponent as Cpu } from './svg/icon-cpu.svg';
import { ReactComponent as Dot } from './svg/icon-dot.svg';
import { ReactComponent as Fix } from './svg/icon-fix.svg';
import { ReactComponent as Hub } from './svg/icon-hub.svg';
import { ReactComponent as K6 } from './svg/icon-k6.svg';

export type IconComponentProps = React.PropsWithoutRef<React.SVGProps<SVGSVGElement> & ContainerProps> &
	React.RefAttributes<SVGSVGElement>;

export type IconComponent = React.FC<IconComponentProps> & {
	Svg: React.FC<React.SVGProps<SVGSVGElement>>;
};

const createIcon = (svg: React.FC<React.SVGProps<SVGSVGElement>>): IconComponent => {
	const boxComponent: React.FC<IconComponentProps> = function CreateIconComponent({
		variant = 'medium',
		sx = {},
		minHeight,
		minWidth,
		maxHeight,
		maxWidth,
		height,
		width,
		m,
		mb,
		ml,
		mr,
		mt,
		mx,
		my,
		p,
		pb,
		pl,
		pr,
		pt,
		px,
		py,
		opacity,
		...props
	}: IconComponentProps): ReactElement {
		return (
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			<ContainerComponent
				as={svg}
				{...props}
				variant={`icons.${variant}`}
				sx={{
					verticalAlign: 'middle',
					height: resolveStyleProp(height),
					minHeight: resolveStyleProp(minHeight),
					maxHeight: resolveStyleProp(maxHeight),
					width: resolveStyleProp(width),
					minWidth: resolveStyleProp(minWidth),
					maxWidth: resolveStyleProp(maxWidth),
					m: resolveStyleProp(m),
					mb: resolveStyleProp(mb),
					ml: resolveStyleProp(ml),
					mr: resolveStyleProp(mr),
					mt: resolveStyleProp(mt),
					mx: resolveStyleProp(mx),
					my: resolveStyleProp(my),
					p: resolveStyleProp(p),
					pb: resolveStyleProp(pb),
					pl: resolveStyleProp(pl),
					pr: resolveStyleProp(pr),
					pt: resolveStyleProp(pt),
					px: resolveStyleProp(px),
					py: resolveStyleProp(py),
					opacity: resolveStyleProp(opacity),
					...resolveSx(sx),
				}}
			/>
		);
	} as IconComponent;

	(boxComponent as IconComponent).Svg = svg;
	return boxComponent as IconComponent;
};

export const IconAgent = createIcon(Agent);
export const IconApiAccessToken = createIcon(ApiAccessToken);
export const IconAws = createIcon(Aws);
export const IconAlertTriangle = createIcon(AlertTriangle);
export const IconArrowDropDown = createIcon(ArrowDropDown);
export const IconArrowDropUp = createIcon(ArrowDropUp);
export const IconArrowLeft = createIcon(ArrowLeft);
export const IconArrowRight = createIcon(ArrowRight);
export const IconCancel = createIcon(Cancel);
export const IconCalendarClock = createIcon(CalendarClock);
export const IconCheck = createIcon(Check);
export const IconLock = createIcon(Lock);
export const IconWeakSpot = createIcon(WeakSpot);
export const IconDot = createIcon(Dot);
export const IconChevronDown = createIcon(ChevronDown);
export const IconChevronDoubleLeft = createIcon(ChevronDoubleLeft);
export const IconChevronDoubleRight = createIcon(ChevronDoubleRight);
export const IconChevronRight = createIcon(ChevronRight);
export const IconChevronLeft = createIcon(ChevronLeft);
export const IconChevronBeginning = createIcon(ChevronBeginning);
export const IconChevronEnd = createIcon(ChevronEnd);
export const IconChevronUp = createIcon(ChevronUp);
export const IconMaintenance = createIcon(Maintenance);
export const IconClock = createIcon(Clock);
export const IconClose = createIcon(Close);
export const IconCpu = createIcon(Cpu);
export const IconDelay = createIcon(Delay);
export const IconDelete = createIcon(Delete);
export const IconDuplicate = createIcon(Duplicate);
export const IconEdit = createIcon(Edit);
export const IconHandle = createIcon(Handle);
export const IconHelp = createIcon(Help);
export const IconHost = createIcon(Host);
export const IconIntegrations = createIcon(Integrations);
export const IconLogo = createIcon(Logo);
export const IconNewTab = createIcon(NewTab);
export const IconClipboard = createIcon(Clipboard);
export const IconSettings = createIcon(Settings);
export const IconAdvanced = createIcon(Advanced);
export const IconDistribution = createIcon(Distribution);
export const IconStart = createIcon(Start);
export const IconState = createIcon(State);
export const IconTarget = createIcon(Target);
export const IconTargetEnrichmentRule = createIcon(TargetEnrichmentRule);
export const IconContainer = createIcon(Container);
export const IconContainerD = createIcon(ContainerD);
export const IconContainerRegistry = createIcon(ContainerRegistry);
export const IconLogfile = createIcon(Logfile);
export const IconKubernetes = createIcon(Kubernetes);
export const IconSaveDisc = createIcon(SaveDisc);
export const IconTeam = createIcon(Team);
export const IconDescription = createIcon(Description);
export const IconEnvironment = createIcon(Environment);
export const IconEnvironmentGlobal = createIcon(EnvironmentGlobal);
export const IconDiscoveryKit = createIcon(DiscoveryKit);
export const IconActionKit = createIcon(ActionKit);
export const IconInProgress = createIcon(InProgress);
export const IconTargetIndicatorAttack = createIcon(TargetIndicatorAttack);
export const IconBlastRadius = createIcon(BlastRadius);
export const IconTags = createIcon(Tags);
export const IconSlack = createIcon(Slack);
export const IconInstana = createIcon(Instana);
export const IconNewRelic = createIcon(NewRelic);
export const IconDatadog = createIcon(Datadog);
export const IconDatabase = createIcon(Database);
export const IconPrometheus = createIcon(Prometheus);
export const IconSaveFile = createIcon(SaveFile);
export const IconWarning = createIcon(Warning);
export const IconWarningSmall = createIcon(WarningSmall);
export const IconWarningCircle = createIcon(WarningCircle);
export const IconWarningCircleOpen = createIcon(WarningCircleOpen);
export const IconMessage = createIcon(Message);
export const IconInformationCircle = createIcon(InformationCircle);
export const IconInformation = createIcon(Information);
export const IconReport = createIcon(Report);
export const IconLinux = createIcon(Linux);
export const IconDocker = createIcon(Docker);
export const IconCrio = createIcon(Crio);
export const IconJava = createIcon(Java);
export const IconAzure = createIcon(Azure);
export const IconGoogleCloud = createIcon(GoogleCloudPlatform);
export const IconExperiment = createIcon(Experiment);
export const IconExperimentBlocked = createIcon(ExperimentBlocked);
export const IconGroup = createIcon(Group);
export const IconShare = createIcon(Share);
export const IconTrendUp = createIcon(TrendUp);
export const IconTrendDown = createIcon(TrendDown);
export const IconUser = createIcon(User);
export const IconExplosion = createIcon(Explosion);
export const IconRefresh = createIcon(Refresh);
export const IconFix = createIcon(Fix);
export const IconRunAgain = createIcon(RunAgain);
export const IconRun = createIcon(Run);
export const IconStopV2 = createIcon(StopV2);
export const IconSleep = createIcon(Sleep);
export const IconRipple = createIcon(Ripple);
export const IconRoundSpinner = createIcon(RoundSpinner);
export const IconRoundSpinnerWhite = createIcon(RoundSpinnerWhite);
export const IconLightbulb = createIcon(Lightbulb);
export const IconFunction = createIcon(Function);
export const IconEnlarge = createIcon(Enlarge);
export const IconPolicyBindingConsole = createIcon(PolicyBindingConsole);
export const IconConsole = createIcon(Console);
export const IconGitHub = createIcon(GitHub);
export const IconTrash = createIcon(Trash);
export const IconMetricQuery = createIcon(MetricQuery);
export const IconMetricCheck = createIcon(MetricCheck);
export const IconBadge = createIcon(Badge);
export const IconAstronaut = createIcon(Astronaut);
export const IconGatling = createIcon(Gatling);
export const IconJMeter = createIcon(JMeter);
export const IconK6 = createIcon(K6);
export const IconKafka = createIcon(Kafka);
export const IconKong = createIcon(Kong);
export const IconOnPrem = createIcon(OnPrem);
export const IconPostman = createIcon(Postman);
export const IconArchive = createIcon(Archive);
export const IconExtension = createIcon(Extension);
export const IconCalendarRecurrent = createIcon(CalendarRecurrent);
export const IconCalendarPaused = createIcon(CalendarPaused);
export const IconSteadybitApproved = createIcon(SteadybitApproved);
export const IconExplore = createIcon(Explore);
export const IconAdviceGeneral = createIcon(AdviceGeneral);
export const IconReset = createIcon(Reset);
export const IconBookmark = createIcon(Bookmark);
export const IconWarningOpen = createIcon(WarningOpen);
export const IconTemplate = createIcon(Template);
export const IconAdviceUnknown = createIcon(AdviceUnknown);
export const IconDocumentation = createIcon(Documentation);
export const IconNotification = createIcon(Notification);
export const IconLogout = createIcon(Logout);
export const IconDebug = createIcon(Debug);
export const IconHub = createIcon(Hub);
export const IconDisconnect = createIcon(Disconnect);
export const IconUpload = createIcon(Upload);
export const IconTemplateAdd = createIcon(TemplateAdd);
export const IconRuns = createIcon(Runs);
export const IconCalendar = createIcon(Calendar);
export const IconConnect = createIcon(Connect);
export const IconInformationCircleFilled = createIcon(InformationCircleFilled);
export const IconLicense = createIcon(License);
export const IconWebhook = createIcon(Webhook);
export const IconWebhookPreflight = createIcon(WebhookPreflight);
export const IconUserAdd = createIcon(UserAdd);

//Attacks
export const IconExperimentError = createIcon(ExperimentError);
export const IconExperimentFailed = createIcon(ExperimentFailed);
export const IconAttackTime = createIcon(AttackTime);
export const IconAdviceActionNeeded = createIcon(AdviceActionNeeded);
export const IconAdviceValidation = createIcon(AdviceValidation);
export const IconAdviceImplemented = createIcon(AdviceImplemented);

//Teams
export const IconTeam1 = createIcon(Team1);
export const IconTeam2 = createIcon(Team2);
export const IconTeam3 = createIcon(Team3);
export const IconTeam4 = createIcon(Team4);
export const IconTeam5 = createIcon(Team5);
export const IconTeam6 = createIcon(Team6);
export const IconTeam7 = createIcon(Team7);
export const IconTeam8 = createIcon(Team8);
export const IconTeam9 = createIcon(Team9);
export const IconTeam10 = createIcon(Team10);
export const IconTeam11 = createIcon(Team11);
export const IconTeam12 = createIcon(Team12);
