/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	ButtonIcon,
	CheckboxChecked,
	Container,
	FormCheckbox,
	Label,
	SettingsGroup,
	SettingsGroupItem,
	SettingsGroupProps,
	Stack,
	Text,
	Tooltip,
} from 'components';
import useActionHierarchy from 'experiment/actions/useActionHierarchy';
import { useTargetDefinitions } from 'targets/useTargetDefinitions';
import { IconChevronDown, IconChevronUp } from 'components/icons';
import { ActionVO, TargetTypeDescriptionVO } from 'ui-api';
import { ReactElement, ReactNode, useState } from 'react';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import ListSelectButton from 'hocs/ListSelectButton';
import { Services } from 'services/services';
import { useFormikContext } from 'formik';

import { TeamFormValues } from './teamTypes';

interface ActionPermissionsProps {
	readonlyExplanation?: ReactNode;
	readonly?: boolean;
}
export function ActionPermissions({ readonlyExplanation, readonly }: ActionPermissionsProps): ReactElement {
	const formik = useFormikContext<TeamFormValues>();
	const [actions] = useAsyncState(() => Services.actions.fetchActions());
	const targetDefinitions = useTargetDefinitions();

	const isAllSelected =
		actions.value?.filter((a) => !a.alwaysPermittedAndReported).length === formik.values.allowedActions?.length;
	const isSomeSelected = formik.values.allowedActions?.length > 0;

	return (
		<Stack size="none">
			<Container sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Stack direction="vertical" size="xxSmall" mr={'small'}>
					<Label>Select Allowed Actions</Label>
					<Text variant={'small'} color={'neutral600'}>
						Define which actions can be executed by this team in an experiment.
					</Text>
				</Stack>

				{!readonly && (
					<Stack direction="horizontal" size="xSmall">
						{!isAllSelected && (
							<ListSelectButton
								label="Select all"
								onClick={() => formik.setFieldValue('allowedActions', actions.value?.map((action) => action.id) ?? [])}
							/>
						)}
						{!isAllSelected && isSomeSelected && <Text>/</Text>}
						{isSomeSelected && (
							<ListSelectButton
								label="Clear all"
								onClick={() =>
									formik.setFieldValue(
										'allowedActions',
										actions.value?.filter((a) => a.alwaysPermittedAndReported).map((action) => action.id) ?? [],
									)
								}
							/>
						)}
					</Stack>
				)}
			</Container>
			{actions.value && targetDefinitions.value && (
				<ActionChooser
					mt={'xSmall'}
					actions={actions.value.filter((action) => !action.deleted)}
					targetDefinitions={targetDefinitions.value}
					selectedActionIds={formik.values.allowedActions ?? []}
					onSelectActionIds={(attackIds) => formik.setFieldValue('allowedActions', attackIds)}
					readonly={!!readonly}
					readonlyExplanation={readonlyExplanation}
				/>
			)}
		</Stack>
	);
}

type ActionChooserProps = SettingsGroupProps & {
	readonly: boolean;
	readonlyExplanation?: ReactNode;
	actions: ActionVO[];
	targetDefinitions: TargetTypeDescriptionVO[];
	selectedActionIds: string[];
	onSelectActionIds: (actionIds: string[]) => void;
};

const allChecked = (actions: ActionVO[], selectedActionIds: string[]): CheckboxChecked => {
	const actionIds = actions.map((action) => action.id);
	if (actionIds.every((actionId) => selectedActionIds.includes(actionId))) {
		return true;
	} else if (actionIds.every((actionId) => !selectedActionIds.includes(actionId))) {
		return false;
	} else {
		return 'Indeterminate';
	}
};

function ActionChooser({
	readonlyExplanation,
	selectedActionIds,
	readonly,
	actions,
	onSelectActionIds,
	...props
}: ActionChooserProps): ReactElement {
	const handleCategoryClick = (actions: ActionVO[]): void => {
		// Not All selected -> Add all
		const categoryChecked = allChecked(actions, selectedActionIds);
		const addAll = categoryChecked === false || categoryChecked === 'Indeterminate';

		const newSelectedActionIds = [...selectedActionIds];
		actions.forEach((action) => {
			if (addAll && !selectedActionIds.includes(action.id)) {
				newSelectedActionIds.push(action.id);
			}
			if (!addAll && selectedActionIds.includes(action.id) && !action.alwaysPermittedAndReported) {
				newSelectedActionIds.splice(newSelectedActionIds.indexOf(action.id), 1);
			}
		});
		onSelectActionIds(newSelectedActionIds);
	};

	const actionCategories = useActionHierarchy({ actions });

	return (
		<>
			{actions.length === 0 ? <Text>No Actions found. Are any agents registered?</Text> : null}
			<SettingsGroup {...props}>
				{actionCategories.map((category) => (
					<ActionCategory
						key={category.label}
						readonly={readonly}
						readonlyExplanation={readonlyExplanation}
						label={category.label}
						actions={[
							...(category.actions || []),
							...(category.subCategories ? category.subCategories.flatMap((sc) => sc.actions) : []),
						].map((a) => a.action)}
						selectedActionIds={selectedActionIds}
						onSelectActionIds={onSelectActionIds}
						isCategoryChecked={allChecked}
						handleCategoryClick={handleCategoryClick}
					/>
				))}
			</SettingsGroup>
		</>
	);
}

interface ActionCategoryProps {
	readonly: boolean;
	readonlyExplanation?: ReactNode;
	label: string;
	actions: ActionVO[];
	selectedActionIds: string[];
	onSelectActionIds: (actionIds: string[]) => void;
	isCategoryChecked: (typeActions: ActionVO[], selectedActionIds: string[]) => CheckboxChecked;
	handleCategoryClick: (targetType: ActionVO[]) => void;
}

function ActionCategory({
	readonly,
	readonlyExplanation,
	actions,
	label,
	handleCategoryClick,
	isCategoryChecked,
	selectedActionIds,
	onSelectActionIds,
}: ActionCategoryProps): ReactElement {
	const actionsContent = (
		<Container
			size={'medium'}
			m={'small'}
			sx={{
				display: 'grid',
				gridTemplateColumns: '1fr 1fr',
				rowGap: 'small',
			}}
		>
			{actions.map((action) => (
				<ActionCheckbox
					disabled={readonly || action.alwaysPermittedAndReported}
					disabledExplanation={readonlyExplanation}
					key={action.id}
					action={action}
					onSelectActionIds={onSelectActionIds}
					selectedActionIds={selectedActionIds}
				/>
			))}
		</Container>
	);

	const [expanded, setExpanded] = useState(false);

	if (!label) {
		return (
			<Container sx={{ px: 'xSmall', borderBottom: '1px solid', borderColor: 'neutral200' }}>
				{actionsContent}
			</Container>
		);
	}

	return (
		<SettingsGroupItem
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				if (!readonly) {
					handleCategoryClick(actions);
				}
			}}
			sx={{
				'& + [data-settings-group-item], [data-settings-group-item] + &, [data-settings-group-header] + &': {
					borderTop: '1px solid',
					borderColor: 'neutral200',
				},
			}}
		>
			<Container display="flex" alignItems="center">
				<Tooltip disabled={!readonlyExplanation} content={readonlyExplanation}>
					<FormCheckbox disabled={readonly} label="" checked={isCategoryChecked(actions, selectedActionIds)} />
				</Tooltip>
				<Container mx={'small'} bg={'neutral200'} height={40} width={'1px'} />
				<Label ml={'xxSmall'} variant={'mediumMedium'}>
					{label}
				</Label>
				<ButtonIcon
					ml="auto"
					variant="small"
					onClick={(e) => {
						e.stopPropagation();
						e.preventDefault();
						setExpanded(!expanded);
					}}
				>
					{expanded ? <IconChevronUp /> : <IconChevronDown />}
				</ButtonIcon>
			</Container>
			{expanded ? actionsContent : null}
		</SettingsGroupItem>
	);
}

interface ActionCheckboxProps {
	disabled: boolean;
	disabledExplanation?: ReactNode;
	action: ActionVO;
	selectedActionIds: string[];
	onSelectActionIds: (actionIds: string[]) => void;
}
function ActionCheckbox({
	action,
	selectedActionIds,
	onSelectActionIds,
	disabled,
	disabledExplanation,
}: ActionCheckboxProps): ReactElement {
	const toggleActionSelection = (actionId: string): void => {
		if (selectedActionIds.includes(actionId)) {
			const newSelectedActionIds = [...selectedActionIds];
			newSelectedActionIds.splice(selectedActionIds.indexOf(action.id), 1);
			onSelectActionIds(newSelectedActionIds);
		} else {
			onSelectActionIds([...selectedActionIds, action.id]);
		}
	};

	return (
		<Container
			disabled={disabled}
			onClick={(e) => {
				e.stopPropagation();
				e.preventDefault();
				if (!disabled) {
					toggleActionSelection(action.id);
				}
			}}
		>
			<Tooltip disabled={!disabledExplanation} content={disabledExplanation}>
				<FormCheckbox
					disabled={disabled}
					name={action.id}
					label={action.name}
					checked={selectedActionIds.includes(action.id)}
					mr="auto"
				/>
			</Tooltip>
			<Text mx={26} color="neutral500">
				{action.description}
			</Text>
		</Container>
	);
}
