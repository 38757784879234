/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import StepConfigurationSidebar from 'pages/experimentsV2/StepConfigurationSidebar/StepConfigurationSidebar';
import StepsSidebar from 'pages/experimentsV2/StepsSidebar/StepsSidebar';
import { useApplicationHeaderHeight } from 'App/ApplicationHeaderHeight';
import DragAndDropHandler from 'pages/experimentsV2/DragAndDropHandler';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import Workspace from 'pages/experimentsV2/Workspace/Workspace';
import { presets } from '@steadybit/ui-components-lib';
import { ReactElement, useState } from 'react';
import { Stack } from 'components';

export default function TemplateExperimentContent(): ReactElement {
	const applicationHeaderHeight = useApplicationHeaderHeight();
	// experiment header and subheader
	const height = applicationHeaderHeight + 65 + 56 + 60;

	const [dropdownContext] = useState(() => new presets.dropdown.DropdownCloseManagement());

	return (
		<>
			{/* Since items can be dragged from the sidebar to the workspace, the context must be mounted here */}
			<DragAndDropHandler>
				<Stack direction="horizontal" size="none">
					<ErrorBoundary log={(err: string, info: string) => console.error(err, info)}>
						<StepsSidebar height={height} />
					</ErrorBoundary>
					<Workspace height={height} />
				</Stack>
			</DragAndDropHandler>

			<presets.dropdown.DropdownContext key="asd" value={dropdownContext}>
				<StepConfigurationSidebar top="175px" disabled={false} />
			</presets.dropdown.DropdownContext>
		</>
	);
}
