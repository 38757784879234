/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useEffect } from 'react';

interface SidebarShrinkerProps {
	expanded: boolean;
	setExpanded: (expanded: boolean) => void;
}
export function SidebarShrinker({ expanded, setExpanded }: SidebarShrinkerProps): null {
	useEffect(() => {
		if (expanded) {
			setExpanded(false);
		}
	}, [expanded]);
	return null;
}
