/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import React, { useEffect, useRef, useState } from 'react';

interface UseScrollPositionResult<T extends HTMLElement> {
	ref: React.RefObject<T | null>;
	scrollHeight: number;
	scrollTop: number;
}

export function useScrollPosition<T extends HTMLElement>(): UseScrollPositionResult<T> {
	const ref = useRef<T>(null);
	const [scrollTop, setScrollTop] = useState(0);
	const [scrollHeight, setScrollHeight] = useState(0);

	useEffect(() => {
		const handleScroll = (): void => {
			if (ref.current) {
				setScrollTop(ref.current.scrollTop);
				setScrollHeight(ref.current.scrollHeight);
			}
		};

		if (ref.current) {
			ref.current.addEventListener('scroll', handleScroll);
		}
		setScrollTop(ref.current?.scrollTop ?? 0);
		setScrollHeight(ref.current?.scrollHeight ?? 0);

		return () => {
			if (ref.current) {
				ref.current.removeEventListener('scroll', handleScroll);
			}
		};
	}, [ref]);

	return { scrollTop, scrollHeight, ref };
}
