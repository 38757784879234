/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ActionKindVO, ActionVO, EnvironmentSummaryVO, SearchObjectsVO, TargetTypeDescriptionVO } from 'ui-api';
import { createFilterParams, UrlState } from 'pages/templates/FromTemplateModal/urlParams';
import { Dropdown, presets, Spacings, TextInput } from '@steadybit/ui-components-lib';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { DataStreamResult } from 'utils/hooks/stream/result';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { usePromise } from 'utils/hooks/usePromise';
import { useUrlState } from 'url/useUrlState';
import { Services } from 'services/services';
import { Stack, Text } from 'components';

import SearchObjects from './SearchObjects';

interface SearchBarProps {
	mode: 'experimentList' | 'templatePreviews' | 'templateList';
	searchObjectsResult: DataStreamResult<SearchObjectsVO>;
	targetDefinitions: TargetTypeDescriptionVO[];
	allowActionDragAndDrop?: boolean;
	cypressTag?: string;
	actions: ActionVO[];
	pathname: string;
	small?: boolean;
}

export default function SearchBar({
	allowActionDragAndDrop = false,
	actions: externalActions,
	searchObjectsResult,
	targetDefinitions,
	cypressTag,
	pathname,
	small,
	mode,
}: SearchBarProps): ReactElement {
	const [actionsData] = useAsyncState(async () => {
		return await Promise.all(
			externalActions.map(async (action) => {
				action.name = (await Services.actions.findActionNameWithTargetTypeIfNotUnique(action.id)) ?? action.name;
				return action;
			}),
		);
	}, [externalActions]);
	const actions = actionsData.value || [];

	const environmentsResult = usePromise(async () => (await Services.environments.fetchEnvironments()).content, []);
	const environemntIdToEnv = useMemo(() => {
		const map = new Map<string, EnvironmentSummaryVO>();
		if (environmentsResult.value) {
			environmentsResult.value.forEach((env) => {
				map.set(env.id, env);
			});
		}
		return map;
	}, [environmentsResult.value]);

	const [
		{ environmentIdParam, tagsParam, actionsParam, targetTypesParam, freeTextPhrasesParam, kindsParam, pageParam },
	] = useState(() => createFilterParams(pathname));

	const [
		{
			environmentIds: environmentIdsFromUrl,
			targetTypes: targetTypesFromUrl,
			actions: actionsFromUrl,
			kinds: kindsFromUrl,
			tags: tagsFromUrl,
			freeTextPhrases,
		},
		,
		updateUrlState,
	] = useUrlState<UrlState>([
		tagsParam,
		environmentIdParam,
		targetTypesParam,
		actionsParam,
		freeTextPhrasesParam,
		kindsParam,
		pageParam,
	]);

	const commonTags = searchObjectsResult.value?.mostUsedTags || [];

	const [query, setQuery] = useState('');

	return (
		<Stack
			size="xSmall"
			sx={{
				p: small ? 'xSmall' : 'small',
				borderRadius: small ? '4px' : '8px',
				backgroundColor: 'neutral100',
				width: '100%',
			}}
		>
			{mode !== 'templateList' && <Text variant="mediumStrong">Search via keywords</Text>}

			<Dropdown
				placement="bottom-start"
				bindHeightToViewport
				renderDropdownContent={({ width, close }) => {
					const value = query.trim();
					return (
						<presets.dropdown.DropdownContentFrame maxHeight="100%">
							<KeydownReturnListener
								onDelete={() => {
									if (value !== '') {
										return;
									}
									if (freeTextPhrases.length > 0) {
										updateUrlState({
											page: 0,
											freeTextPhrases: freeTextPhrases.slice(0, freeTextPhrases.length - 1),
										});
									} else if (actionsFromUrl.length > 0) {
										updateUrlState({ page: 0, actions: actionsFromUrl.slice(0, actionsFromUrl.length - 1) });
									} else if (targetTypesFromUrl.length > 0) {
										updateUrlState({
											page: 0,
											targetTypes: targetTypesFromUrl.slice(0, targetTypesFromUrl.length - 1),
										});
									} else if (environmentIdsFromUrl.length > 0) {
										updateUrlState({
											page: 0,
											environmentIds: environmentIdsFromUrl.slice(0, environmentIdsFromUrl.length - 1),
										});
									} else if (tagsFromUrl.length > 0) {
										updateUrlState({ page: 0, tags: tagsFromUrl.slice(0, tagsFromUrl.length - 1) });
									} else if (kindsFromUrl.length > 0) {
										updateUrlState({ page: 0, kinds: kindsFromUrl.slice(0, kindsFromUrl.length - 1) });
									}
								}}
							/>
							<SearchObjects
								allowActionDragAndDrop={allowActionDragAndDrop}
								searchObjectsResult={searchObjectsResult}
								environemntIdToEnv={environemntIdToEnv}
								targetDefinitions={targetDefinitions}
								actionsData={actions}
								pathname={pathname}
								query={value}
								width={width}
								mode={mode}
								clearQuery={() => {
									setQuery('');
									close();
								}}
							/>
						</presets.dropdown.DropdownContentFrame>
					);
				}}
			>
				{({ setRefElement, isOpen, setOpen }) => {
					return (
						<TextInput
							ref={setRefElement}
							placeholder="Type to search"
							highlighted={isOpen}
							withLeftIcon="search"
							value={query}
							wrap
							prevInputContent={
								<>
									{tagsFromUrl.map((tag) => (
										<presets.pill.Tag
											key={tag}
											appearance={mode === 'experimentList' ? 'experiment' : 'template'}
											onDelete={() => updateUrlState({ page: 0, tags: tagsFromUrl.filter((t) => t !== tag) })}
										>
											{tag}
										</presets.pill.Tag>
									))}
									{environmentIdsFromUrl.map((id) => {
										const resolvedEnv = environemntIdToEnv.get(id);
										return (
											<presets.pill.EnvironmentTag
												key={id}
												global={resolvedEnv?.global}
												onDelete={() =>
													updateUrlState({
														page: 0,
														environmentIds: environmentIdsFromUrl.filter((t) => t !== id),
													})
												}
											>
												{resolvedEnv?.name || ''}
											</presets.pill.EnvironmentTag>
										);
									})}
									{targetTypesFromUrl.map((target) => {
										const targetDefinition = targetDefinitions.find((def) => target === def.id);
										return (
											<presets.pill.TargetTypeTag
												key={target}
												icon={targetDefinition?.icon}
												onDelete={() =>
													updateUrlState({
														page: 0,
														targetTypes: targetTypesFromUrl.filter((t) => t !== target),
													})
												}
											>
												{targetDefinition?.label.one || target}
											</presets.pill.TargetTypeTag>
										);
									})}
									{actionsFromUrl.map((actionId) => {
										const action = actions.find((a) => a.id === actionId);
										return (
											<presets.pill.ActionTag
												key={actionId}
												kind={action?.kind}
												icon={action?.icon}
												onDelete={() =>
													updateUrlState({ page: 0, actions: actionsFromUrl.filter((a) => a !== actionId) })
												}
											>
												{action?.name || actionId}
											</presets.pill.ActionTag>
										);
									})}
									{kindsFromUrl.map((kind) => (
										<presets.pill.KindTag
											key={kind}
											kind={kind as ActionKindVO}
											onDelete={() => updateUrlState({ page: 0, kinds: kindsFromUrl.filter((k) => k !== kind) })}
										/>
									))}
									{freeTextPhrases.map((phrase) => (
										<presets.pill.FreeText
											key={phrase}
											onDelete={() =>
												updateUrlState({
													page: 0,
													freeTextPhrases: freeTextPhrases.filter((p) => p !== phrase),
												})
											}
										>
											{phrase}
										</presets.pill.FreeText>
									))}
								</>
							}
							style={{ paddingTop: Spacings.xSmall, paddingBottom: Spacings.xSmall }}
							data-cy={cypressTag}
							onClick={() => setOpen(true)}
							onFocus={() => setOpen(true)}
							onChange={(v) => {
								setQuery(v);
								if (!isOpen) {
									setOpen(true);
								}
							}}
						/>
					);
				}}
			</Dropdown>

			{mode !== 'templateList' && (
				<MostCommonTags
					mode={mode}
					tags={commonTags}
					onTagClick={(tag) => {
						if (!tagsFromUrl.includes(tag)) {
							updateUrlState({ page: 0, tags: [...tagsFromUrl, tag] });
						}
					}}
				/>
			)}
		</Stack>
	);
}

interface MostCommonTagsProps {
	mode: 'experimentList' | 'templatePreviews';
	tags: string[];
	onTagClick: (tag: string) => void;
}

function MostCommonTags({ mode, tags, onTagClick }: MostCommonTagsProps): ReactElement | null {
	if (tags.length === 0) {
		return null;
	}

	return (
		<Stack direction="horizontal" size="xSmall">
			<Text variant="smallStrong" color="neutral600" mt="3px" minWidth="128px">
				Most common tags:
			</Text>
			<presets.pill.Tags
				appearance={mode === 'experimentList' ? 'experiment' : 'template'}
				tags={tags}
				onClick={onTagClick}
			/>
		</Stack>
	);
}

function KeydownReturnListener({ onDelete }: { onDelete: () => void }): null {
	const keyDownListener = (event: KeyboardEvent): void => {
		if (event.key === 'Backspace') {
			onDelete();
		}
	};

	useEffect(() => {
		window.addEventListener('keydown', keyDownListener, true);
		return () => window.removeEventListener('keydown', keyDownListener, true);
	}, [keyDownListener]);

	return null;
}
