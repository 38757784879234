/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { autoPlacement, autoUpdate, flip, offset, Placement, shift, useFloating } from '@floating-ui/react-dom';
import useObservable from 'react-use/lib/useObservable';
import React, { ReactElement, useMemo } from 'react';
import { Container, StyleProp } from 'components';
import { ReplaySubject } from 'rxjs';
import { debounce } from 'lodash';
import ReactDOM from 'react-dom';

type NewType = {
	content: React.ReactNode | (() => React.ReactNode);
	onlyShowOnEllipsis?: boolean;
	color?: 'dark' | 'light';
	children: ReactElement;
	placement?: Placement;
	disabled?: boolean;
};

export type TooltipProps = NewType;

const assertValidChild = (children?: React.ReactNode): React.ReactElement => {
	const child = React.Children.only(children);
	if (React.isValidElement(child)) {
		return child;
	}
	throw new Error('The Tooltip only accepts a single valid element child.');
};

const isGlobalVisible$ = new ReplaySubject(1);
const setIsGlobalVisible = debounce((b) => isGlobalVisible$.next(b), 400);

const STYLE_DARK = {
	backgroundColor: 'neutral800',
	color: 'neutral000',
};
const STYLE_LIGHT = {
	backgroundColor: 'neutral000',
	color: 'neutral800',
	border: '1px solid',
	borderColor: 'neutral300',
};

export function Tooltip(props: TooltipProps): ReactElement {
	if (props.disabled) {
		return props.children;
	}
	return <TooltipContent {...props}>{props.children}</TooltipContent>;
}

const TooltipContent: React.FC<TooltipProps> = ({
	onlyShowOnEllipsis,
	color = 'dark',
	placement,
	children,
	disabled,
	content,
}) => {
	const isGlobalVisible = useObservable(isGlobalVisible$);
	const [isTriggered, setIsTriggered] = React.useState(false);

	const { refs, floatingStyles } = useFloating({
		placement,
		middleware: [
			placement
				? autoPlacement({
						allowedPlacements: ['top-start', 'bottom-start'],
					})
				: undefined,
			offset(4),
			shift(),
			flip(),
		],
		whileElementsMounted: autoUpdate,
	});

	const tooltipContent = React.useMemo(
		() => (isTriggered && isGlobalVisible ? (typeof content === 'function' ? content() : content) : null),
		[isTriggered, isGlobalVisible, content],
	);

	const sxColor = (): StyleProp => {
		switch (color) {
			case 'dark':
				return STYLE_DARK;
			case 'light':
				return STYLE_LIGHT;
		}
	};

	const child = assertValidChild(children);
	const enhancedChild = useMemo(() => {
		//If the child is disabled, wrap it with a div and set pointer-events: none for the nested element
		//This is due to disabled inputs/buttons don't emitting mouseleave correctly.
		//React fixed this by also not firing mouseenter events. See https://github.com/facebook/react/issues/4251
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const wrappedChild = child.props.disabled ? (
			<span role={'presentation'} style={{ width: 'fit-content' }}>
				{child}
			</span>
		) : (
			child
		);

		return React.cloneElement(wrappedChild, {
			...wrappedChild.props,
			ref: refs.setReference,
			onMouseEnter: (e: React.MouseEvent<HTMLElement>) => {
				if (
					!disabled &&
					(!onlyShowOnEllipsis ||
						(e.currentTarget.offsetWidth ?? 0) < (e.currentTarget.scrollWidth ?? 0) ||
						(e.currentTarget.offsetHeight ?? 0) < (e.currentTarget.scrollHeight ?? 0))
				) {
					setIsTriggered(true);
					setIsGlobalVisible(true);
				}
			},
			onMouseLeave: () => {
				setIsTriggered(false);
				setIsGlobalVisible(false);
			},
		});
	}, [child, onlyShowOnEllipsis]);

	return (
		<>
			{enhancedChild}
			{isTriggered && isGlobalVisible && tooltipContent
				? ReactDOM.createPortal(
						<Container
							ref={refs.setFloating}
							style={floatingStyles}
							sx={{
								display: 'flex',
								justifyContent: 'center',
								variant: 'text.smallStrong',
								maxWidth: '500px',
								zIndex: 200,
								overflow: 'hidden',
								pointerEvents: 'none',
							}}
						>
							<Container
								sx={{
									px: 'xSmall',
									py: 'xxSmall',
									...sxColor(),
									borderRadius: '4px',
									pointerEvents: 'none',
									width: 'fit-content',
									wordBreak: 'break-word',
								}}
							>
								{tooltipContent}
							</Container>
						</Container>,
						document.body,
					)
				: null}
		</>
	);
};
