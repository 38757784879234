/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Dropdown, presets, Pill } from '@steadybit/ui-components-lib';
import { useEnvironments } from 'utils/hooks/useEnvironments';
import { usePromise } from 'utils/hooks/usePromise';
import { Spinner } from 'components/Spinner';
import { Services } from 'services/services';
import { ReactElement } from 'react';

import EnvironmentsDropdownContent from './EnvironmentsDropdownContent';

interface EnvironmentSelectorProps {
	selectedEnvironmentId: string | undefined;
	isLandscapeView?: boolean;
	hasError?: boolean;
	disabled?: boolean;
	teamId: string;
	selectEnvironmentId: (v: string) => void;
}

export default function EnvironmentSelector({
	isLandscapeView = false,
	selectedEnvironmentId,
	hasError = false,
	disabled = false,
	teamId,
	selectEnvironmentId,
}: EnvironmentSelectorProps): ReactElement {
	const teamResult = usePromise(() => Services.teams.fetchTeam(teamId), [teamId]);
	const { environments, isGlobalSelected, selectedEnvironment, environmentsIsLoading } =
		useEnvironments(selectedEnvironmentId);

	const allowedEnvironments = teamResult.value?.allowedEnvironments || [];

	hasError =
		hasError ||
		(selectedEnvironmentId && !environmentsIsLoading && !allowedEnvironments.includes(selectedEnvironmentId)
			? true
			: false) ||
		(!selectedEnvironmentId && !environmentsIsLoading);

	const environmentName = selectedEnvironmentId
		? selectedEnvironment?.name
			? selectedEnvironment.name
			: environmentsIsLoading
				? ''
				: 'Unknown'
		: 'select an environment';

	return (
		<Dropdown
			placement="bottom-start"
			renderDropdownContent={({ close }) => (
				<presets.dropdown.DropdownContentFrame maxHeight="500px" style={{ borderRadius: 'xxSmall' }}>
					{environmentsIsLoading || teamResult.loading ? (
						<Spinner variant={'small'} />
					) : (
						<EnvironmentsDropdownContent
							environments={environments.filter(
								(environment) =>
									environment.id === selectedEnvironmentId || allowedEnvironments.includes(environment.id),
							)}
							selectedEnvironmentId={selectedEnvironmentId}
							allowedEnvironments={allowedEnvironments}
							isLandscapeView={isLandscapeView}
							disabled={disabled}
							selectEnvironmentId={(id) => {
								selectEnvironmentId(id);
								close();
							}}
						/>
					)}
				</presets.dropdown.DropdownContentFrame>
			)}
		>
			{({ setRefElement, isOpen, setOpen }) => {
				return (
					<Pill
						ref={setRefElement}
						withLeftIcon={isGlobalSelected ? 'environment-global' : 'environment'}
						withRightIcon={isOpen ? 'arrow-drop-up' : 'arrow-drop-down'}
						type={hasError ? 'error' : 'default'}
						disabled={disabled}
						onClick={disabled ? undefined : () => setOpen(!isOpen)}
					>
						{environmentName}
					</Pill>
				);
			}}
		</Dropdown>
	);
}
