/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { BasicStyleProperties, resolveStyleProp, resolveSx, resolveTxAndVariant } from 'components/types';
import { Image as RebassImage } from 'theme-ui';
import { ReactElement } from 'react';

export interface ImageProps extends BasicStyleProperties {
	children?: ReactElement;
	src?: string;
	flexShrink?: number;

	tx?: string;
	variant?: string;
}

export function Image({
	children,
	flexShrink,
	backgroundColor,
	bg,
	color,
	height,
	minHeight,
	maxHeight,
	width,
	minWidth,
	maxWidth,
	m,
	mb,
	ml,
	mr,
	mt,
	mx,
	my,
	p,
	pb,
	pl,
	pr,
	pt,
	px,
	py,
	overflow,
	overflowX,
	overflowY,
	opacity,
	sx = {},
	tx,
	variant,
	...props
}: ImageProps): ReactElement {
	return (
		<RebassImage
			{...props}
			sx={{
				flexShrink,
				backgroundColor,
				bg,
				color,
				height: resolveStyleProp(height),
				minHeight: resolveStyleProp(minHeight),
				maxHeight: resolveStyleProp(maxHeight),
				width: resolveStyleProp(width),
				minWidth: resolveStyleProp(minWidth),
				maxWidth: resolveStyleProp(maxWidth),
				m: resolveStyleProp(m),
				mb: resolveStyleProp(mb),
				ml: resolveStyleProp(ml),
				mr: resolveStyleProp(mr),
				mt: resolveStyleProp(mt),
				mx: resolveStyleProp(mx),
				my: resolveStyleProp(my),
				p: resolveStyleProp(p),
				pb: resolveStyleProp(pb),
				pl: resolveStyleProp(pl),
				pr: resolveStyleProp(pr),
				pt: resolveStyleProp(pt),
				px: resolveStyleProp(px),
				py: resolveStyleProp(py),
				opacity: resolveStyleProp(opacity),
				overflow,
				overflowX,
				overflowY,
				...resolveSx(sx),
			}}
			variant={resolveTxAndVariant(tx, variant)}
		>
			{children}
		</RebassImage>
	);
}
