/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import React, { ReactElement } from 'react';

import { Text, TextProps } from '../Text';
import { RouterLink } from '../Link';

export interface BreadcrumbItemProps extends TextProps {
	label: string;
	to: string;
	variant?: string;
}

/**
 * @deprecated BreadCrumb/Breadcrumbs has a too static behaviour and needs to much definition when used from the outside.
 *
 * Use components/Breadcrumbs instead.
 */
export function BreadcrumbItem({ variant, sx, label, to }: BreadcrumbItemProps): ReactElement {
	sx = { color: 'neutral300', ...sx };
	return (
		<>
			<RouterLink to={to}>
				<Text variant={variant} as={'span'} sx={{ ...sx }}>
					{label}
				</Text>
			</RouterLink>{' '}
			<Text variant={variant} sx={{ ...sx }} muted as={'span'}>
				/
			</Text>
		</>
	);
}

export type BreadcrumbsProps = ContainerProps;

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ children, ...props }) => {
	return <Container {...props}>{children}</Container>;
};
