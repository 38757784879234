/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, NumberInput, presets } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';

export interface UnitFieldUnit {
	label: string;
	id: string;
}

interface UnitFieldProps {
	unitSelectorDisabled?: boolean;
	units?: UnitFieldUnit[];
	unit: UnitFieldUnit;
	disabled?: boolean;
	hasError?: boolean;
	width?: string;
	value?: string;
	min?: number;
	max?: number;
	onUnitChange?: (unit: string) => void;
	onChange: (value: number) => void;
}

export default function UnitField({
	unitSelectorDisabled,
	hasError,
	disabled,
	value,
	width,
	min,
	max,
	units,
	unit,
	onUnitChange,
	onChange,
}: UnitFieldProps): ReactElement {
	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<NumberInput
				value={value ? Number.parseInt(value) || 0 : 0}
				disabled={disabled}
				errored={hasError}
				min={min}
				max={max}
				onChange={onChange}
				style={{
					width,
					borderTopRightRadius: 0,
					borderBottomRightRadius: 0,
				}}
			/>

			{units && units.length > 1 && onUnitChange ? (
				<presets.dropdown.SingleChoiceButton
					disabled={disabled || unitSelectorDisabled}
					withKeyboardNavigation
					placement="bottom-end"
					selectedId={unit.id}
					items={units}
					style={{
						borderTopLeftRadius: 0,
						borderBottomLeftRadius: 0,
					}}
					onSelect={onUnitChange}
				>
					{unit.label}
				</presets.dropdown.SingleChoiceButton>
			) : (
				<Button
					type="secondary"
					style={{
						borderTopLeftRadius: 0,
						borderBottomLeftRadius: 0,
					}}
				>
					{unit.label}
				</Button>
			)}
		</div>
	);
}
