/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ErrorMessage, presets, SingleChoiceListItem } from '@steadybit/ui-components-lib';
import { dateAsUserTimezone, getDayPeriod } from 'utils/dateFns';
import { ReactElement, useMemo, useState } from 'react';
import Calendar from 'components/Calendar/Calendar';
import { Stack, Text } from 'components';
import { theme } from 'styles.v2/theme';

type DayPeriod = 'AM' | 'PM';

interface ScheduleOnceProps {
	disabled: boolean;
	pickedDate: Date;
	setPickedDate: (date: Date) => void;
}

export default function ScheduleOnce({ pickedDate, disabled, setPickedDate }: ScheduleOnceProps): ReactElement {
	const [dayPeriod, setDayPeriod] = useState<DayPeriod>(getDayPeriod(pickedDate));

	const userTimezoneNow = dateAsUserTimezone(new Date());
	const isPickedDateValid = pickedDate.getTime() > userTimezoneNow.getTime();

	return (
		<Stack size="small" alignItems="center" mt="large" mb="medium">
			<Stack
				sx={{
					py: 'small',
					px: 'medium',
					borderRadius: 8,
					border: '1px solid ' + theme.colors.neutral300,
					alignItems: 'center',
					background: 'neutral000',
				}}
			>
				<Calendar
					defaultActiveStartDate={pickedDate}
					defaultValue={pickedDate}
					value={pickedDate}
					tileDisabled={() => disabled}
					onChange={(e) => {
						if (e instanceof Date) {
							setPickedDate(e);
							setDayPeriod('AM');
						}
					}}
					minDate={userTimezoneNow}
				/>
				<Stack size="xxSmall" alignItems="center">
					<Text variant="small" color="neutral600" fontWeight={600}>
						Pick a time:
					</Text>

					<Stack direction="horizontal" size="xSmall" alignItems="center">
						<Numbers
							numberOfItems={12}
							startAt={1}
							value={String(
								pickedDate.getHours() > 12
									? pickedDate.getHours() - 12
									: pickedDate.getHours() === 0
										? 12
										: pickedDate.getHours(),
							).padStart(2, '0')}
							setValue={(hours) => {
								const date = new Date(pickedDate);
								// special case, 12AM
								if (hours === '12' && dayPeriod === 'AM') {
									date.setHours(0);
									// special case, 12PM
								} else if (hours === '12' && dayPeriod === 'PM') {
									date.setHours(Number(hours));
								} else {
									date.setHours(Number(hours) + (dayPeriod === 'PM' ? 12 : 0));
								}
								setPickedDate(date);
							}}
							disabled={disabled}
						/>
						<Text variant="small" color="neutral600" fontWeight={600}>
							:
						</Text>
						<Numbers
							numberOfItems={60}
							value={String(pickedDate.getMinutes()).padStart(2, '0')}
							setValue={(minutes) => {
								const date = new Date(pickedDate);
								date.setMinutes(Number(minutes));
								setPickedDate(date);
							}}
							disabled={disabled}
						/>
						<Text variant="small" color="neutral600" fontWeight={600}>
							:
						</Text>
						<Numbers
							numberOfItems={60}
							value={String(pickedDate.getSeconds()).padStart(2, '0')}
							setValue={(seconds) => {
								const date = new Date(pickedDate);
								date.setSeconds(Number(seconds));
								setPickedDate(date);
							}}
							disabled={disabled}
						/>
						<Text variant="small" color="neutral600" fontWeight={600}>
							:
						</Text>

						<presets.dropdown.SingleChoiceButton
							selectedId={dayPeriod}
							items={['AM', 'PM'].map((dp) => ({ id: dp, label: dp }))}
							disabled={disabled}
							size="small"
							onSelect={(id) => {
								const newDayPeriod = id === 'PM' ? 'PM' : 'AM';
								if (dayPeriod === newDayPeriod) {
									return;
								}
								setDayPeriod(newDayPeriod);
								const dateCopy = new Date(pickedDate);
								dateCopy.setHours(Number(dateCopy.getHours()) + (newDayPeriod === 'PM' ? 12 : -12));
								setPickedDate(dateCopy);
							}}
						>
							{dayPeriod}
						</presets.dropdown.SingleChoiceButton>
					</Stack>
				</Stack>
			</Stack>

			{!isPickedDateValid ? (
				<Stack direction="horizontal" size="small" alignItems="center">
					<ErrorMessage type="small" level="error" withIcon>
						You need to select a date in the future
					</ErrorMessage>
				</Stack>
			) : (
				<Stack direction="horizontal" size="small" alignItems="center"></Stack>
			)}
		</Stack>
	);
}

interface NumberSelectorProps {
	numberOfItems: number;
	disabled: boolean;
	startAt?: number;
	value: string;
	setValue: (value: string) => void;
}

function Numbers({ startAt = 0, numberOfItems, value, setValue, disabled }: NumberSelectorProps): ReactElement {
	const options = useMemo(() => {
		const options: SingleChoiceListItem[] = [];
		for (let i = 0; i < numberOfItems; i++) {
			options.push({
				id: String(startAt + i),
				label: String(startAt + i).padStart(2, '0'),
			});
		}
		return options;
	}, [startAt, numberOfItems]);

	return (
		<presets.dropdown.SingleChoiceButton
			items={options}
			disabled={disabled}
			size="small"
			maxContentHeight="300px"
			onSelect={setValue}
		>
			{value}
		</presets.dropdown.SingleChoiceButton>
	);
}
