/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, Link, LinkProps, Spinner, Stack, Text, Tooltip } from 'components';
import LoadMoreIndicator from 'components/Pagination/LoadMoreIndicator';
import { ExecutionLogType } from 'services/executionLogsApi';
import { useExecutionLogs } from 'services/useExecutionLogs';
import { formatTimestamp } from 'utils/dateFns';
import { useTenant } from 'tenancy/useTenant';
import { Services } from 'services/services';
import { ReactElement } from 'react';

function getLevelColor(level: string): string {
	switch (level) {
		case 'DEBUG':
		case 'TRACE':
			return 'slateMidLight';
		case 'INFO':
			return 'neutral600';
		case 'WARN':
			return 'experimentWarning';
		case 'ERROR':
			return 'coral';
		case 'FATAL':
			return 'coral';
		default:
			return 'neutral800';
	}
}

type RawExecutionLogProps = { executionId: number; id: string; type: string } & LinkProps;
export function RawExecutionLog({ executionId, id, type, sx = {}, ...props }: RawExecutionLogProps): ReactElement {
	const tenant = useTenant();
	const rawLogHref = Services.executionLogs.getLogUri(executionId, type, id, tenant.key);
	return (
		<Link sx={{ fontSize: 2, ...sx }} {...props} href={rawLogHref} target="_blank" dontResolve>
			View Raw Log
		</Link>
	);
}

type ExecutionLogProperties = {
	executionId: number;
	id: string | string[];
	type: string;
	liveUpdate?: boolean;
	agentNames?: { value: string; label: string }[];
};

export function ExecutionLog({ executionId, id, type, liveUpdate, agentNames }: ExecutionLogProperties): ReactElement {
	const { result: logs, loadMore } = useExecutionLogs({ executionId, type, id, liveUpdate });

	if (!logs.value?.length) {
		return (
			<Stack size={0} sx={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}>
				{liveUpdate || logs.loading ? (
					<Spinner variant={'xxLarge'} color={'neutral500'} />
				) : (
					<Text muted>No log recorded.</Text>
				)}
			</Stack>
		);
	}

	const getAgentName = (id: string): string | null => {
		const agentName = agentNames?.find((n) => n.value === id);
		return agentName ? 'Agent ' + agentName.label : null;
	};

	return (
		<Container sx={{ fontFamily: 'monospace', '& > [data-log]:nth-child(even)': { bg: 'neutral100' } }}>
			{logs.value?.map((log, idx) => (
				<Tooltip color={'light'} content={() => getAgentName(log.id)} key={idx}>
					<Container data-log sx={{ px: 'medium', whiteSpace: 'pre-wrap' }}>
						{
							/* old log messages don't have timestamp or format, and these should only be shown for agent logs*/
							log.timestamp && type === ExecutionLogType.AGENT ? (
								<>
									<span>{formatTimestamp(log.timestamp)}</span>{' '}
									<Text as={'span'} variant={'inherit'} sx={{ color: getLevelColor(log.level) }}>
										{log.level.padEnd(5)}
									</Text>{' '}
								</>
							) : null
						}
						{/* eslint-disable-next-line react/no-danger*/}
						<span dangerouslySetInnerHTML={{ __html: log.message }} />
					</Container>
				</Tooltip>
			))}
			{liveUpdate && (
				<Container display={'flex'} sx={{ height: 24, alignItems: 'center' }}>
					<Spinner variant={'medium'} color="neutral300" />
				</Container>
			)}
			{loadMore && <LoadMoreIndicator loadMore={loadMore} />}
		</Container>
	);
}
