/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Button, Container, ModalV2, ModalContentV2, ModalFooterV2, ModalHeaderV2, Spinner, Stack } from 'components';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { Form, Formik, FormikHelpers } from 'formik';
import { CreateAccessTokenRequest } from 'ui-api';
import { ValidationError } from 'utils/error';
import { Services } from 'services/services';
import React, { ReactElement } from 'react';

import { AccessTokenDisplay } from './components/accessTokenDisplay';
import AccessTokenForm from './components/accessTokenForm';

const INITIAL_VALUES: CreateAccessTokenRequest = {
	type: 'TEAM',
	name: '',
	teamId: '',
};

const getInitialValues = async (): Promise<CreateAccessTokenRequest> => {
	const team = await Services.teams.findInitialTeam();
	return { ...INITIAL_VALUES, teamId: team?.id ?? '' };
};

function AddAccessToken({ onClose }: { onClose?: () => void }): ReactElement {
	const [submitError, setSubmitError] = React.useState<Error | null>();
	const [createdToken, setCreatedToken] = React.useState<string>();
	const [initialValues] = useAsyncState(getInitialValues);

	const handleSubmit = async (
		{ ...values }: CreateAccessTokenRequest,
		form: FormikHelpers<CreateAccessTokenRequest>,
	): Promise<void> => {
		try {
			setSubmitError(null);
			const response = await Services.accessTokens.createAccessToken({
				...values,
				// Make sure to remove the team ID from the backend call to ensure
				// that we are compliant to the backend validation.
				teamId: values.type === 'ADMIN' ? undefined : values.teamId,
			});
			setCreatedToken(response.token);
		} catch (error) {
			if (error instanceof ValidationError) {
				error.violations.forEach((violation) => form.setFieldError(violation.field, violation.message));
			} else {
				setSubmitError(error);
			}
		}
	};

	const hasToken = Boolean(createdToken);

	return (
		<>
			{initialValues.loading ? (
				<Stack>
					<Spinner variant="large" color={'neutral500'} mr={'auto'} />
				</Stack>
			) : null}
			{!initialValues.loading && initialValues.value ? (
				<Formik initialValues={initialValues.value} onSubmit={handleSubmit}>
					{({ isSubmitting, values }) => (
						<Form noValidate>
							<ModalV2 withFooter>
								<ModalHeaderV2 title={hasToken ? 'API Access Token added' : 'Add API Access Token'} onClose={onClose} />
								<ModalContentV2>
									<AccessTokenForm accessToken={createdToken} isAdminToken={values.type === 'ADMIN'} />
									<AccessTokenDisplay accessToken={createdToken} />
								</ModalContentV2>
								<ModalFooterV2>
									<>
										{submitError ? (
											<Container flex={'1 0 auto'} color="coral">
												{submitError.toString()}
											</Container>
										) : null}
										{!hasToken && (
											<Button id={'save'} type={'submit'} loading={isSubmitting}>
												Save
											</Button>
										)}
									</>
								</ModalFooterV2>
							</ModalV2>
						</Form>
					)}
				</Formik>
			) : null}
		</>
	);
}

export default AddAccessToken;
