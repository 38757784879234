/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ExploreNoEnvironmentsView } from 'targets/Explore/ExploreResultView/ExploreNoEnvironmentsView';
import { EnvironmentSummaryVO, LandscapeViewVO } from 'ui-api';
import { useEnvironments } from 'utils/hooks/useEnvironments';
import { Container, Spinner, Stack, Text } from 'components';
import { DataStreamResult } from 'utils/hooks/stream/result';
import { TableView } from 'targets/TableView/TableView';
import { useLicenseFeature } from 'services/licenseApi';
import { Redirect, Route, Switch } from 'url/router';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import CustomViews from 'pages/customViews';
import { useTeam } from 'services/useTeam';
import AdviceList from 'pages/advice';
import Explore from 'targets/Explore';
import { ReactElement } from 'react';

import { useLastVisitedView } from './hocs/lastVisitedView';
import LandscapeHeader from './components/LandscapeHeader';

export default function LandscapeViewer(): ReactElement {
	const team = useTeam();
	const landscapeEnabled = useLicenseFeature('LANDSCAPE');
	const { lastVisitedViewId, setLastViewedId, deleteLastViewedId } = useLastVisitedView();
	const { defaultSelectedEnvironment, environments, environmentsIsLoading } = useEnvironments();

	const { value: defaultSelectedEnvironmentHasK8SDeployment } = usePromise<boolean | undefined>(async () => {
		if (!landscapeEnabled) {
			return false;
		}
		if (defaultSelectedEnvironment) {
			return environmentHasK8SDeployment(defaultSelectedEnvironment);
		}
	}, [defaultSelectedEnvironment?.id]);

	let templateViews: DataStreamResult<LandscapeViewVO[]> | undefined;
	if (landscapeEnabled) {
		templateViews = usePromise(() => Services.landscapeV2.getTemplateViews(), [team]);
	}
	return (
		<Container
			as={'main'}
			height={'100%'}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gridArea: 'main',
				overflow: 'auto',
				position: 'relative',
			}}
		>
			<Switch>
				{landscapeEnabled && (
					<Route path={'/landscape/explore/<new>'} exact>
						{() => {
							deleteLastViewedId();
							return <Redirect to="/landscape/explore" />;
						}}
					</Route>
				)}
				{landscapeEnabled && (
					<Route path={'/landscape/explore/:viewId?'}>
						{({ match }) => {
							const viewId = match?.params.viewId;

							if (viewId && viewId.startsWith('template')) {
								if (templateViews?.loading) {
									return (
										<Stack mt={'xxLarge'}>
											<Spinner variant={'xxLarge'} color={'neutral200'} />
										</Stack>
									);
								} else if (templateViews?.value) {
									const templateView = templateViews.value.find((templateView) => viewId === templateView.screenshot);
									if (templateView) {
										return <Redirect to={`/landscape/explore/${templateView.id}`} />;
									} else {
										return <Redirect to={'/landscape/explore'} />;
									}
								}
							} else {
								if (viewId && lastVisitedViewId !== viewId) {
									setLastViewedId(viewId);
								}
								return (
									<>
										<LandscapeHeader
											selectedViewId={viewId ?? lastVisitedViewId ?? undefined}
											selectedEnvironmentId={defaultSelectedEnvironment?.id}
											isCustomViews={!!viewId}
											isExplore={!viewId}
										/>
										<Explore viewId={viewId ?? lastVisitedViewId ?? undefined} />
									</>
								);
							}
						}}
					</Route>
				)}
				{landscapeEnabled && (
					<Route path={'/landscape/views'}>
						{() => {
							return (
								<>
									<LandscapeHeader
										selectedEnvironmentId={defaultSelectedEnvironment?.id}
										selectedViewId={lastVisitedViewId}
										isCustomViews
									/>
									<CustomViews />
								</>
							);
						}}
					</Route>
				)}
				<Route path={'/landscape/:environmentId/table'}>
					{({ match }) => {
						const environmentId = match?.params.environmentId;
						if (environmentsIsLoading) {
							return (
								<Stack mt={'xxLarge'}>
									<Spinner variant={'xxLarge'} color={'neutral200'} />
								</Stack>
							);
						}
						const predicate = environments.find((a) => a.id === environmentId)?.predicate;
						if (predicate === null) {
							return (
								<Text muted p={'large'}>
									Environment not found
								</Text>
							);
						}

						return (
							<>
								<LandscapeHeader selectedEnvironmentId={environmentId} selectedViewId={lastVisitedViewId} isTable />
								<TableView
									environmentId={environmentId || ''}
									predicate={predicate ? predicate : { predicates: [], operator: 'AND' }}
								/>
							</>
						);
					}}
				</Route>
				<Route path={'/landscape/:environmentId/advice'}>
					{({ match }) => {
						const environmentId = match?.params.environmentId;
						if (environmentsIsLoading) {
							return (
								<Stack mt={'xxLarge'}>
									<Spinner variant={'xxLarge'} color={'neutral200'} />
								</Stack>
							);
						}
						const predicate = environments.find((a) => a.id === environmentId)?.predicate;
						if (predicate === null) {
							return (
								<Text muted p={'large'}>
									Environment not found
								</Text>
							);
						}

						return (
							<>
								<LandscapeHeader selectedEnvironmentId={environmentId} selectedViewId={lastVisitedViewId} isAdvice />
								<AdviceList
									predicate={predicate ? predicate : { predicates: [], operator: 'AND' }}
									environmentId={environmentId || ''}
								/>
							</>
						);
					}}
				</Route>
				<Route path={'/landscape'}>
					{() => {
						if (
							environmentsIsLoading ||
							(defaultSelectedEnvironment && defaultSelectedEnvironmentHasK8SDeployment === undefined)
						) {
							return (
								<Stack mt={'xxLarge'}>
									<Spinner variant={'xxLarge'} color={'neutral200'} />
								</Stack>
							);
						}

						if (environments?.length === 0 || !defaultSelectedEnvironment) {
							return (
								<Container
									as="main"
									sx={{
										display: 'flex',
										height: '100%',
										width: '100%',
										overflow: 'hidden',
									}}
								>
									<ExploreNoEnvironmentsView />
								</Container>
							);
						}

						if (!landscapeEnabled) {
							return <Redirect to={`/landscape/${defaultSelectedEnvironment?.id}/table`} />;
						}

						if (lastVisitedViewId != null) {
							if (lastVisitedViewId.startsWith('template')) {
								if (templateViews?.loading) {
									return (
										<Stack mt={'xxLarge'}>
											<Spinner variant={'xxLarge'} color={'neutral200'} />
										</Stack>
									);
								} else if (templateViews?.value) {
									const templateView = templateViews.value.find(
										(templateView) => lastVisitedViewId === templateView.screenshot,
									);
									if (templateView) {
										return <Redirect to={`/landscape/explore/${templateView.id}`} />;
									} else {
										deleteLastViewedId();
										return <Redirect to={'/landscape/explore'} />;
									}
								}
							} else {
								return <Redirect to={`/landscape/explore/${lastVisitedViewId}`} />;
							}
						}

						if (defaultSelectedEnvironmentHasK8SDeployment) {
							return <Redirect to="/landscape/explore/77f79cda-e1ef-4dcf-b706-9134428d6aa8" />;
						}

						return <Redirect to="/landscape/explore" />;
					}}
				</Route>
			</Switch>
		</Container>
	);
}

async function environmentHasK8SDeployment(newEnvironment: EnvironmentSummaryVO | undefined): Promise<boolean> {
	if (newEnvironment?.predicate) {
		const { hasKubernetesClusters } = await Services.landscapeV2.getEnvironmentInClusterByPredicate(
			newEnvironment.predicate,
		);
		return hasKubernetesClusters;
	}
	return false;
}
