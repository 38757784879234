/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { BaseExperimentStepVOUnion, ExperimentLaneVO, TemplatePreviewLaneVO, TemplatePreviewStepVO } from 'ui-api';
import { IconDelay, IconExplosion, IconHelp, IconReport, IconState } from 'components/icons';
import { convertDurationToSeconds } from 'utils/duration';
import { theme } from 'styles.v2/theme';
import { StyleProp } from 'components';
import { ReactElement } from 'react';

export function getBackgroundStyle(type: string): string {
	if (type === 'WAIT' || type === 'wait' || type === 'BASIC') {
		return `repeating-linear-gradient(120deg, ${theme.colors.experimentWait}, ${theme.colors.experimentWait} 4px, #aebad1 4px, #aebad1 8px)`;
	}
	if (type === 'ATTACK') {
		return theme.colors.experimentAttack;
	}
	if (type === 'LOAD_TEST') {
		return theme.colors.experimentLoadTest;
	}
	if (type === 'CHECK') {
		return theme.colors.experimentCheck;
	}
	return theme.colors.experimentOther;
}

export function Icon({ type, style }: { type: string; style: StyleProp }): ReactElement {
	if (type === 'WAIT' || type === 'wait' || type === 'BASIC') {
		return <IconDelay sx={style} />;
	}
	if (type === 'ATTACK') {
		return <IconExplosion sx={style} />;
	}
	if (type === 'LOAD_TEST') {
		return <IconReport sx={style} />;
	}
	if (type === 'CHECK') {
		return <IconState sx={style} />;
	}
	return <IconHelp sx={style} />;
}

export function getScaleFaktor(
	lanes: Array<TemplatePreviewLaneVO | ExperimentLaneVO>,
	widthOfPreview: number,
	pixelPerSecond: number,
	gapPerStep: number,
): number {
	const maxGaps = lanes.reduce((acc, lane) => Math.max(acc, lane.steps.length - 1), 0);
	const totalGap = maxGaps * gapPerStep;
	widthOfPreview -= totalGap;

	let maxWidth = 0;
	for (let iL = 0; iL < lanes.length; iL++) {
		const lane = lanes[iL];
		const steps: Array<TemplatePreviewStepVO | BaseExperimentStepVOUnion> = lane.steps;
		const laneDuration = steps.reduce((acc, step) => {
			if (isTemplatePreviewStepVO(step)) {
				return acc + step.durationInSeconds;
			}

			return acc + (convertDurationToSeconds(step.parameters.duration) || 0);
		}, 0);
		maxWidth = Math.max(maxWidth, laneDuration * pixelPerSecond);
	}
	if (maxWidth > widthOfPreview) {
		return widthOfPreview / maxWidth;
	}
	return 1;
}

function isTemplatePreviewStepVO(
	step: TemplatePreviewStepVO | BaseExperimentStepVOUnion,
): step is TemplatePreviewStepVO {
	return (step as TemplatePreviewStepVO).durationInSeconds !== undefined;
}
