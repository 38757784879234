/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Button, Colors, Flex, Text } from '@steadybit/ui-components-lib';
import { ReactElement, useCallback, useState } from 'react';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { useDropzone } from 'react-dropzone';
import { Services } from 'services/services';

import { Snackbar } from '../Snackbar';
import { FileLink } from './FileLink';
import { Spinner } from '../Spinner';
import { Stack } from '../Stack';

interface FileUploadProps {
	useTemporaryFiles: boolean;
	acceptLabel?: string;
	disabled?: boolean;
	accept?: string;
	value?: string;
	onChange?: (value: string | undefined) => void;
}

export function FileUpload({
	useTemporaryFiles,
	acceptLabel,
	disabled,
	accept,
	value,
	onChange,
}: FileUploadProps): ReactElement {
	const [progress, setProgress] = useState<number | undefined>();

	const [filename] = useAsyncState(async () => (value ? Services.files.fetchFilename(value) : undefined), [value]);

	const onDrop = useCallback(
		async (acceptedFiles: File[]) => {
			if (disabled) {
				return;
			}
			try {
				const uploadResult = await Services.files.upload(
					acceptedFiles[0],
					(progressEvent) =>
						progressEvent.total && setProgress(Math.round((100 * progressEvent.loaded) / progressEvent.total)),
					useTemporaryFiles,
				);
				onChange?.(uploadResult.fileId);
			} catch (err) {
				onChange?.(undefined);
				Snackbar.error('File could not be uploaded: ' + err.toString(), {
					toastId: 'file-upload-error',
				});
			} finally {
				setProgress(undefined);
			}
		},
		[onChange],
	);
	const onDelete = useCallback(async () => {
		if (!useTemporaryFiles && value) {
			try {
				await Services.files.deleteFile(value);
			} catch (err) {
				Snackbar.error('File could not be deleted: ' + err.toString(), {
					toastId: 'file-delete-error',
				});
			}
		}
		onChange?.(undefined);
	}, [onChange]);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept, maxFiles: 1 });

	return (
		<>
			{value ? (
				<Flex align="end" style={{ marginTop: '-26px' }}>
					<Button type="chromeless" withLeftIcon="delete" size="small" disabled={disabled} onClick={onDelete}>
						Delete
					</Button>

					<Frame isDragActive={isDragActive}>
						<FileLink fileId={value}>
							<Text type="smallStrong" textEllipsis>
								{!filename.loading && filename.value ? filename.value : '...'}
							</Text>
						</FileLink>
					</Frame>
				</Flex>
			) : (
				<div {...getRootProps()} style={{ width: '100%', cursor: disabled ? 'not-allowed' : 'pointer' }}>
					<Frame isDragActive={isDragActive}>
						<>
							<input {...getInputProps()} disabled={disabled} />

							{progress ? (
								<>
									<Text type="small" style={{ color: Colors.neutral600 }}>
										Uploading... ({progress}%)
									</Text>
									<Stack justifyContent={'center'} alignItems={'center'} ml={'xSmall'} flexShrink={0}>
										<Spinner variant="small" color={'neutral600'} />
									</Stack>
								</>
							) : (
								<Flex align="center" justify="center" spacing="xSmall">
									<div style={{ position: 'relative' }}>
										<svg width="32" height="41" viewBox="0 0 32 41" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M0.75 4.5C0.75 2.70508 2.20508 1.25 4 1.25H20C20.1212 1.25 20.2375 1.29816 20.3232 1.38388L31.1161 12.1768C31.2018 12.2625 31.25 12.3788 31.25 12.5V36.5C31.25 38.2949 29.7949 39.75 28 39.75H4C2.20507 39.75 0.75 38.2949 0.75 36.5V4.5Z"
												fill="white"
												stroke="#CCD4DD"
												strokeWidth="1.5"
											/>
											<path d="M20 1V8.5C20 10.7091 21.7909 12.5 24 12.5H31.5" stroke="#CCD4DD" strokeWidth="1.5" />
										</svg>
										<div
											style={{
												position: 'absolute',
												top: '19px',
												right: '8px',
												display: 'flex',
												alignItems: 'center',
												padding: '1px 10px',
												backgroundColor: Colors.slate,
												borderRadius: '3px',
											}}
										>
											<Text type="xSmallStrong" style={{ color: Colors.neutral000, whiteSpace: 'nowrap' }}>
												{acceptLabel || accept}
											</Text>
										</div>
									</div>
									<Text type="xSmall" style={{ color: Colors.neutral700, textAlign: 'center' }}>
										Drag and drop or click here to upload file {accept}
									</Text>
								</Flex>
							)}
						</>
					</Frame>
				</div>
			)}
		</>
	);
}

export function Frame({ isDragActive, children }: { isDragActive?: boolean; children: ReactElement }): ReactElement {
	return (
		<Flex
			align="center"
			justify="center"
			style={{
				height: '124px',
				width: '100%',
				border: '1px dashed',
				borderRadius: 'xxSmall',
				backgroundColor: Colors.neutral100,
				borderColor: isDragActive ? Colors.slate : Colors.neutral300,
				pointerEvents: 'none',
				overflow: 'hidden',
			}}
		>
			{children}
		</Flex>
	);
}
