/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import ListHeaderTitle from 'components/List/presets/ListHeaderTitle';
import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import Notifications from 'components/Notifications/Notifications';
import useGlobalPermissions from 'services/useGlobalPermissions';
import ListHeader from 'components/List/presets/ListHeader';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { Button } from '@steadybit/ui-components-lib';
import { RouterLink, Tooltip } from 'components';
import DimensionAware from 'hocs/DimensionAware';
import { usePage } from 'utils/hooks/usePage';
import { Services } from 'services/services';
import { IconTeam } from 'components/icons';
import { debounce } from 'lodash';

import ContentWrapper from '../components/ContentWrapper';
import HelpText from '../components/HelpText';
import TeamContent from './TeamContent';
import { ampli } from '../../../ampli';

export default function Teams(): ReactElement {
	const permissions = useGlobalPermissions();

	const page = usePage('/teams', { page: 0, size: 15, sort: [['name', 'asc', 'ignoreCase']] });

	const [teams, fetch] = useAsyncState(
		() => Services.teams.fetchTeams(page.pageParams),
		[page.pageParams.page, page.pageParams.size, page.pageParams.sort.join(',')],
	);

	const debouncedFetch = useMemo(() => debounce(fetch, 100), [fetch]);
	useEventEffect(
		useCallback(
			(event) => {
				if ('team.created' === event.type || 'team.deleted' === event.type || 'team.updated' === event.type) {
					debouncedFetch();
				} else if (teams.value?.content.some((team) => team.id === event.teamId)) {
					debouncedFetch();
				}
			},
			[debouncedFetch, teams],
		),
		[],
		debouncedFetch.cancel,
		[teams.value],
	);

	useEffect(() => {
		ampli.teamListViewed({ url: window.location.href });
	}, []);

	return (
		<ContentWrapper>
			<ListHeader
				left={<ListHeaderTitle title="Teams" Icon={IconTeam} />}
				description={
					<>
						<HelpText>
							Ever wanted to give access to specific environments, experiments and allowed attacks to a set of users?
							That&apos;s exactly where teams come in handy. With the help of teams you can specify who is allowed to
							modify experiments, execute an experiment for an environment as well as which attacks can be performed.
							All others have only read only access.
						</HelpText>
						<Notifications types={['LICENSE_HARD_LIMIT_REACHED_TEAM_SIZE']} />
					</>
				}
				right={
					<Tooltip content={'You reached your maximum number of teams.'} disabled={permissions.teams.canCreate}>
						<RouterLink
							to="/settings/teams/create"
							disabled={!permissions.teams.canCreate}
							style={{ textDecoration: 'none' }}
						>
							<Button
								withLeftIcon="plus"
								disabled={!permissions.teams.canCreate}
								onClick={() => {}}
								data-cy="create-team"
							>
								Create Team
							</Button>
						</RouterLink>
					</Tooltip>
				}
			/>

			<DimensionAware>{({ width }) => <TeamContent width={width} page={page} teams={teams} />}</DimensionAware>
		</ContentWrapper>
	);
}
