/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	GetLicenseFeatureUsageResponseVO,
	GetLicenseResponseVO,
	GetLicenseSummaryVO,
	LicenseFeatureNameVO,
	LicenseFeatureVO,
	LicenseVO,
} from 'ui-api';
import { createResult, DataStreamResult, loadingResult } from 'utils/hooks/stream/result';
import { filter, from, map, startWith, switchMap } from 'rxjs';
import { useObservable } from 'utils/hooks/useObservable';
import { createContext, useContext } from 'react';
import axios from 'axios';

import { DateReviver, withReviver } from './common';
import { EventsApi } from './eventsApi';

export interface LicenseContextType extends LicenseVO {
	hasExpired: boolean;
	expires?: Date;
}
export function hasExpired(license: LicenseContextType | null | undefined): boolean {
	if (!license || !license.expires) {
		return true;
	}
	return license.expires.getTime() < Date.now();
}

export const LicensesContext = createContext<LicenseContextType | null | undefined>(undefined);

export function useLicense(): LicenseContextType | null | undefined {
	return useContext(LicensesContext);
}

export const useLicenseFeature = (licenseFeature: LicenseFeatureNameVO): LicenseFeatureVO | undefined => {
	return useLicense()?.features.find((feature) => feature.name === licenseFeature);
};

export class LicenseApi {
	private eventsApi: EventsApi;

	constructor(eventsApi: EventsApi) {
		this.eventsApi = eventsApi;
	}

	async getActiveLicense(): Promise<GetLicenseResponseVO> {
		return (
			await axios.get<GetLicenseResponseVO>('/ui/license', {
				transformResponse: withReviver(DateReviver(['expires'])),
			})
		).data;
	}

	async getLicenseSummary(): Promise<GetLicenseSummaryVO> {
		return (
			await axios.get<GetLicenseSummaryVO>('/ui/license/summary', {
				transformResponse: withReviver(DateReviver(['expires'])),
			})
		).data;
	}

	private async getLicenseUsage(feature: LicenseFeatureNameVO): Promise<GetLicenseFeatureUsageResponseVO> {
		return (await axios.get<GetLicenseFeatureUsageResponseVO>(`/ui/license/featureUsage/${feature}`)).data;
	}

	useLicenseUsage$(
		feature: LicenseFeatureNameVO,
		startValue: number | undefined,
	): DataStreamResult<GetLicenseFeatureUsageResponseVO> {
		return useObservable(
			() =>
				this.eventsApi.events$.pipe(
					filter((event) => 'license.usage.updated' === event.type && event.licenseFeatureName === feature),
					switchMap(() =>
						from(this.getLicenseUsage(feature)).pipe(
							map((res) => createResult(res)),
							startWith(loadingResult),
						),
					),
					startWith(createResult(createResponse(feature, startValue ?? 0))),
				),
			[feature],
		);
	}
}

function createResponse(feature: LicenseFeatureNameVO, value: number): GetLicenseFeatureUsageResponseVO {
	return {
		usage: {
			name: feature,
			used: value,
		},
	};
}
