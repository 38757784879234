/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import React from 'react';

export const useDimensions = <T extends Element>(
	externalRef?: React.RefObject<T>,
): [React.RefObject<T | null>, [number, number]] => {
	const ref = externalRef || React.useRef<T>(null);
	const [dimensions, setDimensions] = React.useState<[number, number]>([0, 0]);

	const calcOverflow = (): void => {
		const el = ref.current;
		if (el && (dimensions[0] !== el.clientWidth || dimensions[1] !== el.clientHeight)) {
			setDimensions([el.clientWidth, el.clientHeight]);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const observer = React.useMemo(() => new (window as any).ResizeObserver(calcOverflow), [calcOverflow]);
	React.useLayoutEffect(() => {
		const el = ref.current;
		if (el) {
			observer.observe(el.parentNode);
			return () => {
				observer.unobserve(el);
			};
		}
	}, [ref, observer]);

	return [ref, dimensions];
};
