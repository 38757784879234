/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { BasicButton, BasicButtonProps, Container } from 'components';
import React, { ReactElement, ReactNode, useCallback } from 'react';
import { useAsyncState } from 'utils/hooks/useAsyncState';

import { Spinner } from '../Spinner';

export interface ButtonProps extends BasicButtonProps {
	muted?: boolean;
	loading?: boolean;
}

export function Button({
	children,
	loading = false,
	tx,
	variant = 'primary',
	sx = {},
	ref,
	...props
}: ButtonProps): ReactElement {
	const wrapIfLoading = useCallback(
		(children: ReactNode) => {
			if (loading) {
				return (
					<Container as="span" sx={{ visibility: 'hidden' }}>
						{children}
					</Container>
				);
			}
			return children;
		},
		[loading],
	);
	return (
		<BasicButton
			ref={ref}
			tx={tx || 'buttons'}
			variant={variant}
			sx={{
				position: 'relative',
				display: 'inline-flex',
				alignItems: 'center',
				justifyContent: 'center',
				cursor: loading ? 'initial' : 'pointer',
				textDecoration: 'none',

				':hover': {
					textDecoration: 'none',
				},

				':disabled': {
					opacity: '.35',
					pointerEvents: 'none',
				},

				pointerEvents: loading ? 'none' : 'initial',

				'[data-spinner]': {
					margin: 'auto',
					width: '1.25em',
					height: '1.25em',
				},

				...sx,
			}}
			type={'button'}
			{...props}
		>
			{wrapIfLoading(children)}
			{loading && (
				<Spinner
					white={variant.startsWith('primary')}
					data-spinner
					sx={{
						position: 'absolute',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				/>
			)}
		</BasicButton>
	);
}

export interface ProgressButtonProps extends Omit<ButtonProps, 'loading'> {
	onClick: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
}

export function ProgressButton({ onClick, children, ...props }: ProgressButtonProps): ReactElement {
	const [promise, setPromise] = React.useState<Promise<void>>(Promise.resolve());
	const [state] = useAsyncState(() => promise, [promise]);

	return (
		<Button {...props} onClick={(e) => setPromise(onClick(e))} loading={state.loading}>
			{children}
		</Button>
	);
}
