/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ExperimentSummaryVO, LastExperimentExecutionVO } from 'ui-api';
import { DataStreamResult } from 'utils/hooks/stream/result';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import { usePromise } from 'utils/hooks/usePromise';
import { PageParams } from 'utils/hooks/usePage';
import { Services } from 'services/services';
import { useState } from 'react';

export default function useRefreshingExperimentExecutions(
	experiment: ExperimentSummaryVO,
): DataStreamResult<LastExperimentExecutionVO[]> {
	const [execLoadSignal, setExecLoadSignal] = useState(0);
	const lastExecutionsResult: DataStreamResult<LastExperimentExecutionVO[]> = usePromise(async () => {
		if (execLoadSignal === 0) {
			return experiment.lastExperimentExecutions || [];
		}
		const criteria = new URLSearchParams();
		criteria.append('experimentKey', experiment.key);
		const pageParams = new PageParams(0, 10).withSort([['requested', 'desc']]);
		return (await Services.experiments.fetchExperimentRuns(criteria, pageParams)).content.map((e) => ({
			experimentExecutionId: e.id,
			requested: e.requested,
			created: e.created,
			ended: e.ended,
			state: e.state,
			reason: e.reason,
		}));
	}, [execLoadSignal]);
	useEventEffect(
		(event) => {
			if (event.type.startsWith('experiment.execution') && event.experimentKey === experiment.key) {
				setExecLoadSignal(execLoadSignal + 1);
			}
		},
		[],
		() => {},
		[experiment.key, execLoadSignal],
	);

	return lastExecutionsResult;
}
