/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useEditorSettings } from 'pages/experimentsV2/useEditorSettings';
import VariableInputsForm from 'components/Variables/VariableInputsForm';
import { Link, Stack, Text, userConfirmV2 } from 'components';
import { Button } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';
import { VariableVO } from 'ui-api';
import { useField } from 'formik';

interface AddVariablesButtonProps {
	onVariableSelected: (variable: string) => void;
}

export default function AddVariablesButton({ onVariableSelected }: AddVariablesButtonProps): ReactElement {
	const { mode } = useEditorSettings();

	if (mode === 'experiment') {
		return <AddExperimentVariablesButton onVariableSelected={onVariableSelected} />;
	}

	return <AddEnvironmentVariablesButton onVariableSelected={onVariableSelected} />;
}

function AddExperimentVariablesButton({ onVariableSelected }: AddVariablesButtonProps): ReactElement {
	const [{ value: environmentId }] = useField<string>('environmentId');
	const [{ value: experimentVariables = [] }, , { setValue, setTouched }] =
		useField<VariableVO[]>('experimentVariables');

	return (
		<Button
			withLeftIcon="plus"
			type="chromeless"
			size="small"
			onClick={async () => {
				await userConfirmV2({
					title: 'Add new experiment variable',
					width: '900px',
					message: ({ setDisabled }) => (
						<AddExperimentVariableContent
							variables={experimentVariables}
							environmentId={environmentId}
							setDisabled={setDisabled}
						/>
					),
					actions: [
						{
							value: 'confirm',
							label: 'Add variable',
							variant: 'primary',
							dataCy: 'create-env-var',
							action: async () => {
								const key = (document.getElementById('variable-key') as HTMLInputElement)?.value;
								const value = (document.getElementById('variable-value') as HTMLInputElement)?.value;

								if (key && value) {
									setValue([...experimentVariables, { key, value }]);
									setTouched(true);
									onVariableSelected(`{{${key}}}`);
								}
							},
						},
					],
					secondaryActions: [{ value: 'cancel', label: 'Cancel' }],
				});
			}}
			data-cy="add-env-var"
		>
			Add experiment variable
		</Button>
	);
}

function AddEnvironmentVariablesButton({ onVariableSelected }: AddVariablesButtonProps): ReactElement {
	const [{ value: environmentVariables = [] }, , { setValue, setTouched }] = useField<VariableVO[]>('variables');

	return (
		<Button
			withLeftIcon="plus"
			type="chromeless"
			size="small"
			onClick={async () => {
				await userConfirmV2({
					title: 'Add new environment variable',
					width: '900px',
					message: ({ setDisabled }) => (
						<AddEnvironmentVariableContent variables={environmentVariables} setDisabled={setDisabled} />
					),
					actions: [
						{
							value: 'confirm',
							label: 'Add variable',
							variant: 'primary',
							dataCy: 'create-env-var',
							action: async () => {
								const key = (document.getElementById('variable-key') as HTMLInputElement)?.value;
								const value = (document.getElementById('variable-value') as HTMLInputElement)?.value;

								if (key && value) {
									setValue([...environmentVariables, { key, value }]);
									setTouched(true);
									onVariableSelected(`{{${key}}}`);
								}
							},
						},
					],
					secondaryActions: [{ value: 'cancel', label: 'Cancel' }],
				});
			}}
			data-cy="add-env-var"
		>
			Add environment variable
		</Button>
	);
}

function AddExperimentVariableContent({
	environmentId,
	variables,
	setDisabled,
}: {
	variables: VariableVO[];
	environmentId: string;
	setDisabled: (disabled: boolean) => void;
}): ReactElement {
	return (
		<Stack size="small">
			<Text as="span">
				You can use this variable within the scope of this{' '}
				<Text variant="mediumStrong" as="span">
					experiment
				</Text>
				.
			</Text>

			<VariableInputsForm existingVariables={variables} variableValue="" variableKey="" setHasErrors={setDisabled} />
			<Text as="span" variant="medium" color="neutral600">
				Do you need to create an environment variable? You can create one directly from the
				<Link href={`/settings/environments/${environmentId}/variables`} ml="xxSmall" external dontResolve>
					<Text as="span" variant="mediumStrong" color="slate">
						environment settings
					</Text>
				</Link>
				.
			</Text>
		</Stack>
	);
}

function AddEnvironmentVariableContent({
	variables,
	setDisabled,
}: {
	variables: VariableVO[];
	setDisabled: (disabled: boolean) => void;
}): ReactElement {
	return (
		<Stack size="small">
			<Text as="span">You can use this variable across all experiments within this environment.</Text>
			<VariableInputsForm existingVariables={variables} variableValue="" variableKey="" setHasErrors={setDisabled} />
		</Stack>
	);
}
