/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { RouterLink, Stack, TableDataCell, Text, Tooltip } from 'components';
import { useUser } from 'services/usersApi';
import { ReactElement } from 'react';
import { TeamVO } from 'ui-api';

import TeamIcon from '../TeamIcon';

interface TeamNameProps {
	team: TeamVO;
}

export default function TeamName({ team }: TeamNameProps): ReactElement {
	const allowEdit = team._actions.includes('edit') || team._actions.includes('manage-members');
	const user = useUser();
	const userIsMember = !!team.members.find((member) => member.username === user.username);
	return (
		<TableDataCell>
			<Stack direction="horizontal" size="xSmall" alignItems="center">
				<TeamIcon
					userIsMember={userIsMember}
					tooltip={userIsMember ? undefined : 'You are not a member of this team, thus it is view only'}
					color={team.logoColor}
					logoId={team.logoId}
				/>

				{allowEdit ? (
					<RouterLink to={'/settings/teams/' + team.id} variant={'secondary'}>
						<TeamNameCell team={team} />
					</RouterLink>
				) : (
					<TeamNameCell team={team} />
				)}
			</Stack>
		</TableDataCell>
	);
}

function TeamNameCell({ team }: { team: TeamVO }): ReactElement {
	return (
		<Tooltip content={`${team.name} (${team.key})`} onlyShowOnEllipsis>
			<Text
				variant={'mediumStrong'}
				maxWidth={'200px'}
				overflow={'hidden'}
				sx={{ textOverflow: 'ellipsis' }}
				data-cy={`team-key-${team.key}`}
			>
				{team.name} ({team.key})
			</Text>
		</Tooltip>
	);
}
