/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	ButtonIcon,
	Checkbox,
	Code,
	Container,
	Heading,
	Link,
	Message,
	ModalContentV2,
	ModalHeaderV2,
	ModalOverlay,
	ModalV2,
	Pagination,
	Snackbar,
	Stack,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
	Text,
	Tooltip,
	userConfirm,
} from 'components';
import { IconAdviceGeneral, IconDelete, IconInformation, IconWarningCircle } from 'components/icons';
import { usePromiseWithReExecution } from 'utils/hooks/usePromiseWithReExecution';
import ListHeaderSearch from 'components/List/presets/ListHeaderSearch';
import EmptyListContent from 'components/List/EmptyListContent';
import TableLoadingRow from 'components/Table/TableLoadingRow';
import { ReactElement, useMemo, useState } from 'react';
import { localeCompareIgnoreCase } from 'utils/string';
import invokePromise from 'utils/ignorePromise';
import { Services } from 'services/services';
import { json } from 'utils/mediaTypes';
import { debounce } from 'lodash';

import { useEventEffect } from '../../../utils/hooks/useEventEffect';
import { useTrackExtensionsListViewed } from './ExtensionList';
import { usePromise } from '../../../utils/hooks/usePromise';
import { AdviceDefinitionSummaryVO } from '../../../ui-api';
import Pill from '../../../components/Pill/Pill';
import { sortAdviceDefinition } from './utils';
import HelpText from '../components/HelpText';
import { ampli } from '../../../ampli';
import VersionTag from './VersionTag';

export default function AdviceDefinitions(): ReactElement {
	const [adviceResult, refetch] = usePromiseWithReExecution(
		() => Services.adviceDefinitionsApi.fetchAdviceSummaries(),
		[],
	);
	const [showDetails, setShowDetails] = useState<AdviceDefinitionSummaryVO | null>(null);
	const [selectedAdvice, setSelectedAdvice] = useState<string[]>([]);

	const debouncedFetch = useMemo(() => debounce(refetch, 100), [refetch]);
	useEventEffect(
		debouncedFetch,
		['advice.definition.created', 'advice.definition.deleted', 'advice.definition.updated'],
		() => debouncedFetch.cancel,
	);

	const [query, setQuery] = useState<string>('');
	const pageSize = 10;
	const [page, setPage] = useState(0);
	const sortedItems = adviceResult.value?.content
		? sortAdviceDefinition(search(adviceResult.value.content, query))
		: [];

	const items = sortedItems.slice(page * pageSize, (page + 1) * pageSize);
	const totalElements = sortedItems.length;
	const totalPages = Math.ceil(totalElements / pageSize);

	const unavailableItems = items.filter((a) => !a.reportedByAgents);
	const itemsWithMultipleVersions = items.filter((a) => a.reportedByAgentsInDifferentVersions);
	const itemsWithUnknownVersion = items.filter((a) => a.reportedByAgentsWithUnknownVersion);

	const totalDeletableElements = sortedItems.filter((a) => a._actions.includes('delete')).length;
	const deleteEnabled = totalDeletableElements > 0;

	useTrackExtensionsListViewed(
		adviceResult.loading ? undefined : 'advice_definitions',
		page,
		totalElements,
		totalPages,
		unavailableItems.map((action) => action.id),
		itemsWithMultipleVersions.map((action) => action.id),
		itemsWithUnknownVersion.map((action) => action.id),
	);

	const allSelected = selectedAdvice.length === totalDeletableElements;

	return (
		<>
			{showDetails && (
				<ModalOverlay open onClose={() => setShowDetails(null)}>
					{({ close }) => (
						<AdviceDefinitionDetails
							summary={showDetails}
							close={close}
							onDeleteClick={() =>
								handleDeleteClick([showDetails], () => {
									setSelectedAdvice([]);
								})
							}
						/>
					)}
				</ModalOverlay>
			)}
			<Stack m="xLarge">
				<Stack direction={'horizontal'}>
					<HelpText sx={{ flex: '50%' }}>
						Advice describes states in your system. Advice are defined through extensions leveraging our{' '}
						<Link href="https://github.com/steadybit/advice-kit/blob/main/docs/advice-api.md" external>
							AdviceKit
						</Link>
						.
					</HelpText>
					{unavailableItems.length > 0 && (
						<Message variant={'warning'} title={'Missing Agents for Advice'} flex={'50%'}>
							No agent is reporting these advice. <br />
							If you removed the extensions, you can remove the advice from Steadybit by deleting them.
							<br />
							If this is not on purpose, please check the extension and agent logs for errors.
						</Message>
					)}
					{itemsWithMultipleVersions.length > 0 && (
						<Message variant={'warning'} title={'Multiple Versions for Advice'} flex={'50%'}>
							We found advice with multiple versions. <br />
							This could lead to unexpected behavior.
							<br />
							If this is not on purpose, please check the extension and agent logs for errors.
						</Message>
					)}
					{itemsWithUnknownVersion.length > 0 && (
						<Message variant={'warning'} title={'Unversioned Advice'} flex={'50%'}>
							We found unversioned advice. These are potentially unstable. Consider updating your extension to a
							released version. You can delete unversioned advice, agents will then be triggered to retransmit their
							current advice.
						</Message>
					)}
				</Stack>

				<Stack size={'xxSmall'}>
					<Container display="flex" alignItems="center" flexDirection="row-reverse" justifyContent="space-between">
						<ListHeaderSearch
							title="Search advice"
							value={query ?? ''}
							setValue={(v) => {
								setQuery(v);
								setPage(0);
							}}
						/>
						{deleteEnabled ? (
							<Stack direction={'horizontal'} justifyContent={'flex-end'} mb={'xxSmall'}>
								<Button
									disabled={selectedAdvice.length === 0}
									variant={'chromelessSmall'}
									onClick={() =>
										handleDeleteClick(sortedItems.filter((t) => selectedAdvice.includes(t.id)) ?? [], () => {
											setSelectedAdvice([]);
										})
									}
								>
									<IconDelete mr="xSmall" ml="-xSmall" /> Delete selected Advice
									{selectedAdvice.length > 0 ? ' (' + selectedAdvice.length + ')' : ''}
								</Button>
							</Stack>
						) : null}
					</Container>

					<Table width={'100%'}>
						<TableHead>
							<TableRow>
								<TableHeadCell colSpan={2}>
									<Tooltip content={`Select all ${totalDeletableElements} deletable Advice on all pages`}>
										<Stack direction="horizontal" size="xSmall" alignItems="center">
											<Checkbox
												checked={allSelected}
												onChange={() =>
													setSelectedAdvice(
														!allSelected
															? sortedItems.filter((i) => i._actions.includes('delete')).map((i) => i.id)
															: [],
													)
												}
												flexShrink={0}
											/>
											<Text variant="tableHeader" sx={{ textWrap: 'nowrap' }}>
												{allSelected ? 'Deselect all' : 'Select all'}
											</Text>
										</Stack>
									</Tooltip>
								</TableHeadCell>
								<TableHeadCell>Name</TableHeadCell>
								<TableHeadCell>Id</TableHeadCell>
								<TableHeadCell>Details</TableHeadCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{adviceResult.loading && (
								<>
									<TableLoadingRow numColumns={5} />
									<TableLoadingRow numColumns={5} />
									<TableLoadingRow numColumns={5} />
								</>
							)}
							{items.map((t) => {
								const props: AdviceRowProps = {
									summary: t,
									onShowDetailsClick: () => setShowDetails(t),
									selected: selectedAdvice.includes(t.id),
								};
								if (t._actions.includes('delete')) {
									props.onSelectClick = () =>
										setSelectedAdvice((prev) =>
											prev.includes(t.id) ? prev.filter((id) => id !== t.id) : [...prev, t.id],
										);
								}
								return <AdviceRow key={t.id} {...props} />;
							})}
						</TableBody>
					</Table>

					{adviceResult.value && items.length === 0 && (
						<EmptyListContent
							icon={<IconAdviceGeneral variant="xxLarge" color="purple700" />}
							title={query ? 'No advice matching your filter found' : 'No advice found'}
						/>
					)}
				</Stack>

				<Pagination activePage={page} totalPages={totalPages} onClick={setPage} />
			</Stack>
		</>
	);
}

type AdviceRowProps = {
	summary: AdviceDefinitionSummaryVO;
	onShowDetailsClick?: () => void;
	onSelectClick?: () => void;
	selected?: boolean;
};

function AdviceRow({ summary, selected, onSelectClick, onShowDetailsClick }: AdviceRowProps): ReactElement {
	return (
		<TableRow hoverable onClick={onSelectClick}>
			{onSelectClick ? (
				<TableDataCell width={'30px'}>
					<Checkbox checked={selected} onChange={onSelectClick} onClick={(e) => e.stopPropagation()} />
				</TableDataCell>
			) : null}
			<TableDataCell
				colSpan={onSelectClick ? 1 : 2}
				maxWidth={'170px'}
				minWidth={'0px'}
				width={
					!summary.reportedByAgents ||
					summary.reportedByAgentsWithUnknownVersion ||
					summary.reportedByAgentsInDifferentVersions
						? '170px'
						: '0px'
				}
			>
				<Stack size={'xSmall'} my={'xxSmall'}>
					{!summary.reportedByAgents && (
						<Pill
							backgroundColor={'feedbackWarningLightPill'}
							color={'feedbackWarningDark'}
							sx={{
								height: 32,
								borderRadius: 20,
							}}
						>
							<IconWarningCircle variant={'small'} mr={'xxSmall'} />
							<Text variant="smallStrong" ml="xxSmall" mr="xxSmall">
								Missing Agent
							</Text>
						</Pill>
					)}
					{summary.reportedByAgentsWithUnknownVersion && <VersionTag unversioned />}
					{summary.reportedByAgentsInDifferentVersions && <VersionTag multipleVersions />}
				</Stack>
			</TableDataCell>
			<TableDataCell>{summary.label}</TableDataCell>
			<TableDataCell>
				{summary.id}
				{summary.version != 'unknown' && ` @ ${summary.version}`}
			</TableDataCell>
			<TableDataCell>
				<ButtonIcon onClick={onShowDetailsClick} variant={'small'} tooltip={'Show Details'}>
					<IconInformation size={'small'} />
				</ButtonIcon>
			</TableDataCell>
		</TableRow>
	);
}

const handleDeleteClick = (summaries: AdviceDefinitionSummaryVO[], onDeleted: () => void): void => {
	const count = summaries.length;
	invokePromise(async () => {
		if (
			(await userConfirm({
				width: '550px',
				title: 'Delete Advice',
				message: (
					<>
						<Stack>
							<Text>
								Do you really want to delete the following advice{count > 1 ? 's' : ''}?
								<ul>
									{summaries.map((s) => (
										<li key={s.id}>
											<strong>{s.label}</strong>
											<br />
											{s.id}
										</li>
									))}
								</ul>
							</Text>
						</Stack>
					</>
				),
				actions: [
					{ value: 'cancel', label: 'Cancel' },
					{
						value: 'confirm',
						label: `Delete ${count == 1 ? 'Advice' : `${count} Advice`}`,
						variant: 'primary',
					},
				],
			})) === 'confirm'
		) {
			try {
				await Promise.all(summaries.map((s) => Services.adviceDefinitionsApi.deleteAdvice(s.id)));
				ampli.extensionTypeDeleted({
					url: window.location.href,
					extension_type: 'advice_definitions',
					extension_type_ids: summaries.map((s) => s.id),
				});
				Snackbar.dark('Advice deleted.', { toastId: 'advice-deleted' });
				onDeleted();
			} catch (err) {
				Snackbar.error('Advice not deleted: ' + err.toString(), {
					toastId: 'advice-deleted',
				});
			}
		}
	});
};

interface AdviceDetailsProps {
	summary: AdviceDefinitionSummaryVO;
	onDeleteClick: () => void;
}

function AdviceDefinitionDetails({
	summary,
	close,
	onDeleteClick,
}: AdviceDetailsProps & { close: () => void }): ReactElement {
	const infos = usePromise(() => Services.adviceDefinitionsApi.fetchAdviceOnAgentInfo(summary.id), [summary.id]);
	const definition = usePromise(() => Services.adviceDefinitionsApi.fetchAdvice(summary.id), [summary.id]);
	return (
		<ModalV2 width="auto">
			<ModalHeaderV2 title={`"${summary.label}" Version Info`} onClose={close} />
			<ModalContentV2>
				<Stack maxWidth="1200px" width="90vw" pb="large">
					{summary.reportedByAgents ? (
						<>
							<HelpText>
								These are the agents the advice is currently registered on. The version may differ between the agents.
							</HelpText>
							<Table width="100%">
								<TableHead>
									<TableRow>
										<TableHeadCell>Agent Hostname</TableHeadCell>
										<TableHeadCell>Advice Version</TableHeadCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{infos.loading && (
										<>
											<TableLoadingRow numColumns={2} />
										</>
									)}
									{infos.value?.content
										?.slice()
										.sort((a, b) => localeCompareIgnoreCase(a.agent, b.agent))
										.map((a) => (
											<TableRow hoverable key={a.agent}>
												<TableDataCell>{a.agent}</TableDataCell>
												<TableDataCell>{a.versions.join(', ')}</TableDataCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
							{summary.reportedByAgentsWithUnknownVersion && (
								<Message variant={'warning'} title={'Unversioned Advice'} flex={'50%'}>
									<p>
										The advice is potentially unstable cause it is reported with an unknown version. Consider updating
										your extension to a released version. You can delete the unversioned advuce, agents will then be
										triggered to retransmit their current advice.
									</p>
									{onDeleteClick && (
										<Button
											variant={'primary'}
											onClick={() => {
												close();
												onDeleteClick();
											}}
											disabled={!summary._actions.includes('delete')}
											width="fit-content"
										>
											<IconDelete mr={'xSmall'} /> Delete Advice
										</Button>
									)}
								</Message>
							)}
						</>
					) : (
						<Message variant={'danger'} title={'Missing Agents for Advice'} flex={'50%'}>
							<p>
								The advice extension is not reported by any of the registered agents. <br />
								If you removed the extension you can remove the advice from Steadybit by deleting it. <br />
								If this is not on purpose, please check the extension and agent logs for errors.
							</p>
							<Button
								variant={'primary'}
								onClick={() => {
									close();
									onDeleteClick();
								}}
								disabled={!summary._actions.includes('delete')}
								width="fit-content"
							>
								<IconDelete mr={'xSmall'} /> Delete Advice
							</Button>
						</Message>
					)}
					{definition.value && (
						<>
							<Heading>Details</Heading>
							<Code
								lang="json"
								withCopyToClipboard
								withDownload={{
									fileName: `${definition.value?.id}.json`,
									mediaType: json,
								}}
								sx={{
									fontSize: 'small',
								}}
							>
								{JSON.stringify(definition.value, undefined, 2)}
							</Code>
						</>
					)}
				</Stack>
			</ModalContentV2>
		</ModalV2>
	);
}

function search(items: AdviceDefinitionSummaryVO[], query: string): AdviceDefinitionSummaryVO[] {
	query = query.trim().toLowerCase();
	return items.filter((item) => {
		return item.id.toLowerCase().includes(query) || item.label.toLowerCase().includes(query);
	});
}
