/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { IconCalendar, IconIntegrations, IconSlack, IconWebhook, IconWebhookPreflight } from 'components/icons';
import { ModalOverlay, Tab, TabList, TabPanel, Tabs, Tooltip } from 'components';
import LicenseTooltipContent from 'App/components/LicenseTooltipContent';
import ListHeaderTitle from 'components/List/presets/ListHeaderTitle';
import ListHeader from 'components/List/presets/ListHeader';
import { useLicenseFeature } from 'services/licenseApi';
import { Route, Switch } from 'url/router';
import { useHistory } from 'url/hooks';
import { ReactElement } from 'react';
import { WebhookVO } from 'ui-api';

import ContentWrapper from '../components/ContentWrapper';
import EditWebhook from './webhooks/editWebhook';
import WebhookList from './webhooks/webhookList';
import IcalSettings from './ical/IcalSettings';
import AddWebhook from './webhooks/addWebhook';
import HelpText from '../components/HelpText';

function Integrations({ active }: { active?: string }): ReactElement {
	const preflightWebhooksEnabled = useLicenseFeature('WEBHOOK_PREFLIGHT');
	const customWebhooksEnabled = useLicenseFeature('WEBHOOK_CUSTOM');
	const icalAccessEnabled = useLicenseFeature('ICAL_ACCESS');
	const history = useHistory();

	function getWebhookAddTitle(active: string | undefined): string | undefined {
		switch (active) {
			case 'slack':
				return 'Add Slack Integration';
			case 'webhookPreflight':
				return 'Add Preflight Webhook';
			default:
				return undefined;
		}
	}

	function getWebhookEditTitle(active: string | undefined): ((webhook?: WebhookVO) => string) | undefined {
		switch (active) {
			case 'slack':
				return (webhook) => `Edit Slack Integration ${webhook?.name}`;
			case 'webhookPreflight':
				return (webhook) => `Edit Preflight Webhook ${webhook?.name}`;
			default:
				return undefined;
		}
	}

	let pathParts = 'slack';
	if (customWebhooksEnabled) {
		pathParts += '|webhook';
	}
	if (preflightWebhooksEnabled) {
		pathParts += '|webhookPreflight';
	}

	return (
		<ContentWrapper>
			<ListHeader
				left={<ListHeaderTitle title="Integrations" Icon={IconIntegrations} />}
				description={
					<HelpText>
						You can integrate Steadybit with any tool capable of being triggered via HTTP. One example would be to
						receive a message in Slack whenever an experiment is executed, canceled or stopped as well as when the
						killswitch was engaged.
						<br />
						But that&apos;s just something we had in mind, we are sure you have plenty other great ideas of
						customization.
					</HelpText>
				}
			/>
			<Tabs onChange={(target) => history.push(target)} value={active}>
				<TabList>
					<Tab value={'slack'}>
						<IconSlack size={'small'} mr={'xSmall'} ml={-8} /> Slack
					</Tab>
					<Tooltip content={<LicenseTooltipContent />} disabled={!!preflightWebhooksEnabled}>
						<Tab value={'webhookPreflight'} disabled={!preflightWebhooksEnabled}>
							<IconWebhookPreflight size={'small'} mr={'xSmall'} ml={-8} /> Preflight Webhooks
						</Tab>
					</Tooltip>
					<Tooltip content={<LicenseTooltipContent />} disabled={!!customWebhooksEnabled}>
						<Tab value={'webhook'} disabled={!customWebhooksEnabled}>
							<IconWebhook size={'small'} mr={'xSmall'} ml={-8} /> Custom Webhooks
						</Tab>
					</Tooltip>
					<Tooltip content={<LicenseTooltipContent />} disabled={!!icalAccessEnabled}>
						<Tab value={'icalAccess'} disabled={!icalAccessEnabled}>
							<IconCalendar size={'small'} mr={'xSmall'} ml={-8} /> iCal Access
						</Tab>
					</Tooltip>
				</TabList>
				<TabPanel value={'slack'}>
					<WebhookList
						type={active}
						pathSegment="/slack"
						onCreate={() => '/settings/integrations/slack/create'}
						onEdit={(id) => `/settings/integrations/slack/${id}`}
						labelCreateWebhook={'Add Slack Integration'}
						labelNoData={'No Slack Integrations found'}
					/>
				</TabPanel>
				{customWebhooksEnabled && (
					<TabPanel value={'webhook'}>
						<WebhookList
							type={active}
							pathSegment="/webhook"
							onCreate={() => '/settings/integrations/webhook/create'}
							onEdit={(id) => `/settings/integrations/webhook/${id}`}
						/>
					</TabPanel>
				)}
				{preflightWebhooksEnabled && (
					<TabPanel value={'webhookPreflight'}>
						<WebhookList
							type={active}
							pathSegment="/webhookPreflight"
							onCreate={() => '/settings/integrations/webhookPreflight/create'}
							onEdit={(id) => `/settings/integrations/webhookPreflight/${id}`}
						/>
					</TabPanel>
				)}
				{icalAccessEnabled && (
					<TabPanel value={'icalAccess'}>
						<IcalSettings />
					</TabPanel>
				)}
			</Tabs>
			<Switch>
				<Route path={`/settings/integrations/:active(${pathParts})/create`}>
					{({ match }) => (
						<ModalOverlay open={Boolean(match)} onClose={() => history.push(`/settings/integrations/${active}`)}>
							{({ close }) => (
								<AddWebhook
									// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
									type={match!.params.active}
									onClose={close}
									labelTitle={getWebhookAddTitle(active)}
								/>
							)}
						</ModalOverlay>
					)}
				</Route>
				<Route exact path={`/settings/integrations/:active(${pathParts})/:id`}>
					{({ match }) => (
						<ModalOverlay
							open={!!match && match.params.id !== 'create'}
							onClose={() => history.push(`/settings/integrations/${active}`)}
						>
							{match
								? ({ close }) => (
										<EditWebhook onClose={close} id={match.params.id} labelTitle={getWebhookEditTitle(active)} />
									)
								: null}
						</ModalOverlay>
					)}
				</Route>
			</Switch>
		</ContentWrapper>
	);
}

export default Integrations;
