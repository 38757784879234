/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	ButtonIcon,
	Container,
	RouterLink,
	RouterPagination,
	Snackbar,
	Spinner,
	Stack,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
	TableSortLink,
	Text,
	Tooltip,
	userConfirm,
} from 'components';
import { IconDelete, IconEdit, IconEnvironment, IconEnvironmentGlobal, IconFunction, IconTeam } from 'components/icons';
import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo } from 'react';
import ListHeaderTitle from 'components/List/presets/ListHeaderTitle';
import Notifications from 'components/Notifications/Notifications';
import { Order, PageLocation, usePage } from 'utils/hooks/usePage';
import { useStableInstance } from 'utils/hooks/useStableInstance';
import useGlobalPermissions from 'services/useGlobalPermissions';
import ListHeader from 'components/List/presets/ListHeader';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { Button } from '@steadybit/ui-components-lib';
import invokePromise from 'utils/ignorePromise';
import { EnvironmentSummaryVO } from 'ui-api';
import { Services } from 'services/services';
import { theme } from 'styles.v2/theme';
import debounce from 'lodash/debounce';
import { useHistory } from 'url/hooks';
import { ampli } from 'ampli';

import ContentWrapper from '../components/ContentWrapper';
import HelpText from '../components/HelpText';

function Environments(): ReactElement {
	const page = usePage('/environments', {
		size: 15,
		sort: [
			['isGlobal', 'desc', 'ignoreCase'],
			['name', 'asc', 'ignoreCase'],
		],
	});

	const permissions = useGlobalPermissions();
	const [stableId, stablePageParams] = useStableInstance(page.pageParams);
	const [environments, fetch] = useAsyncState(
		() => Services.environments.fetchEnvironments(stablePageParams),
		[stableId],
	);

	const debouncedFetch = useMemo(() => debounce(fetch, 100), [fetch]);
	useEventEffect(
		useCallback(
			(event) => {
				if (['environment.created', 'environment.deleted'].includes(event.type)) {
					debouncedFetch();
				} else if (environments.value?.content.some((environment) => environment.id === event.environmentId)) {
					debouncedFetch();
				}
			},
			[debouncedFetch, environments],
		),
		[],
		debouncedFetch.cancel,
	);

	useEffect(() => {
		ampli.environmentListViewed({ url: window.location.href });
	}, []);

	return (
		<ContentWrapper>
			<ListHeader
				left={<ListHeaderTitle title="Environments" Icon={IconEnvironment} />}
				description={
					<>
						<HelpText>
							Your system is discovered automatically by Steadybit, but slicing it right is a job for you. Set up
							environments to assign a set of discovered targets to a team. This ensures that teams can only run
							experiments against their own infrastructure and don&apos;t interfere with other teams.
						</HelpText>
						<Notifications types={['LICENSE_HARD_LIMIT_REACHED_ENVIRONMENT_SIZE']} />
					</>
				}
				right={
					<Tooltip
						content={'You reached your maximum number environments.'}
						disabled={permissions.environments.canCreate}
					>
						<RouterLink
							color={'primaryLarge'}
							to={'/settings/environments/<new>'}
							disabled={!permissions.environments.canCreate}
							style={{ textDecoration: 'none' }}
						>
							<Button withLeftIcon="plus" disabled={!permissions.environments.canCreate} onClick={() => {}}>
								Create Environment
							</Button>
						</RouterLink>
					</Tooltip>
				}
			/>
			<Stack>
				{environments.error && <Text>Error loading Environments: {environments.error.message}</Text>}
				{environments.loading && !environments.value ? (
					<Spinner variant="large" color={'neutral500'} mr={'auto'} />
				) : null}
				<Stack direction={'vertical'} size={'large'}>
					{environments.value ? (
						<EnvironmentTable page={page}>
							{environments.value.content.map((environment) => (
								<EnvironmentRow key={environment.id} environment={environment} />
							))}
						</EnvironmentTable>
					) : null}
					<RouterPagination
						activePage={page.pageParams.page}
						totalPages={environments.value?.totalPages}
						to={(i) => page.withPage(i).toString()}
					/>
				</Stack>
			</Stack>
		</ContentWrapper>
	);
}

export default Environments;

const SORT_NAME_ASC: Order[] = [['name', 'asc', 'ignoreCase']];
const SORT_NAME_DESC: Order[] = [['name', 'desc', 'ignoreCase']];

const EnvironmentTable: React.FC<{ page: PageLocation; children: ReactNode }> = ({ children, page }) => {
	return (
		<Table width={'100%'}>
			<TableHead>
				<TableRow>
					<TableHeadCell width={180}>
						<TableSortLink
							sort={page.getDirection(SORT_NAME_ASC, SORT_NAME_DESC)}
							to={page.toggleSort(SORT_NAME_ASC, SORT_NAME_DESC).toString()}
							onClick={() => {
								ampli.environmentListSorted({ sorted_by: 'Name' });
							}}
						>
							Environment Name
						</TableSortLink>
					</TableHeadCell>
					<TableHeadCell width={'auto'}>Teams</TableHeadCell>
					<TableHeadCell width={120} />
				</TableRow>
			</TableHead>
			<TableBody>{children}</TableBody>
		</Table>
	);
};

const handleDeleteEnvironmentClick = (environmentVO: EnvironmentSummaryVO) => () => {
	invokePromise(async () => {
		if (
			(await userConfirm({
				title: 'Delete Environment',
				message: `Do you really want to delete ${environmentVO.name}?`,
				actions: [
					{ value: 'cancel', label: 'Cancel' },
					{ value: 'confirm', label: `Delete ${environmentVO.name}`, variant: 'primary' },
				],
			})) === 'confirm'
		) {
			try {
				await Services.environments.deleteEnvironment(environmentVO.id);
				Snackbar.dark('Environment deleted.', { toastId: 'environment-deleted' });
			} catch (err) {
				Snackbar.error('Environment not deleted: ' + err.toString(), { toastId: 'environment-deleted' });
			}
		}
	});
};

const EnvironmentRow: React.FC<{ environment: EnvironmentSummaryVO }> = ({ environment }) => {
	const { push, createHref } = useHistory();
	return (
		<TableRow
			hoverable={true}
			height={54}
			sx={{
				...(environment.global
					? {
							borderTop: '2px solid',
							borderTopColor: 'neutral400',
							borderBottom: '2px solid',
							borderBottomColor: 'neutral400',
						}
					: {}),
			}}
		>
			<TableDataCell>
				{environment._actions.includes('edit') || environment._actions.includes('team-assign') ? (
					<RouterLink to={`/settings/environments/${environment.id}`} variant={'secondary'}>
						<EnvironmentIdentifier environment={environment} />
					</RouterLink>
				) : (
					<EnvironmentIdentifier environment={environment} />
				)}
			</TableDataCell>
			<TableDataCell>
				<IconTeam mr={'xSmall'} />
				{environment.teams.map((t) => t.name).join(', ')}
			</TableDataCell>
			<TableDataCell justifyContent={'flex-end'}>
				<ButtonIcon
					tooltip="Edit Environment variables"
					onClick={() =>
						push(
							createHref((location) => {
								location.pathname = `/settings/environments/${environment.id}/variables`;
							}),
						)
					}
				>
					<IconFunction />
				</ButtonIcon>

				{environment._actions.includes('edit') || environment._actions.includes('team-assign') ? (
					<ButtonIcon
						tooltip={'Edit Environment'}
						onClick={() =>
							push(
								createHref((location) => {
									location.pathname = `/settings/environments/${environment.id}`;
								}),
							)
						}
					>
						<IconEdit />
					</ButtonIcon>
				) : null}
				{environment._actions.includes('delete') ? (
					<ButtonIcon onClick={handleDeleteEnvironmentClick(environment)} tooltip={'Delete Environment'}>
						<IconDelete />
					</ButtonIcon>
				) : null}
			</TableDataCell>
		</TableRow>
	);
};

const EnvironmentIdentifier: React.FC<{ environment: EnvironmentSummaryVO }> = ({ environment }) => {
	return (
		<Container display={'flex'}>
			{environment.global ? (
				<IconEnvironmentGlobal
					style={{
						color: theme.colors.neutral700,
					}}
				/>
			) : (
				<IconEnvironment
					style={{
						color: theme.colors.neutral700,
					}}
				/>
			)}
			<Text variant={'mediumStrong'} as={'span'} ml={'xSmall'}>
				{environment.name}
			</Text>
		</Container>
	);
};
