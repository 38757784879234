/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	ButtonIcon,
	Container,
	Heading,
	Link,
	RouterLink,
	RouterPagination,
	Snackbar,
	Spinner,
	Stack,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
	TableSortLink,
	Text,
	userConfirm,
} from 'components';
import { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import ListHeaderSearch from 'components/List/presets/ListHeaderSearch';
import ListHeaderTitle from 'components/List/presets/ListHeaderTitle';
import { Order, PageLocation, usePage } from 'utils/hooks/usePage';
import { IconApiAccessToken, IconDelete } from 'components/icons';
import { AccessTokenVO, TeamSummaryVO, UserVO } from 'ui-api';
import ListHeader from 'components/List/presets/ListHeader';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { Button } from '@steadybit/ui-components-lib';
import { Services } from 'services/services';
import { useDebounce } from 'react-use';
import { useHistory } from 'url/hooks';
import { debounce } from 'lodash';

import TeamSelection from '../teams/components/TeamSelection';
import { formatDateWithTime } from '../../../utils/dateFns';
import ContentWrapper from '../components/ContentWrapper';
import invokePromise from '../../../utils/ignorePromise';
import HelpText from '../components/HelpText';
import { ampli } from '../../../ampli';

interface AccessTokensListProps {
	onCreate: () => string;
}

export default function AccessTokensList({ onCreate }: AccessTokensListProps): ReactElement {
	const page = usePage('/api-tokens', { size: 15, sort: [['name', 'asc', 'ignoreCase']] });
	const [tokens, fetch] = useAsyncState(
		() => Services.accessTokens.findAccessTokens(page.criteria, page.pageParams),
		[page],
	);
	const [users] = useAsyncState(
		async () =>
			tokens.value ? Services.users.findUsersByUsername(tokens.value.content.map((t) => t.createdBy)) : new Map(),
		[tokens.value?.content],
		new Map(),
	);
	const [teams] = useAsyncState(
		async () => (tokens.value ? Services.teams.findTeamsById(tokens.value.content.map((t) => t.teamId)) : new Map()),
		[tokens.value?.content],
		new Map(),
	);
	const debouncedFetch = useMemo(() => debounce(fetch, 100), [fetch]);
	useEventEffect(
		useCallback(
			(event) => {
				if (
					event.type === 'accessToken.created' ||
					tokens.value?.content.some((token) => token.id === event.accessTokenId)
				) {
					debouncedFetch();
				}
			},
			[debouncedFetch, tokens],
		),
		[],
		debouncedFetch.cancel,
		[tokens.value],
	);

	const handleDelete = (token: AccessTokenVO) => () => {
		invokePromise(async () => {
			if (
				(await userConfirm({
					title: 'Delete Access Token',
					message: `Do you really want to delete '${token.name}'?`,
					actions: [
						{ value: 'cancel', label: 'Cancel' },
						{ value: 'confirm', label: `Delete ${token.name}`, variant: 'primary' },
					],
				})) === 'confirm'
			) {
				try {
					await Services.accessTokens.deleteAccessToken(token.id);
					Snackbar.dark(`'${token.name}' removed`, { toastId: 'token-remove' });
				} catch (err) {
					Snackbar.error(`'${token.name}' not removed: ${err.toString()}`, { toastId: 'token-remove' });
				}
			}
		});
	};

	useEffect(() => {
		ampli.apiAccessTokensListViewed({ url: window.location.href });
	}, []);

	return (
		<ContentWrapper>
			<ListHeader
				left={<ListHeaderTitle title="Access Tokens" Icon={IconApiAccessToken} />}
				description={
					<HelpText>
						You want to integrate Steadybit into your existing pipelines and automation processes? We provide you with
						the API for that.
						<br />
						<br />
						<Heading variant={'small'}>API</Heading>
						Learn more by reading our{' '}
						<Link href={`${window.location.origin}/api/spec`} target={'_blank'}>
							OpenAPI specification
						</Link>{' '}
						or simply try it out with our{' '}
						<Link href={`${window.location.origin}/api/swagger`} target={'_blank'}>
							interactive API
						</Link>
						. In case you need more help on how to get started, have a look at our
						<Link href={`${process.env.REACT_APP_DOCS_URL}/integrate-with-steadybit/api`} target={'_blank'}>
							{' '}
							documentation
						</Link>{' '}
						to learn more.
						<Heading variant={'small'}>CLI</Heading>
						If you want to use our CLI to link your repository with Steadybit, have a look at our{' '}
						<Link href={`${process.env.REACT_APP_DOCS_URL}/integrate-with-steadybit/cli`} target={'_blank'}>
							{' '}
							CLI documentation{' '}
						</Link>
						or in the corresponding{' '}
						<Link href={'https://github.com/steadybit/cli'} target={'_blank'}>
							{' '}
							GitHub repository of the CLI
						</Link>
						.
					</HelpText>
				}
				right={
					<>
						<AccessTokenFilters page={page} />
						{(tokens.value?._actions.includes('create') || tokens.value?._actions.includes('createAdmin')) && (
							<RouterLink color={'primaryLarge'} to={onCreate()} style={{ textDecoration: 'none' }}>
								<Button withLeftIcon="plus" onClick={() => {}}>
									Create API Access Token
								</Button>
							</RouterLink>
						)}
					</>
				}
			/>

			<Stack>
				{tokens.error && <Text>Error loading Access Tokens: {tokens.error.message}</Text>}
				{tokens.loading && !tokens.value ? <Spinner variant="large" color={'neutral500'} mr={'auto'} /> : null}
				<Stack direction={'vertical'} size={'large'}>
					{tokens.value ? (
						<AccessTokenTable page={page}>
							{tokens.value.content.map((token) => (
								<AccessTokenRow
									key={token.id}
									value={token}
									onDelete={handleDelete(token)}
									createdBy={users.value.get(token.createdBy)}
									team={teams.value.get(token.teamId)}
								/>
							))}
							{!tokens.value.content.length && (
								<TableRow>
									<TableDataCell colSpan={7}>
										<Text muted>No Access Tokens found.</Text>
									</TableDataCell>
								</TableRow>
							)}
						</AccessTokenTable>
					) : null}
					<RouterPagination
						activePage={page.pageParams.page}
						totalPages={tokens.value?.totalPages}
						to={(i) => page.withPage(i).toString()}
					/>
				</Stack>
			</Stack>
		</ContentWrapper>
	);
}

function AccessTokenFilters({ page }: { page: PageLocation }): ReactElement {
	const history = useHistory();
	const [name, setName] = useState<string | null>(page.criteria.get('name'));
	useDebounce(
		() => {
			if (name) {
				ampli.apiAccessTokensListFiltered({ filter_text: name });
			}
			history.replace(page.withCriterion('name', name).toString());
		},
		350,
		[name],
	);

	return (
		<>
			<ListHeaderSearch
				title="Search token"
				value={name ?? ''}
				setValue={(v) => {
					setName(v);
					// reset pagination information when filtering
					history.replace(page.withPage(0).toString());
				}}
			/>

			<Container width="200px">
				<TeamSelection
					value={page.criteria.get('teamId') || undefined}
					includeAny
					onChange={(teamId) => history.replace(page.withCriterion('teamId', teamId || null).toString())}
				/>
			</Container>
		</>
	);
}

const SORT_ID_ASC: Order[] = [['id', 'asc']];
const SORT_ID_DESC: Order[] = [['id', 'desc']];
const SORT_NAME_ASC: Order[] = [['name', 'asc', 'ignoreCase']];
const SORT_NAME_DESC: Order[] = [['name', 'desc', 'ignoreCase']];

const AccessTokenTable: React.FC<{ page: PageLocation; children: ReactNode }> = ({ page, children }) => {
	return (
		<>
			<Table width={'100%'}>
				<TableHead>
					<TableRow>
						<TableHeadCell>Type</TableHeadCell>
						<TableHeadCell>
							<TableSortLink
								sort={page.getDirection(SORT_ID_ASC, SORT_ID_DESC)}
								to={page.toggleSort(SORT_ID_ASC, SORT_ID_DESC).toString()}
								onClick={() => {
									ampli.apiAccessTokensListSorted({ sorted_by: 'Prefix' });
								}}
							>
								Prefix
							</TableSortLink>
						</TableHeadCell>
						<TableHeadCell>
							<TableSortLink
								sort={page.getDirection(SORT_NAME_ASC, SORT_NAME_DESC)}
								to={page.toggleSort(SORT_NAME_ASC, SORT_NAME_DESC).toString()}
								onClick={() => {
									ampli.apiAccessTokensListSorted({ sorted_by: 'Name' });
								}}
							>
								Name
							</TableSortLink>
						</TableHeadCell>
						<TableHeadCell>Team</TableHeadCell>
						<TableHeadCell>User</TableHeadCell>
						<TableHeadCell>Last used</TableHeadCell>
						<TableHeadCell width={50} />
					</TableRow>
				</TableHead>
				<TableBody>{children}</TableBody>
			</Table>
		</>
	);
};

interface AccessTokenRowProps {
	value: AccessTokenVO;
	team?: TeamSummaryVO;
	createdBy?: UserVO;
	onDelete: () => void;
}

function AccessTokenRow({ value, createdBy, team, onDelete }: AccessTokenRowProps): ReactElement {
	return (
		<TableRow hoverable>
			<TableDataCell>
				<code>{value.type === 'ADMIN' ? 'Admin' : 'Team'}</code>
			</TableDataCell>
			<TableDataCell>
				<code>{value.id}</code>
			</TableDataCell>
			<TableDataCell>
				<Text variant={'mediumStrong'}>{value.name}</Text>
			</TableDataCell>
			<TableDataCell>{team ? `${team.key} ${team.name}` : value.teamId || '-'}</TableDataCell>
			<TableDataCell data-private>{createdBy ? createdBy.name : value.createdBy}</TableDataCell>
			<TableDataCell>{value.lastUsed ? `${formatDateWithTime(new Date(value.lastUsed))}` : 'unknown'}</TableDataCell>
			<TableDataCell>
				{value._actions.includes('delete') ? (
					<ButtonIcon onClick={onDelete} tooltip={'Delete Access Token'}>
						<IconDelete />
					</ButtonIcon>
				) : null}
			</TableDataCell>
		</TableRow>
	);
}
