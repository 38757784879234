/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Pill, Text } from '@steadybit/ui-components-lib';
import { IconTargetType } from 'components/icons';
import { TargetTypeDescriptionVO } from 'ui-api';
import { ReactElement } from 'react';

interface TargetProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	targetId: string;
}

export function Target({ targetDefinitions, targetId }: TargetProps): ReactElement {
	const resolvedTargetDefinition = (targetDefinitions || []).find(
		(targetDefinition) => targetDefinition.id === targetId,
	);

	return (
		<Pill
			style={{
				padding: '0 8px',
				height: '24px',
				maxWidth: '240px',
			}}
		>
			<IconTargetType targetType={targetId} size="small" />
			<Text type="smallStrong" textEllipsis>
				{resolvedTargetDefinition?.label.one || targetId}
			</Text>
		</Pill>
	);
}
