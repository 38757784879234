/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { IconArrowLeft, IconChevronRight } from 'components/icons';
import SlideInPages from 'components/SlideInPages/SlideInPages';
import { Fragment, ReactElement, useState } from 'react';
import { Text, Container } from 'components';
import { theme } from 'styles.v2/theme';

import useKeyboardNavigation from './components/useKeyboardNavigation';
import ItemList from './components/ItemList';
import { Group, Label } from './types';

type RenderLabel = (label: Label) => ReactElement;

export interface GroupedProps {
	queryString?: string;
	flatten?: boolean;
	width?: number | string;
	groups: Group[];
	preContent?: ReactElement;
	onSelect: (label: Label) => void;
	renderLabel: RenderLabel;
}

export default function Grouped({
	groups,
	preContent,
	onSelect,
	queryString,
	width = 200,
	flatten,
	renderLabel,
}: GroupedProps): ReactElement | null {
	const [selectedGroup, setSelectedGroup] = useState<number>(0);

	if (groups.length === 0) {
		return null;
	}

	return (
		<>
			{preContent}
			{queryString || flatten ? (
				<FilteredList
					width={width}
					queryString={queryString ?? ''}
					groups={groups}
					onSelect={onSelect}
					renderLabel={renderLabel}
				/>
			) : (
				<SlideInPages numPages={2} width={width}>
					{({ page, next, prev }) => {
						if (page === 0) {
							return (
								<GroupList
									groups={groups}
									onClick={(i) => {
										setSelectedGroup(i);
										next?.();
									}}
								/>
							);
						}
						return (
							<GroupItemsList
								group={groups[selectedGroup]}
								onClick={onSelect}
								onPrevClick={() => prev?.()}
								renderLabel={renderLabel}
							/>
						);
					}}
				</SlideInPages>
			)}
		</>
	);
}

interface GroupListProps {
	onClick: (i: number) => void;
	groups: Group[];
}

function GroupList({ onClick, groups }: GroupListProps): ReactElement {
	const [hoveredIndex, setHoveredIndex] = useKeyboardNavigation(groups.length, (i) => onClick(i));

	return (
		<ItemList
			type="strict"
			onClick={onClick}
			highlightedIndex={hoveredIndex}
			onHover={setHoveredIndex}
			totalItems={groups.length}
		>
			{groups.map(({ label, highlighted }) => (
				<Fragment key={label}>
					<Text color={highlighted ? 'slate' : 'neutral800'} variant={highlighted ? 'smallStrong' : 'small'}>
						{label}
					</Text>
					<IconChevronRight color="neutral400" ml="small" width={16} />
				</Fragment>
			))}
		</ItemList>
	);
}

interface GroupItemsListProps {
	onClick: (label: Label) => void;
	onPrevClick: () => void;
	group: Group;
	renderLabel: RenderLabel;
}

function GroupItemsList({ onPrevClick, onClick, renderLabel, group }: GroupItemsListProps): ReactElement {
	const [hoveredIndex, setHoveredIndex] = useKeyboardNavigation(group.options.length, (i) => onClick(group.options[i]));

	return (
		<Container>
			<Container
				onClick={onPrevClick}
				sx={{
					display: 'flex',
					alignItems: 'center',
					p: 'xSmall',
					borderBottom: '1px solid ' + theme.colors.neutral200,

					':hover': {
						backgroundColor: 'neutral100',
						cursor: 'pointer',
					},
				}}
			>
				<IconArrowLeft color="neutral400" mr="xSmall" width={16} />
				<Text color="neutral800" variant="smallStrong">
					{group.label}
				</Text>
			</Container>
			<ItemList
				type="strict"
				totalItems={group.options.length}
				highlightedIndex={hoveredIndex}
				onClick={(i) => onClick(group.options[i])}
				onHover={setHoveredIndex}
			>
				{group.options.map(renderLabel)}
			</ItemList>
		</Container>
	);
}

interface FilteredListProps {
	queryString: string;
	groups: Group[];
	onSelect: (label: Label) => void;
	renderLabel: RenderLabel;
	width?: number | string;
}

function FilteredList({ queryString, width, groups, onSelect, renderLabel }: FilteredListProps): ReactElement {
	const filteredGroups = groups
		.map((group) => getMatchingLabels(queryString, group))
		.filter(({ options }) => options.length > 0);

	const itemCount = filteredGroups.reduce((acc, group) => acc + group.options.length, 0);

	const [hoveredIndex, setHoveredIndex] = useKeyboardNavigation(itemCount, (selectedGlobalIndex) => {
		let groupCount = 0;
		for (let i = 0; i < filteredGroups.length; i++) {
			const { options } = filteredGroups[i];
			if (selectedGlobalIndex < groupCount + options.length) {
				onSelect(options[selectedGlobalIndex - groupCount]);
				return;
			}
			groupCount = options.length;
		}
	});

	return (
		<Container width={width}>
			{filteredGroups.map(({ label, options }, i) => {
				const startingIndex = filteredGroups.slice(0, i).reduce((acc, group) => acc + group.options.length, 0);
				const localHoveredIndex =
					hoveredIndex !== undefined && hoveredIndex >= startingIndex && hoveredIndex <= startingIndex + options.length
						? hoveredIndex - startingIndex
						: undefined;

				return (
					<Container key={label}>
						<Container
							sx={{
								display: 'flex',
								alignItems: 'center',
								p: 'xSmall',
								borderTop: '1px solid ' + theme.colors.neutral200,
							}}
						>
							<Text color="neutral800" variant="smallStrong">
								{label}
							</Text>
						</Container>
						<ItemList
							type="strict"
							onClick={(i) => onSelect(options[i])}
							highlightedIndex={localHoveredIndex}
							onHover={(i) => setHoveredIndex(startingIndex + i)}
						>
							{options.map(renderLabel)}
						</ItemList>
					</Container>
				);
			})}
		</Container>
	);
}

function getMatchingLabels(queryString: string, group: Group): Group {
	return {
		...group,
		options: group.options.filter(({ label }) => label.toLowerCase().includes(queryString.toLowerCase())),
	};
}
