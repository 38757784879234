/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { ReactElement, ReactNode } from 'react';
import { Container } from 'components';

interface WizardContentProps {
	children: ReactNode | ReactNode[];
	height: string;
}
export default function WizardWrapper({ height, children }: WizardContentProps): ReactElement {
	return (
		<Container
			sx={{
				position: 'relative',
				minHeight: height,
				maxHeight: height,
			}}
		>
			<Container
				sx={{
					position: 'absolute',
					top: '0',
					left: '0',
					right: '0',
					bottom: '0',

					display: 'flex',
					flexDirection: 'column',
				}}
			>
				{children}
			</Container>
		</Container>
	);
}
