/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { StyleProp } from 'components/StyleProp';
import React, { ReactElement } from 'react';

import { Button, ButtonProps } from '../Button';
import { TabContext } from './Tabs';

type TabOwnProps = {
	value: string;
};

export type TabProps = TabOwnProps & Omit<ButtonProps, 'css' | keyof TabOwnProps>;

export function Tab({ value, variant = 'medium', children, sx = {}, ref, ...props }: TabProps): ReactElement {
	const context = React.useContext(TabContext);
	const active = context.value === value;

	const sxActive: StyleProp = active
		? {
				color: 'neutral800',
				bg: 'neutral000',
				my: -1,
				borderColor: 'neutral300',
				borderBottomColor: 'neutral000',
				'&:first-of-type': {
					borderLeftColor: 'transparent',
				},
			}
		: {
				color: 'slate',
				borderColor: 'transparent',
				bg: 'neutral100',
				'&[disabled]': {
					color: 'neutral400',
					pointerEvents: 'all',
				},
				'&:hover': {
					textDecoration: props.disabled ? 'none' : 'underline',
				},
				cursor: props.disabled ? 'default' : 'pointer',
			};

	return (
		<Button
			ref={ref}
			tx={'tabs.tab'}
			variant={variant}
			sx={{
				border: '1px solid',
				borderRadius: 0,
				display: 'inline-flex',
				alignItems: 'center',
				transition: 'color, background-color',
				transitionDuration: '200ms',
				...sxActive,
				...sx,
			}}
			{...props}
			onClick={() => !active && context.onChange?.(value)}
		>
			{children}
		</Button>
	);
}
