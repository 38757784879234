/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import ExperimentExecutionTimerange from 'pages/experiments/components/ExperimentExecutionTimerange';
import { autoPlacement, autoUpdate, offset, shift, useFloating } from '@floating-ui/react-dom';
import React, { CSSProperties, Fragment, ReactElement, useState } from 'react';
import { IconExperimentError, IconExperimentFailed } from 'components/icons';
import { Container, Stack } from 'components';
import { formatTime } from 'utils/dateFns';
import { createPortal } from 'react-dom';
import { theme } from 'styles.v2/theme';
import { clamp } from 'lodash';

import {
	PlayerBackground,
	PlayerCursor,
	PlayerCursorAnimated,
	PlayerCursorWrapper,
	PlayerLane,
	PlayerLaneWrapper,
	PlayerProgress,
	PlayerStep,
	PlayerTimeScale,
} from './components/player';
import {
	ExperimentPlayerLanes,
	ExperimentPlayerStep,
	ExperimentPlayerStepId,
	ExperimentPlayerTimeStamp,
} from './types';

export interface ExperimentPlayerProps extends React.ComponentProps<'div'> {
	cursorPosition: ExperimentPlayerTimeStamp | null;
	selectedStepId: ExperimentPlayerStepId | null;
	progress?: ExperimentPlayerTimeStamp;
	duration: ExperimentPlayerTimeStamp;
	stopped?: ExperimentPlayerTimeStamp;
	start: ExperimentPlayerTimeStamp;
	completedWithFailure: boolean;
	lanes: ExperimentPlayerLanes;
	hoveredStepId: string | null;
	completedWithError: boolean;
	setSelectedStepId: (selectedStepId: ExperimentPlayerStepId | null) => void;
	onPositionSelect: (position: ExperimentPlayerTimeStamp | null) => void;
	renderStepContent: (id: ExperimentPlayerStepId) => React.ReactNode;
	setHoveredStepId: (id: string | null) => void;
}

export function timelinePercentage(pos: number, start: number, duration: number): string {
	return duration > 0 ? `${(100 / duration) * (pos - start)}%` : '0%';
}

export function ExperimentPlayer({
	completedWithFailure,
	completedWithError,
	cursorPosition,
	hoveredStepId,
	progress,
	duration,
	stopped,
	start,
	lanes,
	renderStepContent,
	setSelectedStepId,
	setHoveredStepId,
	onPositionSelect,
}: ExperimentPlayerProps): React.ReactElement {
	const [localHoveredStepId, setLocalHoveredStepId] = useState<string | null>(hoveredStepId);

	const [hoverPositionPercentage, setHoverPositionPercentage] = useState<ExperimentPlayerTimeStamp | null>(null);

	const { refs, floatingStyles } = useFloating({
		middleware: [
			autoPlacement({
				allowedPlacements: ['top-start', 'bottom-start'],
			}),
			offset(4),
			shift(),
		],
		whileElementsMounted: autoUpdate,
	});

	const progressPosition = progress ? clamp(progress, start, start + duration) : undefined;
	const stoppedPosition = stopped ? clamp(stopped, start, start + duration) : undefined;
	const animatedCursorPosition = cursorPosition || progressPosition;

	let tooltipIsLeftAligned = true;
	const hoveredStep: ExperimentPlayerStep | undefined = localHoveredStepId
		? lanes
				.map((lane) => lane.flatMap((step) => step))
				.flat()
				.find((step) => step.id === localHoveredStepId)
		: undefined;
	if (hoveredStep) {
		const passesHalfOfTheTimeline = (hoveredStep.effectiveStart || hoveredStep.start) - start > duration / 2;
		tooltipIsLeftAligned = !passesHalfOfTheTimeline;
	}

	const [backgroundRef, setBackgroundRef] = useState<HTMLDivElement | null>(null);
	return (
		<PlayerBackground
			backgroundRef={backgroundRef}
			duration={duration}
			start={start}
			setBackgroundRef={(ref: HTMLDivElement) => setBackgroundRef(ref)}
			onHoverPositionChange={setHoverPositionPercentage}
			onPositionSelect={onPositionSelect}
		>
			{progressPosition !== undefined && (
				<PlayerProgress position={timelinePercentage(progressPosition, start, duration)} />
			)}
			{stoppedPosition !== undefined && (
				<PlayerProgress position={timelinePercentage(stoppedPosition, start, duration)} />
			)}
			<PlayerLaneWrapper>
				{lanes.map((lane, laneIndex) => (
					<PlayerLane key={laneIndex} prefixContent={laneIndex + 1}>
						{lane.map((step, stepIndex) => {
							if (step.effectiveSx === null) {
								return null;
							}

							const stepEnd =
								step.state === 'RUNNING' && progressPosition && step.end < progressPosition
									? progressPosition
									: step.end;

							const stepDuration = step.effectiveStart
								? stepEnd - step.effectiveStart + Math.abs(step.effectiveStart - step.start)
								: stepEnd - step.start;

							const width = duration > 0 ? `${(stepDuration / duration) * 100}%` : '0%';

							const left = timelinePercentage(step.start, start, duration);

							const widthOfInitialisation = step.effectiveStart
								? timelinePercentage(step.effectiveStart, step.start, stepEnd - step.start)
								: 0;

							return (
								<Fragment key={`${laneIndex}-${stepIndex}`}>
									<PlayerStep
										width={width}
										left={left}
										zIndex={lane.length - stepIndex}
										initSx={step.initSx}
										widthOfInitialisation={widthOfInitialisation}
										effectiveSx={step.effectiveSx}
										textSx={step.textSx}
										state={step.state}
										experimentExecutionEnd={stoppedPosition}
										start={step.start}
										end={step.effectiveStart || stepEnd}
										ref={hoveredStep?.id === step.id ? refs.setReference : null}
										onClick={(e) => {
											e.stopPropagation();
											setSelectedStepId(step.id);
										}}
										onMouseMove={(e) => e.stopPropagation()}
										onMouseEnter={(e) => {
											e.stopPropagation();
											setHoverPositionPercentage(null);
											setHoveredStepId(step.id);
											setLocalHoveredStepId(step.id);
										}}
										onMouseLeave={() => {
											setHoveredStepId(null);
											setLocalHoveredStepId(null);
										}}
										highlighted={hoveredStepId === step.id}
										muted={hoveredStepId ? hoveredStepId !== step.id : undefined}
									>
										{step.label ?? null}
									</PlayerStep>

									<ExperimentStepStateIcon
										state={step.state}
										left={`calc(${left} - 8px)`}
										onMouseEnter={(e) => {
											e.stopPropagation();
											setHoverPositionPercentage(null);
											setHoveredStepId(step.id);
											setLocalHoveredStepId(step.id);
										}}
										onMouseLeave={() => {
											setHoveredStepId(null);
											setLocalHoveredStepId(null);
										}}
										zIndex={lane.length + 1}
									/>
								</Fragment>
							);
						})}
					</PlayerLane>
				))}
			</PlayerLaneWrapper>
			<PlayerTimeScale duration={duration} />
			<PlayerCursorWrapper>
				{animatedCursorPosition && (
					<PlayerCursorAnimated
						totalWidth={backgroundRef?.clientWidth || 0}
						variant="slate"
						position={timelinePercentage(animatedCursorPosition, start, duration)}
					>
						{formatTime(new Date(animatedCursorPosition))}
					</PlayerCursorAnimated>
				)}
				{stopped && (
					<PlayerCursor
						totalWidth={backgroundRef?.clientWidth || 0}
						variant={completedWithFailure ? 'failed' : completedWithError ? 'errored' : 'success'}
						position={timelinePercentage(stopped, start, duration)}
					>
						{formatTime(new Date(stopped))}
					</PlayerCursor>
				)}
				{!!hoverPositionPercentage && (
					<PlayerCursor
						totalWidth={backgroundRef?.clientWidth || 0}
						variant="hover"
						position={`${hoverPositionPercentage}%`}
					>
						{formatTime(new Date(start + duration * (hoverPositionPercentage / 100)))}
					</PlayerCursor>
				)}
			</PlayerCursorWrapper>

			{hoveredStep
				? createPortal(
						<Container
							ref={refs.setFloating}
							style={floatingStyles}
							sx={{
								zIndex: 12,
								display: 'flex',
								justifyContent: tooltipIsLeftAligned ? 'flex-start' : 'flex-end',
								pointerEvents: 'none',
							}}
						>
							<Container
								sx={{
									px: 'small',
									py: 'xSmall',
									bg: 'neutral050',
									border: getStateBorderColor(hoveredStep.originalExperimentStep?.state),
									boxShadow: 'applicationLarge',
									marginLeft:
										tooltipIsLeftAligned && hoveredStep.effectiveStart
											? timelinePercentage(
													Math.max(hoveredStep.effectiveStart, hoveredStep.start),
													hoveredStep.start,
													hoveredStep.end - hoveredStep.start,
												)
											: 0,
									borderRadius: 4,
									minWidth: 320,
									width: 'fit-content',
								}}
							>
								<Stack size="xSmall">
									<ExperimentExecutionTimerange
										state={hoveredStep.state}
										start={Math.max(hoveredStep.effectiveStart || 0, hoveredStep.start)}
										end={hoveredStep.end}
										experimentExecutionEnd={stoppedPosition}
									/>
									<Container>{renderStepContent(hoveredStep.id)}</Container>
								</Stack>
							</Container>
						</Container>,
						document.body,
					)
				: null}
		</PlayerBackground>
	);
}

interface ExperimentStepStateIconProps {
	state: string;
	left: string;
	zIndex: number;
	onMouseEnter: (e: React.MouseEvent) => void;
	onMouseLeave: (e: React.MouseEvent) => void;
}

function ExperimentStepStateIcon({
	state,
	left,
	zIndex,
	onMouseEnter,
	onMouseLeave,
}: ExperimentStepStateIconProps): ReactElement | null {
	const commonStyle: CSSProperties = {
		position: 'absolute',
		top: -8,
		left,
		width: 27,
		height: 27,

		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',

		zIndex,
	};

	if (state === 'ERRORED') {
		return <IconExperimentError sx={{ ...commonStyle }} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />;
	}
	if (state === 'FAILED') {
		return <IconExperimentFailed sx={{ ...commonStyle }} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />;
	}

	return null;
}

function getStateBorderColor(state: string | undefined): string {
	if (state === 'ERRORED') {
		return '2px solid ' + theme.colors.coral;
	}
	if (state === 'FAILED') {
		return '2px solid ' + theme.colors.experimentWarning;
	}
	return 'none';
}
