/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { Link as ReactRouterLink, LinkProps as ReactRouterLinkProps } from 'url/Link';
import { ReactElement } from 'react';

import { Button, ButtonProps } from './Button';

export type RouterButtonProps = ButtonProps & ReactRouterLinkProps<never>;

export function RouterButton({ disabled, children, ref, ...props }: RouterButtonProps): ReactElement {
	if (disabled) {
		return (
			<Button ref={ref} {...props} disabled={true}>
				{children}
			</Button>
		);
	}
	return (
		<Button ref={ref} as={ReactRouterLink} {...props}>
			{children}
		</Button>
	);
}
