/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import useActionHierarchy, { ActionCategory, ActionCategoryItem } from 'experiment/actions/useActionHierarchy';
import { Button, Colors, Dropdown, presets, SingleChoiceListItem } from '@steadybit/ui-components-lib';
import { IconArrowDropDown, IconArrowDropUp } from 'components/icons';
import { ReactElement, ReactNode, useState } from 'react';
import useActions from 'pages/experimentsV2/useActions';
import { Container, Stack, Text } from 'components';
import { localeCompare } from 'utils/string';
import { theme } from 'styles.v2/theme';

interface StepTypeSwitcherProps {
	onActionClick: (actionId: string) => void;
	actionId: string;
	label: string;
}

export default function StepTypeSwitcher({ actionId, label, onActionClick }: StepTypeSwitcherProps): ReactElement {
	return (
		<Dropdown
			placement="bottom-start"
			renderDropdownContent={({ width }) => (
				<Content key={actionId} width={width} actionId={actionId} onActionClick={onActionClick} />
			)}
		>
			{({ setRefElement, isOpen, setOpen }) => {
				return (
					<Button
						ref={setRefElement}
						type="chromeless"
						size="small"
						withRightIcon={isOpen ? 'arrow-drop-up' : 'arrow-drop-down'}
						style={{ width: 'fit-content', maxWidth: '100%', color: Colors.neutral600 }}
						onClick={() => setOpen(!isOpen)}
					>
						{label}
					</Button>
				);
			}}
		</Dropdown>
	);
}

interface ContentProps {
	actionId: string;
	width: string;
	onActionClick: (actionId: string) => void;
}

function Content({ width, actionId, onActionClick }: ContentProps): ReactElement {
	const { actions } = useActions();
	const actionCategories = useActionHierarchy({ actions });

	return (
		<presets.dropdown.DropdownContentFrame maxHeight="500px" minWidth={`max(300px, ${width})`} maxWidth="350px">
			{actionCategories.map((category) => (
				<Category key={category.label} label={category.label} initExpanded={containsAction(category, actionId)}>
					{category.subCategories &&
						category.subCategories.map((subCategory) => (
							<SubCategory key={subCategory.label} label={subCategory.label}>
								<presets.dropdown.SingleChoiceList
									items={mapActions(actionId, subCategory.actions)}
									onSelect={onActionClick}
								/>
							</SubCategory>
						))}

					{category.actions && (
						<presets.dropdown.SingleChoiceList
							items={mapActions(actionId, category.actions)}
							onSelect={onActionClick}
						/>
					)}
				</Category>
			))}
		</presets.dropdown.DropdownContentFrame>
	);
}

interface CategoryProps {
	initExpanded: boolean;
	children: ReactNode;
	label: string;
}

function Category({ initExpanded, children, label }: CategoryProps): ReactElement | null {
	const [isExpanded, setIsExpanded] = useState(initExpanded);

	if (!label) {
		return null;
	}

	return (
		<Container
			sx={{
				borderBottom: '1px solid ' + theme.colors.neutral200,
				':last-child': {
					borderBottom: 'none',
				},
			}}
		>
			<Stack
				direction="horizontal"
				onClick={() => setIsExpanded(!isExpanded)}
				sx={{
					alignItems: 'center',
					justifyContent: 'space-between',
					px: 'small',
					py: 'small',
					color: 'neutral800',
					cursor: 'pointer',
					':hover': {
						bg: 'neutral100',
						color: 'slate',
					},
				}}
			>
				<Text variant="smallStrong">{label}</Text>
				{isExpanded ? <IconArrowDropUp color="neutral600" /> : <IconArrowDropDown color="neutral600" />}
			</Stack>
			{isExpanded && children}
		</Container>
	);
}

function SubCategory({ children, label }: { label: string; children: ReactNode }): ReactElement {
	return (
		<Stack size="none" mb="small">
			<Text variant="small" color="neutral600" mx="small">
				{label}
			</Text>
			{children}
		</Stack>
	);
}

function containsAction(category: ActionCategory, actionId: string): boolean {
	if (category.subCategories) {
		return category.subCategories.some((subCategory) =>
			subCategory.actions.some((action) => action.action.id === actionId),
		);
	}
	if (category.actions) {
		return category.actions.some((action) => action.action.id === actionId);
	}
	return false;
}

function mapActions(selectedActionId: string, actions: ActionCategoryItem[]): SingleChoiceListItem[] {
	return actions
		.map((action) => ({
			id: action.action.id,
			label: action.label,
			isSelected: action.action.id === selectedActionId,
		}))
		.sort((a, b) => localeCompare(a.label, b.label));
}
