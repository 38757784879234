/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { BaseExperimentStepExecutionVOUnion, BaseExperimentStepVOUnion } from 'ui-api';
import { IconInformationCircleFilled, IconWarning } from 'components/icons';
import { ErrorMessage } from '@steadybit/ui-components-lib';
import { Container, Stack, Text } from 'components';
import { ActionInfoBox } from 'hocs/ActionInfo';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

import { ExperimentError } from '../types';

interface StepTooltipContentProps {
	step: BaseExperimentStepVOUnion | BaseExperimentStepExecutionVOUnion;
	errors: ExperimentError[];
}

export default function StepTooltipContent({ step, errors: allErrors }: StepTooltipContentProps): ReactElement {
	const errors = allErrors.filter((error) => error.level === 'error');
	const warnings = allErrors.filter((error) => error.level === 'warning');
	const infos = allErrors.filter((error) => error.level === 'info');
	const errorColor = errors.length > 0 ? theme.colors.coral : theme.colors.experimentWarning;

	return (
		<div
			style={{
				border: errors.length > 0 || warnings.length > 0 ? '2px solid ' + errorColor : undefined,
				margin: '-5px -8px',
				padding: '8px',
				borderRadius: '4px',
				maxWidth: '400px',
			}}
		>
			{errors.length > 0 && (
				<>
					<ErrorMessage level="error" type="small" withIcon>
						Errors:
					</ErrorMessage>
					<Stack size="xxSmall" pb="xSmall">
						{errors.map(({ level, message }, index) => (
							<ErrorMessage key={index} level={level} type="small">
								{message}
							</ErrorMessage>
						))}
					</Stack>
				</>
			)}

			{warnings.length > 0 && (
				<>
					<Container display="flex" alignItems="center" py="xSmall" color={errorColor}>
						<IconWarning variant="small" mr="xxSmall" />
						<Text variant="smallStrong">Warnings:</Text>
					</Container>
					<Stack size="xxSmall" pb="xSmall">
						{warnings.map(({ level, message }, index) => (
							<ErrorMessage key={index} level={level} type="small">
								{message}
							</ErrorMessage>
						))}
					</Stack>
				</>
			)}

			{infos.length > 0 && (
				<Stack size="xxSmall" pb="xSmall">
					{infos.map(({ message, level }, index) => (
						<Container key={index} display="flex" alignItems="center" py="xSmall" color="experimentOther">
							<IconInformationCircleFilled variant="xSmall" mr="xxSmall" />
							<ErrorMessage key={index} level={level} type="small">
								{message}
							</ErrorMessage>
						</Container>
					))}
				</Stack>
			)}

			{step.type === 'wait' ? (
				<>
					<Text variant="smallStrong">{step.customLabel ? step.customLabel : 'Wait'}</Text>
					<Stack size="none" mt="xxSmall">
						{step.customLabel ? <Text variant="small">Type: Wait</Text> : null}
						<Text variant="small">Duration: {step.parameters.duration}</Text>
					</Stack>
				</>
			) : (
				<ActionInfoBox value={step} />
			)}
		</div>
	);
}
