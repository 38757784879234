/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, Colors, Flex, Pill, Text } from '@steadybit/ui-components-lib';
import { useEditorSettings } from 'pages/experimentsV2/useEditorSettings';
import { FileUpload, Frame } from 'components/FileUpload/FileUpload';
import { smellsLikeTemplatePlaceholder } from 'components';
import { ReactElement } from 'react';

import AddPlaceholdersButton from './AddPlaceholdersButton';

interface FileProps {
	value: string | undefined;
	disabled: boolean;
	accept: string;
	setValue: (value: string | undefined) => void;
}

export default function File({ value, disabled, accept, setValue }: FileProps): ReactElement {
	const { mode } = useEditorSettings();

	if (mode === 'templateEditor') {
		if (smellsLikeTemplatePlaceholder(value)) {
			return (
				<Flex align="end" style={{ marginTop: '-26px' }}>
					<Button type="chromeless" withLeftIcon="delete" size="small" onClick={() => setValue(undefined)}>
						Delete placeholder
					</Button>
					<Frame>
						<Pill style={{ background: Colors.aqua300 }}>
							<Text type="smallStrong" style={{ color: Colors.aqua800 }}>
								{value}
							</Text>
						</Pill>
					</Frame>
				</Flex>
			);
		}
		if (!value) {
			return (
				<Flex align="end" style={{ marginTop: '-26px' }}>
					<AddPlaceholdersButton onPlaceholderSelected={setValue} />
					<FileUpload useTemporaryFiles disabled={disabled} accept={accept} value={value} onChange={setValue} />
				</Flex>
			);
		}
	}

	return <FileUpload useTemporaryFiles disabled={disabled} accept={accept} value={value} onChange={setValue} />;
}
