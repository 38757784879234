/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import { ReactElement } from 'react';

export type TabListProps = ContainerProps;

export function TabList({ children, sx = {}, ...props }: TabListProps): ReactElement {
	return (
		<Container
			display={'flex'}
			sx={{
				bg: 'neutral100',
				borderBottom: '1px solid',
				borderColor: 'neutral300',
				...sx,
			}}
			{...props}
		>
			{children}
		</Container>
	);
}
