/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { Line, LineChart, Tooltip as RechartTooltip, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { Fragment, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { ExperimentExecutionVO, MetricIdentityVO, MetricQueryVO } from 'ui-api';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { Container, Li, Stack, Text, Tooltip, Ul } from 'components';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { presets } from '@steadybit/ui-components-lib';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { formatTime } from 'utils/dateFns';
import { theme } from 'styles.v2/theme';
import { schemeCategory10 } from 'd3';
import { getHash } from 'utils/hash';
import { Heading } from 'theme-ui';
import { range } from 'lodash';

interface ExecutionMetricsProps {
	metricQueries: MetricQueryVO[];
	experimentExecution: ExperimentExecutionVO;
}

interface EnhancedMetricIdentityVO extends MetricIdentityVO {
	id: string;
	color: string;
}

const MAX_METRICS_TO_RENDER = 5;

export default function ExecutionMetrics({ metricQueries, experimentExecution }: ExecutionMetricsProps): ReactElement {
	const [selectedMetricId, setSelectedMetricId] = useState<string | null>(null);
	const selecteMetricDefinition = metricQueries.find((q) => q.id === selectedMetricId);
	const metricsResult = useMetrics(experimentExecution.id, !experimentExecution.ended) || [];
	const allMetricValues = (metricsResult || []).filter((m) =>
		metricQueries.find((q) => q.id === m.labels.steadybit_metric_query_id),
	);

	// Set the first metric as selected if none is selected
	useEffect(() => {
		if (selectedMetricId) {
			return;
		}

		const firstMetric = allMetricValues.find((m) => m.labels.steadybit_metric_query_id);
		if (firstMetric) {
			setSelectedMetricId(firstMetric.labels.steadybit_metric_query_id);
		}
	}, [selectedMetricId, metricsResult]);

	// All metrics for the selected metric query
	const metrics: EnhancedMetricIdentityVO[] = allMetricValues
		.filter((m) => m.labels.steadybit_metric_query_id === selectedMetricId)
		.map((m, i) => ({
			...m,
			id: caluclateIdForMetric(m),
			color: schemeCategory10[i % 10],
		}));

	if (!metricsResult) {
		return (
			<Frame>
				<Container display="flex" justifyContent="center" py="xLarge">
					<LoadingIndicator variant="xxLarge" color="slate" />
				</Container>
			</Frame>
		);
	}
	if (allMetricValues.length === 0) {
		return (
			<Frame>
				<Container display="flex" justifyContent="center" py="xLarge" color="neutral500">
					There were no metrics collected
				</Container>
			</Frame>
		);
	}
	if (!selecteMetricDefinition) {
		return (
			<Frame>
				<Container display="flex" justifyContent="center" py="xLarge" color="neutral500">
					There is no metric selected
				</Container>
			</Frame>
		);
	}

	const options: Map<string, string> = new Map();
	allMetricValues.map((metricValue) => {
		const id = metricValue.labels.steadybit_metric_query_id;
		if (!options.has(id)) {
			const definition = metricQueries.find((q) => q.id === id);
			options.set(id, definition ? definition.label : metricValue.name || '');
		}
	});

	return (
		<Frame
			headerContent={
				<>
					{/* Metric name selector */}
					<presets.dropdown.SingleChoiceButton
						placement="bottom-start"
						selectedId={selectedMetricId || undefined}
						maxContentHeight="250px"
						items={Array.from(options.entries()).map(([id, label]) => ({
							id,
							label,
						}))}
						size="small"
						onSelect={setSelectedMetricId}
						style={{ minWidth: '200px' }}
					>
						{selecteMetricDefinition.label}
					</presets.dropdown.SingleChoiceButton>
				</>
			}
		>
			<MetricPresenter
				metrics={metrics}
				experimentExecution={experimentExecution}
				selectedMetricId={selectedMetricId}
			/>
		</Frame>
	);
}
interface MetricPresenterProps {
	metrics: EnhancedMetricIdentityVO[];
	experimentExecution: ExperimentExecutionVO;
	selectedMetricId: string | null;
}
interface DataPoint {
	id: string;
	ts: number;
	value: number;
	parent: CombinedMetricData;
}
interface CombinedMetricData {
	id: string;
	color: string;
	description: ReactNode;
	data: DataPoint[];
}
function MetricPresenter({ experimentExecution, metrics, selectedMetricId }: MetricPresenterProps): ReactElement {
	const [selectedMetricIds, setSelectedMetricIds] = useState<string[]>([]);
	const [position, setPosition] = useState<number>(0);
	const [combinedData, setCombinedData] = useState<CombinedMetricData[]>([]);
	function addData(combinedData: CombinedMetricData): void {
		setCombinedData((prev) => {
			const existingData = prev.find((d) => d.id === combinedData.id);
			if (existingData) {
				const newData = prev.slice();
				newData.splice(prev.indexOf(existingData), 1, combinedData);
				return newData;
			}
			return [...prev, combinedData];
		});
	}
	useEffect(() => setCombinedData((prev) => prev.filter((d) => metrics.find((m) => m.id === d.id))), [metrics]);
	useEffect(() => setSelectedMetricIds(metrics.slice(0, MAX_METRICS_TO_RENDER).map((m) => m.id)), [selectedMetricId]);

	const [start, end] = getChartDuration(experimentExecution, combinedData);
	const duration = end - start;
	const xTicks = useMemo(() => {
		const tickCount = Math.min(8, duration / 1000);
		return range(start, start + duration + 1, duration / tickCount);
	}, [start, duration]);
	if (metrics.length === 0) {
		return <MessageContainer>Please select metrics to show their values</MessageContainer>;
	}
	const isLoading = combinedData.length === 0;
	return (
		<Container>
			{isLoading ? (
				<MessageContainer>
					<LoadingIndicator />
				</MessageContainer>
			) : selectedMetricIds.length === 0 ? (
				<MessageContainer>Please select metrics to show their values</MessageContainer>
			) : (
				<ResponsiveContainer width="100%" height={450}>
					<LineChart
						margin={{ left: 0, top: 10, right: 0, bottom: 0 }}
						onClick={(event) => setPosition(event?.activePayload?.[0]?.payload?.ts)}
					>
						<RechartTooltip content={(p) => renderTooltip(p)} />
						<XAxis
							{...theme.charts.axes}
							dataKey="ts"
							type="number"
							domain={[start, start + duration]}
							ticks={xTicks}
							tickFormatter={(t) => formatTime(new Date(t))}
						/>
						<YAxis
							{...theme.charts.axes}
							orientation="left"
							domain={[0, (dataMax: number) => dataMax + 1]}
							tickSize={5}
							interval={1}
						/>
						{combinedData
							.filter(({ id }) => selectedMetricIds.includes(id))
							.map(({ data }, i) => (
								<Line
									key={i}
									data={data}
									type="monotone"
									dataKey="value"
									stroke={data[0].parent.color}
									isAnimationActive={false}
									dot
								/>
							))}
						{position && <ReferenceLine x={position} stroke={theme.colors.slate} strokeWidth={2} />}
					</LineChart>
				</ResponsiveContainer>
			)}
			{metrics
				.filter((m) => selectedMetricIds.includes(m.id))
				.map((metric) => (
					<MetricDataHander
						key={metric.id}
						metric={metric}
						experimentExecution={experimentExecution}
						addData={addData}
					/>
				))}
			<Stack size="xSmall" pl="xLarge" pr="small">
				{metrics.map((metric) => (
					<LegendItem
						key={metric.id}
						color={metric.color}
						deactivated={!selectedMetricIds.includes(metric.id)}
						disabled={!selectedMetricIds.includes(metric.id) && selectedMetricIds.length >= MAX_METRICS_TO_RENDER}
						onClick={() => {
							if (selectedMetricIds.includes(metric.id)) {
								setSelectedMetricIds(selectedMetricIds.filter((_id) => metric.id !== _id));
							} else {
								setSelectedMetricIds([...selectedMetricIds, metric.id]);
							}
						}}
					>
						{getMetricDescription(metric)}
					</LegendItem>
				))}
			</Stack>
		</Container>
	);
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function renderTooltip(props: any): ReactElement | null {
	const { payload } = props;
	if (!payload || payload.length === 0) {
		return null;
	}
	return (
		<Ul
			sx={{
				px: 'small',
				py: 'xSmall',
				backgroundColor: 'neutral000',
				border: '1px solid ' + theme.colors.neutral200,
			}}
		>
			{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
			{payload.map((p: any, i: number) => {
				const dataPoint: DataPoint = p.payload;
				return (
					<Li key={i} display="flex" alignItems="center" maxWidth={400}>
						<LegendItem color={p.stroke} shorten>
							{dataPoint.parent.description}
						</LegendItem>
						<Text variant="smallStrong" ml="xSmall">
							{dataPoint.value}
						</Text>
					</Li>
				);
			})}
		</Ul>
	);
}
interface MetricDataHandlerProps {
	metric: EnhancedMetricIdentityVO;
	experimentExecution: ExperimentExecutionVO;
	addData: (data: CombinedMetricData) => void;
}
function MetricDataHander({ metric, experimentExecution, addData }: MetricDataHandlerProps): null {
	const metricId = metric.id;
	const [metricUpdateCount, setMetricUpdateCount] = useState(0);
	useEffect(() => {
		if (!experimentExecution.ended) {
			const interval = setInterval(() => setMetricUpdateCount((prev) => prev + 1), 5000);
			return () => clearInterval(interval);
		}
	}, [experimentExecution.ended]);
	const metricResult = usePromise(
		() => Services.executionMetrics.fetchMetric(experimentExecution.id, metric),
		[metricId, metricUpdateCount],
	);
	useEffect(() => {
		if (metricResult.value) {
			const dataPoints: DataPoint[] = [];
			const combined: CombinedMetricData = {
				id: metricId,
				color: metric.color,
				data: dataPoints,
				description: getMetricDescription(metric),
			};
			for (let i = 0; i < metricResult.value.length; i++) {
				const { samples } = metricResult.value[i];
				for (let iS = 0; iS < samples.length; iS++) {
					const { timestamp, value } = samples[iS];
					dataPoints.push({
						id: metric.id,
						ts: new Date(timestamp).getTime(),
						parent: combined,
						value,
					});
				}
			}
			addData(combined);
		}
	}, [metricResult.value]);
	return null;
}
interface FrameProps {
	headerContent?: ReactNode;
	children: ReactNode;
}
function Frame({ headerContent, children }: FrameProps): ReactElement {
	return (
		<Stack
			size="none"
			sx={{
				borderRadius: 2,
				overflow: 'hidden',
				boxShadow: theme.shadows.applicationSmall,
			}}
		>
			<Stack direction="horizontal" alignItems="center" px="small" py="xSmall" backgroundColor="neutral600">
				<Heading variant="mediumStrong" color="neutral000">
					Metrics
				</Heading>
				{headerContent}
			</Stack>
			<Container p="small" backgroundColor="neutral000">
				{children}
			</Container>
		</Stack>
	);
}
function caluclateIdForMetric(metric: MetricIdentityVO): string {
	return getHash(metric);
}
function getChartDuration(
	experimentExecution: ExperimentExecutionVO,
	combinedMetricData: CombinedMetricData[],
): [number, number] {
	const experimentStart = (experimentExecution.created ?? experimentExecution.requested).getTime();
	const experimentEnd = experimentExecution.ended ? experimentExecution.ended.getTime() : Date.now();
	const minMetricTs = combinedMetricData.reduce(
		(min, { data }) =>
			Math.min(
				min,
				data.reduce((min, { ts }) => Math.min(min, ts), Number.MAX_SAFE_INTEGER),
			),
		Number.MAX_SAFE_INTEGER,
	);
	const maxMetricTs = combinedMetricData.reduce(
		(max, { data }) =>
			Math.max(
				max,
				data.reduce((max, { ts }) => Math.max(max, ts), 0),
			),
		0,
	);
	const start = Math.min(experimentStart, minMetricTs);
	const end = Math.max(experimentEnd, maxMetricTs);
	const duration = end - start;
	return [start - duration * 0.025, end + duration * 0.025];
}
function getMetricDescription(metric: MetricIdentityVO): ReactNode {
	const keys = Object.keys(metric.labels);
	return (
		<>
			{'{ '}
			{keys.map((key, i) => (
				<Fragment key={key}>
					<Text variant="smallStrong" minWidth="fit-content">
						{key}
					</Text>
					<Text variant="small" mr="xxSmall" minWidth="fit-content">
						=&quot;{metric.labels[key]}&quot;{i < keys.length - 1 ? ',' : ''}
					</Text>
				</Fragment>
			))}
			{' }'}
		</>
	);
}
interface LegendItemProps {
	color: string;
	children: ReactNode;
	shorten?: boolean;
	deactivated?: boolean;
	disabled?: boolean;
	onClick?: () => void;
}
function LegendItem({ color, children, onClick, disabled, deactivated, shorten }: LegendItemProps): ReactElement {
	return (
		<Tooltip content={disabled ? `You can compare only up to ${MAX_METRICS_TO_RENDER} metrics` : ''}>
			<Container
				onClick={disabled ? undefined : onClick}
				sx={{
					display: 'flex',
					alignItems: 'center',
					px: 'xSmall',
					cursor: disabled ? undefined : 'pointer',
					'&:hover': {
						backgroundColor: disabled ? undefined : 'neutral100',
					},
				}}
			>
				<Container
					sx={{
						minWidth: 12,
						minHeight: 12,
						borderRadius: '50%',
						backgroundColor: deactivated ? 'neutral300' : color,
						mr: 'xSmall',
					}}
				/>
				<Container
					display="flex"
					alignItems="center"
					sx={shorten ? { ...textEllipsis } : { flexWrap: 'wrap', opacity: disabled ? 0.5 : 1 }}
				>
					{children}
				</Container>
			</Container>
		</Tooltip>
	);
}
function useMetrics(executionId: number, update: boolean): MetricIdentityVO[] | null {
	const [metricUpdateCount, setMetricUpdateCount] = useState(0);
	const [metricsResultInternal, setMetricsResult] = useState<MetricIdentityVO[] | null>(null);
	useEffect(() => {
		if (update) {
			const interval = setInterval(() => setMetricUpdateCount((prev) => prev + 1), 5000);
			return () => clearInterval(interval);
		}
	}, [update]);
	const metricsResult = usePromise(
		() => Services.executionMetrics.fetchExecutionMetrics(executionId),
		[executionId, metricUpdateCount],
	);
	if (metricsResult.value && metricsResult.value !== metricsResultInternal) {
		setMetricsResult(metricsResult.value);
	}
	return metricsResultInternal;
}
function MessageContainer({ children }: { children: ReactNode }): ReactElement {
	return (
		<Container display="flex" justifyContent="center" alignItems="center" py="xLarge" color="neutral500" height={450}>
			{children}
		</Container>
	);
}
