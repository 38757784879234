/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ReactElement, Suspense, lazy } from 'react';
import { useHistory } from 'url/hooks';

import useCreateExperimentFromScratch from './useCreateExperimentFromScratch';
import Card from './Card';

const Image = lazy(() => import('./images/Image'));

interface FromScratchProps {
	newExperimentTags?: string[];
}

export default function FromScratch({ newExperimentTags }: FromScratchProps): ReactElement {
	const history = useHistory();

	const onCreateExperimentFromDraft = useCreateExperimentFromScratch(newExperimentTags);

	return (
		<Card
			title="From scratch"
			description="Use the intuitive drag-and-drop editor to build a custom experiment"
			onClick={async () => history.push('/experiments/edit/<new>/design/', await onCreateExperimentFromDraft())}
		>
			<Suspense fallback={<div />}>
				<Image type="fromScratch" />
			</Suspense>
		</Card>
	);
}
