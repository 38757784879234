/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { Link, LinkProps } from 'url/Link';
import { ReactElement } from 'react';

import { ButtonIcon, ButtonIconProps } from './ButtonIcon';

export type RouterButtonIconProps = ButtonIconProps & LinkProps<never>;

export function RouterButtonIcon({ children, ...props }: RouterButtonIconProps): ReactElement {
	return (
		<ButtonIcon as={Link} {...props}>
			{children}
		</ButtonIcon>
	);
}
