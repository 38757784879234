/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import * as StyledSystem from 'styled-system';
import React, { ReactElement } from 'react';

export type TableHeadCellProps = ContainerProps &
	Omit<React.TdHTMLAttributes<HTMLTableDataCellElement>, keyof ContainerProps> &
	StyledSystem.JustifyContentProps;

export function TableHeadCell({ children, justifyContent, sx = {}, ...props }: TableHeadCellProps): ReactElement {
	return (
		<Container as="th" {...props}>
			{children ? (
				<Container display={'flex'} alignItems={'center'} sx={{ justifyContent, ...sx }}>
					{children}
				</Container>
			) : null}
		</Container>
	);
}
