/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react';
import { Stack } from 'components';

interface ViewWrapperProps {
	children: ReactElement | ReactElement[];
}

export default function ViewWrapper({ children }: ViewWrapperProps): ReactElement {
	return (
		<Stack as="main" px="xxLarge" pt="xLarge" pb="small" width="100%" size="medium" overflowY="auto">
			{children}
		</Stack>
	);
}
