/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import TagBadgeModal from 'pages/dashboard/components/TagBadgeModal';
import CreateBox from 'pages/dashboard/components/CreateBox';
import { IconBadge } from 'components/icons';
import { RouterButton } from 'components';
import { useHistory } from 'url/hooks';
import { ReactElement } from 'react';
import { Route } from 'url/router';

import { ampli } from '../../../ampli';

export function IncidentBadgeWidget(): ReactElement {
	const history = useHistory();
	return (
		<CreateBox
			mt={0}
			width={470}
			icon={IconBadge}
			header="Spread the word!"
			text={
				'Use dynamic Steadybit badges to easily create experiments from within any content, link to existing experiments, and show experiment results.'
			}
			height={220}
		>
			<Route path={'/dashboard/badge'}>
				{({ match }) => <>{match ? <TagBadgeModal onClose={() => history.goBack()} /> : null}</>}
			</Route>

			<RouterButton
				to={'/dashboard/badge'}
				onClick={() => ampli.dashboardHeroTriggered({ dashboard_hero_action: 'create_incident_badge' })}
				variant={'secondary'}
				width={150}
			>
				Create a Badge
			</RouterButton>
		</CreateBox>
	);
}
