/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { IconTargetIndicatorAttack, IconTargetType } from 'components/icons';
import { Container, ContainerProps } from 'components';
import { ReactElement } from 'react';

interface AttackedTargetIndicatorProps extends Omit<ContainerProps, 'children>'> {
	targetType: string;
	color?: 'coral' | 'purple700';
}

export default function AttackedTargetIndicator({
	targetType,
	color = 'purple700',
	...props
}: AttackedTargetIndicatorProps): ReactElement {
	const offset = props.variant === 'xxLarge' ? 12 : 8;
	return (
		<Container
			tx={'targetIndicator.icon'}
			variant={'medium'}
			sx={{ position: 'relative', minWidth: 'fit-content' }}
			{...props}
		>
			<IconTargetIndicatorAttack width={'100%'} height={'100%'} color={color} />
			<div
				style={{
					position: 'absolute',
					top: `calc(50% - ${offset + 1}px)`,
					left: `calc(50% - ${offset}px)`,
				}}
			>
				<IconTargetType size={props.variant === 'xxLarge' ? 'medium' : 'small'} targetType={targetType} />
			</div>
		</Container>
	);
}
