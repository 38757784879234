/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, Flex, Tooltip } from '@steadybit/ui-components-lib';
import { ReactElement, useState } from 'react';
import { ExperimentScheduleVO } from 'ui-api';
import { IconTrash } from 'components/icons';

interface ScheduleFooterProps {
	schedule: ExperimentScheduleVO | undefined;
	isValidDateSelected: boolean;
	disabled: boolean;
	setScheduleIdToDelete: (id: string | undefined) => void;
	onSave: () => Promise<void>;
	close: () => void;
}

export default function ScheduleFooter({
	isValidDateSelected,
	schedule,
	disabled,
	setScheduleIdToDelete,
	onSave,
	close,
}: ScheduleFooterProps): ReactElement {
	const [isSaving, setIsSaving] = useState<boolean>(false);

	return (
		<Flex
			direction="horizontal"
			spacing="small"
			justify={schedule ? 'spread' : 'end'}
			style={{
				width: '100%',
			}}
		>
			{schedule && (
				<Button
					type="chromeless"
					size="small"
					onClick={async () => {
						setScheduleIdToDelete(schedule?.id ?? '');
					}}
					disabled={disabled}
				>
					<IconTrash mr="xxSmall" />
					Delete Schedule
				</Button>
			)}

			<Flex direction="horizontal" spacing="small">
				<Button type="secondary" onClick={close}>
					Cancel
				</Button>

				<Tooltip content={!isValidDateSelected ? 'Please select a valid date' : undefined}>
					{({ setRefElement }) => (
						<Button
							ref={setRefElement}
							disabled={!isValidDateSelected || isSaving || disabled}
							withLeftIcon="save-disc"
							type="primary"
							onClick={async () => {
								setIsSaving(true);
								await onSave();
								setIsSaving(false);
							}}
						>
							{isSaving ? 'Saving...' : 'Save Schedule'}
						</Button>
					)}
				</Tooltip>
			</Flex>
		</Flex>
	);
}
