/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ReactElement, useEffect, useState } from 'react';

import ButtonRound, { ButtonRoundProps, variants } from './ButtonRound';
import ProgressIndicator from './ProgressIndicator';

export interface ButtonRoundLongClickProps extends ButtonRoundProps {
	onClick: () => void;
	onLongClickReleased?: (fired: boolean, progress: number) => void;
}

const clickDelay = 1000;

const clickingVariants: Record<variants, variants> = {
	primary: 'primaryClicking',
	secondary: 'primaryClicking',
	danger: 'danger',
	primaryClicking: 'primaryClicking',
};

export default function ButtonRoundLongClick({
	onClick,
	onLongClickReleased,
	children,
	variant = 'primary',
	...props
}: ButtonRoundLongClickProps): ReactElement {
	const [clickingStartTime, setClickingStartTime] = useState<Date | undefined>(undefined);
	const start: () => void = () => setClickingStartTime(new Date());
	const abort: () => void = () => {
		if (clickingStartTime != undefined) {
			onLongClickReleased?.(false, ((new Date().getTime() - clickingStartTime.getTime()) / clickDelay) * 100);
		}
		setClickingStartTime(undefined);
	};

	return (
		<ButtonRound
			{...props}
			variant={clickingStartTime != undefined ? clickingVariants[variant] : variant}
			onMouseDown={start}
			onTouchStart={start}
			onMouseUp={abort}
			onMouseLeave={abort}
			onTouchEnd={abort}
			onClick={noop}
		>
			{clickingStartTime != undefined && (
				<ProgressHandler
					{...props}
					onTimeoutEnded={() => {
						onLongClickReleased?.(true, ((new Date().getTime() - clickingStartTime.getTime()) / clickDelay) * 100);
						setClickingStartTime(undefined);
						onClick?.();
					}}
				/>
			)}
			{children}
		</ButtonRound>
	);
}

interface ProgressHandlerProps extends ButtonRoundProps {
	onTimeoutEnded: () => void;
}

function ProgressHandler({ onTimeoutEnded, ...props }: ProgressHandlerProps): ReactElement {
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		const timeout: number = window.setTimeout(() => setProgress(1), 1);
		return () => window.clearTimeout(timeout);
	}, []);

	useEffect(() => {
		const timeout: number = window.setTimeout(onTimeoutEnded, clickDelay);
		return () => window.clearTimeout(timeout);
	}, [onTimeoutEnded]);

	return <ProgressIndicator {...props} progress={progress} inversed />;
}

function noop(): void {}
