/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import { ReactElement } from 'react';

export type DialogProps = ContainerProps;

export function Dialog({ children, sx = {}, ...props }: DialogProps): ReactElement {
	return (
		<Container
			display={'flex'}
			flexDirection={'column'}
			width={480}
			alignSelf={'center'}
			maxWidth={'calc(100vw - 4rem)'}
			maxHeight={'calc(100vh - 4rem)'}
			bg="neutral000"
			{...props}
			sx={{
				boxShadow: 'applicationLarge',
				borderRadius: 4,
				...sx,
			}}
		>
			{children}
		</Container>
	);
}
