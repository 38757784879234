/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { ReactElement, ReactNode } from 'react';
import { useObservable } from 'react-use';
import { ensure } from 'utils/ensure';
import { ReplaySubject } from 'rxjs';

import { DialogContent } from './DialogContent';
import { DialogFooter } from './DialogFooter';
import { DialogHeader } from './DialogHeader';
import { ModalOverlay } from '../Modal';
import { Button } from '../Button';
import { Dialog } from './Dialog';

type ConfirmAction = {
	value: string;
	label: ReactNode;
	variant?: 'primary' | 'secondary' | 'primaryAttention' | 'chromeless';
	disabled?: boolean;
	autoFocus?: boolean;
	dataCyTag?: string;
};

type ConfirmOptions = {
	message: ReactNode;
	title?: string;
	actions?: ConfirmAction[];
	dataCy?: string;
	width?: string;
};

type OutstandingConfirmation = ConfirmOptions & {
	resolve: (result: string) => void;
};

const DEFAULT_OPTIONS = {
	title: 'Are you sure?',
	actions: [
		{ value: 'cancel', label: 'Cancel', variant: 'secondary', autoFocus: true },
		{ value: 'confirm', label: 'Ok', variant: 'primary' },
	] as ConfirmAction[],
};

const outstandingConfirmation$ = new ReplaySubject<OutstandingConfirmation | null>(1);

export function UserConfirmContainer(): ReactElement {
	const confirmation = useObservable(outstandingConfirmation$);
	return (
		<ModalOverlay open={Boolean(confirmation)} onClose={() => confirmation?.resolve?.('cancel')} zIndex={40}>
			{({ close }) => {
				const { title, message, actions, dataCy, width } = { ...DEFAULT_OPTIONS, ...ensure(confirmation) };

				return (
					<Dialog {...(dataCy ? { 'data-cy': dataCy } : {})} {...(width ? { width: width } : {})} maxHeight="80vh">
						<DialogHeader title={title} onClose={close} />
						<DialogContent>{message}</DialogContent>
						<DialogFooter>
							{actions.map((action, i) => (
								<Button
									key={action.value}
									ml={i === actions.length - 1 ? 'auto' : undefined}
									variant={action.variant ?? 'secondary'}
									disabled={action.disabled}
									onClick={(e) => {
										e.stopPropagation();
										confirmation?.resolve(action.value);
									}}
									autoFocus={action.autoFocus}
									data-cy={action.dataCyTag}
								>
									{action.label}
								</Button>
							))}
						</DialogFooter>
					</Dialog>
				);
			}}
		</ModalOverlay>
	);
}

export function userConfirm(message: string): Promise<string>;
export function userConfirm(options: ConfirmOptions): Promise<string>;
export function userConfirm(arg: string | ConfirmOptions): Promise<string> {
	return new Promise<string>((resolve) => {
		outstandingConfirmation$.next({
			resolve: (result: string) => {
				outstandingConfirmation$.next(null);
				resolve(result);
			},
			...(typeof arg === 'string' ? { message: arg } : arg),
		});
	});
}
