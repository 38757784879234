/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	IconAdviceActionNeeded,
	IconAdviceGeneral,
	IconAdviceImplemented,
	IconAdviceValidation,
	IconInformation,
} from 'components/icons';
import LicenseTooltipContent from 'App/components/LicenseTooltipContent';
import { Container, Stack, Text, Toggle, Tooltip } from 'components';
import { useLicenseFeature } from 'services/licenseApi';
import Skeletons from 'components/Skeleton/Skeletons';
import { ReactElement, ReactNode } from 'react';
import { Services } from 'services/services';

interface ShowAdviceToggleProps {
	theme: 'light' | 'dark';
	withLegend?: boolean;
	readOnly?: boolean;
	disabled?: boolean;
	loading?: boolean;
	sqashed?: boolean;
	checked: boolean;
	onChange: (checked: boolean) => void;
}

export default function ShowAdviceToggle({
	withLegend,
	readOnly,
	disabled,
	checked,
	loading,
	sqashed,
	theme,
	onChange,
}: ShowAdviceToggleProps): ReactElement {
	const globalTargetTypesWithAdviceResult = Services.adviceApi.useTargetTypesWithAdvice$({
		operator: 'AND',
		predicates: [],
	});
	const isAnyAdviceAvailable =
		globalTargetTypesWithAdviceResult.value && globalTargetTypesWithAdviceResult.value?.length > 0;

	const isDisabledBecauseOfLicense = !useLicenseFeature('RELIABILITY_ADVICE');
	const isDisabled = isDisabledBecauseOfLicense || disabled;

	return (
		<Tooltip
			content={
				isDisabledBecauseOfLicense ? (
					<LicenseTooltipContent />
				) : isAnyAdviceAvailable ? (
					'There is no advice in your current target selection'
				) : (
					'There is no extension installed that can give advice'
				)
			}
			disabled={!isDisabled}
		>
			<Stack
				size="xSmall"
				sx={{
					py: 'small',
					px: sqashed ? '6px' : 'small',
					bg: theme === 'light' ? 'neutral000' : 'neutral100',
					borderRadius: '8px',
				}}
			>
				<Tooltip content={sqashed ? 'Show advice' : undefined}>
					<Stack
						direction="horizontal"
						sx={{
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						{!sqashed && (
							<Stack direction="horizontal" alignItems="center" size="xSmall">
								<IconAdviceGeneral color={isDisabled ? 'neutral500' : 'slate'} />
								{loading ? (
									<Skeletons widths={[100]} height={16} />
								) : (
									<Text variant="smallStrong" color={isDisabled ? 'neutral500' : 'neutral800'}>
										Show advice
									</Text>
								)}
								<Tooltip
									content="Steadybit advice help you discover which chaos engineering experiments to run next and how to set them up."
									disabled={isDisabled}
								>
									<IconInformation variant="small" color={isDisabled ? 'neutral400' : 'neutral600'} />
								</Tooltip>
							</Stack>
						)}

						<Toggle
							disabled={readOnly || isDisabled || loading}
							checked={checked}
							onChange={(e) => onChange(e.target.checked)}
						/>
					</Stack>
				</Tooltip>
				{withLegend && checked && <AdviceLegend />}
			</Stack>
		</Tooltip>
	);
}

function AdviceLegend(): ReactElement {
	return (
		<Container
			sx={{
				display: 'flex',
				alignItems: 'center',
				gap: '12px',
			}}
		>
			<Text variant="xSmall" color="neutral600">
				Advice types:
			</Text>

			<IconWrapper color="coral" tooltip="Some actions are needed">
				<IconAdviceActionNeeded color="neutral000" width={14} height={14} />
			</IconWrapper>

			<IconWrapper color="experimentWarning" tooltip="Some validation is needed">
				<IconAdviceValidation color="neutral000" width={14} height={14} />
			</IconWrapper>

			<IconWrapper color="feedbackSuccess" tooltip="All advice correctly implemented">
				<IconAdviceImplemented color="neutral000" width={14} height={14} />
			</IconWrapper>
		</Container>
	);
}

function IconWrapper({
	color,
	tooltip,
	children,
}: {
	tooltip: string;
	color: string;
	children: ReactNode;
}): ReactElement {
	return (
		<Tooltip content={tooltip}>
			<Container
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					backgroundColor: color,
					borderRadius: '50%',
					padding: '4px',
				}}
			>
				{children}
			</Container>
		</Tooltip>
	);
}
