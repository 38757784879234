/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { ActionVO, EnvironmentSummaryVO, ExperimentStepActionVO, TemplateVO } from 'ui-api';
import { ReactElement, useMemo } from 'react';

import UnknownActionsTemplateModal from './UnknownActionsTemplateModal';
import { UseTemplateFormData } from './UseTemplateFormLoadingHandler';
import UseTemplateForm from './UseTemplateForm';

interface UseTemplateActionsCheckerProps {
	withExperimentHypothesisExtraction?: boolean;
	withExperimentNameExtraction?: boolean;
	environments: EnvironmentSummaryVO[];
	isCreatedByAdvice: boolean;
	newExperimentTags?: string[];
	children: ReactElement;
	template: TemplateVO;
	actions: ActionVO[];
	onIsEmpty: (values: UseTemplateFormData) => void;
	onSubmit: (values: UseTemplateFormData) => void;
	onClose: () => void;
}

export default function UseTemplateActionsChecker({
	withExperimentHypothesisExtraction = true,
	withExperimentNameExtraction = true,
	isCreatedByAdvice,
	newExperimentTags,
	environments,
	template,
	children,
	actions,
	onIsEmpty,
	onSubmit,
	onClose,
}: UseTemplateActionsCheckerProps): ReactElement {
	const unknownActions: string[] = useMemo(() => {
		return calculateUnknownActions(actions, template);
	}, [template?.id, actions]);

	if (unknownActions.length > 0) {
		return <UnknownActionsTemplateModal unknownActions={unknownActions} onClose={onClose} />;
	}

	return (
		<UseTemplateForm
			withExperimentHypothesisExtraction={withExperimentHypothesisExtraction}
			withExperimentNameExtraction={withExperimentNameExtraction}
			isCreatedByAdvice={isCreatedByAdvice}
			newExperimentTags={newExperimentTags}
			environmentId={environments[0]?.id}
			template={template}
			onIsEmpty={onIsEmpty}
			onSubmit={onSubmit}
			onClose={onClose}
		>
			{children}
		</UseTemplateForm>
	);
}

function calculateUnknownActions(actions: ActionVO[], template: TemplateVO): string[] {
	const unknownActions: string[] = [];
	const availableActionIds = new Set(actions.map((action) => action.id));
	for (let iL = 0; iL < template.lanes.length; iL++) {
		const { steps } = template.lanes[iL];
		for (let iS = 0; iS < steps.length; iS++) {
			const step = steps[iS];
			if (step.type === 'action') {
				const action = step as ExperimentStepActionVO;
				if (!availableActionIds.has(action.actionId)) {
					unknownActions.push(action.actionId);
				}
			}
		}
	}
	return unknownActions;
}
