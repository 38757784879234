/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { Container, StyleProp } from 'components';
import { ReactElement, ReactNode } from 'react';

interface PillProps {
	id?: string;
	backgroundColor: string;
	backgroundColorOnHover?: string;
	color: string;
	colorOnHover?: string;
	children: string | number | ReactElement | ReactNode | Array<string | number | ReactElement | ReactNode>;
	ref?: React.Ref<HTMLElement> | undefined;
	onClick?: () => void;
	sx?: StyleProp | undefined;
}

export default function Pill({
	id,
	backgroundColor,
	backgroundColorOnHover,
	color,
	colorOnHover,
	onClick,
	children,
	ref,
	sx,
}: PillProps): ReactElement {
	return (
		<Container
			id={id}
			display={'flex'}
			ref={ref}
			tx="pill"
			variant="medium"
			alignItems="center"
			bg={backgroundColor}
			color={color}
			onClick={onClick}
			sx={{
				justifyContent: 'center',
				cursor: onClick ? 'pointer' : 'auto',
				display: 'flex',
				textAlign: 'center',
				lineHeight: 1,
				whiteSpace: 'nowrap',
				':hover': {
					backgroundColor: onClick ? backgroundColorOnHover || backgroundColor : backgroundColor,
					color: onClick ? colorOnHover || color : color,
				},
				...sx,
			}}
		>
			{children}
		</Container>
	);
}
