/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { CustomIcon, Pill, Text } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';
import { Stack } from 'components';

interface TargetTypeProps {
	type: string;
	icon: string | undefined;
}

export default function TargetTypeBadge({ type, icon }: TargetTypeProps): ReactElement {
	return (
		<Stack direction="horizontal" size="xSmall" alignItems="center">
			<Text type="xSmallStrong">Type:</Text>
			<Pill
				style={{
					padding: '0 8px',
					height: '24px',
					maxWidth: '240px',
				}}
			>
				{icon && <CustomIcon svgDataUri={icon} size="small" />}
				<Text type="smallStrong" textEllipsis>
					{type}
				</Text>
			</Pill>
		</Stack>
	);
}
