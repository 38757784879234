/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import { formatDifference } from 'utils/dateFns';
import { useNowObservable } from 'utils/now';
import { ReactElement } from 'react';

type TimeDiffProps = { value: Date } & Omit<ContainerProps, 'css' | 'value'>;
export function TimeDiff({ value, sx = {}, ...props }: TimeDiffProps): ReactElement | null {
	const now = useNowObservable();
	if (!value) {
		return null;
	}
	return (
		<Container sx={{ fontVariantNumeric: 'tabular-nums', ...sx }} {...props}>
			{formatDifference(now, value)}
		</Container>
	);
}
