/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, Flex, Text } from '@steadybit/ui-components-lib';
import { LoadingIndicator, Svg, SvgProps } from 'components';
import { formatDifference } from 'utils/dateFns';
import { ExperimentExecutionVO } from 'ui-api';
import { useNowObservable } from 'utils/now';
import { motion } from 'framer-motion';
import { ReactElement } from 'react';

import { IconComponent } from '../icons';

export interface ButtonRunAction {
	icon?: IconComponent;
	disabled?: boolean;
	label: string;
	value: string;
}

type ButtonRunIconStopProps = Omit<SvgProps, 'ref'> & { started?: Date; estimatedEnd?: Date; size?: string };

export function ButtonRunIconStop({
	size = 'medium',
	estimatedEnd,
	started,
	...props
}: ButtonRunIconStopProps): ReactElement {
	const now = useNowObservable();
	let progress = 0;
	if (started && estimatedEnd) {
		const duration = estimatedEnd.valueOf() - started.valueOf();
		const progressed = now.valueOf() - started.valueOf();
		progress = Math.max(Math.min(progressed / duration, 1.0), 0.0);
	}
	return (
		<Svg tx={'runButtonStop'} variant={size} viewBox={'0 0 24 24'} xmlns={'http://www.w3.org/2000/svg'} {...props}>
			<rect x={'9'} y={'9'} width={'6'} height={'6'} rx={1} fill={'currentColor'} />
			<circle opacity={0.3} cx={12} cy={12} r={10} stroke={'currentColor'} fill={'none'} strokeWidth={2} />
			<motion.circle
				cx={12}
				cy={12}
				r={10}
				fill={'none'}
				stroke={'currentColor'}
				strokeWidth={2}
				strokeDasharray={63}
				initial={{ strokeDashoffset: 0 }}
				animate={{ strokeDashoffset: 63 * progress }}
				style={{
					transform: 'rotate(-90deg)',
					transformOrigin: '50% 50%',
				}}
			/>
		</Svg>
	);
}

interface ButtonRunProps {
	experimentExecution: ExperimentExecutionVO;
	loading: boolean;
	onClick: () => void;
}

export function ButtonRun({ experimentExecution, loading, onClick }: ButtonRunProps): ReactElement {
	const { started, estimatedEnd } = experimentExecution;
	const now = useNowObservable();
	return (
		<Button
			type="primaryRunning"
			onClick={loading ? undefined : onClick}
			data-cy={`run-${experimentExecution.name}`}
			style={{
				minWidth: '130px',
				fontVariantNumeric: 'tabular-nums',
				paddingLeft: '12px',
			}}
		>
			{loading ? (
				<Flex direction="horizontal" justify="center" style={{ width: '100%' }}>
					<LoadingIndicator />
				</Flex>
			) : (
				<Flex direction="horizontal" align="center" spacing="xSmall" style={{ pointerEvents: 'none' }}>
					<ButtonRunIconStop started={started} estimatedEnd={estimatedEnd} />
					<Text type="mediumStrong">{estimatedEnd && started ? formatDifference(now, estimatedEnd) : '...'}</Text>
				</Flex>
			)}
		</Button>
	);
}
