/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { DataStreamResult } from 'utils/hooks/stream/result';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { AdviceVO } from 'ui-api';
import { useState } from 'react';

export default function useAdvice(targetType: string, targetName: string): DataStreamResult<AdviceVO[]> {
	const [reloadCounter, setReloadCounter] = useState(0);

	useEventEffect(
		(event) => {
			if (!event.targetReference || event.targetReference === targetName) {
				setReloadCounter(reloadCounter + 1);
			}
		},
		['advice-updated'],
		() => {},
		[reloadCounter],
	);

	const adviceResult = usePromise(
		() => Services.adviceApi.getAdvice(targetType, targetName),
		[targetType, targetName, reloadCounter],
	);

	return adviceResult;
}
