/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Container,
	Link,
	Pagination,
	RouterLink,
	RouterPagination,
	ShortenedText,
	Spinner,
	Stack,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
	TableSort,
	TableSortLink,
	Text,
	Tooltip,
} from 'components';
import { GetTargetsPageResponse, TargetAttributeDescriptionVO, TargetTypeDescriptionVO, TargetVO } from 'ui-api';
import { getAttributeHeader, getAttributeKeyValue, getKey } from 'targets/util';
import { IconNewTab, IconTarget, IconTargetType } from 'components/icons';
import AttackedTargetIndicator from 'targets/AttackedTargetIndicator';
import { getDirection, Order, toggleSort } from 'utils/hooks/usePage';
import EmptyListContent from 'components/List/EmptyListContent';
import TableLoadingRow from 'components/Table/TableLoadingRow';
import { DataStreamResult } from 'utils/hooks/stream/result';
import AdviceBadges from 'components/advice/AdviceBadges';
import { Flex } from '@steadybit/ui-components-lib';
import { isUserAdmin } from 'utils/user';
import { getLabel } from 'i18n/label';
import { ReactElement } from 'react';

import AstroidScreen from '../../components/List/AstroidScreen/AstroidScreen';
import { useTenant } from '../../tenancy/useTenant';

interface TargetsTableProps {
	targetDefinition: DataStreamResult<TargetTypeDescriptionVO | undefined>;
	attributeDefinitions: DataStreamResult<TargetAttributeDescriptionVO[]>;
	fetchResult: DataStreamResult<GetTargetsPageResponse>;
	showAdvice?: boolean;
	borderless?: boolean;
	sort: Order[];
	query: string;
	page: number;
	EmptyListResult?: (props: EmptyListContentProps) => ReactElement;
	getTargetDetailHref?: (target: TargetVO) => string;
	getSortHref?: (sort: Order[]) => string;
	onChangeSort?: (sort: Order[]) => void;
	getPageHref?: (page: number) => string;
	onChangePage?: (page: number) => void;
	EmptyResult?: () => ReactElement;
}

export function TargetsTable({
	attributeDefinitions,
	showAdvice = false,
	borderless = false,
	targetDefinition,
	fetchResult,
	query,
	page,
	sort,
	EmptyListResult = DefaultEmptyListContent,
	EmptyResult = DefaultEmptyContent,
	getTargetDetailHref,
	onChangePage,
	onChangeSort,
	getPageHref,
	getSortHref,
}: TargetsTableProps): ReactElement {
	if (targetDefinition.loading || attributeDefinitions.loading) {
		return <Spinner variant="xLarge" color="neutral300" />;
	} else if (!targetDefinition.value || !attributeDefinitions.value) {
		return <EmptyResult />;
	}

	const SORT_ADVVICE_ASC: Order[] = [['advice', 'asc']];
	const SORT_ADVVICE_DESC: Order[] = [['advice', 'desc']];

	return (
		<Stack size="medium">
			<Table width={'100%'}>
				<TableHead>
					<TableRow>
						{targetDefinition.value.table.columns.map((column, i) => {
							const sortAttributes = [column.attribute, ...(column.fallbackAttributes ?? [])].map(
								(attr) => `attributes[${attr}]`,
							);

							const orderAsc: Order[] = sortAttributes.map((attribute) => [attribute, 'asc']);
							const orderDesc: Order[] = sortAttributes.map((attribute) => [attribute, 'desc']);

							const header = getAttributeHeader(column, attributeDefinitions.value);
							const label = <span>{header.label}</span>;

							return (
								<TableHeadCell key={i}>
									{getSortHref && (
										<TableSortLink
											sort={getDirection(sort, orderAsc, orderDesc)}
											to={getSortHref(toggleSort(sort, orderAsc, orderDesc))}
										>
											{header.tooltip ? <Tooltip content={header.tooltip}>{label}</Tooltip> : label}
										</TableSortLink>
									)}

									{onChangeSort && (
										<TableSort
											sort={getDirection(sort, orderAsc, orderDesc)}
											onClick={() => onChangeSort(toggleSort(sort, orderAsc, orderDesc))}
										>
											{header.tooltip ? <Tooltip content={header.tooltip}>{label}</Tooltip> : label}
										</TableSort>
									)}
								</TableHeadCell>
							);
						})}
						{showAdvice && (
							<TableHeadCell width="180px">
								{getSortHref ? (
									<TableSortLink
										sort={getDirection(sort, SORT_ADVVICE_ASC, SORT_ADVVICE_DESC)}
										to={getSortHref(toggleSort(sort, SORT_ADVVICE_ASC, SORT_ADVVICE_DESC))}
									>
										Advice
									</TableSortLink>
								) : onChangeSort ? (
									<TableSort
										sort={getDirection(sort, SORT_ADVVICE_ASC, SORT_ADVVICE_DESC)}
										onClick={() => onChangeSort(toggleSort(sort, SORT_ADVVICE_ASC, SORT_ADVVICE_DESC))}
									>
										Advice
									</TableSort>
								) : (
									'Advice'
								)}
							</TableHeadCell>
						)}
					</TableRow>
				</TableHead>
				<TableBody>
					{fetchResult.loading && (
						<>
							<TableLoadingRow numColumns={targetDefinition.value.table.columns.length + (showAdvice ? 1 : 0)} />
							<TableLoadingRow numColumns={targetDefinition.value.table.columns.length + (showAdvice ? 1 : 0)} />
							<TableLoadingRow numColumns={targetDefinition.value.table.columns.length + (showAdvice ? 1 : 0)} />
						</>
					)}

					{!fetchResult.loading &&
						fetchResult.value?.content.map((target) => {
							return (
								<TableRow hoverable key={getKey(target)}>
									{targetDefinition.value?.table.columns.map((column, i) => {
										const value = getAttributeKeyValue(column, target.attributes)?.value;
										let content = null;
										if (!value && i === 0) {
											content = (
												<ShortenedText
													noWrap
													variant="medium"
													sx={{
														fontStyle: 'italic',
													}}
												>
													{target.name ? target.name : `Unnamed target ${target.type} - ${target.agentId}`}
												</ShortenedText>
											);
										} else {
											let color: string | undefined = undefined;
											if (value === 'true') {
												color = 'slateDark';
											} else if (value === 'false') {
												color = 'experimentWarning';
											}

											content = (
												<ShortenedText noWrap variant="medium" color={color}>
													{value}
												</ShortenedText>
											);
										}

										if (i === 0 && targetDefinition.value) {
											content = (
												<Flex direction="horizontal" spacing="xSmall" align="center" style={{ cursor: 'pointer' }}>
													<IconTargetType targetType={targetDefinition.value.id} size="small" />
													{content}
												</Flex>
											);
										}

										if (getTargetDetailHref && i === 0) {
											content = <RouterLink to={getTargetDetailHref(target)}>{content}</RouterLink>;
										} else if (value != null && shouldPresentAsExternalLink(value)) {
											content = (
												<Link href={value} external sx={{ display: 'flex', alignItems: 'center', gap: 'xSmall' }}>
													{content}
													<IconNewTab color="neutral800" width={16} flexShrink={0} />
												</Link>
											);
										}

										return (
											<TableDataCell key={i} maxWidth={1}>
												{content}
											</TableDataCell>
										);
									})}

									{showAdvice && <AdviceColumn target={target} />}
								</TableRow>
							);
						})}

					{fetchResult.value?.content.length === 0 && (
						<TableRow>
							<TableDataCell
								colSpan={targetDefinition.value.table.columns.length}
								justifyContent="center"
								style={{
									borderBottom: borderless ? 'none' : undefined,
								}}
							>
								<EmptyListResult query={query} targetDefinition={targetDefinition.value} />
							</TableDataCell>
						</TableRow>
					)}
				</TableBody>
			</Table>

			{getPageHref && (
				<RouterPagination activePage={page} totalPages={fetchResult.value?.totalPages} to={(i) => getPageHref(i)} />
			)}
			{onChangePage && (
				<Pagination activePage={page} totalPages={fetchResult.value?.totalPages} onClick={onChangePage} />
			)}
		</Stack>
	);
}

interface AdviceColumnProps {
	target: TargetVO;
}

function AdviceColumn({ target }: AdviceColumnProps): ReactElement {
	const adviceRequireAction = target.attributes.filter((a) => a.key === 'advice.status.action-needed').length;
	const adviceRequireValidation = target.attributes.filter((a) => a.key === 'advice.status.validation-needed').length;
	const adviceDone = target.attributes.filter((a) => a.key === 'advice.status.implemented').length;

	return (
		<TableDataCell>
			<AdviceBadges
				variant="small"
				adviceRequireValidation={adviceRequireValidation}
				adviceRequireAction={adviceRequireAction}
				adviceDone={adviceDone}
				floating={false}
			/>
		</TableDataCell>
	);
}

export interface EmptyListContentProps {
	query?: string;
	targetDefinition: TargetTypeDescriptionVO;
}

function DefaultEmptyListContent({ query, targetDefinition }: EmptyListContentProps): ReactElement {
	return (
		<EmptyListContent
			icon={<AttackedTargetIndicator targetType={targetDefinition.id} variant="xxLarge" color="purple700" />}
			title={
				query
					? `No ${getLabel(targetDefinition.label, 0)} matching your filter found`
					: `No ${getLabel(targetDefinition.label, 0)} found`
			}
		/>
	);
}

function DefaultEmptyContent(): ReactElement {
	const tenant = useTenant();
	const isAdmin = isUserAdmin(tenant.user);
	return (
		<Container
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				height: '100%',
				width: '100%',
				marginTop: '-160px',
			}}
		>
			<AstroidScreen
				title={
					<Text variant="xLargeStrong" color="purple700">
						No Targets Found
					</Text>
				}
				icon={<IconTarget variant="xxLarge" color="purple700" />}
				description={
					<Text variant="large" color="neutral600" textAlign={'center'}>
						{isAdmin ? (
							<>
								The selected environment doesn&apos;t contain any targets.
								<br />
								<br />
								Please reconfigure the environment or install an agent / more extensions to discover further targets.
								<br />
								<br />
								You can have an overview of all the available Extensions in our{' '}
								<Link
									href="https://hub.steadybit.com/?utm_campaign=extensions&utm_source=platform-target-table&utm_medium=button&utm_content=environment-empty-admin"
									external
								>
									Reliability Hub
								</Link>
								.
							</>
						) : (
							<>
								The selected environment doesn&apos;t contain any targets.
								<br />
								<br />
								Please reach out to your administrator to reconfigure the environment or install an agent / more
								extensions to discover further targets.
								<br />
								<br />
								You can have an overview of all the available Extensions in our{' '}
								<Link
									href="https://hub.steadybit.com/?utm_campaign=extensions&utm_source=platform-target-table&utm_medium=button&utm_content=environment-empty-user"
									external
								>
									Reliability Hub
								</Link>
								.
							</>
						)}
					</Text>
				}
			/>
		</Container>
	);
}

function shouldPresentAsExternalLink(s: string): boolean {
	s = s.toLowerCase();
	if (!(s.startsWith('https://') || s.startsWith('http://'))) {
		return false;
	}

	try {
		new URL(s);
		return true;
	} catch {
		return false;
	}
}
