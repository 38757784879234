/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { TemplateVO, TrackingCreationMethodVO } from 'ui-api';
import { Services } from 'services/services';
import { useTeam } from 'services/useTeam';
import { ModalOverlay } from 'components';
import { useHistory } from 'url/hooks';
import { ReactElement } from 'react';
import { ampli } from 'ampli';

import UseTemplateFormLoadingHandler from './UseTemplateFormLoadingHandler';
import { createExperimentRequestFromTemplate } from './utils';

interface UseTemplateModalProps {
	experimentCreationMethod: TrackingCreationMethodVO;
	newExperimentTags?: string[];
	templatePreviewId: string;
	template?: TemplateVO;
	onClose: () => void;
}

export default function UseTemplateModal({
	experimentCreationMethod,
	templatePreviewId,
	newExperimentTags,
	template,
	onClose,
}: UseTemplateModalProps): ReactElement {
	const history = useHistory();
	const team = useTeam();

	return (
		<ModalOverlay open onClose={onClose}>
			<UseTemplateFormLoadingHandler
				newExperimentTags={newExperimentTags}
				templateId={templatePreviewId}
				template={template}
				onClose={onClose}
				onSubmit={async (values) => {
					ampli.experimentTemplateUsedWizardStepCompleted({
						experiment_template_name: values.templateTitle,
						experiment_template_step: values.placeholders.length + 1,
						experiment_template_last_step: true,
						experiment_template_step_placeholder: values.placeholders[values.placeholders.length - 1]?.name,
						experiment_template_total_steps: values.placeholders.length + 1,
					});

					await Services.environments.updateEnvironmentVariables(values.environmentId, {
						variables: Array.from(values.variableValuesMap.entries()).map(([k, v]) => ({ key: k, value: v })),
					});

					history.push('/experiments/edit/<new>/design', {
						preparedFormData: {
							actions: ['save', 'run-by-team', 'run-by-license'],
							initialValues: createExperimentRequestFromTemplate({
								formData: values,
								teamId: team.id,
								experimentCreationMethod,
								tags: newExperimentTags,
								capNameAndHypothesis: true,
							}),
							initialErrors: {},
						},
					});
				}}
			/>
		</ModalOverlay>
	);
}
