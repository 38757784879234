/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	ActionVO,
	BaseExperimentStepExecutionVOUnion,
	CreateExperimentRequest,
	CreateExperimentResponse,
	CreateExperimentScheduleVO,
	EndedExperimentVO,
	ExperimentDeletionMethodVO,
	ExperimentEditorInformationVO,
	ExperimentExecutionLaneVO,
	ExperimentExecutionResponse,
	ExperimentExecutionSummaryVO,
	ExperimentExecutionVO,
	ExperimentMetadataVO,
	ExperimentRunsRequestVO,
	ExperimentScheduleSummaryVO,
	ExperimentScheduleVO,
	ExperimentVO,
	FieldVO,
	GetActionParametersResponse,
	GetEndedExperimentsResponse,
	GetExecutionStatsResponse,
	GetExperimentExecutionsPageResponse,
	GetExperimentsPageResponse,
	GetExperimentsRequestVO,
	GetRunningExperimentsResponse,
	MetricQueryVO,
	PagedModel,
	RunningExperimentVO,
	SearchObjectsVO,
	TargetPredicateVO,
	TrackingExecutionSourceVO,
	UpdateExperimentRequest,
	UpdateExperimentResponse,
	ValidateCronExpressionRequest,
	ValidateCronExpressionResponse,
	ViolationVO,
} from 'ui-api';
import { filter, map, mergeMap, shareReplay, startWith, switchMap, throttleTime } from 'rxjs/operators';
import { createResult, DataStreamResult, loadingResult } from 'utils/hooks/stream/result';
import { Criteria, PageLocation, PageParams } from 'utils/hooks/usePage';
import { useObservable } from 'utils/hooks/useObservable';
import { concat, defer, from, Observable } from 'rxjs';
import axios, { AxiosResponse } from 'axios';
import { json, yml } from 'utils/mediaTypes';
import { days, seconds } from 'utils/time';
import { flatMap, uniq } from 'lodash';
import cached from 'utils/cached';

import { DateReviver, withReviver } from './common';
import { EventsApi } from './eventsApi';
import { useTeam } from './useTeam';

const relevantRunningEvents = [
	'experiment.execution.requested',
	'experiment.execution.created',
	'experiment.execution.preflight',
	'experiment.execution.prepared',
	'experiment.execution.started',
	'experiment.execution.step-started',
	'experiment.execution.completed',
	'experiment.execution.failed',
	'experiment.execution.errored',
	'experiment.execution.canceled',
	'events_reconnected',
];

const relevantEndedEvents = [
	'experiment.execution.completed',
	'experiment.execution.failed',
	'experiment.execution.canceled',
	'experiment.execution.errored',
	'events_reconnected',
];

export type ExperimentSaveResult = {
	experimentKey: string;
	criteria: Criteria;
	pageParams: PageParams;
	created: boolean;
	teamKey: string;
};

export function findStep(
	lanes: ExperimentExecutionLaneVO[],
	predicate?: string | ((step: BaseExperimentStepExecutionVOUnion) => boolean),
): BaseExperimentStepExecutionVOUnion | null {
	const result = findSteps(lanes, predicate);
	if (result.length) {
		return result[0];
	}
	return null;
}

function findSteps(
	lanes: ExperimentExecutionLaneVO[],
	predicate?: string | ((step: BaseExperimentStepExecutionVOUnion) => boolean),
): BaseExperimentStepExecutionVOUnion[] {
	if (typeof predicate === 'string') {
		return findSteps(lanes, (step) => step.id === predicate);
	}
	const result: BaseExperimentStepExecutionVOUnion[] = [];
	if (typeof predicate === 'function') {
		for (let i = 0; i < lanes.length; i++) {
			const steps = lanes[i].steps;
			for (let j = 0; j < steps.length; j++) {
				if (predicate(steps[j])) {
					result.push(steps[j]);
				}
			}
		}
	}
	return result;
}

export function flatMapSteps<R>(
	lanes: ExperimentExecutionLaneVO[],
	fn: (step: BaseExperimentStepExecutionVOUnion, laneIdx: number, stepIds: number) => R[],
): R[] {
	return flatMap(lanes, (lane, i) => flatMap(lane.steps, (step, j) => fn(step, i, j)));
}

export class ExperimentApi {
	private eventsApi: EventsApi;

	running$: Observable<RunningExperimentVO[]>;
	ended$: Observable<EndedExperimentVO[]>;

	constructor(events: EventsApi) {
		this.eventsApi = events;
		this.running$ = concat(
			defer(() => from(ExperimentApi.fetchRunning())),
			events.events$.pipe(
				filter((event) => relevantRunningEvents.includes(event.type)),
				mergeMap(() => ExperimentApi.fetchRunning()),
			),
		).pipe(shareReplay(1));

		const pageParams = new PageParams(0, 6, [['ended', 'desc', 'ignoreCase']]);
		this.ended$ = concat(
			defer(() => from(ExperimentApi.fetchEnded(pageParams))),
			events.events$.pipe(
				filter((event) => relevantEndedEvents.includes(event.type)),
				mergeMap(() => ExperimentApi.fetchEnded(pageParams)),
			),
		).pipe(shareReplay(1));
	}

	async fetchExperiments(body: GetExperimentsRequestVO): Promise<GetExperimentsPageResponse> {
		return axios
			.post<GetExperimentsPageResponse>('/ui/experiments/list', {
				...body,
				transformResponse: withReviver(DateReviver(['requested', 'created', 'edited'])),
			})
			.then(extractData)
			.then(transformDates);
	}

	async fetchExperiment(key: string): Promise<ExperimentVO> {
		return axios
			.get<ExperimentVO>(`/ui/experiments/${key}`, {
				transformResponse: withReviver(DateReviver(['requested', 'created', 'edited'])),
			})
			.then(extractData);
	}

	async fetchExperimentInApiFormat(key: string): Promise<never> {
		return axios
			.get<never>(`/ui/experiments/${encodeURIComponent(key)}/api-format`, {
				headers: {
					Accept: json,
				},
			})
			.then(extractData);
	}

	async getSearchObjects(teamId: string): Promise<SearchObjectsVO> {
		return (await axios.get<SearchObjectsVO>(`/ui/experiments/search_objects/${teamId}`)).data;
	}

	async getEditorInformation(teamId: string): Promise<ExperimentEditorInformationVO> {
		return (await axios.get<ExperimentEditorInformationVO>(`/ui/experiments/editor_information/${teamId}`)).data;
	}

	async runExperiment(key: string, executionSource: TrackingExecutionSourceVO, allowParallel = false): Promise<number> {
		return (
			await axios
				.post<ExperimentExecutionResponse>(`/ui/experiments/${key}/run`, null, {
					params: new URLSearchParams({ allowParallel: String(allowParallel), executionSource: executionSource }),
				})
				.then(extractData)
		).experimentExecutionId;
	}

	async createExperiment(body: Partial<CreateExperimentRequest>): Promise<CreateExperimentResponse> {
		return axios.post<CreateExperimentResponse>('/ui/experiments', body).then(extractData);
	}

	async createExperimentFromFileContent(
		experimentContent: string,
		teamIdFallback?: string,
		additionalTags?: string[],
		contentType?: string,
	): Promise<ExperimentVO> {
		const queryParams = [];
		if (additionalTags) {
			queryParams.push(`additionalTags=${additionalTags}`);
		}
		if (teamIdFallback) {
			queryParams.push(`teamId=${teamIdFallback}`);
		}
		if (!contentType) {
			contentType = yml;
		}
		return (
			await axios.post<ExperimentVO>(
				`/ui/experiments/from_file${queryParams ? `?${queryParams.join('&')}` : ''}`,
				experimentContent,
				{
					headers: {
						'Content-Type': contentType,
					},
				},
			)
		).data;
	}

	async validateExperiment(body: Partial<CreateExperimentRequest>): Promise<ViolationVO[]> {
		return axios.post<ViolationVO[]>('/ui/experiments/validate', body).then(extractData);
	}

	async validateMetricQuery(query: MetricQueryVO): Promise<ViolationVO[]> {
		return axios.post<ViolationVO[]>('/ui/experiments/validate/query', query).then(extractData);
	}

	async updateExperiment(key: string, body: UpdateExperimentRequest): Promise<UpdateExperimentResponse> {
		return axios.post<UpdateExperimentResponse>(`/ui/experiments/${key}`, body).then(extractData);
	}

	async createOrUpdateExperiment(
		values: CreateExperimentRequest & { experimentKey?: string; version?: number },
	): Promise<ExperimentSaveResult> {
		let response;
		let created;
		if (values.experimentKey && values.version !== undefined) {
			response = await this.updateExperiment(values.experimentKey, { ...values, version: values.version });
			created = false;
		} else {
			response = await this.createExperiment(values);
			created = true;
		}

		return {
			created,
			experimentKey: response.experimentKey,
			criteria: new URLSearchParams(response.criteria),
			pageParams: PageParams.of(response.pageRequest),
			teamKey: response.experimentKey.split('-')[0],
		};
	}

	async deleteExperiment(key: string, deletionMethod: ExperimentDeletionMethodVO): Promise<void> {
		await axios.delete(`/ui/experiments/${key}`, {
			params: { deletionMethod },
		});
	}

	async fetchExperimentRun(experimentExecutionId: number): Promise<ExperimentExecutionVO> {
		return axios
			.get<ExperimentExecutionVO>(`/ui/experiments/runs/${experimentExecutionId}`, {
				transformResponse: withReviver(
					DateReviver([
						'requested',
						'created',
						'started',
						'prepared',
						'estimatedStart',
						'ended',
						'estimatedEnd',
						'progress',
						'time',
					]),
				),
			})
			.then(extractData);
	}

	async getExperimentMetadata(experiment: CreateExperimentRequest): Promise<ExperimentMetadataVO> {
		return (await axios.post<ExperimentMetadataVO>('/ui/experiments/metadata', experiment)).data;
	}

	async cancelExperimentRun(experimentExecutionId: number): Promise<void> {
		await axios.post<number, void>(`/ui/experiments/runs/${experimentExecutionId}/cancel`);
	}

	private static async fetchRunning(): Promise<RunningExperimentVO[]> {
		return (
			await axios
				.get<GetRunningExperimentsResponse>('/ui/experiments/runs/running', {
					transformResponse: withReviver(DateReviver(['estimatedEnd', 'started'])),
				})
				.then(extractData)
		).content;
	}

	private static async fetchEnded(params: PageParams): Promise<EndedExperimentVO[]> {
		return (
			await axios
				.get<GetEndedExperimentsResponse>('/ui/experiments/runs/ended', {
					params,
					transformResponse: withReviver(DateReviver(['ended'])),
				})
				.then(extractData)
		).content;
	}

	async fetchExperimentRuns(
		criteria: Criteria,
		page: PageParams,
		experimentKey?: string,
	): Promise<GetExperimentExecutionsPageResponse> {
		const params = page.appendTo(new URLSearchParams(criteria));
		if (experimentKey) {
			params.append('experimentKey', experimentKey);
		}
		return axios
			.get<GetExperimentExecutionsPageResponse>('/ui/experiments/runs', {
				params,
				transformResponse: withReviver(DateReviver(['created', 'requested'])),
			})
			.then(extractData);
	}

	async fetchExperimentRunsList({
		environmentIds,
		teamIds,
		states,
		time,
		page,
		pageSize,
		sort,
		direction,
	}: {
		time: string | [number, number] | undefined;
		environmentIds: string[];
		teamIds: string[];
		states: string[];
		page: number;
		pageSize: number;
		sort: string;
		direction: string;
	}): Promise<GetExperimentExecutionsPageResponse> {
		let requestedFrom: number | null = null;
		let requestedTo: number | null = null;
		if (time) {
			requestedFrom = new Date().getTime();
			requestedTo = new Date().getTime();
			if (Array.isArray(time)) {
				requestedFrom = time[0];
				requestedTo = time[1];
			} else if (time === 'lastDay') {
				requestedFrom = requestedTo - days(1).getMillis();
			} else if (time === 'lastWeek') {
				requestedFrom = requestedTo - days(7).getMillis();
			} else if (time === 'lastMonth') {
				requestedFrom = requestedTo - days(31).getMillis();
			}
		}

		const request: ExperimentRunsRequestVO = {
			environmentIds,
			teamIds,
			states,
			requestedFrom: requestedFrom ? new Date(requestedFrom) : undefined,
			requestedTo: requestedTo ? new Date(requestedTo) : undefined,
			page,
			pageSize,
			sort,
			direction,
		};

		return axios
			.post<GetExperimentExecutionsPageResponse>('/ui/experiments/runs/list', request, {
				transformResponse: withReviver(DateReviver(['created', 'ended', 'requested'])),
			})
			.then(extractData);
	}

	async fetchExperimentRunIds(criteria: Criteria, page: PageParams, experimentKey: string): Promise<string[]> {
		const params = page.appendTo(new URLSearchParams(criteria));
		params.append('experimentKey', experimentKey);
		return (await axios.get<string[]>('/ui/experiments/runs/ids', { params })).data.map(String);
	}

	async fetchRunStats(teamId: string, requestedFrom?: Date, requestedTo?: Date): Promise<GetExecutionStatsResponse> {
		return axios
			.get<GetExecutionStatsResponse>('/ui/experiments/runs/stats', {
				params: { teamId, requestedFrom: requestedFrom?.toISOString(), requestedTo: requestedTo?.toISOString() },
			})
			.then(extractData);
	}

	async countExperimentRuns(criteria: Criteria): Promise<number> {
		const params = new URLSearchParams(criteria);
		return axios.get<number>('/ui/experiments/runs/count', { params }).then(extractData);
	}

	async fetchAction(attackId: string, teamId: string): Promise<ActionVO> {
		return axios.get<ActionVO>(`/ui/experiments/actions/${attackId}`, { params: { teamId } }).then(extractData);
	}

	fetchActionParameters = cached(this.fetchActionParametersInternal.bind(this), seconds(15).getMillis());

	private async fetchActionParametersInternal(
		actionId: string,
		environmentId?: string,
		predicate?: TargetPredicateVO,
	): Promise<FieldVO[]> {
		const params = new URLSearchParams();
		if (environmentId) {
			params.append('environmentId', environmentId);
		}

		return (
			await axios
				.post<GetActionParametersResponse>(`/ui/experiments/actions/${actionId}/parameters`, { predicate }, { params })
				.then(extractData)
		).content;
	}

	async fetchMetricQueryParameters(attackId: string, parameters: Record<string, string> = {}): Promise<FieldVO[]> {
		return (
			await axios
				.post<GetActionParametersResponse>(`/ui/experiments/actions/${attackId}/metric-query/parameters`, {
					parameters,
					query: { condition: 'AND', predicates: [] },
				})
				.then(extractData)
		).content;
	}

	async fetchActionParametersAsMap(
		environmentId: string | undefined,
		attackIds: string[],
	): Promise<Map<string, FieldVO[]>> {
		const promises = uniq(attackIds).map(
			async (attackId): Promise<[string, FieldVO[]]> => [
				attackId,
				await this.fetchActionParameters(environmentId, attackId),
			],
		);
		return new Map(await Promise.all(promises));
	}

	async fetchActionIdsForTeam(teamId: string): Promise<string[]> {
		return axios.get<string[]>('/ui/experiments/actions', { params: { teamId } }).then(extractData);
	}

	// schedules
	async scheduleExperiment(schedule: CreateExperimentScheduleVO): Promise<void> {
		await axios.put('/ui/experiments/schedule', schedule);
	}

	async updateExperimentSchedule(schedule: CreateExperimentScheduleVO): Promise<void> {
		await axios.post('/ui/experiments/schedule', schedule);
	}

	async validateCronExpression(data: ValidateCronExpressionRequest): Promise<ValidateCronExpressionResponse> {
		const result = (await axios.post<ValidateCronExpressionResponse>('/ui/experiments/schedule/validate/cron/v3', data))
			.data;
		if (result != undefined && result.nextExecutions != undefined) {
			for (let i = 0; i < result.nextExecutions.length; i++) {
				result.nextExecutions[i] = new Date(result.nextExecutions[i]);
			}
		}
		return result;
	}

	async deleteExperimentSchedule(id: string): Promise<void> {
		await axios.delete(`/ui/experiments/schedule/${id}`);
	}

	async fetchSchedule(key: string): Promise<ExperimentScheduleVO | undefined> {
		if (!key) {
			return undefined;
		}
		return (await axios.get(`/ui/experiments/schedule/${key}`)).data;
	}

	async fetchSchedules(
		criteria: Criteria,
		page: PageParams,
		teamId: string,
		type: 'recurrent' | 'once' | 'all',
	): Promise<PagedModel<ExperimentScheduleSummaryVO>> {
		const params = page.appendTo(new URLSearchParams(criteria));
		return (await axios.get(`/ui/experiments/schedules/${teamId}/${type}`, { params })).data;
	}

	async getPermissions(teamId: string): Promise<string[]> {
		return (await axios.get(`/ui/experiments/permissions/${teamId}`)).data;
	}

	useExecution$(execution: ExperimentExecutionSummaryVO): DataStreamResult<ExperimentExecutionVO> {
		return useObservable(
			() =>
				this.eventsApi.events$.pipe(
					filter((event) => event.type.startsWith('experiment.execution.') && event.executionId === execution.id),
					throttleTime(1_000),
					switchMap(() =>
						from(this.fetchExperimentRun(execution.id)).pipe(
							map((res) => createResult(res)),
							startWith(loadingResult),
						),
					),
				),
			[execution.id],
		);
	}

	useSchedules$(
		type: 'recurrent' | 'once' | 'all',
		refreshMode: 'count-only' | 'details',
		page: PageLocation,
	): DataStreamResult<PagedModel<ExperimentScheduleSummaryVO>> {
		const team = useTeam();

		return useObservable(
			() =>
				this.eventsApi.events$.pipe(
					filter(
						(event) =>
							event.type === 'schedule.created' ||
							event.type === 'schedule.deleted' ||
							(refreshMode === 'details' &&
								(event.type === 'schedule.updated' ||
									event.type === 'experiment.execution.failed' ||
									event.type === 'experiment.execution.completed' ||
									event.type === 'experiment.execution.errored' ||
									event.type === 'experiment.execution.canceled')),
					),
					startWith(undefined),
					switchMap(() => {
						return from(this.fetchSchedules(page.criteria, page.pageParams, team.id, type)).pipe(
							map((res) => createResult(res)),
							startWith(loadingResult),
						);
					}),
				),
			[page.criteria.toString(), page.pageParams.toUrlSearchParams().toString(), team.id, type, refreshMode],
		);
	}
}

function extractData<T>(response: AxiosResponse<T>): T {
	return response.data;
}

function transformDates(response: GetExperimentsPageResponse): GetExperimentsPageResponse {
	return {
		...response,
		content: response.content.map((experiment) => {
			return {
				...experiment,
				edited: new Date(experiment.edited),
				lastExperimentExecution: experiment.lastExperimentExecution
					? {
							...experiment.lastExperimentExecution,
							requested: new Date(experiment.lastExperimentExecution.requested),
							created: experiment.lastExperimentExecution.created
								? new Date(experiment.lastExperimentExecution.created)
								: undefined,
							ended: experiment.lastExperimentExecution.ended
								? new Date(experiment.lastExperimentExecution.ended)
								: undefined,
						}
					: undefined,
				lastExperimentExecutions: (experiment.lastExperimentExecutions || []).map((execution) => ({
					...execution,
					requested: new Date(execution.requested),
					created: execution.created ? new Date(execution.created) : undefined,
					ended: execution.ended ? new Date(execution.ended) : undefined,
				})),
			};
		}),
	};
}
