/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { ReactElement, useEffect, useRef, useState } from 'react';
import { Container } from 'components';

interface DimensionAwareProps {
	children: (params: { width: number; height: number }) => ReactElement;
}

export default function DimensionAware({ children }: DimensionAwareProps): ReactElement {
	const ref = useRef<HTMLDivElement>(null);
	const [dimensions, setDimensions] = useState({ width: -1, height: -1 });

	useEffect(() => {
		if (!ref.current) {
			return;
		}
		const resizeObserver = new ResizeObserver((entries) => {
			for (const entry of entries) {
				if (entry.target === ref.current) {
					setDimensions({ width: entry.contentRect.width, height: entry.contentRect.height });
				}
			}
		});

		resizeObserver.observe(ref.current);

		return () => {
			resizeObserver.disconnect();
		};
	}, [ref.current]);

	return (
		<Container width="100%" ref={ref}>
			{children(dimensions)}
		</Container>
	);
}
