/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Colors, Pill, Text } from '@steadybit/ui-components-lib';
import { TemplatePlaceholderVO } from 'ui-api';
import { ReactElement } from 'react';

interface TemplatePlaceholderProps {
	placeholders: TemplatePlaceholderVO[];
	withHelpText?: boolean;
	onClick: (placeholder: TemplatePlaceholderVO) => void;
}

export default function TemplatePlaceholder({ placeholders, onClick }: TemplatePlaceholderProps): ReactElement | null {
	if (placeholders.length === 0) {
		return null;
	}

	return (
		<>
			{placeholders.map((placeholder) => (
				<Pill
					key={placeholder.key}
					onClick={() => onClick(placeholder)}
					style={{
						padding: '2px 6px 2px 6px',
						height: '24px',
						color: Colors.aqua800,
						backgroundColor: Colors.aqua200,
						onHover: {
							backgroundColor: Colors.aqua300,
						},
					}}
				>
					<Text type="xSmallStrong" textEllipsis>
						{`[[${placeholder.key}]]`}
					</Text>
				</Pill>
			))}
		</>
	);
}
