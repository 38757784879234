/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { Container, RouterLink, ShortenedText, Stack, StateBadge, Text } from 'components';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { formatDateWithTime } from 'utils/dateFns';
import { Services } from 'services/services';
import { EndedExperimentVO } from 'ui-api';
import { ReactElement } from 'react';

export function EndedExecutions({ ended }: { ended: EndedExperimentVO[] }): ReactElement {
	const [users] = useAsyncState(
		() => Services.users.findUsersByUsername(ended.map((run) => run.createdBy)),
		[ended],
		new Map(),
	);

	return (
		<Stack size="none">
			{ended.map((executed) => (
				<Container
					key={executed.executionId}
					mt={'small'}
					width={'100%'}
					sx={{
						display: 'grid',
						gridTemplateColumns: '1fr auto',
						alignItems: 'center',

						borderRadius: '4px',
						p: 'xSmall',
						':hover': {
							bg: 'neutral100',
						},
					}}
				>
					<RouterLink
						to={`/experiments/edit/${executed?.experimentKey}/executions/${executed?.executionId}`}
						variant={'secondary'}
					>
						<ShortenedText
							noWrap
							tooltip={
								<>
									<Text variant="smallStrong" as="span" mr="xSmall">
										{executed.experimentKey}
									</Text>
									<Text variant="small" as="span" mr="xSmall">
										{executed.name}
									</Text>
								</>
							}
						>
							<Text variant={'mediumMedium'} as={'span'} mr={'xSmall'}>
								{executed.experimentKey}
							</Text>
							<Text variant={'medium'} as={'span'} color={'neutral700'}>
								{executed.name}
							</Text>
						</ShortenedText>
						<ShortenedText noWrap>
							<Text variant={'small'} color={'neutral600'} as={'span'} data-private>
								#{executed.executionId} ({formatDateWithTime(executed.ended)} by{' '}
								{users.value.get(executed.createdBy)?.name ?? executed?.createdBy})
							</Text>
						</ShortenedText>
					</RouterLink>
					<Container
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
						}}
					>
						<Text color={'neutral600'}>
							<StateBadge as="state" value={executed.state} />
						</Text>
					</Container>
				</Container>
			))}
		</Stack>
	);
}
