/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { TextArea as TextAreaC, Dropdown, presets, Colors } from '@steadybit/ui-components-lib';
import { useEditorSettings } from 'pages/experimentsV2/useEditorSettings';
import { ReactElement } from 'react';

import VariablesAndPlaceholders from './VariablesAndPlaceholders';

interface TextAreaProps {
	hasErrors?: boolean;
	disabled: boolean;
	value: string;
	setValue: (value: string) => void;
}

export default function TextArea({ value, hasErrors, disabled, setValue }: TextAreaProps): ReactElement {
	const { mode } = useEditorSettings();

	if (mode === 'experiment') {
		return (
			<TextAreaC
				value={value || ''}
				disabled={disabled}
				onChange={setValue}
				style={{
					minHeight: '96px',
					borderColor: hasErrors ? Colors.coral : undefined,
				}}
			/>
		);
	}

	return (
		<Dropdown
			renderDropdownContent={({ width, close }) => (
				<presets.dropdown.DropdownContentFrame width={width}>
					<VariablesAndPlaceholders
						width={width}
						selectItem={(v) => {
							setValue(v);
							close();
						}}
					/>
				</presets.dropdown.DropdownContentFrame>
			)}
		>
			{({ setRefElement, setOpen }) => {
				return (
					<div ref={setRefElement} onFocus={() => setOpen(true)} style={{ width: '100%' }}>
						<TextAreaC
							value={value || ''}
							disabled={disabled}
							onChange={setValue}
							style={{
								width: '100%',
								borderColor: hasErrors ? Colors.coral : undefined,
							}}
						/>
					</div>
				);
			}}
		</Dropdown>
	);
}
