/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import EnvironmentSelector from 'components/EnvironmentSelector/EnvironmentSelector';
import { useViewsUpdateSignal } from 'pages/customViews/hooks/useViewsUpdateSignal';
import { Button, Dropdown, Flex, IconButton } from '@steadybit/ui-components-lib';
import LicenseTooltipContent from 'App/components/LicenseTooltipContent';
import { Container, RouterLink, Tooltip } from 'components';
import { useLicenseFeature } from 'services/licenseApi';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { useTeam } from 'services/useTeam';
import { LandscapeViewVO } from 'ui-api';
import { theme } from 'styles.v2/theme';
import { useHistory } from 'url/hooks';
import { Helmet } from 'react-helmet';
import { ReactElement } from 'react';

import ExploreViewsContent from './ExploreViewsContent';

interface LandscapeHeaderProps {
	selectedEnvironmentId?: string;
	selectedViewId?: string | null;
	isCustomViews?: boolean;
	isExplore?: boolean;
	isAdvice?: boolean;
	isTable?: boolean;
}

export default function LandscapeHeader({
	selectedEnvironmentId,
	isCustomViews = false,
	isExplore = false,
	isAdvice = false,
	isTable = false,
	selectedViewId,
}: LandscapeHeaderProps): ReactElement {
	const { push } = useHistory();
	const landscapeEnabled = useLicenseFeature('LANDSCAPE');
	const adviceEnabled = useLicenseFeature('RELIABILITY_ADVICE');

	const team = useTeam();

	async function onChangeEnvironment(environmentId: string): Promise<void> {
		if (isAdvice) {
			push(`/landscape/${environmentId}/advice`);
		} else {
			push(`/landscape/${environmentId}/table`);
		}
	}

	return (
		<Container
			sx={{
				display: 'grid',
				gridTemplateColumns: '1fr 1fr 1fr',

				backgroundColor: 'neutral700',
				maxHeight: 72,
				minHeight: 72,
				px: 'medium',
				py: 'xSmall',
			}}
		>
			<Container display="flex" alignItems="center">
				{!isExplore && !isCustomViews && (
					<EnvironmentSelector
						selectedEnvironmentId={selectedEnvironmentId}
						isLandscapeView={isTable}
						teamId={team.id}
						selectEnvironmentId={onChangeEnvironment}
					/>
				)}
			</Container>

			<Container display="flex" alignItems="center" justifyContent="center">
				<ViewsButton
					selectedEnvironmentId={selectedEnvironmentId}
					isActive={isExplore || isCustomViews}
					selectedViewId={selectedViewId}
					disabled={!landscapeEnabled}
				/>

				<RouterLink to={`/landscape/${selectedEnvironmentId}/table`} style={{ textDecoration: 'none' }}>
					<Button
						type={isTable ? 'landscapeHeaderPrimary' : 'landscapeHeader'}
						size="small"
						onClick={() => {}}
						style={{
							borderRadius: 'none',
							border: '1px solid ' + theme.colors.neutral600,
							borderRight: 0,
							px: 'large',
						}}
					>
						Targets
					</Button>
				</RouterLink>

				<RouterLink to={`/landscape/${selectedEnvironmentId}/advice`} style={{ textDecoration: 'none' }}>
					<Tooltip content={<LicenseTooltipContent />} disabled={!!adviceEnabled}>
						<Button
							type={isAdvice ? 'landscapeHeaderPrimary' : 'landscapeHeader'}
							disabled={!adviceEnabled}
							size="small"
							style={{
								border: '1px solid ' + theme.colors.neutral600,
								borderRadius: 'xxSmall',
								borderTopLeftRadius: 0,
								borderBottomLeftRadius: 0,
								px: 'large',
							}}
							onClick={() => {}}
						>
							Advice
						</Button>
					</Tooltip>
				</RouterLink>
			</Container>
		</Container>
	);
}

interface ViewsButtonProps {
	selectedViewId?: string | null;
	selectedEnvironmentId?: string;
	disabled?: boolean;
	isActive: boolean;
}

function ViewsButton({ isActive, disabled, selectedViewId, selectedEnvironmentId }: ViewsButtonProps): ReactElement {
	const team = useTeam();
	const landscapeEnabled = useLicenseFeature('LANDSCAPE');

	const viewResponse = usePromise(() => {
		if (!selectedViewId || !selectedEnvironmentId) {
			return undefined;
		}
		return Services.landscapeV2.getView(selectedViewId, team.id, selectedEnvironmentId);
	}, [selectedViewId, selectedEnvironmentId]);
	const selectedView = viewResponse.value;

	return (
		<Tooltip content={<LicenseTooltipContent />} disabled={!!landscapeEnabled}>
			<Flex direction="horizontal">
				{selectedView?.name && (
					<Helmet>
						<title>Explorer / {selectedView.name}</title>
					</Helmet>
				)}

				<RouterLink
					to={selectedView ? `/landscape/explore/${selectedView.id}` : '/landscape/explore/<new>'}
					disabled={disabled}
					style={{ textDecoration: 'none' }}
				>
					<Tooltip content={selectedView?.name ?? ''}>
						<Button
							type={isActive ? 'landscapeHeaderPrimary' : 'landscapeHeader'}
							disabled={disabled}
							size="small"
							style={{
								borderRadius: 'xxSmall',
								borderTopRightRadius: 0,
								borderBottomRightRadius: 0,
								border: '1px solid ' + theme.colors.neutral600,
								maxWidth: '180px',
							}}
							onClick={() => {}}
						>
							{selectedView?.name || 'Landscape'}
						</Button>
					</Tooltip>
				</RouterLink>

				<Dropdown
					placement="bottom-end"
					renderDropdownContent={({ close }) => (
						<ViewsDropDownContent teamId={team.id} selectItem={close} selectedViewId={selectedViewId} />
					)}
				>
					{({ setRefElement, isOpen, setOpen }) => {
						return (
							<IconButton
								ref={setRefElement}
								disabled={disabled}
								type={isOpen ? 'landscapeHeaderPrimary' : 'landscapeHeader'}
								size="small"
								icon={isOpen ? 'triangle-up' : 'triangle-down'}
								style={{
									borderRadius: 'none',
									borderTop: '1px solid ' + theme.colors.neutral600,
									borderBottom: '1px solid ' + theme.colors.neutral600,
								}}
								onClick={() => setOpen(!isOpen)}
							/>
						);
					}}
				</Dropdown>
			</Flex>
		</Tooltip>
	);
}

interface ViewsDropDownContentProps {
	selectedViewId?: string | null;
	selectItem: () => void;
	teamId: string;
}

function ViewsDropDownContent({ teamId, selectItem, selectedViewId }: ViewsDropDownContentProps): ReactElement {
	const reloadSignal = useViewsUpdateSignal();
	const viewsResponse = usePromise<LandscapeViewVO[]>(
		() => Services.landscapeV2.getViews(teamId),
		[teamId, reloadSignal],
	);
	const views = viewsResponse.value ?? [];

	const templateViewsResponse = usePromise<LandscapeViewVO[]>(() => Services.landscapeV2.getTemplateViews(), [teamId]);
	const templateViews = templateViewsResponse.value ?? [];

	return (
		<ExploreViewsContent
			selectedViewId={selectedViewId}
			templateViews={templateViews}
			onChangeView={selectItem}
			views={views}
		/>
	);
}
