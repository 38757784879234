/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	CompositeTargetPredicateVO,
	TargetAttributeKeyValuePredicateVO,
	TargetPredicateTemplateVO,
	TargetPredicateVO,
} from 'ui-api';
import { isCompositeTargetPredicateVO } from 'queryLanguage/parser/visitor';
import { Colors, Flex, Text } from '@steadybit/ui-components-lib';
import { toTargetPredicate } from 'queryLanguage/parser/parser';
import { isParsingError } from 'queryLanguage/parser/types';
import { ReactElement, useEffect, useState } from 'react';
import { Tooltip } from 'components/Tooltip';
import { IconHelp } from 'components/icons';

import { isTargetAttributeKeyValuePredicateVO } from './utils';

interface PredicateTemplatesProps {
	predicateTemplates: TargetPredicateTemplateVO[];
	keys: string[];
	onApplyTemplate: (predicate: TargetPredicateTemplateVO) => void;
}

export default function PredicateTemplates({
	predicateTemplates,
	keys,
	onApplyTemplate,
}: PredicateTemplatesProps): ReactElement | null {
	const filteredTemplates = filterPredicateTemplates(predicateTemplates, keys);
	if (filteredTemplates.length === 0) {
		return null;
	}

	return (
		<Flex spacing="xxSmall">
			<Flex direction="horizontal" align="center" spacing="xSmall">
				<Tooltip
					color="light"
					content={<Text type="small">Narrow down your selection using one of these pre-filled query</Text>}
				>
					<Flex align="center">
						<IconHelp variant="small" color="neutral600" />
					</Flex>
				</Tooltip>
				<Text type="small">Prefill query with:</Text>
			</Flex>
			<Flex direction="horizontal" align="center" spacing="xSmall" wrap>
				{filteredTemplates.map((tpt) => (
					<PredicateTemplate
						key={tpt.name}
						predicate={tpt}
						onClick={() => {
							onApplyTemplate(tpt);
						}}
					/>
				))}
			</Flex>
		</Flex>
	);
}

function filterPredicateTemplates(
	templates: TargetPredicateTemplateVO[],
	attributeKeys: string[],
): TargetPredicateTemplateVO[] {
	return templates.filter((template) => {
		const parseResult = toTargetPredicate(template.template);
		return !isParsingError(parseResult) && isPredicateTemplateApplicable(parseResult, attributeKeys);
	});
}

interface PredicateTemplateProps {
	predicate: TargetPredicateTemplateVO;
	onClick: () => void;
}

function PredicateTemplate({ predicate, onClick }: PredicateTemplateProps): ReactElement {
	const [applied, setApplied] = useState(false);
	useEffect(() => {
		if (applied) {
			const timeout = setTimeout(() => setApplied(false), 3000);
			return () => clearTimeout(timeout);
		}
	}, [applied]);

	return (
		<Tooltip content={applied ? null : predicate.description} key={predicate.name}>
			<div>
				<Text
					key={predicate.name}
					type="smallStrong"
					style={{
						color: Colors.slate,
						whiteSpace: 'nowrap',
						textDecoration: 'underline',
						cursor: 'pointer',
					}}
					data-track="target-predicate-template"
					onClick={() => {
						onClick();
						setApplied(true);
					}}
				>
					{predicate.name}
				</Text>
			</div>
		</Tooltip>
	);
}

function isPredicateTemplateApplicable(predicate: TargetPredicateVO, attributeKeys: string[]): boolean {
	if (isCompositeTargetPredicateVO(predicate)) {
		const composite = predicate as CompositeTargetPredicateVO;
		return composite.predicates.every((predicate: TargetPredicateVO) =>
			isPredicateTemplateApplicable(predicate, attributeKeys),
		);
	}
	if (isTargetAttributeKeyValuePredicateVO(predicate)) {
		const keyValuePredicate = predicate as TargetAttributeKeyValuePredicateVO;
		return attributeKeys.includes(keyValuePredicate.key);
	}
	return true;
}
