/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Input } from 'pages/experimentsV2/StepConfigurationSidebar/Fields/Controls/Text';
import { Container, Stack } from 'components';
import React, { ReactElement } from 'react';

import { ButtonIcon } from '../ButtonIcon';
import { IconDelete } from '../icons';

export interface KeyValuePair {
	key: string;
	value: string;
}

interface KeyValueListInputProps {
	value?: KeyValuePair[];
	disabled?: boolean;
	onChange?: (value: KeyValuePair[]) => void;
}

export function KeyValueListInput({ value = [], onChange, disabled }: KeyValueListInputProps): ReactElement {
	const [newPair, setNewPair] = React.useState<KeyValuePair>({
		key: '',
		value: '',
	});
	const list = React.useMemo(() => [...value, ...(disabled ? [] : [newPair])], [value, disabled, newPair]);

	return (
		<Stack size={'xxSmall'}>
			{list.map((v, i) => {
				const isNewPredicate = i >= (value.length ?? 0);
				const handlers = isNewPredicate
					? {
							onChange: setNewPair,
							onDelete: undefined,
							onComplete: (pair: KeyValuePair) => {
								onChange?.([...value, pair]);
								setNewPair({
									key: '',
									value: '',
								});
							},
						}
					: {
							onChange: (pair: KeyValuePair) => onChange?.(replaceAt(value, i, pair)),
							onDelete: () => onChange?.(removeAt(value, i)),
							onComplete: undefined,
						};
				return (
					<React.Fragment key={i}>
						<KeyValuePairInput value={v} disabled={disabled} id={`kvp-${i}`} {...handlers} />
					</React.Fragment>
				);
			})}
		</Stack>
	);
}

function replaceAt<T>(array: T[], index: number, item: T): T[] {
	const newArray = [...array];
	newArray[index] = item;
	return newArray;
}

function removeAt<T>(array: T[], index: number): T[] {
	const newArray = [...array];
	newArray.splice(index, 1);
	return newArray;
}

interface KeyValuePairInputProps {
	value: KeyValuePair;
	disabled?: boolean;
	id?: string;
	onComplete?: (value: KeyValuePair) => void;
	onChange?: (value: KeyValuePair) => void;
	onDelete?: () => void;
}

function KeyValuePairInput({
	id = 'kvp',
	disabled,
	value,
	onChange,
	onDelete,
	onComplete,
}: KeyValuePairInputProps): ReactElement {
	const emitChange = (kvp: KeyValuePair): void => {
		onChange?.(kvp);
		if (kvp.key && kvp.value) {
			onComplete?.(kvp);
		}
	};

	return (
		<Container
			sx={{
				display: 'grid',
				gridTemplateColumns: '1fr 1fr 32px',
				gap: 'xSmall',
				alignItems: 'center',
			}}
		>
			<Input
				value={value.key}
				placeholder={'Key'}
				onChange={(v) => emitChange({ ...value, key: v ?? '' })}
				disabled={disabled || false}
			/>

			<Input
				value={value.value}
				placeholder={'Value'}
				onChange={(v) => emitChange({ ...value, value: v ?? '' })}
				disabled={disabled || false}
			/>

			<ButtonIcon
				id={`${id}-delete`}
				variant={'small'}
				flex={'0 0 auto'}
				muted
				disabled={disabled || !onDelete}
				onClick={onDelete}
				tooltip={'Delete'}
				color={'neutral600'}
			>
				<IconDelete />
			</ButtonIcon>
		</Container>
	);
}
