/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Container, Pill, TableDataCell, Text, Tooltip } from 'components';
import UserIcon from 'components/UserIcon/UserIcon';
import { TeamMemberVO } from 'ui-api';
import { ReactElement } from 'react';

interface MembersProps {
	members: TeamMemberVO[];
	reduced: boolean;
}

export default function Members({ members, reduced }: MembersProps): ReactElement {
	const width = reduced ? '100%' : (Math.min(members.length, 10) || 0) * 20 + 12 + 'px';
	return (
		<TableDataCell pl={8} sx={{ width, ':first-of-type': { pl: '8px' } }} data-private>
			{reduced ? (
				<Pill color="neutral800" backgroundColor="neutral200">
					{members.length}
				</Pill>
			) : (
				<TeamMembersCell members={members} />
			)}
		</TableDataCell>
	);
}

function TeamMembersCell({ members }: { members: TeamMemberVO[] }): ReactElement {
	return (
		<>
			{members.map((member, index) => (index < 9 ? <TeamUserIcon member={member} key={member.username} /> : null))}
			{members.length > 9 ? (
				<Tooltip
					content={
						<>
							{members.map((member, index) =>
								index >= 9 ? (
									<Container key={index} data-private>
										{member.name}
									</Container>
								) : null,
							)}
						</>
					}
				>
					<Container
						display={'flex'}
						justifyContent={'center'}
						alignItems={'center'}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',

							backgroundColor: 'neutral200',
							width: '28px',
							height: '28px',
							border: '2px solid',
							borderRadius: '50%',
							borderColor: 'neutral000',
							'tr:hover &': {
								borderColor: 'neutral100',
							},
						}}
						ml={-8}
					>
						<Text as="span" variant="xSmallStrong">
							{members.length - 9 > 99 ? '99+' : `+${members.length - 9}`}
						</Text>
					</Container>
				</Tooltip>
			) : null}
		</>
	);
}

function TeamUserIcon({ member }: { member?: TeamMemberVO }): ReactElement | null {
	if (!member) {
		return null;
	}
	return <UserIcon src={member.pictureUrl} stacked tooltip={member.name} />;
}
