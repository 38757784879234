/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { Container } from 'components';
import { ReactElement } from 'react';

export function Separator(): ReactElement {
	return (
		<Container
			sx={{
				borderBottom: '1px solid',
				borderColor: 'neutral200',
			}}
			mt={'medium'}
			mb={'small'}
		/>
	);
}
