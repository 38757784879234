/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { wrapWithEnvironmentVariableMarkers, wrapWithPlaceholderMarkers } from 'pages/templates/utils';
import { Mode, useEditorSettings } from 'pages/experimentsV2/useEditorSettings';
import { Chunk, ChunkLUT } from '@steadybit/ui-components-lib';
import { TemplatePlaceholderVO, VariableVO } from 'ui-api';
import { mergeAndSort } from 'utils/envVars';
import { useField } from 'formik';

export default function useChunkLUT(): ChunkLUT {
	const { mode: editorMode } = useEditorSettings();

	const [{ value: placeholderValuesMap = new Map() }] = useField<Map<string, string>>('placeholderValuesMap');
	const [{ value: variableValuesMap = new Map() }] = useField<Map<string, string>>('variableValuesMap');
	const [{ value: experimentVariables = [] }] = useField<VariableVO[]>('experimentVariables');
	const [{ value: placeholders = [] }] = useField<TemplatePlaceholderVO[]>('placeholders');
	const [{ value: environmentVariables = [] }] = useField<VariableVO[]>('variables');
	const chunkLUT: ChunkLUT = getChunkLUT({
		environmentAndExperimentVariables: mergeAndSort(environmentVariables, experimentVariables),
		placeholderValuesMap,
		variableValuesMap,
		placeholders,
		editorMode,
	});

	return chunkLUT;
}

function getChunkLUT({
	environmentAndExperimentVariables,
	placeholderValuesMap,
	variableValuesMap,
	placeholders,
	editorMode,
}: {
	environmentAndExperimentVariables: VariableVO[];
	placeholderValuesMap: Map<string, string>;
	variableValuesMap: Map<string, string>;
	placeholders: TemplatePlaceholderVO[];
	editorMode: Mode | undefined;
}): ChunkLUT {
	const chunkLUT: ChunkLUT = {};

	// merge variables and value map so we have all used and available variables
	variableValuesMap = new Map(variableValuesMap);
	for (let i = 0; i < environmentAndExperimentVariables.length; i++) {
		const variable = environmentAndExperimentVariables[i];
		variableValuesMap.set(variable.key, variableValuesMap.get(variable.key) || variable.value || '');
	}

	chunkLUT.variables = Array.from(variableValuesMap.entries()).reduce(
		(acc, [key, value]) => {
			if (value) {
				acc[wrapWithEnvironmentVariableMarkers(key)] = { value };
			} else {
				acc[wrapWithEnvironmentVariableMarkers(key)] = { tooltip: 'Value is not yet assigned' };
			}
			return acc;
		},
		{} as Record<string, Chunk>,
	);

	if (editorMode !== 'experiment') {
		// merge placeholders and placeholder map so we have all used and available placeholders
		placeholderValuesMap = new Map(placeholderValuesMap);
		for (let i = 0; i < placeholders.length; i++) {
			const placeholder = placeholders[i];
			placeholderValuesMap.set(placeholder.key, placeholderValuesMap.get(placeholder.key) || '');
		}

		chunkLUT.placeholders = Array.from(placeholderValuesMap.entries()).reduce(
			(acc, [key, value]) => {
				if (value) {
					acc[wrapWithPlaceholderMarkers(key)] = { value };
				} else {
					const placeholder = placeholders.find((p) => p.key === key);
					if (placeholder) {
						if (editorMode === 'templateUsage') {
							acc[wrapWithPlaceholderMarkers(key)] = {
								placeholder: placeholder.name,
								tooltip: 'Value is not yet assigned',
							};
						} else {
							acc[wrapWithPlaceholderMarkers(key)] = {
								value: placeholder.name,
							};
						}
					}
				}
				return acc;
			},
			{} as Record<string, Chunk>,
		);
	}

	return chunkLUT;
}
