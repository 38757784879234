/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { createFromTemplate } from 'pages/templates/TemplateEditor/TemplateEditorLoader';
import { templateImportConflictConfirm } from 'templates/importConflictQuestion';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { ReactElement, Suspense, lazy, useState } from 'react';
import { useEnvironments } from 'utils/hooks/useEnvironments';
import { Container, Text } from 'components';
import { useDropzone } from 'react-dropzone';
import { Services } from 'services/services';
import { json, yml } from 'utils/mediaTypes';
import { theme } from 'styles.v2/theme';
import { useHistory } from 'url/hooks';
import { TemplateVO } from 'ui-api';

import { aggregateErrorInformation } from '../../../utils/error';
import Card from './Card';

const Image = lazy(() => import('./images/Image'));

interface FromUploadProps {
	setUploadError: (error: string) => void;
}

export default function FromUpload({ setUploadError }: FromUploadProps): ReactElement {
	const environments = useEnvironments();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);

	async function onDrop(files: File[]): Promise<void> {
		const file = files[0];
		if (!file || !environments?.defaultSelectedEnvironment) {
			setIsLoading(false);
			return;
		}

		try {
			setIsLoading(true);
			const content = await readFile(file);
			try {
				const template: TemplateVO = await Services.templatesApi.createTemplateFromFileContent(content, file.type);

				let templateAlreadyExists = false;
				try {
					// throws an error (404) if the template does not exist, therefore we catch it
					const existingTemplate: TemplateVO = await Services.templatesApi.getTemplate(template.id);
					templateAlreadyExists = !!existingTemplate;
					if (templateAlreadyExists) {
						template.version = existingTemplate.version;
					}
				} catch {
					templateAlreadyExists = false;
				}

				if (templateAlreadyExists && !(await templateImportConflictConfirm(1))) {
					return;
				}

				// we can always redirect to a new template, also if the template is already present. The save button will override it.
				const preparedFormData = createFromTemplate(template, '', [], 'UI_FILE_IMPORT');
				preparedFormData.initialValues.isNewTemplateFromFile = true;
				history.push('/settings/templates/design/<new>', {
					preparedFormData,
				});
			} catch (err) {
				setUploadError(aggregateErrorInformation(err));
				return;
			}
		} catch (e) {
			let message = typeof e === 'string' ? e : (e.message as string | undefined);
			if (!message) {
				message = 'Failed to import template';
				if (typeof e.title === 'string') {
					message += `: ${e.title}`;
				}
			}
			setUploadError(message);
		} finally {
			setIsLoading(false);
		}
	}

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: [yml, json], maxFiles: 1 });

	return (
		<Card title="Upload" description="x-yaml, json">
			<div {...getRootProps()}>
				<Container
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						gap: '16px',

						padding: '8px',
						backgroundColor: isDragActive ? theme.colors.purple100 : theme.colors.neutral100,
						border: `1px dashed ${isDragActive ? theme.colors.primary : theme.colors.neutral300}`,
						borderRadius: '4px',
						width: '168px',
						height: '126px',

						'&:hover': {
							border: `1px dashed ${theme.colors.primary}`,
							backgroundColor: 'purple100',
							cursor: 'pointer',
						},
					}}
				>
					<input {...getInputProps()} />
					{isLoading ? (
						<LoadingIndicator sx={{ height: '42px', mt: '15px' }} />
					) : (
						<Suspense fallback={<div />}>
							<Image type="fromUpload" />
						</Suspense>
					)}
					<Text variant="xSmall" color="neutral700">
						Drag &apos;n&apos; drop or click here
					</Text>
				</Container>
			</div>
		</Card>
	);
}

async function readFile(file: File): Promise<string> {
	return new Promise<string>((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = reject;
		reader.readAsText(file);
	});
}
