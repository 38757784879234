/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { ReactElement, useLayoutEffect, useState } from 'react';
import { TextInput } from '@steadybit/ui-components-lib';
import { useObservable } from 'react-use';
import { ensure } from 'utils/ensure';
import { ReplaySubject } from 'rxjs';

import { DialogContent } from './DialogContent';
import { DialogFooter } from './DialogFooter';
import { DialogHeader } from './DialogHeader';
import { ModalOverlay } from '../Modal';
import { Button } from '../Button';
import { Dialog } from './Dialog';

type UserInputOptions = {
	title: string;
	placeholder?: string;
	buttonOkCaption?: string;
};

type UserInputResult = {
	action: 'cancel' | 'ok';
	input?: string;
};

type OutstandingInput = UserInputOptions & {
	resolve: (result: UserInputResult) => void;
};

const outstandingInputs$ = new ReplaySubject<OutstandingInput | null>(1);

export function UserInputContainer(): ReactElement | null {
	const userInput = useObservable(outstandingInputs$);

	if (!userInput) {
		return null;
	}

	return <UserInputOverlay userInput={userInput} />;
}

function UserInputOverlay({ userInput }: { userInput: OutstandingInput }): ReactElement {
	const [value, setValue] = useState<string>('');

	const cancel = (): void => {
		userInput?.resolve({ action: 'cancel' });
		setValue('');
	};

	const confirm = (): void => {
		userInput?.resolve({ action: 'ok', input: value });
		setValue('');
	};

	useLayoutEffect(() => {
		document.getElementById('user-input')?.focus();
	}, []);

	return (
		<ModalOverlay open onClose={cancel} zIndex={40}>
			{({ close }) => {
				const { title, placeholder, buttonOkCaption } = ensure(userInput);
				return (
					<Dialog>
						<DialogHeader title={title} onClose={close} />
						<DialogContent>
							<TextInput
								id="user-input"
								placeholder={placeholder}
								value={value}
								autoFocus
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										confirm();
									} else if (e.key === 'Escape' || e.key === 'Esc') {
										cancel();
									}
								}}
								onChange={setValue}
								style={{ margin: '1px 0' }}
							/>
						</DialogContent>
						<DialogFooter>
							<Button
								ml={'auto'}
								variant={'secondary'}
								onClick={(e) => {
									e.stopPropagation();
									cancel();
								}}
								data-cy="cancel-button"
							>
								Cancel
							</Button>
							<Button
								ml={'small'}
								variant={'primary'}
								onClick={(e) => {
									e.stopPropagation();
									confirm();
								}}
								data-cy="ok-button"
								disabled={!value?.length}
							>
								{buttonOkCaption ? buttonOkCaption : 'Ok'}
							</Button>
						</DialogFooter>
					</Dialog>
				);
			}}
		</ModalOverlay>
	);
}

export function userInput(arg: UserInputOptions): Promise<UserInputResult> {
	return new Promise<UserInputResult>((resolve) => {
		outstandingInputs$.next({
			resolve: (result: UserInputResult) => {
				outstandingInputs$.next(null);
				resolve(result);
			},
			...arg,
		});
	});
}
