/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components/Container';
import { ReactElement } from 'react';

type LiProps = ContainerProps;

export default function Li({ children, sx = {}, ...props }: LiProps): ReactElement {
	return (
		<Container as="li" sx={sx} {...props}>
			{children}
		</Container>
	);
}
