/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { createRef, ReactElement, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Container } from 'components';

interface SlideInPagesProps {
	numPages: number;
	width: string | number;
	children: (props: { page: number; next?: () => void; prev?: () => void }) => ReactElement;
}

export default function SlideInPages({ width, numPages, children }: SlideInPagesProps): ReactElement {
	const [[page, direction], setPage] = useState([0, 'next']);

	const [height, setHeight] = useState(0);
	const ref = createRef<HTMLDivElement>();
	useEffect(() => {
		const rect = ref.current?.getBoundingClientRect();
		setHeight(rect?.height || 0);
	}, [page]);

	return (
		<Container
			sx={{
				overflow: 'hidden',
				position: 'relative',
				height,
				width,
			}}
		>
			<AnimatePresence initial={false} custom={[width, direction]}>
				<motion.div
					style={{
						position: 'absolute',
						left: 0,
						right: 0,
					}}
					key={page}
					initial="enter"
					animate="center"
					exit="exit"
					custom={[width, direction]}
					transition={{
						x: { type: 'spring', stiffness: 300, damping: 30 },
					}}
					variants={variants}
				>
					<Container bg="neutral000" ref={ref}>
						{children({
							page,
							next: page === numPages ? undefined : () => setPage([page + 1, 'next']),
							prev: () => setPage([Math.max(0, page - 1), 'prev']),
						})}
					</Container>
				</motion.div>
			</AnimatePresence>
		</Container>
	);
}

const variants = {
	enter: ([width, direction]: [string, string]) => {
		return {
			x: direction === 'next' ? width : `-${width}`,
		};
	},
	center: {
		x: 0,
	},
	exit: ([width, direction]: [string, string]) => {
		return {
			x: direction === 'next' ? `-${width}` : width,
		};
	},
};
