/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import textEllipsis2Lines from 'utils/styleSnippets/textEllipsis2Lines';
import { Flex } from '@steadybit/ui-components-lib';
import { Text, Tooltip } from 'components';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';
import { ActionVO } from 'ui-api';

import ActionIconWithBorder from './ActionIconWithBorder';
import CollapsedEntry from './CollapsedEntry';

interface ActionProps {
	action: ActionVO | undefined;
	isDraggingOverLane?: boolean;
	isDragging?: boolean;
	isSelected?: boolean;
	disabled?: boolean;
	collapsed: boolean;
	label: string;
}

export default function Action({
	isDraggingOverLane,
	isSelected,
	isDragging,
	collapsed,
	disabled,
	action,
	label,
}: ActionProps): ReactElement {
	if (collapsed) {
		return (
			<CollapsedEntry onClick={() => {}} tooltip={label}>
				<ActionIconWithBorder action={action} cursor={disabled ? 'pointer' : 'grab'} />
			</CollapsedEntry>
		);
	}

	const { slate, purple100, neutral000, neutral100, neutral400 } = theme.colors;

	return (
		<Flex
			direction="horizontal"
			align="center"
			spacing="xSmall"
			style={{
				gap: '8px',
				padding: '10px 8px',
				width: '100%',
				height: '52px',

				cursor: disabled ? 'pointer' : 'grab',

				backgroundColor: isDraggingOverLane || isSelected ? purple100 : neutral100,
				borderRadius: 'xSmall',
				border: isDraggingOverLane ? '2px dashed' : '2px solid',
				borderColor: isDraggingOverLane || isSelected ? slate : isDragging ? neutral400 : neutral000,

				onHover: {
					borderColor: slate,
					backgroundColor: neutral100,
				},
			}}
		>
			<ActionIconWithBorder action={action} small cursor={disabled ? 'pointer' : 'grab'} />
			<Tooltip content={label} onlyShowOnEllipsis>
				<Text
					variant="smallStrong"
					color="neutral800"
					sx={{
						...textEllipsis2Lines,
						lineHeight: '120%',
					}}
				>
					{label}
				</Text>
			</Tooltip>
		</Flex>
	);
}
