/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Dropdown, Pill, presets, TextArea } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';

interface DescriptionProps {
	description: string;
	setDescription: (description: string) => void;
	readonly: boolean;
}
export default function Description({ description, readonly, setDescription }: DescriptionProps): ReactElement {
	return (
		<Dropdown
			placement="bottom-start"
			renderDropdownContent={() => (
				<presets.dropdown.DropdownContentFrame
					maxHeight="500px"
					style={{ padding: '1px', borderRadius: 'xxSmall', border: 'none' }}
				>
					<TextArea
						placeholder="No description"
						value={description}
						maxLength={20_000}
						autoFocus
						onChange={setDescription}
						style={{
							minWidth: '400px',
							minHeight: '150px',
						}}
					/>
				</presets.dropdown.DropdownContentFrame>
			)}
		>
			{({ setRefElement, isOpen, setOpen }) => {
				return (
					<Pill
						ref={setRefElement}
						withLeftIcon="description"
						withRightIcon={isOpen ? 'arrow-drop-up' : 'arrow-drop-down'}
						onClick={readonly ? undefined : () => setOpen(!isOpen)}
					>
						{description || 'No description'}
					</Pill>
				);
			}}
		</Dropdown>
	);
}
