/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import { theme } from 'styles.v2/theme';
import React from 'react';

export interface ToggleProps extends ContainerProps {
	checked?: boolean;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	inputProps?: Partial<ContainerProps>;
}

export const Toggle: React.FC<ToggleProps> = ({ checked, disabled, onChange, inputProps, value, name, ...props }) => {
	return (
		<Container
			bg={checked ? (disabled ? 'cyanLight' : 'cyanDark') : disabled ? 'neutral150' : 'neutral400'}
			p="2px"
			sx={{
				position: 'relative',
				borderRadius: 999,
				':focus-within': disabled
					? {}
					: {
							boxShadow: `0 0 0 2px ${theme.colors.slate}`,
						},
			}}
			width={36}
			height={20}
			{...props}
		>
			<Container
				width={16}
				height={16}
				bg="neutral000"
				sx={{
					boxShadow: 'applicationSmall',
					borderRadius: 999,
					transition: '150ms transform ease-in-out',
					transform: `translateX(${checked ? 16 : 0}px)`,
				}}
			/>
			<Container
				as="input"
				type={'checkbox'}
				width="100%"
				height="100%"
				onChange={onChange}
				disabled={disabled}
				sx={{
					cursor: disabled ? 'initial' : 'pointer',
					pointerEvents: disabled ? 'none' : 'initial',
					opacity: 0,
					display: 'block',
					position: 'absolute',
					left: 0,
					right: 0,
					bottom: 0,
					top: 0,
				}}
				name={name}
				value={value}
				checked={checked}
				{...inputProps}
			/>
		</Container>
	);
};
