/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { suppressSubmitOnEnter } from 'utils/form';
import { ReactElement, useState } from 'react';
import { Form, Formik } from 'formik';
import { TemplateVO } from 'ui-api';
import { cloneDeep } from 'lodash';

import { UseTemplateFormData } from './UseTemplateFormLoadingHandler';
import UseTemplateEmptyChecker from './UseTemplateEmptyChecker';
import PlaceholderSyncJob from './PlaceholderSyncJob';
import ValidationHandler from './ValidationHandler';
import MetadataSyncJob from './MetadataSyncJob';

interface UseTemplateFormProps {
	withExperimentHypothesisExtraction?: boolean;
	withExperimentNameExtraction?: boolean;
	isCreatedByAdvice: boolean;
	newExperimentTags?: string[];
	children: ReactElement;
	environmentId: string;
	template: TemplateVO;
	onIsEmpty: (values: UseTemplateFormData) => void;
	onSubmit: (values: UseTemplateFormData) => void;
	onClose: () => void;
}

export default function UseTemplateForm({
	withExperimentHypothesisExtraction,
	withExperimentNameExtraction,
	isCreatedByAdvice,
	newExperimentTags,
	environmentId,
	template,
	children,
	onIsEmpty,
	onSubmit,
	onClose,
}: UseTemplateFormProps): ReactElement {
	const [__originalLanes] = useState(() => cloneDeep(template.lanes));
	const [__originalExperimentName] = useState(() => template.experimentName);
	const [__originalHypothesis] = useState(() => template.hypothesis);

	return (
		<Formik<UseTemplateFormData>
			initialValues={{
				...template,
				placeholderValuesMap: new Map(Array.from(template.placeholders).map(({ key }) => [key, ''])),
				variableValuesMap: new Map(),
				placeholdersMap: new Map(),
				variablesMap: new Map(),
				newExperimentTags,
				variables: [],
				environmentId,
				__originalLanes,
				__originalExperimentName,
				__originalHypothesis,
			}}
			// see <ValidationHandler /> for docs
			validateOnChange={false}
			validateOnBlur={false}
			onSubmit={onSubmit}
		>
			{({ values }) => (
				<UseTemplateEmptyChecker
					isCreatedByAdvice={isCreatedByAdvice}
					template={template}
					onIsEmpty={() => onIsEmpty(values)}
					onClose={onClose}
				>
					<Form key={template.id} onKeyDown={suppressSubmitOnEnter} noValidate>
						<MetadataSyncJob
							withExperimentHyphotesisExtraction={withExperimentHypothesisExtraction}
							withExperimentNameExtraction={withExperimentNameExtraction}
						/>
						<PlaceholderSyncJob />
						<ValidationHandler />
						{children}
					</Form>
				</UseTemplateEmptyChecker>
			)}
		</Formik>
	);
}
