/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { cssTransition, Id, toast, ToastContainer, ToastContent, ToastOptions, TypeOptions } from 'react-toastify';
import { Global, keyframes } from '@emotion/react';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

const trackProgress = keyframes`
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
`;

const bounceInRight = keyframes`
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
`;

const bounceOutRight = keyframes`
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
`;

const bounceInLeft = keyframes`
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: none;
  }
`;

const bounceOutLeft = keyframes`
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
`;

const bounceInUp = keyframes`
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
`;

const bounceOutUp = keyframes`
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
`;

const bounceInDown = keyframes`
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
`;

const bounceOutDown = keyframes`
  20% {
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
`;

const zoomIn = keyframes`
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
`;

const zoomOut = keyframes`
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
`;

const flipIn = keyframes`
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
`;

const flipOut = keyframes`
  from {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
`;

const slideInRight = keyframes`
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
`;

const slideInLeft = keyframes`
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
`;

const slideInUp = keyframes`
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
`;

const slideInDown = keyframes`
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
`;

const slideOutRight = keyframes`
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
`;

const slideOutLeft = keyframes`
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
`;

const slideOutDown = keyframes`
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
`;

const slideOutUp = keyframes`
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
`;

function ToastifyStyles(): ReactElement {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return (
		<Global
			styles={{
				'.Toastify__toast-container': {
					zIndex: 9999,
					position: 'fixed',
					padding: theme.space.xxSmall,
					maxWidth: 500,
					boxSizing: 'border-box',
					color: theme.colors.neutral000,
					'@media only screen and (max-width : 500px)': {
						width: '100vw',
						padding: 0,
						left: 0,
						margin: 0,
					},
				},
				'.Toastify__toast-container--top-left': {
					top: '1em',
					left: '1em',

					'@media only screen and (max-width : 500px)': {
						top: 0,
						transform: 'translateX(0)',
					},
				},
				'.Toastify__toast-container--top-center': {
					top: '1em',
					left: '50%',
					transform: 'translateX(-50%)',
					'@media only screen and (max-width : 500px)': {
						top: 0,
						transform: 'translateX(0)',
					},
				},
				'.Toastify__toast-container--top-right': {
					top: '1em',
					right: '1em',
					'@media only screen and (max-width : 500px)': {
						top: 0,
						transform: 'translateX(0)',
					},
				},
				'.Toastify__toast-container--bottom-left': {
					bottom: '1em',
					left: '1em',
					'@media only screen and (max-width : 500px)': {
						bottom: 0,
						transform: 'translateX(0)',
					},
				},
				'.Toastify__toast-container--bottom-center': {
					bottom: '1em',
					left: '50%',
					transform: 'translateX(-50%)',
					'@media only screen and (max-width : 500px)': {
						bottom: 0,
						transform: 'translateX(0)',
					},
				},
				'.Toastify__toast-container--bottom-right': {
					bottom: '1em',
					right: '1em',
					'@media only screen and (max-width : 500px)': {
						bottom: 0,
						transform: 'translateX(0)',
					},
				},
				'.Toastify__toast-container--rtl': {
					'@media only screen and (max-width : 500px)': {
						right: 0,
						left: 'initial',
					},
				},
				'.Toastify__toast': {
					fontFamily: theme.fonts.body,
					lineHeight: theme.lineHeights.body,
					fontSize: theme.fontSizes[2],
					fontWeight: theme.fontWeights.strong,
					position: 'relative',
					boxSizing: 'border-box',
					marginBottom: '1rem',
					padding: '12px 12px 12px 16px',
					borderRadius: 8,
					boxShadow: theme.shadows.applicationMedium,
					display: 'flex',
					justifyContent: 'space-between',
					maxHeight: 800,
					overflow: 'hidden',
					cursor: 'pointer',
					direction: 'ltr',
					'@media only screen and (max-width : 500px)': {
						marginBottom: 0,
					},
				},
				'.Toastify__toast--rtl': {
					direction: 'rtl',
				},
				'.Toastify__toast--dark': {
					background: theme.colors.neutral800,
					color: theme.colors.neutral000,
				},
				'.Toastify__toast--default': {
					background: theme.colors.neutral000,
					color: theme.colors.neutral800,
				},
				'.Toastify__toast--info': {
					background: theme.colors.slate,
				},
				'.Toastify__toast--success': {
					background: theme.colors.cyanDark,
				},
				'.Toastify__toast--warning': {
					background: theme.colors.experimentWarning,
				},
				'.Toastify__toast--error': {
					background: theme.colors.coral,
				},
				'.Toastify__toast-body': {
					margin: 'auto 0',
					marginRight: '12px',
				},
				'.Toastify--animate': {
					animationFillMode: 'both',
					animationDuration: '0.7s',
				},
				'.Toastify__close-button': {
					color: theme.colors.neutral000,
					background: 'transparent',
					outline: 'none',
					border: 'none',
					padding: 0,
					cursor: 'pointer',
					opacity: 0.7,
					transition: '0.3s ease',
					alignSelf: 'flex-start',
					':hover': {
						opacity: 1,
					},
					':focus': {
						opacity: 1,
					},
				},
				'.Toastify__close-button--default': {
					color: theme.colors.neutral800,
					opacity: 0.3,
				},
				'.Toastify__close-button > svg': {
					fill: 'currentColor',
					height: '16px',
					width: '14px',
				},
				'.Toastify__progress-bar': {
					position: 'absolute',
					bottom: 0,
					left: 0,
					width: '100%',
					height: '5px',
					zIndex: 9999,
					opacity: 0.7,
					backgroundColor: 'rgba(255, 255, 255, 0.7)',
					transformOrigin: 'left',
				},
				'.Toastify__progress-bar--animated': {
					animationName: trackProgress,
					animationTimingFunction: 'linear',
					animationIterationCount: 1,
					animationFillMode: 'forwards',
				},
				'.Toastify__progress-bar--controlled': {
					transition: 'transform 0.2s',
				},
				'.Toastify__progress-bar--rtl': {
					right: 0,
					left: 'initial',
					transformOrigin: 'right',
				},
				'.Toastify__progress-bar--default': {
					background: 'linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55)',
				},
				'.Toastify__progress-bar--dark': {
					background: '#bb86fc',
				},
				'.Toastify__bounce-enter--top-left': {
					animationName: bounceInLeft,
				},
				'.Toastify__bounce-enter--bottom-left': {
					animationName: bounceInLeft,
				},
				'.Toastify__bounce-enter--top-right': {
					animationName: bounceInRight,
				},
				'.Toastify__bounce-enter--bottom-right': {
					animationName: bounceInRight,
				},
				'.Toastify__bounce-enter--top-center': {
					animationName: bounceInDown,
				},
				'.Toastify__bounce-enter--bottom-center': {
					animationName: bounceInUp,
				},
				'.Toastify__bounce-exit--top-left': {
					animationName: bounceOutLeft,
				},
				'.Toastify__bounce-exit--bottom-left': {
					animationName: bounceOutLeft,
				},
				'.Toastify__bounce-exit--top-right': {
					animationName: bounceOutRight,
				},
				'.Toastify__bounce-exit--bottom-right': {
					animationName: bounceOutRight,
				},
				'.Toastify__bounce-exit--top-center': {
					animationName: bounceOutUp,
				},
				'.Toastify__bounce-exit--bottom-center': {
					animationName: bounceOutDown,
				},
				'.Toastify__zoom-enter': {
					animationName: zoomIn,
				},
				'.Toastify__zoom-exit': {
					animationName: zoomOut,
				},
				'.Toastify__flip-enter': {
					animationName: flipIn,
				},
				'.Toastify__flip-exit': {
					animationName: flipOut,
				},
				'.Toastify__slide-enter--top-left': {
					animationName: slideInLeft,
				},
				'.Toastify__slide-enter--bottom-left': {
					animationName: slideInLeft,
				},
				'.Toastify__slide-enter--top-right': {
					animationName: slideInRight,
				},
				'.Toastify__slide-enter--bottom-right': {
					animationName: slideInRight,
				},
				'.Toastify__slide-enter--top-center': {
					animationName: slideInDown,
				},
				'.Toastify__slide-enter--bottom-center': {
					animationName: slideInUp,
				},
				'.Toastify__slide-exit--top-left': {
					animationName: slideOutLeft,
				},
				'.Toastify__slide-exit--bottom-left': {
					animationName: slideOutLeft,
				},
				'.Toastify__slide-exit--top-right': {
					animationName: slideOutRight,
				},
				'.Toastify__slide-exit--bottom-right': {
					animationName: slideOutRight,
				},
				'.Toastify__slide-exit--top-center': {
					animationName: slideOutUp,
				},
				'.Toastify__slide-exit--bottom-center': {
					animationName: slideOutDown,
				},
				'.Toastify__fade-enter': {
					animationName: fadeIn,
				},
				'.Toastify__fade-exit': {
					animationName: fadeOut,
				},
			}}
		/>
	);
}

const SnackbarContainerId = 'snackbar';

const FadeIn = cssTransition({
	enter: 'Toastify--animate Toastify__fade-enter',
	exit: 'Toastify--animate Toastify__fade-exit',
});

export function SnackbarContainer(): ReactElement {
	return (
		<>
			<ToastifyStyles />
			<ToastContainer
				newestOnTop={true}
				enableMultiContainer
				containerId={SnackbarContainerId}
				position={'bottom-center'}
				hideProgressBar
				draggable={false}
				closeButton
				transition={FadeIn}
			/>
		</>
	);
}

function showSnackbar(type: TypeOptions, autoClose: number) {
	return (content: ToastContent, options: ToastOptions = {}): Id => {
		const opts = { autoClose, ...options, type, containerId: SnackbarContainerId };
		if (options.toastId && toast.isActive(options.toastId)) {
			toast.dismiss(options.toastId);
		}
		return toast(content, opts);
	};
}

export const Snackbar = {
	dark: showSnackbar('dark', 3000),
	darkForever: showSnackbar('dark', Number.MAX_VALUE),
	error: showSnackbar('error', 5000),
	errorForever: showSnackbar('error', Number.MAX_VALUE),
	warnForever: showSnackbar('warning', Number.MAX_VALUE),
	dismiss: toast.dismiss,
};
