/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useContext, createContext, useState, useEffect } from 'react';
import { Subject } from 'rxjs';

type SignalsType = {
	validationSignal$: Subject<number>;
};

const Signals = createContext<SignalsType>({
	validationSignal$: new Subject<number>(),
});

export const useValidationSignal$ = (): number => {
	const { validationSignal$ } = useContext(Signals);
	const [validationSignal, setValidationSignal] = useState(0);
	useEffect(() => {
		const subscription = validationSignal$.pipe().subscribe(() => {
			setValidationSignal((s) => s + 1);
		});
		return () => {
			subscription.unsubscribe();
		};
	}, [validationSignal$, setValidationSignal]);
	return validationSignal;
};

export default function useSignals$(): SignalsType {
	return useContext(Signals);
}
