/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	ExperimentPlayerLanes,
	ExperimentPlayerStepId,
	ExperimentPlayerTimeStamp,
} from 'components/ExperimentPlayer/types';
import { BaseExperimentStepExecutionVOUnion, ExperimentExecutionVO } from 'ui-api';
import { Container, ExperimentPlayer, StyleProp } from 'components';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { AsyncState } from 'utils/hooks/useAsyncState';
import { findStep } from 'services/experimentApi';
import { keyframes } from '@emotion/react';
import { useInterval } from 'react-use';

import { ExperimentExecutionLogsAndMetrics } from './components/experimentExecutionLogsAndMetrics';
import useTrackingExperimentExecutionViewed from './hooks/useTrackingExperimentExecutionViewed';
import { isCompleted, isErrored, isFailed, isRunning } from './components/utils';
import { ExperimentStepInfo } from './components/experimentStepInfo';
import { ampli } from '../../ampli';

const STEP_COLORS: Record<string, string> = {
	wait: 'experimentWait',
	action: 'experimentOther', //used for actions, which are not yet installed and the subType is unknown
	'action-ATTACK': 'experimentAttack',
	'action-LOAD_TEST': 'experimentLoadTest',
	'action-CHECK': 'experimentCheck',
	'action-OTHER': 'experimentOther',
};

const STEP_COLORS_EFFECTS: Record<string, string> = {
	wait: 'repeating-linear-gradient(120deg, transparent, transparent 4px, #aebad1 4px, #aebad1 8px)',
};

const animatedBackground = keyframes`
	from {
		background-position: 0 0;
	}
	to {
		background-position: 100% 0;
	}
`;

const hatchingLinesBg = '#d9dde4';
const hatchingLines = 'repeating-linear-gradient(120deg, transparent, transparent 4px, #d0d5de 4px, #d0d5de 8px)';
export const getStepStyleInit = (step: BaseExperimentStepExecutionVOUnion): StyleProp => {
	return {
		bg: hatchingLinesBg,
		backgroundImage: hatchingLines,
		animation: !step.started ? `${animatedBackground} 30s linear infinite` : undefined,
	};
};

export const getStepStyleText = (step: BaseExperimentStepExecutionVOUnion): StyleProp => {
	let color = 'neutral700';
	let additional = {};

	if (step.state === 'SKIPPED') {
		return {
			color: color,
			...additional,
		};
	}

	switch (step.type) {
		case 'wait':
			color = 'neutral700';
			break;
		case 'action':
			switch (step.kind) {
				case 'ATTACK':
					color = 'neutral000';
					additional = {
						bg: 'experimentAttack',
					};
					break;
				case 'CHECK':
					color = 'neutral000';
					additional = {
						bg: 'experimentCheck',
					};
					break;
				case 'LOAD_TEST':
					color = 'purple900';
					additional = {
						bg: 'experimentLoadTest',
					};
					break;
				case 'OTHER':
					color = 'neutral000';

					break;
			}
			break;
	}

	return {
		color: color,
		...additional,
	};
};

const getStepStyleEffective = (step: BaseExperimentStepExecutionVOUnion): StyleProp => {
	const subType = step.type === 'action' && step.actionId ? step.kind : '';
	if (step.state === 'SKIPPED') {
		return {
			bg: hatchingLinesBg,
			backgroundImage: hatchingLines,
		};
	}
	return {
		bg: STEP_COLORS[`${step.type}-${subType}`] ?? STEP_COLORS[step.type],
		backgroundImage: STEP_COLORS_EFFECTS[`${step.type}-${subType}`] ?? STEP_COLORS_EFFECTS[step.type],
	};
};

function useExperimentPlayerLanes(
	experimentExecution: ExperimentExecutionVO | undefined,
	progress: number,
): [lanes: ExperimentPlayerLanes, start: number, duration: number] {
	return useMemo(() => {
		if (!experimentExecution) {
			return [[], 0, 0];
		}
		const experimentStart = (
			experimentExecution.prepared ??
			experimentExecution.created ??
			experimentExecution.requested
		).getTime();

		const lanes =
			experimentExecution.lanes
				.map((lane) =>
					lane.steps.map((step) => {
						const start =
							step.started && step.started < step.estimatedStart
								? step.started.getTime()
								: step.estimatedStart.getTime();
						let end = isRunning(step.state)
							? Math.max(progress, start + step.duration)
							: !step.ignoreFailure && (isFailed(step.state) || isErrored(step.state)) && step.duration // show intended duration if the step failed and has a duration, but only if ignoreFailure is false (otherwise we would overlap with the next step)
								? Math.max(start + step.duration, step.ended?.getTime() || 0)
								: (isCompleted(step.state) || isFailed(step.state) || isErrored(step.state)) && step.ended
									? step.ended.getTime()
									: step.ended && !step.duration // Step without duration - Show the actual duration of the step execution
										? step.ended.getTime()
										: (step.started?.getTime() ?? step.estimatedStart.getTime()) + step.duration; // Step with duration -> Show the planned length of the attack

						// when preparing, expriment steps without any duration have start = end. Then, steps are overlapping each other, so we're adding a default duration of 1s.
						if (start === end && !step.ended) {
							end = start + 1_000;
						}

						const effectiveStart =
							step.started?.getTime() ?? (experimentExecution.prepared && progress > start ? progress : undefined);

						return {
							id: step.id,
							label: step.customLabel ? step.customLabel : `${step.name}`,
							state: step.state,
							start,
							end,
							effectiveStart,
							initSx: getStepStyleInit(step),
							effectiveSx: getStepStyleEffective(step),
							textSx: getStepStyleText(step),
							originalExperimentStep: step,
						};
					}),
				)
				.filter((lane) => lane.length) ?? [];

		let experimentEnd = experimentStart;

		// Because we are maybe adding a default duration, start and end are sometimes off.
		lanes.forEach((lane) => {
			lane.forEach((step, index) => {
				const prevStep = lane[index - 1];
				if (prevStep && prevStep.end > step.start) {
					const offset = prevStep.end - step.start;
					step.start += offset;
					step.end += offset;
					if (step.effectiveStart) {
						step.effectiveStart += offset;
					}
				}
				experimentEnd = Math.max(step.end, experimentEnd);
			});
		});

		if (isRunning(experimentExecution.state)) {
			experimentEnd = Math.max(experimentEnd, progress);
		}

		let experimentDuration = experimentEnd - experimentStart;
		if (experimentDuration === 0) {
			const defaultExperimentDuration = 10_000;
			experimentDuration = defaultExperimentDuration;
		}
		return [lanes, experimentStart, experimentDuration];
	}, [experimentExecution, progress]);
}

export function ExperimentExecution({ value }: AsyncState<ExperimentExecutionVO | undefined>): ReactElement | null {
	const experimentExecution = value;
	const isPlaying = Boolean(experimentExecution?.started && !experimentExecution?.ended);
	const [progress, setProgress] = useState(new Date().getTime());
	const [selectedPosition, setSelectedPosition] = useState<ExperimentPlayerTimeStamp | null>(null);

	useEffect(() => {
		if (value && selectedPosition) {
			ampli.experimentExecutionMarkerSet({
				experiment_key: value.experimentKey,
				experiment_execution_id: value.id,
				experiment_execution_running: isRunning(value.state),
			});
		}
	}, [selectedPosition, value]);

	const running = isRunning(experimentExecution?.state || '');
	useInterval(
		() => setProgress(!isPlaying && running ? experimentStart : Date.now()),
		isPlaying || running ? 100 : null,
	);

	const [playerLanes, experimentStart, experimentDuration] = useExperimentPlayerLanes(
		experimentExecution,
		isPlaying || running ? progress : 0,
	);

	useTrackingExperimentExecutionViewed(experimentExecution, 'attack_monitor');
	const [selectedStepId, setSelectedStepId] = useState<ExperimentPlayerStepId | null>(null);
	const [hoveredStepId, setHoveredStepId] = useState<string | null>(null);

	if (!experimentExecution) {
		return null;
	}

	return (
		<>
			<Container
				display={'grid'}
				sx={{
					gridTemplateColumns: '1fr',
					borderBottom: '1px solid',
					borderColor: 'neutral200',
					bg: 'neutral000',
					boxShadow: 'applicationSmall',
					zIndex: 1, //to hide the shadow of the headerbar
				}}
			>
				<ExperimentPlayer
					lanes={playerLanes}
					cursorPosition={selectedPosition}
					progress={isPlaying || running ? progress : undefined}
					start={experimentStart}
					duration={experimentDuration}
					stopped={experimentExecution.ended?.getTime()}
					onPositionSelect={setSelectedPosition}
					completedWithFailure={experimentExecution.state === 'FAILED'}
					completedWithError={experimentExecution.state === 'ERRORED'}
					setSelectedStepId={setSelectedStepId}
					selectedStepId={selectedStepId}
					renderStepContent={(id) => <ExperimentStepInfo step={findStep(experimentExecution.lanes, id)} experimentExecution={experimentExecution} />}
					hoveredStepId={hoveredStepId}
					setHoveredStepId={setHoveredStepId}
				/>
			</Container>

			<ExperimentExecutionLogsAndMetrics
				experimentExecution={experimentExecution}
				position={selectedPosition}
				start={experimentStart}
				duration={experimentDuration}
				progress={isPlaying || running ? progress : undefined}
				onPositionSelect={setSelectedPosition}
				setSelectedStepId={setSelectedStepId}
				selectedStepId={selectedStepId}
				hoveredStepId={hoveredStepId}
				setHoveredStepId={setHoveredStepId}
			/>
		</>
	);
}
