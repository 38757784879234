/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react';

import PaginationComponent from './PaginationComponent';
import { PaginationProps } from './Pagination';

type RouterPaginationOwnProps = {
	to: (pageNo: number) => string;
};

export type RouterPaginationProps = RouterPaginationOwnProps &
	Omit<PaginationProps, 'css' | 'onClick' | keyof RouterPaginationOwnProps>;

export function RouterPagination(props: RouterPaginationProps): ReactElement {
	return <PaginationComponent {...props} />;
}
