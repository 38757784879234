/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ActionVO, BaseExperimentStepVOUnion, ExperimentLaneVO, OccuranceTemplateFieldVO, OccuranceVO } from 'ui-api';
import { DataStreamResult } from 'utils/hooks/stream/result';
import { findStep } from 'pages/experimentsV2/utils';
import { useFormikContext } from 'formik';
import { Stack, Text } from 'components';
import { ReactElement } from 'react';
import { get } from 'lodash';

import {
	isOccuranceActionPredicateVO,
	isOccuranceStepLabelVO,
	isOccuranceStepParameterVO,
} from '../UseTemplateModal/types';
import { VariableTextfieldUsage } from '../UseTemplateModal/pages/TemplatePlaceholder';
import Steps from './Steps/Steps';

interface OccurancessCommon {
	actionsResult: DataStreamResult<ActionVO[]>;
	environmentVariable?: string;
	occurances: OccuranceVO[];
	lanes: ExperimentLaneVO[];
	entityName: string;
}

interface OccurancessWithEnvironmentVariable extends OccurancessCommon {
	environmentVariable: string;
}

export default function Occurances({
	environmentVariable,
	actionsResult,
	occurances,
	entityName,
	lanes,
}: OccurancessCommon | OccurancessWithEnvironmentVariable): ReactElement {
	const { errors } = useFormikContext();

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const stepIds = new Set(occurances.filter((occurance) => occurance.stepId).map((occurance) => occurance.stepId));
	const stepIdToPath = new Map<string, string>();
	const steps: [BaseExperimentStepVOUnion, string][] = [];
	for (const stepId of stepIds) {
		// we take the original lanes here, because it's only for rendering. We want to have the placeholder and variable markers in there
		// to have a proper highlighting in the frontend. The input fields are using the lanes.
		const [step, path] = findStep(lanes, stepId, '__originalLanes');
		if (step) {
			stepIdToPath.set(stepId, path);
			steps.push([step, path]);
		}
	}

	const errorneousSteps: Set<string> = new Set();
	occurances.forEach((occurance) => {
		const stepId = getStepId(occurance);
		if (!stepId) {
			return;
		}

		const stepPath = stepIdToPath.get(stepId) || '';
		const pathToCheck = stepPath + getPathToCheck(occurance);
		if (!pathToCheck) {
			return;
		}

		const error = get(errors, pathToCheck);
		if (error) {
			errorneousSteps.add(stepId);
		}
	});

	return (
		<Stack size="large">
			{occurances
				.filter(isOccuranceTemplateFieldVO)
				.map((occurance, i) => {
					if (occurance.templateField === 'EXPERIMENT_NAME') {
						return (
							<VariableTextfieldUsage
								key={i}
								originalFieldname="__originalExperimentName"
								placeholderName={`This ${entityName}`}
								usageName="Experiment name"
								fieldName="experimentName"
								errorFieldName="name"
								maxChars={255}
							/>
						);
					}
					if (occurance.templateField === 'HYPOTHESIS') {
						return (
							<VariableTextfieldUsage
								key={i}
								originalFieldname="__originalHypothesis"
								placeholderName={`This ${entityName}`}
								errorFieldName="hypothesis"
								fieldName="hypothesis"
								usageName="hypothesis"
								maxChars={20_000}
							/>
						);
					}
					return null;
				})
				.filter((element) => element !== null)}
			{steps.length > 0 ? (
				<Stack size="xSmall">
					<Text as="span" variant="medium" color="neutral800">
						This {entityName} is used in the following{' '}
						<Text as="span" variant="mediumStrong" color="neutral800">
							actions:
						</Text>
					</Text>
					<Steps
						environmentVariable={environmentVariable ? environmentVariable : undefined}
						errorneousSteps={errorneousSteps}
						actionsResult={actionsResult}
						occurances={occurances}
						steps={steps}
					/>
				</Stack>
			) : null}
		</Stack>
	);
}

function isOccuranceTemplateFieldVO(occurance: OccuranceVO): occurance is OccuranceTemplateFieldVO {
	return 'templateField' in occurance;
}

export function getStepId(occurance: OccuranceVO): string | undefined {
	if (isOccuranceActionPredicateVO(occurance)) {
		return occurance.stepId;
	} else if (isOccuranceStepLabelVO(occurance)) {
		return occurance.stepId;
	} else if (isOccuranceStepParameterVO(occurance)) {
		return occurance.stepId;
	}
}

function getPathToCheck(occurance: OccuranceVO): string | undefined {
	if (isOccuranceActionPredicateVO(occurance)) {
		return '.blastRadius.predicate';
	} else if (isOccuranceStepLabelVO(occurance)) {
		return '.customLabel';
	} else if (isOccuranceStepParameterVO(occurance)) {
		return '.parameters.' + occurance.field.name;
	}
}
