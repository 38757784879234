/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, presets, SingleChoiceListItem } from '@steadybit/ui-components-lib';
import { Container, ContainerProps, Link, Stack, Text } from 'components';
import { IconReport, IconTrendDown, IconTrendUp } from 'components/icons';
import { Area, AreaChart, Tooltip as TooltipRecharts } from 'recharts';
import { DashboardWidget } from 'components/DashboardWidget';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { forIn, groupBy, orderBy, sum } from 'lodash';
import { withBaseHref } from 'utils/getBaseHref';
import { ReactElement, useState } from 'react';
import { useTenant } from 'tenancy/useTenant';
import { Services } from 'services/services';
import { useTeam } from 'services/useTeam';
import { daysAgo } from 'utils/dateFns';

import { ampli } from '../../../ampli';

const mapTimeRangeOptionsToAmplitude = (id: string): '7_days' | '30_days' | 'all_time' => {
	if (id === '7') {
		return '7_days';
	} else if (id === '30') {
		return '30_days';
	} else {
		return 'all_time';
	}
};

export function TeamActivitiesWidget(props: ContainerProps): ReactElement {
	const [timeRange, setTimeRange] = useState('7');
	const timeRangeOptions: SingleChoiceListItem[] = [
		{
			id: '7',
			label: 'last 7 Days',
		},
		{
			id: '30',
			label: 'last 30 Days',
		},
		{
			id: 'all',
			label: 'all time',
		},
	];

	const selectedTimeRange = timeRangeOptions.find((option) => option.id === timeRange);

	return (
		<DashboardWidget
			{...props}
			title={'Activities of your Team'}
			icon={IconReport}
			actionsLeft={
				<presets.dropdown.SingleChoiceButton
					placement="bottom-start"
					selectedId={timeRange}
					items={timeRangeOptions}
					size="small"
					onSelect={(id) => {
						setTimeRange(id);
						ampli.dashboardTeamActivityFiltered({ team_activity_time_range: mapTimeRangeOptionsToAmplitude(id) });
					}}
					maxContentHeight="250px"
				>
					{selectedTimeRange?.label || ''}
				</presets.dropdown.SingleChoiceButton>
			}
			actionsRight={<ReportLink timeRange={timeRange} />}
		>
			<LatestStats lastNoOfDays={timeRange === 'all' ? null : parseInt(timeRange)} />
		</DashboardWidget>
	);
}

function ReportLink({ timeRange }: { timeRange: string }): ReactElement {
	const lastNoOfDays = timeRange === 'all' ? null : parseInt(timeRange);
	const tenant = useTenant();
	const team = useTeam();
	const params = new URLSearchParams({ tenantKey: tenant.key, teamIds: team.id });
	if (lastNoOfDays) {
		params.set('createdFrom', daysAgo(lastNoOfDays).toISOString());
	}
	return (
		<Link
			href={withBaseHref(`/ui/reports/experiments/runs?${params.toString()}`)}
			onClick={() => {
				ampli.dashboardTeamActivityExported({ team_activity_time_range: mapTimeRangeOptionsToAmplitude(timeRange) });
			}}
			variant={'primary'}
			display={'inline'}
			style={{
				textDecoration: 'none',
			}}
		>
			<Button type="secondary" size="small" withLeftIcon="save-file" onClick={() => {}}>
				Export csv
			</Button>
		</Link>
	);
}

interface Stats {
	date: string;
	count: number;
}

const groupStats = (stats: Stats[]): Stats[] => {
	const groupedByDate = groupBy(stats, 'date');
	const result: Stats[] = [];
	forIn(groupedByDate, (value, key) => {
		result.push({
			date: key,
			count: sum(value.map((v) => v.count)),
		});
	});
	if (result.length === 1) {
		result.push(result[0]);
	}
	return result;
};

function LatestStats({ lastNoOfDays }: { lastNoOfDays: number | null }): ReactElement {
	const team = useTeam();
	const [stats] = useAsyncState(async () => {
		const [currentExperiments, beforeExperiments] = await Promise.all([
			Services.experiments.fetchRunStats(team.id, lastNoOfDays !== null ? daysAgo(lastNoOfDays) : undefined),
			lastNoOfDays !== null
				? Services.experiments.fetchRunStats(team.id, daysAgo(2 * lastNoOfDays), daysAgo(lastNoOfDays))
				: undefined,
		]);
		const current = {
			executed: currentExperiments?.executed || 0,
			completed: currentExperiments?.completed || 0,
			failed: currentExperiments?.failed || 0,
			errored: currentExperiments?.errored || 0,
			executedStats: orderBy(groupStats(currentExperiments?.executedStats), ['date'], ['asc']),
			completedStats: orderBy(groupStats(currentExperiments?.completedStats), ['date'], ['asc']),
			failedStats: orderBy(groupStats(currentExperiments?.failedStats), ['date'], ['asc']),
			erroredStats: orderBy(groupStats(currentExperiments?.erroredStats), ['date'], ['asc']),
		};
		const before = {
			executed: beforeExperiments?.executed || 0,
			completed: beforeExperiments?.completed || 0,
			failed: beforeExperiments?.failed || 0,
			errored: beforeExperiments?.errored || 0,
		};
		return { current, before };
	}, [team.id, lastNoOfDays]);

	const hasNoOfDays: boolean =
		lastNoOfDays && lastNoOfDays > 0 && stats.value?.before?.executed && stats.value?.before?.executed > 0
			? true
			: false;

	const trendDisplayVersion = stats.value?.current
		? getTrendDisplayVersion(
				stats.value?.current?.executed,
				stats.value?.current?.completed,
				stats.value?.current?.failed,
				stats.value?.current?.errored,
			)
		: 'large';
	return (
		<>
			<Container
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					gap: '12px',
					justifyContent: 'space-between',
				}}
			>
				<TrendItem
					name={'Executed'}
					current={stats.value?.current?.executed}
					before={stats.value?.before?.executed}
					stats={stats.value?.current?.executedStats}
					displayVersion={trendDisplayVersion}
				/>
				<TrendItem
					name={'Completed'}
					current={stats.value?.current?.completed}
					before={stats.value?.before?.completed}
					stats={stats.value?.current?.completedStats}
					displayVersion={trendDisplayVersion}
				/>
				<TrendItem
					name={'Failed'}
					current={stats.value?.current?.failed}
					before={stats.value?.before?.failed}
					invertColor={true}
					stats={stats.value?.current?.failedStats}
					displayVersion={trendDisplayVersion}
				/>
				<TrendItem
					name={'Errored'}
					current={stats.value?.current?.errored}
					before={stats.value?.before?.errored}
					invertColor={true}
					stats={stats.value?.current?.erroredStats}
					displayVersion={trendDisplayVersion}
				/>
			</Container>
			{hasNoOfDays && (
				<Text fontSize={12} color={'neutral500'} mt={'xSmall'}>
					Percentage values reflect development compared to previous {lastNoOfDays} days.
				</Text>
			)}
		</>
	);
}

const getTrendDisplayVersion = (...values: number[]): 'small' | 'medium' | 'large' => {
	if (Math.max(...values) >= 1000) {
		return 'small';
	} else if (Math.max(...values) >= 100) {
		return 'medium';
	}
	return 'large';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderChartsTooltip = (props: { active?: boolean; payload?: Array<any> }): ReactElement | null => {
	if (props.active && props.payload && props.payload.length) {
		return (
			<Container p={'xxSmall'} width={98}>
				<Text color={'neutral500'} variant={'xSmall'} fontSize={9} textAlign={'right'}>
					{props.payload[0].payload.count} on {props.payload[0].payload.date}
				</Text>
			</Container>
		);
	}

	return null;
};

interface TrendItemProps {
	displayVersion: 'small' | 'medium' | 'large';
	invertColor?: boolean;
	current?: number;
	before?: number;
	stats?: Stats[];
	name: string;
}
function TrendItem({
	invertColor = false,
	displayVersion,
	current,
	before,
	stats,
	name,
}: TrendItemProps): ReactElement {
	return (
		<Stack direction={'vertical'} size={'xxSmall'} justifyContent={'center'} alignItems={'flex-start'}>
			<Text variant={'smallMedium'} sx={{ 'a:hover &': { textDecoration: 'underline', color: 'neutral700' } }}>
				{name}
			</Text>
			<Stack size={0} mb={'xSmall'} m={0} direction={'horizontal'}>
				<Text fontSize={32} fontWeight={'strong'} lineHeight={'36px'} color={'neutral800'}>
					{current}
				</Text>

				{stats && stats.length > 0 && (
					<AreaChart
						width={displayVersion === 'small' ? 50 : displayVersion === 'medium' ? 80 : 100}
						height={35}
						data={stats ?? stats}
					>
						<defs>
							<linearGradient id="color-count" x1="0" y1="0" x2="0" y2="1">
								<stop offset="-14%" stopColor="#DAD5FF" stopOpacity={0.3} />
								<stop offset="109%" stopColor="#ECE9FF" stopOpacity={0} />
							</linearGradient>
						</defs>
						<TooltipRecharts position={{ x: 0, y: 35 }} content={renderChartsTooltip} wrapperStyle={{ outline: '0' }} />
						<Area type="monotone" dataKey="count" stroke="#7B6ADE" strokeWidth={1} fillOpacity={1} fill="#ECE9FF" />
					</AreaChart>
				)}
			</Stack>
			<TrendIcon current={current} before={before} invertColor={invertColor} />
		</Stack>
	);
}

interface TrendIconProps {
	invertColor: boolean;
	current?: number;
	before?: number;
}
function TrendIcon({ current, before, invertColor }: TrendIconProps): ReactElement {
	if (typeof current === 'undefined' || typeof before === 'undefined' || before === 0) {
		return (
			<Text variant={'xxSmall'} color={'neutral500'} fontSize={13} lineHeight={'150%'} fontWeight={600}>
				&nbsp;
			</Text>
		);
	}
	const resultNum = Math.abs(Number(100 - (current / before) * 100));
	const result = resultNum.toFixed(1);
	if (resultNum !== 0) {
		if (invertColor ? current < before : current > before) {
			return (
				<Text variant={'xxSmall'} color={'cyanDark'} fontSize={13} lineHeight={'150%'} fontWeight={600}>
					<IconTrendUp height={16} width={16} ml={'xSmall'} variant={'large'} />
					{result}%
				</Text>
			);
		}

		if (invertColor ? current > before : current < before) {
			return (
				<Text variant={'xxSmall'} color={'coral'} fontSize={13} lineHeight={'150%'} fontWeight={600}>
					<IconTrendDown height={16} width={16} ml={'xSmall'} variant={'large'} />
					{result}%
				</Text>
			);
		}
	}
	return (
		<Text variant={'xxSmall'} color={'neutral500'} fontSize={13} lineHeight={'150%'} fontWeight={600}>
			{result}%
		</Text>
	);
}
