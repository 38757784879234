/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import ExperimentActionHint from 'pages/experiments/components/experimentActionHint';
import { ErrorMessage, Flex } from '@steadybit/ui-components-lib';
import { Container, Stack, Text, Tooltip } from 'components';
import { ExperimentError } from 'pages/experimentsV2/types';
import { IconHelp, IconWarning } from 'components/icons';
import { ReactElement, ReactNode } from 'react';
import { FieldVO } from 'ui-api';

interface FieldWrapperProps {
	errors?: ExperimentError[];
	children: ReactNode;
	field: FieldVO;
}

export default function FieldWrapper({ errors = [], children, field }: FieldWrapperProps): ReactElement | null {
	const { deprecationMessage, description, deprecated, label, hint } = field;
	const direction = field.type === 'boolean' ? 'horizontal' : 'vertical';
	const optional = !field.required;

	const labelAndIcons = (
		<Container display="flex" alignItems="center" justifyContent="space-between" pr="xxSmall">
			<Container
				sx={{
					display: 'flex',
					alignItems: 'flex-start',
					justifyContent: 'space-between',
					gap: '8px',
				}}
			>
				<Text variant="smallMedium" sx={{ minWidth: 'min-content' }}>
					{label}
				</Text>

				{optional && (
					<Text variant="smallMedium" sx={{ fontStyle: 'italic', color: 'neutral500' }}>
						(Optional)
					</Text>
				)}

				{description && (
					<Tooltip
						color="light"
						content={
							<Text variant="small" maxWidth="25em">
								{description}
							</Text>
						}
					>
						<Flex align="center">
							<IconHelp variant="small" color="neutral400" mt={2} />
						</Flex>
					</Tooltip>
				)}

				{deprecated && (
					<Tooltip
						color="light"
						content={
							<Text variant="small" maxWidth="25em">
								deprecated - {deprecationMessage}
							</Text>
						}
					>
						<div>
							<IconWarning variant="small" color="experimentWarning" mt={2} />
						</div>
					</Tooltip>
				)}
			</Container>
			{direction === 'horizontal' && children}
		</Container>
	);

	return (
		<Stack size="xxSmall">
			{labelAndIcons}

			{direction === 'vertical' && children}

			{hint && (
				<Container pt="xxSmall">
					<ExperimentActionHint hint={hint} />
				</Container>
			)}

			{errors.map(({ level, message }, index) => (
				<ErrorMessage key={index} level={level} type="small" withIcon>
					{message}
				</ErrorMessage>
			))}
		</Stack>
	);
}
