/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react';
import { useField } from 'formik';

import { FormCheckbox, FormCheckContainerProps } from '../Checkbox';

type FormikCheckboxOwnProps = {
	name: string;
	value?: string;
};

export type FormikCheckContainerProps = FormikCheckboxOwnProps &
	Omit<FormCheckContainerProps, 'error' | 'touched' | 'ref' | keyof FormikCheckboxOwnProps>;

export function FormikCheckbox(props: FormikCheckContainerProps): ReactElement {
	const [field, meta] = useField({ type: 'checkbox', name: props.name, value: props.value });
	return <FormCheckbox {...field} {...meta} {...props} />;
}
