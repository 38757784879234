/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { Kind, utils } from '@steadybit/ui-components-lib';
import { ActionVO, TargetTypeDescriptionVO } from 'ui-api';
import { includes } from 'utils/string';

interface FilterActionsByFreeTextParams {
	actions: ActionVO[];
	targetDefinitions: TargetTypeDescriptionVO[];
	freeTextPhrases: string[];
}

export function filterActionsByFreeText({
	targetDefinitions,
	freeTextPhrases,
	actions,
}: FilterActionsByFreeTextParams): ActionVO[] {
	return actions.filter((action) => {
		const matchingTargetDefinition = targetDefinitions.find((td) => td.id === action.target.type);

		for (let iFt = 0; iFt < freeTextPhrases.length; iFt++) {
			const freeTextPhrase = freeTextPhrases[iFt];

			if (matchesFreeText(action, freeTextPhrase)) {
				return true;
			}

			if (matchingTargetDefinition && includes(matchingTargetDefinition.label.one, freeTextPhrase)) {
				return true;
			}

			if (action.hubTags?.some((tag) => includes(tag, freeTextPhrase))) {
				return true;
			}

			if (includes(utils.experiment.getLabelForKind(action.kind as Kind), freeTextPhrase)) {
				return true;
			}
		}
	});
}

export function matchesFreeText(action: ActionVO, freeTextPhrase: string): boolean {
	return includes(action.name, freeTextPhrase) || includes(action.technology || '', freeTextPhrase);
}
