/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import UseTemplateModal from 'pages/templates/UseTemplateModal/UseTemplateModal';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { lazy, ReactElement, Suspense, useState } from 'react';
import { useEnvironments } from 'utils/hooks/useEnvironments';
import { aggregateErrorInformation } from 'utils/error';
import { ExperimentVO, TemplateVO } from 'ui-api';
import { Container, Text } from 'components';
import { useDropzone } from 'react-dropzone';
import { Services } from 'services/services';
import { json, yml } from 'utils/mediaTypes';
import { useTeam } from 'services/useTeam';
import { theme } from 'styles.v2/theme';
import { useHistory } from 'url/hooks';

import { PreparedFormData } from '../ExperimentEditorLoader';
import Card from './Card';

const Image = lazy(() => import('./images/Image'));

interface FromUploadProps {
	newExperimentTags?: string[];
	setUploadError: (error: string) => void;
}
export default function FromUpload({ newExperimentTags, setUploadError }: FromUploadProps): ReactElement {
	const environments = useEnvironments();
	const history = useHistory();
	const team = useTeam();
	const [isLoading, setIsLoading] = useState(false);
	const [uploadedTemplate, setUploadedTemplate] = useState<TemplateVO | null>(null);

	async function onDrop(files: File[]): Promise<void> {
		const file = files[0];
		if (!file || !environments?.defaultSelectedEnvironment) {
			setIsLoading(false);
			return;
		}

		try {
			setIsLoading(true);
			const content = await readFile(file);

			const isTemplate = isTemplateFile(content);
			if (isTemplate) {
				const template: TemplateVO = await Services.templatesApi.createTemplateFromFileContent(content, file.type);
				setUploadedTemplate(template);
				setIsLoading(false);
				return;
			}

			const experiment: ExperimentVO = await Services.experiments.createExperimentFromFileContent(
				content,
				team.id,
				newExperimentTags,
				file.type,
			);

			const preparedFormData: PreparedFormData = {
				actions: ['save', 'run-by-team', 'run-by-license'],
				initialValues: {
					...experiment,
					creationMethod: isTemplate ? 'UI_TEMPLATE_FILE' : 'UI_FROM_SCRATCH',
					externalId: '',
					templateTitle: '',
					actions: ['save', 'run-by-team', 'run-by-license'],
					variables: [],
					experimentKey: experiment.key,
				},
				initialErrors: {},
			};
			history.push('/experiments/edit/<new>/design', { preparedFormData });
			return;
		} catch (e) {
			setUploadError(aggregateErrorInformation(e));
			setIsLoading(false);
		}
	}

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: [yml, json], maxFiles: 1 });

	return (
		<>
			{uploadedTemplate && (
				<UseTemplateModal
					experimentCreationMethod="UI_TEMPLATE_FILE"
					templatePreviewId={uploadedTemplate.id}
					newExperimentTags={newExperimentTags}
					template={uploadedTemplate}
					onClose={() => setUploadedTemplate(null)}
				/>
			)}

			<Card title="Upload" description="Upload your experiment or template file right away">
				<div {...getRootProps()}>
					<Container
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							gap: '16px',

							padding: '8px',
							backgroundColor: isDragActive ? theme.colors.purple100 : theme.colors.neutral100,
							border: `1px dashed ${isDragActive ? theme.colors.primary : theme.colors.neutral300}`,
							borderRadius: '4px',
							width: '168px',
							height: '126px',

							'&:hover': {
								border: `1px dashed ${theme.colors.primary}`,
								backgroundColor: 'purple100',
								cursor: 'pointer',
							},
						}}
					>
						<input {...getInputProps()} />
						{isLoading ? (
							<LoadingIndicator sx={{ height: '42px', mt: '15px' }} />
						) : (
							<Suspense fallback={<div />}>
								<Image type="uploadExperiment" />
							</Suspense>
						)}
						<Text variant="xSmall" color="neutral700">
							Drag &apos;n&apos; drop or click here
						</Text>
					</Container>
				</div>
			</Card>
		</>
	);
}

async function readFile(file: File): Promise<string> {
	return new Promise<string>((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = reject;
		reader.readAsText(file);
	});
}

function isTemplateFile(content: string): boolean {
	return content.includes('templateTitle') && content.includes('templateDescription');
}
