/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Droppable, DroppableProvided } from 'react-beautiful-dnd';
import { ReactElement, ReactNode, useEffect } from 'react';
import { useDimensions } from 'utils/hooks/useDimensions';
import { ExperimentLaneVO } from 'ui-api';
import { useField } from 'formik';

import { WorkspaceLanesMaker } from '../DragAndDropHandler';
import { useStableErrorMap } from '../useFieldErrors';
import LaneFalloffCatcher from './LaneFalloffCatcher';
import EmptyLanes from './EmptyLanes';
import Lane from './Lane';

interface LanesProps {
	setWorkspaceWidth: (width: number) => void;
}

export default function Lanes({ setWorkspaceWidth }: LanesProps): ReactElement {
	const stepIdToError = useStableErrorMap();
	const [lanesField] = useField<ExperimentLaneVO[]>({ name: 'lanes' });
	const { value: lanes } = lanesField;

	if (lanes.length === 0) {
		return (
			<Wrapper setWorkspaceWidth={setWorkspaceWidth}>
				<EmptyLanes />
			</Wrapper>
		);
	}

	return (
		<Wrapper setWorkspaceWidth={setWorkspaceWidth}>
			<LaneFalloffCatcher above />
			<Droppable
				droppableId={WorkspaceLanesMaker}
				ignoreContainerClipping
				isCombineEnabled={false}
				isDropDisabled={false}
				direction="vertical"
				type="lanes"
			>
				{(dropProvided: DroppableProvided) => {
					return (
						<div id={WorkspaceLanesMaker} ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
							{lanes.map((lane, i) => {
								return (
									<Lane
										key={i}
										index={i}
										lane={lane}
										stepIdToError={stepIdToError}
										isDragDisabled={lanes.length <= 1}
									/>
								);
							})}
						</div>
					);
				}}
			</Droppable>
			<LaneFalloffCatcher />
		</Wrapper>
	);
}

interface WrapperProps {
	setWorkspaceWidth: (width: number) => void;
	children: ReactNode;
}

function Wrapper({ children, setWorkspaceWidth }: WrapperProps): ReactElement {
	const [divRef, [wrapperWidth]] = useDimensions<HTMLDivElement>();

	useEffect(() => {
		setWorkspaceWidth(wrapperWidth);
	}, [wrapperWidth]);

	return (
		<div
			ref={divRef}
			style={{
				display: 'flex',
				flexDirection: 'column',
				width: 'fit-content',
				height: '100%',
			}}
		>
			{children}
		</div>
	);
}
