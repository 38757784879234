/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { CurrentUserVO, ExperimentVO, TemplateCreationMethodVO, TemplateVO } from 'ui-api';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { LoadingIndicator, Stack } from 'components';
import { useHistory, useLocation } from 'url/hooks';
import { ReactElement, ReactNode } from 'react';
import { Services } from 'services/services';
import { useUser } from 'services/usersApi';

import TemplateEditorForm from './TemplateEditorForm';
import { TemplateFormValues } from './types';

export interface PreparedFormData {
	initialValues: TemplateFormValues;
}

interface TemplateEditorLoaderProps {
	experimentKey?: string;
	templateId?: string;
	children: ReactNode;
}

export default function TemplateEditorLoader({
	experimentKey,
	templateId,
	children,
}: TemplateEditorLoaderProps): ReactElement {
	const user = useUser();
	const history = useHistory();
	const location = useLocation<{ preparedFormData?: PreparedFormData }>();

	const [preparedFormData] = useAsyncState(async () => {
		const { environmentId, allTags } = await Services.templatesApi.getEditorInformation();

		// File Upload
		if (location?.state?.preparedFormData) {
			const pfd = location.state.preparedFormData;
			pfd.initialValues.environmentId = environmentId;
			return pfd;
		}

		if (experimentKey) {
			const experiment = await Services.experiments.fetchExperiment(experimentKey);
			return createFormFromExperiment(experiment, environmentId, allTags, 'UI_FROM_EXPERIMENT', user);
		}

		if (templateId) {
			try {
				const template = await Services.templatesApi.getTemplate(templateId);
				return createFromTemplate(template, environmentId, allTags, 'UI_FROM_SCRATCH');
			} catch {
				history.replace('/experiments/', { showError: `Template ${templateId} not found` });
				return;
			}
		}
		return createEmptyForm(environmentId, allTags, 'UI_FROM_SCRATCH', user);
	}, [experimentKey]);

	if (!preparedFormData || !preparedFormData.value || preparedFormData.loading) {
		return (
			<Stack
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					height: '100%',
				}}
			>
				<LoadingIndicator variant="xxLarge" color="slate" />
			</Stack>
		);
	}

	return <TemplateEditorForm formData={preparedFormData.value}>{children}</TemplateEditorForm>;
}

function createEmptyForm(
	environmentId: string,
	allTags: string[],
	creationMethod: TemplateCreationMethodVO,
	user: CurrentUserVO,
): PreparedFormData {
	return {
		initialValues: {
			creationMethod,
			templateTitle: '',
			templateDescription: '',
			experimentName: '',
			hypothesis: '',
			lanes: [],
			created: new Date(),
			createdBy: user,
			editedBy: user,
			edited: new Date(),
			tags: [],
			placeholders: [],
			variables: [],
			environmentId,
			hidden: false,
			allTags,
			_actions: ['save'],
			actions: ['save'],
			version: 0,
		},
	};
}

function createFormFromExperiment(
	experiment: ExperimentVO,
	environmentId: string,
	allTags: string[],
	creationMethod: TemplateCreationMethodVO,
	user: CurrentUserVO,
): PreparedFormData {
	return {
		initialValues: {
			...createEmptyForm(environmentId, allTags, creationMethod, user).initialValues,
			variables: [],
			hypothesis: experiment.hypothesis,
			experimentName: experiment.name,
			lanes: experiment.lanes,
		},
	};
}

export function createFromTemplate(
	template: TemplateVO,
	environmentId: string,
	allTags: string[],
	creationMethod: TemplateCreationMethodVO,
): PreparedFormData {
	const placeholders = template.placeholders.map((placeholder) => ({
		...placeholder,
		protected: Boolean(placeholder.description),
	}));

	return {
		initialValues: {
			...template,
			creationMethod,
			placeholders,
			variables: [],
			environmentId,
			allTags,
			actions: ['save'],
		},
	};
}
