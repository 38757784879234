/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { UnstyledLink, UnstyledLinkProps } from 'components';
import { ReactElement } from 'react';

import { Button, ButtonProps } from './Button';

export type LinkButtonProps = ButtonProps & UnstyledLinkProps;

export function LinkButton({ children, ...props }: LinkButtonProps): ReactElement {
	return (
		<Button as={UnstyledLink} {...props}>
			{children}
		</Button>
	);
}
