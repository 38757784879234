/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import * as StyledSystem from 'styled-system';
import React, { ReactElement } from 'react';

export type TableDataCellProps = ContainerProps &
	Omit<React.TdHTMLAttributes<HTMLTableDataCellElement>, keyof ContainerProps> &
	StyledSystem.JustifyContentProps;

export function TableDataCell({ children, justifyContent, sx = {}, ...props }: TableDataCellProps): ReactElement {
	return (
		<Container as="td" {...props}>
			{children ? (
				<Container
					display={'flex'}
					sx={{
						alignItems: 'center',
						justifyContent,

						'& > svg': {
							flex: '0 0 auto',
						},
						...sx,
					}}
				>
					{children}
				</Container>
			) : null}
		</Container>
	);
}
