/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, ModalOverlay } from 'components';
import { ReactElement } from 'react';

import { EnvironmentConfig } from './types';

interface DidNotSavePromptViewProps {
	onSave: () => Promise<EnvironmentConfig | undefined>;
	resolve: (v: boolean) => void;
}

export default function DidNotSavePromptView({ onSave, resolve }: DidNotSavePromptViewProps): ReactElement {
	return (
		<ModalOverlay open onClose={() => resolve(false)}>
			<Dialog>
				<DialogHeader variant="medium" title="Are you sure?" onClose={() => resolve(false)} />
				<DialogContent>Do you want to keep changes in this view?</DialogContent>
				<DialogFooter>
					<Button variant="secondary" onClick={() => resolve(true)}>
						Discard changes
					</Button>
					<Button variant="secondary" onClick={() => resolve(false)} ml="auto">
						Cancel
					</Button>
					<Button
						variant="primary"
						ml="xSmall"
						onClick={async () => {
							const savedConfig = await onSave();
							if (savedConfig) {
								resolve(true);
							} else {
								resolve(false);
							}
						}}
					>
						Save
					</Button>
				</DialogFooter>
			</Dialog>
		</ModalOverlay>
	);
}
