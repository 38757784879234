/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	Container,
	ModalContentV2,
	ModalFooterV2,
	ModalHeaderV2,
	ModalOverlay,
	ModalV2,
	Stack,
	Text,
} from 'components';
import { Button, Dropdown, presets, Spacings } from '@steadybit/ui-components-lib';
import { ReactElement, useState } from 'react';
import { useFormikContext } from 'formik';
import { theme } from 'styles.v2/theme';

import { ColorMapping, LandscapeConfig } from '../types';
import { toColorString } from './ColorList';
import { colors } from '../utils';

interface ConfigureColorsOverrideModalProps {
	onSave: (overrides: { [index: string]: string }) => void;
	colorMapping: ColorMapping;
	onClose: () => void;
}

export default function ConfigureColorsOverrideModal({
	onClose,
	onSave,
	colorMapping,
}: ConfigureColorsOverrideModalProps): ReactElement {
	const formik = useFormikContext<LandscapeConfig>();
	const { colorOverrides: currentOverrides } = formik.values;
	const [colorOverrides, setColorOverrides] = useState(currentOverrides || {});

	const colorsToRender = colorMapping.colorList;

	return (
		<ModalOverlay open centerContent onClose={onClose}>
			{({ close }) => (
				<ModalV2 slick withFooter width="800px">
					<ModalHeaderV2 title="Edit colors" onClose={close} />
					<ModalContentV2>
						<Container
							sx={{
								display: 'grid',
								gridTemplateColumns: 'repeat(2, 1fr)',
								gap: '12px',
							}}
						>
							{colorsToRender.map(({ label, color }, i) => {
								return (
									<Stack key={i} direction="horizontal" alignItems="center">
										<Dropdown
											renderDropdownContent={({ close }) => (
												<presets.dropdown.DropdownContentFrame>
													<Text variant="largeStrong" color="neutral900" ml="small" my="xSmall">
														Pick a swatch
													</Text>
													<Container
														sx={{
															display: 'grid',
															gridTemplateColumns: 'repeat(7, 1fr)',
															gap: 'xSmall',
															px: 'small',
															pb: 'xSmall',
															bg: 'neutral000',
														}}
													>
														{[
															colors.red,
															colors.orangeDark,
															colors.orangeLight,
															colors.yellow,
															colors.green,
															colors.tiffany,
															colors.teal,
															colors.blueLight,
															colors.blue,
															colors.plum,
															colors.berries,
															colors.violet,
															colors.rosePink,
															colors.pink,
															colors.greyDefault,
														].map((colorArray) => {
															const colorString = toColorString(colorArray);
															return (
																<Container
																	key={colorString}
																	onClick={() => {
																		setColorOverrides({ ...colorOverrides, [label]: colorString });
																		close();
																	}}
																	sx={{
																		width: '36px',
																		height: '36px',
																		borderRadius: '8px',
																		backgroundColor: colorString,
																		cursor: 'pointer',
																		':hover': {
																			border: '2px solid ' + theme.colors.slate,
																		},
																	}}
																/>
															);
														})}
													</Container>
												</presets.dropdown.DropdownContentFrame>
											)}
										>
											{({ setRefElement, isOpen, setOpen }) => {
												return (
													<Button
														type="chromeless"
														size="small"
														withRightIcon={isOpen ? 'arrow-drop-up' : 'arrow-drop-down'}
														onClick={() => setOpen(!isOpen)}
														ref={setRefElement}
														style={{
															paddingLeft: Spacings.xSmall,
															border: '1px solid ' + theme.colors.neutral300,
														}}
													>
														<Container
															minWidth="16px"
															minHeight="16px"
															backgroundColor={colorOverrides[label] || toColorString(color)}
														/>
													</Button>
												);
											}}
										</Dropdown>

										<Text
											as="span"
											variant="medium"
											color="neutral600"
											sx={{
												wordBreak: 'break-word',
											}}
										>
											{label}
										</Text>
									</Stack>
								);
							})}
						</Container>
					</ModalContentV2>
					<ModalFooterV2>
						<Stack direction="horizontal" alignItems="center">
							<Button type="secondary" onClick={onClose}>
								Cancel
							</Button>
							<Button
								disabled={Object.keys(colorOverrides).length === 0}
								onClick={() => {
									onSave(colorOverrides);
								}}
							>
								Save configuration
							</Button>
						</Stack>
					</ModalFooterV2>
				</ModalV2>
			)}
		</ModalOverlay>
	);
}
