/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { TextInput } from '@steadybit/ui-components-lib';
import { ButtonIcon } from 'components/ButtonIcon';
import { Stack, Text, Tooltip } from 'components';
import { ReactElement, useState } from 'react';
import { IconEdit } from 'components/icons';

interface EditableLabelProps {
	theme?: 'light' | 'dark' | 'slate';
	minWidth?: string | number;
	maxWidth?: string | number;
	maxLength?: number | null;
	width?: string | number;
	withTooltip?: boolean;
	placeholder?: string;
	hasError?: boolean;
	disabled?: boolean;
	value: string;
	/**
	 * @deprecated Dont use this. This is only for the storybook
	 */
	isInitialEditing?: boolean;
	onChange?: (value: string) => void;
}

export default function EditableLabel({
	value,
	theme = 'dark',
	hasError,
	placeholder = 'Untitled',
	disabled,
	onChange,
	width,
	withTooltip,
	minWidth,
	maxWidth,
	maxLength,
	isInitialEditing,
}: EditableLabelProps): ReactElement {
	const color = theme === 'light' ? 'neutral000' : theme === 'dark' ? 'neutral700' : 'slate';
	const shadedColor = theme === 'light' ? 'neutral300' : theme === 'dark' ? 'neutral500' : 'purple500';
	let text = (
		<Text
			tx="heading"
			variant="medium"
			sx={{
				color: hasError ? 'coral' : value ? color : shadedColor,
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				marginTop: 1,
				whiteSpace: 'nowrap',
			}}
		>
			{value || placeholder}
		</Text>
	);

	if (withTooltip) {
		text = <Tooltip content={value}>{text}</Tooltip>;
	}

	const [isEditing, setIsEditing] = useState(isInitialEditing);
	if (!isEditing) {
		return (
			<Stack
				direction="horizontal"
				size="xxxSmall"
				sx={{
					alignItems: 'center',
					cursor: disabled ? undefined : 'pointer',
					height: 40,
				}}
				onClick={() => {
					if (!disabled) {
						setIsEditing(true);
					}
				}}
				width={width}
				minWidth={minWidth}
				maxWidth={maxWidth}
			>
				{!disabled && (
					<ButtonIcon variant="small" ml={1} minWidth={32}>
						<IconEdit color={color} />
					</ButtonIcon>
				)}
				{text}
			</Stack>
		);
	}

	return (
		<TextInput
			maxLength={maxLength === null ? undefined : (maxLength ?? 255)} // Provide null as a way to remove restriction, otherwise fall back to default value
			placeholder={placeholder}
			disabled={disabled}
			withLeftIcon="edit"
			value={value || ''}
			autoFocus
			onBlur={() => setIsEditing(false)}
			onChange={onChange}
			onKeyDown={(e) => {
				if (e.key === 'Enter') {
					setIsEditing(false);
				}
			}}
			style={{
				minWidth,
				maxWidth,
				width,
			}}
		/>
	);
}
