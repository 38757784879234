/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Button, ModalContentV2, ModalFooterV2, ModalHeaderV2, ModalV2, Snackbar } from 'components';
import { Form, Formik, FormikHelpers } from 'formik';
import { ValidationError } from 'utils/error';
import { Services } from 'services/services';
import { useHistory } from 'url/hooks';
import { ReactElement } from 'react';
import { set } from 'lodash';

import { InviteUserForm, InviteUserFormValues } from './components/inviteUserForm';

const InitialInviteUserFormValues: InviteUserFormValues = {
	invitations: [],
};

function InviteUser(): ReactElement {
	const history = useHistory();

	const handleSubmit = async (
		{ ...values }: InviteUserFormValues,
		form: FormikHelpers<InviteUserFormValues>,
	): Promise<void> => {
		try {
			await Services.users.inviteUsers({ invitations: values.invitations });
			Snackbar.dark('Invitation has been sent.', { toastId: 'user-invited' });
			history.replace('/settings/users');
		} catch (error) {
			if (error instanceof ValidationError) {
				error.violations.forEach((violation) => form.setFieldError(violation.field, violation.message));
			} else {
				Snackbar.error(error.toString(), { toastId: 'user-invited' });
			}
		}
	};

	return (
		<>
			<Formik
				initialValues={InitialInviteUserFormValues}
				validate={async (values) => {
					const errors = {};
					const violations = await Services.users.validateInviteUsersRequest(values);
					violations.forEach(({ field, message }) => set(errors, field, message));
					return errors;
				}}
				onSubmit={handleSubmit}
			>
				{({ isSubmitting, dirty, errors }) => (
					<>
						<Form noValidate>
							<ModalV2 withFooter>
								<ModalHeaderV2
									title="Invite Users"
									onClose={() => {
										history.goBack();
									}}
								/>
								<ModalContentV2>
									<InviteUserForm />
								</ModalContentV2>
								<ModalFooterV2>
									<Button
										id={'invite-user'}
										type={'submit'}
										disabled={!dirty || Object.keys(errors).length > 0}
										loading={isSubmitting}
										data-cy="send-invitation"
									>
										Send Invitation
									</Button>
								</ModalFooterV2>
							</ModalV2>
						</Form>
					</>
				)}
			</Formik>
		</>
	);
}

export default InviteUser;
