/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Colors, Pill, Text } from '@steadybit/ui-components-lib';
import { Tooltip } from 'components';
import { ReactElement } from 'react';
import { VariableVO } from 'ui-api';

interface VariablesProps {
	scope: 'environment' | 'experiment';
	variables: VariableVO[];
	withHelpText?: boolean;
	onVariableClick: (variableKey: string) => void;
}

export default function Variables({ scope, variables, onVariableClick }: VariablesProps): ReactElement | null {
	if (variables.length === 0) {
		return null;
	}

	return (
		<>
			{variables.map((variable) => {
				return (
					<Tooltip key={variable.key} content={variable.value}>
						<Pill
							onClick={() => onVariableClick(variable.key)}
							withLeftIcon={scope === 'environment' ? 'environment' : 'experiment'}
							style={{
								padding: '2px 6px 2px 6px',
								height: '24px',
								color: Colors.neutral800,
								backgroundColor: Colors.purple200,
								onHover: {
									backgroundColor: Colors.slateMidLight,
								},
							}}
						>
							<Text type="xSmallStrong" textEllipsis>
								{`{{${variable.key}}}`}
							</Text>
						</Pill>
					</Tooltip>
				);
			})}
		</>
	);
}
