/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import React, { ReactElement } from 'react';

export type TableProps = ContainerProps &
	Omit<React.TableHTMLAttributes<HTMLTableElement>, keyof ContainerProps> & {
		fixed?: boolean;
	};

export function Table({ fixed, variant = 'default', sx = {}, ...props }: TableProps): ReactElement {
	return (
		<Container
			as="table"
			tx="table"
			variant={variant}
			{...props}
			sx={{
				borderCollapse: 'collapse',
				tableLayout: fixed ? 'fixed' : 'auto',
				...sx,
			}}
		/>
	);
}
