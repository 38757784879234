/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { presets, SingleChoiceListItem } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';

interface StateSelectProps {
	value: string | null;
	onChange: (v: string | null) => void;
}

export default function StateSelect({ value, onChange }: StateSelectProps): ReactElement {
	const stateOptions: SingleChoiceListItem[] = [
		{ id: 'ALL', label: 'All' },
		{ id: 'COMPLETED', label: 'Completed' },
		{ id: 'FAILED', label: 'Failed' },
		{ id: 'ERRORED', label: 'Errored' },
		{ id: 'CANCELED', label: 'Canceled' },
	];

	return (
		<presets.dropdown.SingleChoiceButton
			selectedId={value || 'ALL'}
			items={stateOptions}
			size="small"
			onSelect={(id) => onChange?.(id === 'ALL' ? null : id)}
			style={{ width: '100%' }}
		>
			{stateOptions.find((a) => value === a.id)?.label ?? 'All'}
		</presets.dropdown.SingleChoiceButton>
	);
}
