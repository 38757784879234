/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { BasicStyleProperties } from 'components/types';
import { ReactElement } from 'react';
import { Heading } from 'components';

interface HeaderProps extends BasicStyleProperties {
	label: string;
	variant?: string;
}

export function Header({ label, variant = 'small', ...props }: HeaderProps): ReactElement {
	return (
		<Heading variant={variant} mb={'small'} {...props}>
			{label}
		</Heading>
	);
}
