/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { UrlParam } from 'url/useUrlState';

const pathSegment = '/fromTemplates';

export interface UrlState {
	kinds: string[];
	tags: string[];
	targetTypes: string[];
	actions: string[];
	freeTextPhrases: string[];
	includeNonPermitted: boolean;
	newExperimentTags: string[] | undefined;
	page: number;
	size: number;
	selectedTemplateId: string | null;
	useTemplateId: string | null;
	environmentIds: string[];
}

export const includeNonPermittedParam: UrlParam<boolean> = {
	pathSegment,
	name: 'includeNonPermitted',
	defaultValue: true,
};

export const pageParam: UrlParam<number> = {
	pathSegment,
	name: 'page',
	defaultValue: 0,
};

export const selectedTemplateIdParam: UrlParam<string | null> = {
	pathSegment,
	name: 'selectedTemplateId',
	defaultValue: null,
};

export const useTemplateIdParam: UrlParam<string | null> = {
	pathSegment,
	name: 'useTemplateId',
	defaultValue: null,
};

export const newExperimentTagsParam: UrlParam<string[] | undefined> = {
	pathSegment,
	name: 'newExperimentTags',
	defaultValue: undefined,
};
interface FilterParams {
	kindsParam: UrlParam<string[]>;
	freeTextPhrasesParam: UrlParam<string[]>;
	targetTypesParam: UrlParam<string[]>;
	actionsParam: UrlParam<string[]>;
	tagsParam: UrlParam<string[]>;
	pageParam: UrlParam<number>;
	pageSizeParam: UrlParam<number>;
	environmentIdParam: UrlParam<string[]>;
	includeNonPermittedParam: UrlParam<boolean>;
}

export function createFilterParams(pathSegment: string): FilterParams {
	const tagsParam: UrlParam<string[]> = {
		pathSegment,
		name: 'tags',
		defaultValue: [],
	};

	const targetTypesParam: UrlParam<string[]> = {
		pathSegment,
		name: 'targetTypes',
		defaultValue: [],
	};

	const kindsParam: UrlParam<string[]> = {
		pathSegment,
		name: 'kinds',
		defaultValue: [],
	};

	const actionsParam: UrlParam<string[]> = {
		pathSegment,
		name: 'actions',
		defaultValue: [],
	};

	const freeTextPhrasesParam: UrlParam<string[]> = {
		pathSegment,
		name: 'freeTextPhrases',
		defaultValue: [],
	};

	const environmentIdParam: UrlParam<string[]> = {
		pathSegment,
		name: 'environmentIds',
		defaultValue: [],
	};

	const pageParam: UrlParam<number> = {
		pathSegment,
		name: 'page',
		defaultValue: 0,
	};

	const pageSizeParam: UrlParam<number> = {
		pathSegment,
		name: 'size',
		defaultValue: 15,
	};

	const includeNonPermittedParam: UrlParam<boolean> = {
		pathSegment,
		name: 'includeNonPermitted',
		defaultValue: true,
	};

	return {
		includeNonPermittedParam,
		kindsParam,
		pageParam,
		environmentIdParam,
		pageSizeParam,
		tagsParam,
		targetTypesParam,
		actionsParam,
		freeTextPhrasesParam,
	};
}
