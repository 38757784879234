/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Container, LoadingIndicator, RouterButton, Stack, Text } from 'components';
import { AsyncState } from 'utils/hooks/useAsyncState';
import { GetAgentsPageResponse } from 'ui-api';
import { ReactElement } from 'react';

interface AgentLogLevelProps {
	agents: AsyncState<GetAgentsPageResponse | undefined>;
}

export default function AgentLogLevel({ agents }: AgentLogLevelProps): ReactElement {
	const isLoading = agents.loading && !agents;
	const numAgents = agents.value?.totalElements ?? 0;

	return (
		<Container>
			{isLoading && (
				<Stack alignItems="center" justifyContent="center">
					<LoadingIndicator variant="xLarge" color="slate" />
				</Stack>
			)}
			{agents.value && numAgents > 0 && !agents.error && (
				<Stack minHeight={'100%'} size={'large'}>
					<Stack direction={'horizontal'} size={'small'} alignItems={'center'}>
						{agents.value._actions.includes('changeLogLevel') ? (
							<RouterButton to={'/settings/agents/installed/loglevel'} variant={'secondary'}>
								<Text variant="mediumStrong" sx={{ whiteSpace: 'nowrap' }}>
									Change Log Levels
								</Text>
							</RouterButton>
						) : null}
					</Stack>
				</Stack>
			)}
		</Container>
	);
}
