/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import useChunkLUT from 'pages/experimentsV2/StepConfigurationSidebar/Fields/Controls/useChunkLUT';
import { ChunkLUT, Chunks } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';

interface ChunksWithPlaceholdersProps {
	value: string;
}

export default function ChunksWithPlaceholders({ value }: ChunksWithPlaceholdersProps): ReactElement {
	const chunkLUT: ChunkLUT = useChunkLUT();
	return <Chunks value={value} chunkLUT={chunkLUT} />;
}
