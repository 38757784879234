/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Container, Stack, Text, Tooltip } from 'components';
import { ReactElement, ReactNode } from 'react';
import { theme } from 'styles.v2/theme';

interface CardProps {
	children: ReactElement;
	description?: string;
	cypressTag?: string;
	tooltip?: ReactNode;
	disabled?: boolean;
	title: string;
	onClick?: () => void;
}

export default function Card({
	title,
	description,
	tooltip,
	disabled,
	children,
	cypressTag,
	onClick,
}: CardProps): ReactElement {
	return (
		<Tooltip content={tooltip}>
			<Container
				data-cy={cypressTag}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'space-between',

					width: '216px',
					height: '258px',
					py: 'medium',

					borderRadius: '8px',
					border: '2px solid ' + theme.colors.neutral200,
					color: 'neutral700',
					opacity: disabled ? 0.5 : 1,

					...(onClick
						? {
								'&:hover': {
									backgroundColor: 'purple100',
									border: '2px solid ' + theme.colors.slate,
									color: 'slate',
									cursor: 'pointer',
								},
							}
						: {}),
				}}
				onClick={onClick}
			>
				{children}

				<Stack size="xxSmall" justifyContent="center" mx="small">
					<Text variant="mediumStrong" textAlign="center">
						{title}
					</Text>
					{description && (
						<Text variant="xSmall" textAlign="center">
							{description}
						</Text>
					)}
				</Stack>
			</Container>
		</Tooltip>
	);
}
