/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { Flex } from '@steadybit/ui-components-lib';
import { IconUser } from 'components/icons';
import { Image, Tooltip } from 'components';
import { ReactElement } from 'react';

type IconSize = 'small' | 'medium' | 'large';

type UserIconProps = {
	stacked?: boolean;
	tooltip?: string;
	size?: IconSize;
	src?: string;
};

export default function UserIcon({ src, size = 'small', tooltip, stacked }: UserIconProps): ReactElement {
	const sizeInPx = size === 'small' ? '24px' : size === 'medium' ? '32px' : '40px';

	if (src) {
		return (
			<Tooltip content={tooltip ? <span data-private>{tooltip}</span> : undefined}>
				<Image
					data-private
					tx="userIcon"
					src={src}
					sx={{
						marginLeft: stacked ? '-8px' : undefined,
						minWidth: sizeInPx,
						minHeight: sizeInPx,
						maxWidth: sizeInPx,
						maxHeight: sizeInPx,
						boxSizing: 'content-box',
						borderRadius: '50%',
					}}
				/>
			</Tooltip>
		);
	}

	return (
		<Tooltip content={tooltip ? <span data-private>{tooltip}</span> : undefined}>
			<Flex
				align="center"
				justify="center"
				style={{
					marginLeft: stacked ? '-8px' : undefined,
					minWidth: sizeInPx,
					minHeight: sizeInPx,
					maxWidth: sizeInPx,
					maxHeight: sizeInPx,

					backgroundColor: '#2a81a6',
					borderRadius: 'large',
				}}
				data-private
			>
				<IconUser color="neutral000" />
			</Flex>
		</Tooltip>
	);
}
