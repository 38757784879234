/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import React, { ReactElement } from 'react';

export type TableRowProps = ContainerProps &
	Omit<React.HTMLAttributes<HTMLTableRowElement>, keyof ContainerProps> & {
		hoverable?: boolean;
	};

export const SX_HOVERABLE = {
	'& a': { color: 'neutral800' },
	'& a svg': { color: 'neutral400' },
	':hover': {
		backgroundColor: 'neutral100',
		'& a': { textDecoration: 'underline', color: 'slate' },
		'& a svg': { color: 'slate' },
	},
};

export function TableRow({ hoverable, sx = {}, ...props }: TableRowProps): ReactElement {
	const sxHover = hoverable ? SX_HOVERABLE : {};
	return <Container as="tr" sx={{ ...sxHover, ...sx }} {...props} />;
}
