/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { BasicStyleProperties, InteractionTypes, resolveTxAndVariant } from 'components/types';
import React, { ReactElement, ReactNode } from 'react';
import { resolveTo } from 'url/Redirect';
import { useHistory } from 'url/hooks';
import { Link } from 'theme-ui';

export interface UnstyledLinkProps extends BasicStyleProperties, InteractionTypes<HTMLAnchorElement | HTMLLinkElement> {
	rel?: string;
	target?: '_blank';
	href?: string;
	to?: string;

	display?: 'inline';
	children?: ReactNode | string;
	fontSize?: string | number;
	fontWeight?: string | number;
	lineHeight?: string | number;
	muted?: boolean;
	disabled?: boolean;
	external?: boolean;

	tx?: string;
	variant?: string;
	as?: React.ElementType;
	dontResolve?: boolean;
}

export function UnstyledLink({
	children,
	variant,
	tx,
	external,
	href,
	to,
	rel,
	target,
	...props
}: UnstyledLinkProps): ReactElement {
	if (external) {
		rel = `${rel || ''} noopener noreferrer`;
		target = target ?? '_blank';
	}

	const history = useHistory();
	const resolvedHref = props.dontResolve ? to || href : resolveTo(history, to || href).href;

	return (
		<Link
			rel={rel}
			target={target}
			href={resolvedHref || undefined}
			variant={resolveTxAndVariant(tx, variant)}
			{...props}
		>
			{children}
		</Link>
	);
}
