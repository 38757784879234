/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import React, { ReactElement } from 'react';
import { motion } from 'framer-motion';

import { TabContext } from './Tabs';

type TabPanelOwnProps = {
	value: string;
};

export type TabPanelProps = TabPanelOwnProps & Omit<ContainerProps, 'css' | keyof TabPanelOwnProps>;

const animate = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	exit: { opacity: 0 },
	transition: { duration: 0.3 },
};

export function TabPanel({ value, children, ...props }: TabPanelProps): ReactElement | null {
	const context = React.useContext(TabContext);
	const selected = context.value === value;

	if (!selected) {
		return null;
	}

	return (
		<motion.div {...animate} style={{ willChange: 'opacity, transform' }}>
			<Container {...props}>{children}</Container>
		</motion.div>
	);
}
