/*
 * Copyright 2020 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps } from 'components';
import React, { ReactElement } from 'react';

export type TableBodyProps = ContainerProps & Omit<React.HTMLAttributes<HTMLTableSectionElement>, keyof ContainerProps>;

export function TableBody(props: TableBodyProps): ReactElement {
	return <Container as="tbody" {...props} />;
}
