/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { ExecutionLogEventVO, ExperimentExecutionVO, LogWidgetVO } from 'ui-api';
import { ExperimentPlayerTimeStamp } from 'components/ExperimentPlayer/types';
import { Container, Stack, Text, TextProps, Tooltip } from 'components';
import { useExecutionLogs } from 'services/useExecutionLogs';
import { formatTime, formatTimestamp } from 'utils/dateFns';
import { Fragment, ReactElement } from 'react';
import { theme } from 'styles.v2/theme';

import { ExperimentRunLogsAndMetricsProps } from '../../experimentExecutionLogsAndMetrics';
import LoadingContent from '../../metrics/LoadingContent';
import EmptyContent from '../../metrics/EmptyContent';
import { EventLog } from './EventLog';

interface LogCardPresenterProps extends Pick<ExperimentRunLogsAndMetricsProps, 'position' | 'start' | 'duration'> {
	widget: LogWidgetVO;
	experimentExecutionId: number;
	onPositionSelect: (position: ExperimentPlayerTimeStamp | null) => void;
	experimentExecution: ExperimentExecutionVO;
	selectedLogLevel?: string[];
	setAvailableLogLevel?: (availableLogLevel: string[]) => void;
	filter?: string;
}

export default function LogCardPresenter({
	position,
	start,
	duration,
	widget,
	experimentExecutionId,
	experimentExecution,
	onPositionSelect,
	selectedLogLevel,
	setAvailableLogLevel,
	filter,
}: LogCardPresenterProps): ReactElement {
	const logWidget = widget as LogWidgetVO;
	const liveUpdate = !experimentExecution.ended;
	const { result: logEvents } = useExecutionLogs({
		executionId: experimentExecutionId,
		type: logWidget.logType,
		id: undefined,
		liveUpdate,
	});
	if (!logEvents.value?.length) {
		return (
			<Stack size={0} sx={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}>
				{liveUpdate || logEvents.loading ? (
					<LoadingContent loading={logEvents.loading}>Waiting for events...</LoadingContent>
				) : (
					<EmptyContent>No Events recorded.</EmptyContent>
				)}
			</Stack>
		);
	}

	const fragment = (idx: number, event: ExecutionLogEventVO, indexBeforePosition: number | null): ReactElement => (
		<Fragment key={idx}>
			<Tooltip color={'light'} content={() => <EventTooltip event={event} />}>
				<Container
					height={24}
					data-log
					sx={{
						opacity: idx > (indexBeforePosition ?? Number.MAX_SAFE_INTEGER) ? 0.5 : 1,
						display: 'grid',
						gridTemplateColumns: event.fields?.reason ? '4rem 4rem 6rem 1fr' : '4rem 4rem 1fr',
						columnGap: 'xSmall',
						':hover': {
							bg: 'neutral100',
						},
						cursor: 'pointer',
					}}
					onClick={() => onPositionSelect(event?.timestamp?.getTime())}
				>
					<span>{formatTime(event.timestamp)}</span>
					<Text as={'span'} variant={'inherit'} {...getStyle(event.level)}>
						{event.level}
					</Text>
					{event.fields?.reason && <span>{event.fields?.reason}</span>}
					{/* eslint-disable-next-line react/no-danger*/}
					<span dangerouslySetInnerHTML={{ __html: event.message }} />
				</Container>
			</Tooltip>
			{indexBeforePosition === idx && (
				<Container height={24} sx={{ borderTop: '2px solid ' + theme.colors.slateMid }} />
			)}
		</Fragment>
	);

	return (
		<EventLog
			type={logWidget.logType}
			actionId={undefined}
			state={experimentExecution.state}
			reason={experimentExecution.reason}
			position={position}
			onPositionSelect={onPositionSelect}
			experimentExecution={experimentExecution}
			fragment={fragment}
			start={start}
			duration={duration}
			selectedLogLevel={selectedLogLevel}
			setAvailableLogLevel={setAvailableLogLevel}
			filter={filter}
		/>
	);
}

function EventTooltip({ event }: { event: ExecutionLogEventVO }): ReactElement {
	return (
		<Container
			sx={{
				display: 'grid',
				gridTemplateColumns: '1fr 4fr',
				fontWeight: '300',
				fontFamily: 'code',
				width: 640,
				p: 'xSmall',
			}}
		>
			<span>Timestamp</span>
			<span>{formatTimestamp(event.timestamp)}</span>
			<span>Level</span>
			<Text as={'span'} variant={'inherit'} {...getStyle(event.level)}>
				{event.level}
			</Text>
			{event.fields && (
				<>
					{Object.entries(event.fields).map(([key, value]) => (
						<>
							<span>{key}</span>
							<span>{value}</span>
						</>
					))}
				</>
			)}
			<span>Message</span>
			<span dangerouslySetInnerHTML={{ __html: event.message }} />
		</Container>
	);
}

function getStyle(level: string): TextProps {
	switch (level) {
		case 'Warning':
			return { color: 'experimentWarning', fontFamily: 'code' };
		default:
			return { color: 'neutral600' };
	}
}
