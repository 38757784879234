/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { ReactElement, ReactNode } from 'react';

import { CheckContainerProps, Container, Label } from '..';
import { Checkbox, CheckboxChecked } from './Checkbox';

interface FormCheckboxOwnProps {
	label: string | ReactNode;
	error?: string;
	touched?: boolean;
	checked?: CheckboxChecked;
	disabled?: boolean;
}

export type FormCheckContainerProps = FormCheckboxOwnProps & Omit<CheckContainerProps, keyof FormCheckboxOwnProps>;

export function FormCheckbox({
	label,
	error,
	touched,
	disabled,
	flex,
	my,
	mx,
	mt,
	mb,
	ml,
	mr,
	sx,
	checked,
	ref,
	...props
}: FormCheckContainerProps): ReactElement {
	const hasError = Boolean(touched && error);

	return (
		<Container
			display={'flex'}
			flexDirection={'column'}
			my={my}
			mx={mx}
			mt={mt}
			mb={mb}
			ml={ml}
			mr={mr}
			flex={flex}
			sx={sx}
		>
			<Label
				sx={{
					display: 'flex',
					alignItems: 'center',
					color: disabled ? 'neutral500' : 'neutral800',
					pointerEvents: disabled ? 'none' : 'initial',
				}}
			>
				<Checkbox
					ref={ref}
					mr={'xSmall'}
					hasError={hasError}
					checked={checked}
					inputProps={props}
					disabled={disabled}
					{...props}
				/>
				{label}
			</Label>
		</Container>
	);
}
