/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, ModalOverlay, Tooltip } from 'components';
import { CustomPromptBlockPredicate, useCustomPrompt } from 'utils/hooks/useCustomPrompt';
import { ReactElement } from 'react';

interface DidNotSavePromptProps {
	saveDisabledBecauseOf?: string;
	entityTitle: string;
	when: boolean;
	onSaveCallback: () => unknown | null;
	block: CustomPromptBlockPredicate;
}
export function DidNotSavePrompt({
	saveDisabledBecauseOf,
	entityTitle,
	when,
	onSaveCallback,
	block,
}: DidNotSavePromptProps): ReactElement {
	const { showPrompt, resolve } = useCustomPrompt(when, block);

	return (
		<ModalOverlay open={showPrompt} onClose={() => resolve(false)}>
			<Dialog>
				<DialogHeader title={'Are you sure?'} onClose={() => resolve(false)} variant={'medium'} />
				<DialogContent>Do you want to keep changes in this {entityTitle}?</DialogContent>
				<DialogFooter>
					<Button variant={'secondary'} onClick={() => resolve(true)}>
						Discard changes
					</Button>
					<Button ml={'auto'} variant={'secondary'} onClick={() => resolve(false)}>
						Cancel
					</Button>
					<Tooltip content={saveDisabledBecauseOf}>
						<Button
							disabled={!!saveDisabledBecauseOf}
							ml={'xSmall'}
							variant={'primary'}
							onClick={async () => {
								const result = await onSaveCallback();
								if (result == null) {
									resolve(false);
								} else {
									resolve(true);
								}
							}}
						>
							Save
						</Button>
					</Tooltip>
				</DialogFooter>
			</Dialog>
		</ModalOverlay>
	);
}
